<template>
  <input-wrapper sm="12" md="12" v-show="show">
    <h5
      class="text--primary font-weight-bold pa-1"
      :class="{'text--primary': rules.length > 0}"
    >
      {{ label }}
    </h5>
    <html-editor
      v-model="value"
      :disabled="boxProbs.editmode && readonly"
      :readonly="!boxProbs.editmode"
      :error-messages="rules"
      :loading="loading"
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import HtmlEditor from "./HTMLEditor/HtmlEditor.vue";
import textInputMixin from "../../../mixins/TextInputMixin.js";

export default {
  name: "HtmlInput",
  path: "components.edit.edit_box_children.html_input",
  components: {HtmlEditor, InputWrapper},
  mixins: [textInputMixin],
};
</script>

<style scoped>

</style>

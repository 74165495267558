<template>
  <div>
    <template v-if="!regmask_error">
      <v-progress-linear :value="loadingProgress" v-if="loadingProgress < 100"/>
      <div
        v-if="regmask.loaded"
        :key="loadRegmaskFor.id"
      >
        <p v-html="regmask.header" />
        <reg-mask-body
          :open-sections.sync="open_sections"
        >
          <reg-mask-section
            v-for="(section, index) in sections"
            :key="`${section.title}-${index}`"
            :section="section"
            :valid.sync="valid_sections[index]"
          />
          <v-btn
            block
            color="primary"
            class="mt-6"
            :disabled="!all_sections_valid || !loaded || !all_sections_loaded"
            @click="saveRegmask"
            :loading="saving"
            style="cursor: pointer"
          >
            {{ $t("finish_registration") }}
            <v-icon
              right
              dark
            >
              mdi-email-send
            </v-icon>
          </v-btn>
        </reg-mask-body>
        <p v-html="regmask.header" />
      </div>
      <reg-mask-placeholder v-else />
    </template>
    <success-alert v-model="save_success" :message="successMessage || $t('save_success')"/>
    <error-alert v-model="save_error" :message="errorMessage || $t('save_error')"/>
    <error-alert v-model="regmask_error" :message="regmask_error_message"/>
  </div>
</template>

<script>
import RegMaskBody from "./RegMaskBody.vue";
import RegMaskSection from "./RegMaskSection.vue";
import SuccessAlert from "../Alerts/SuccessAlert.vue";
import ErrorAlert from "../Alerts/ErrorAlert.vue";
import RegMaskPlaceholder from "./RegMaskPlaceholder.vue";

export default {
  name: "RegMask",
  path: "components.reg_mask.reg_mask",
  components: {
    RegMaskPlaceholder,
    ErrorAlert,
    SuccessAlert,
    RegMaskSection,
    RegMaskBody,
  },
  props: {
    loadRegmaskFor: { type: Object, required: true },
    loadingProgress: { type: Number, default: 0 }, // 0-20 is controlled by Parent, 25-100 by RegMask
    successMessage: { type: String, required: false, default: "" },
    errorMessage: { type: String, required: false, default: "" },
  },
  data: () => ({
    regmask: {
      active: false,
      loaded: false,
      structure: {
        default_section: null,
        sections: [],
      },
    },
    default_section: null,
    sections: [],
    valid_sections: [],
    open_sections: [],
    saving: false,
    save_error: false,
    save_success: false,

    regmask_error: false,
    regmask_error_message: "",
  }),
  watch: {
    "loadRegmaskFor.id": {
      handler(loadForId) {
        if (loadForId) {
          this.loadRegMask();
        }
      },
      immediate: true,
    },
  },
  computed: {
    loaded() {
      return this.regmask?.loaded;
    },
    all_sections_valid() {
      return this.valid_sections.every((val) => val);
    },
    all_sections_loaded() {
      return this.sections.every((sec) => sec.loaded);
    },
  },
  methods: {
    loadRegMask() {
      if (this.loaded) { return; }
      const base_percentage = 25;
      this.$emit("update:loading-progress", 20);
      this.loadRegmaskFor.RegMaskPlugin.loadSequential().then(([default_section_groups_options_pl, sections_groups_pl]) => {
        this.$emit("update:loading-progress", base_percentage);
        this.regmask = this.loadRegmaskFor.RegMaskPlugin;

        if (!this.regmask.structure.sections) {
          this.regmask_error = true;
          this.regmask_error_message = this.$t("regmask_error.no_sections");
          return;
        }

        this.initEmptySections();
        this.initValidSections();
        this.initRegmaskOpenStates();

        Promise.all(default_section_groups_options_pl).then(() => {
          this.default_section = this.regmask.structure.default_section;
        });
        Promise.all(sections_groups_pl).then(() => {
          this.$emit("update:loading-progress", 100);
        });

        sections_groups_pl.forEach((section_promise, i) => {
          section_promise.then(() => {
            const progress = Math.ceil(base_percentage + ((100 - base_percentage) / sections_groups_pl.length) * (i + 1));
            this.$emit("update:loading-progress", progress);
            this.$set(this.sections, i, this.regmask.structure.sections[i]);
          });
        });
      })
        .catch((error) => {
          this.regmask_error = true;
          this.regmask_error_message = `${this.$t("regmask_error.unknown")} ${error}`;
        });
    },
    initValidSections() {
      this.valid_sections = this.regmask.structure.sections.map(() => false);
    },
    initEmptySections() {
      this.sections = this.regmask.structure.sections.map((section) => ({...section}));
    },
    initRegmaskOpenStates() {
      this.open_sections = this.regmask.structure.sections
        .map((section, index) => ({open: !!section.default_open, index}))
        .filter((section) => section.open)
        .map((section) => section.index);
    },
    saveRegmask() {
      this.saving = true;
      this.regmask.submit()
        .then(() => {
          this.save_success = true;
          this.$emit("saved");
        })
        .catch(() => {
          this.save_error = true;
          this.$emit("error");
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
  i18n: {
    messages: {
      de: {
        finish_registration: "Anmeldung abschliessen",
        save_success: "Ihre Anmeldung wurde gespeichert!",
        save_error: "Beim Speichern der Anmeldung ist etwas schief gegangen. Bitte versuchen Sie es erneut.",
        regmask_error: {
          no_sections: "Diese RegMask ist falsch parametrisiert: Es wurden keine Sektionen gefunden!",
          unknown: "Ein unbekannter RegMask Fehler ist aufgetreten:",
        },
      },
      fr: {
        finish_registration: "Terminer l'inscription",
        save_success: "Votre inscription a été enregistrée!",
        save_error: "Une erreur s'est produite lors de l'enregistrement de l'enregistrement. Veuillez réessayer.",
        regmask_error: {
          no_sections: "Ce RegMask n'est pas paramétré correctement: aucune section n'a été trouvée!",
          unknown: "Une erreur RegMask inconnue s'est produite:",
        },
      },
    },
  },
};
</script>

<template>
  <p>
    RMCheckBox
  </p>
</template>

<script>
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMCheckBox",
  path: "components.reg_mask.reg_mask_field.r_m_check_box",
  mixins: [RegMaskFieldMixin],
};
</script>

<style scoped>

</style>

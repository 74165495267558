<template>
    <v-expansion-panel
      :disabled="!section.collapsible"
    >
      <v-expansion-panel-header>
        {{ section.title }}
        <template slot="actions" v-if="!section.loaded">
          <v-progress-circular
            indeterminate
            size="18"
            width="2"
            color="primary"
          />
        </template>
        <template slot="actions" v-else-if="all_items_valid">
          <v-icon color="success">
            mdi-check
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager class="pt-3">
        <v-form
          v-if="section.loaded"
        >
          <p v-html="section.header" />
          <r-m-item-resolver
            v-for="(group, index) in groups"
            :key="`${group.title}-${index}`"
            :group="group"
            :valid.sync="valid_items[index]"
          />
          <p v-html="section.footer" />
        </v-form>
        <v-skeleton-loader
          v-else
          type="article, actions"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import RMItemResolver from "./RegMaskField/Resolver/RMItemResolver.vue";

export default {
  name: "RegMaskSection",
  path: "components.reg_mask.reg_mask_section",
  components: {RMItemResolver},
  props: {
    section: { type: Object, required: true },
    valid: { type: Boolean, required: true },
  },
  data: () => ({
    valid_items: [],
  }),
  computed: {
    all_items_valid() {
      return this.valid_items.every((val) => val);
    },
    groups() {
      return this.section.groups;
    },
  },
  watch: {
    all_items_valid: {
      handler(valid) {
        this.$emit("update:valid", valid);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <input-wrapper>
    <v-autocomplete
      v-model="value"
      :items="addresses"
      item-text="last_name"
      item-value="short_id"
      :label="label"
      auto-select-first
      :readonly="!boxProbs.editmode"
      :loading="loading || addresses.length === 0"
      :disabled="boxProbs.editmode && readonly"
      placeholder=" "
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import InputMixin from "../../../mixins/InputMixin.js";

export default {
  name: "AddressIdInput",
  path: "components.edit.edit_box_children.address_id_input",
  components: {InputWrapper},
  mixins: [InputMixin],
  props: {
    selectableTypeNumbers: { type: Array, required: true },
  },
  data: () => ({
    addresses: [],
  }),
  created() {
    this.$bom.addresses.all
      .thatAreInvolvedInRelationOfType(...this.selectableTypeNumbers)
      .fetch()
      .then(({addresses}) => {
        this.addresses = addresses.map((obj) => ({...obj, short_id: obj.id.split("#")[0]}));
      });
  },
};
</script>

<style scoped>

</style>

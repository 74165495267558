<template>
  <v-menu
    bottom
    left
    :offset-y="true"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-school</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group
        :value="selected_index"
        color="primary"
      >
        <v-list-item
          v-for="client in clients"
          :key="client.client_id"
          @click="switchTo(client)"
        >
          <v-list-item-title
            class="clickable-list-item"
          >
            {{ client.client_name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

/**
 * The ClientSwitch component is shown on the right side of the topbar. It is a simple dropdown
 * that displays all available clients for the currently logged-in user.
 * When one client is selected, the client is switched using the user store module.
 */
export default {
  name: "ClientSwitch",
  path: "components.navigation.topbar_components.client_switch",
  data() {
    return {
      selected_index: undefined, // Index that is selected in the client-dropdown
    };
  },
  watch: {
    /**
     * Watch when the client (from the user store module) changes and calculate the new index
     * for the dropdown
     */
    client: {
      handler() {
        if (!this.client) { return; }
        this.selected_index = this.clients
          .map((client) => client.client_id)
          .indexOf(this.client.client_id);
        this.$router.replace({
          params: {
            ...this.$route.params,
            client: this.client.client_id,
          },
        });
      },
      immediate: true,
    },
  },
  /**
   * Map the available clients and the currenlty selected client from the user store module
   * to local computed properties
   */
  computed: {
    ...mapGetters("user", {
      clients: "getValidClients",
      client: "getClient",
    }),
  },
  methods: {
    /**
     * Map the switch client action from the user store module to a local method
     */
    ...mapActions("user", {
      switchClient: "switchClient",
    }),
    /**
     * Map the loadAndBlock and unblock methods from the global store module to local methods
     */
    ...mapActions("global", {
      loadAndBlock: "loadAndBlock",
      unblock: "unblock",
    }),
    /**
     * Block the whole application using the global stores "loadAndBlock" functionality, switch
     * the client using a store action, then unblock the application again.
     * @param client
     */
    switchTo(client) {
      this.loadAndBlock(this.$t("wait", {name: client.client_name}));
      this.switchClient(client)
        .then(() => {
          this.unblock();
        });
    },
  },
  i18n: {
    messages: {
      de: {
        wait: "Organisation wird geändert zu '{name}'...",
      },
      fr: {
        wait: "L'organisation est remplacée par '{name}'...",
      },
    },
  },
};
</script>

<style scoped>

</style>

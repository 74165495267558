<template>
  <fragment>
    <card-group
      :loading="loadingItems"
      :loading-message="$t('loading_message')"
      :empty="items.length === 0"
      :empty-message="$t('empty_message')"
    >
      <slot :items="items"></slot>
    </card-group>
    <v-btn
      dark
      color="primary"
      class="add-button"
      @click="loadMoreOnClick"
      :loading="loadingItems"
    >
      {{$t('more')}}
    </v-btn>
  </fragment>
</template>

<script>

import CardGroup from "../Cards/CardGroup.vue";

/**
 * The TagsWithLoadingButton component always shows the two most recent items of the specific tag,
 * with the plus button, two more tags of the same specific tag can be loaded.
 */

export default {
  name: "TagsWithLoadingButton",
  path: "components.search_mechanisms.tags_with_loading_button",
  components: {
    CardGroup,
  },
  props: {
    selectedTag: {
      type: Object,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
    objectType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchItems();
  },
  data: () => ({
    loadingItems: false,
    items: [],
    totalNumberOfItems: undefined,
  }),
  methods: {
    fetchItems() {
      this.loadingItems = true;
      this.$bom[this.module][this.objectType]
        .all
        .skip(this.items.length)
        .sortedBy("from")
        .descending() // because we want the newest item on top
        .take(2)
        .including("TagsPlugin")
        .withTags(this.selectedTag.number)
        .fetch()
        .then((data) => {
          const results = data[this.objectType];
          this.totalNumberOfItems = data.items_in_database;
          this.items = this.items.concat(results);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingItems = false; // put it as false so that it will not load anymore items
        });
    },
    loadMoreOnClick() {
      if (!this.loadingItems && this.items.length < this.totalNumberOfItems) {
        this.fetchItems();
      }
    },
  },
  i18n: {
    messages: {
      de: {
        loading_message: "Daten werden geladen",
        empty_message: "Keine Daten gefunden",
        more: "Weitere",
      },
      fr: {
        loading_message: "Les données sont en cours de chargement",
        empty_message: "Pas de données trouvées",
        more: "autres",
      },
    },
  },
};
</script>

<style scoped>
.add-button {
  margin: 0 auto;
  display: block;
}
</style>

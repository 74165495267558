<template>
  <v-card
    :elevation="0"
    @click="cardClicked"
    :ripple="!!action"
  >
    <v-card-text class="py-2">
      <h4>{{title}}</h4>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>

/**
 * This component is a plane card. First line it shows the persons name
 * and on the second line a slot in which subcomponents can be inserted.
 * As an input property, the plane card specifies an "action" function. It will be executed
 * once the user clicks on the card. This can be used to route to a different page once the user
 * clicks on the card.
 */
export default {
  name: "DetailsPlaneCard",
  path: "components.cards.details_plane_card",
  props: {
    // Title that is displayed on the card
    title: {type: String, required: true},
    // Function that is executed once the plane card is clicked
    action: {type: Function, default: undefined},
  },
  methods: {
    /**
     * When the card gets clicked, check if the user provided an action function. If he did,
     * execute the given action.
     */
    cardClicked() {
      if (this.action) {
        this.action();
      }
    },
  },
};
</script>

<style scoped>

</style>

import Vue from "vue";
import VueRouter from "vue-router";

// Here we import all pages
import {BECOME_MENTOR} from "@/constants/MENTOR.js";
import i18n from "../languages/i18n.js";
import FormDetails from "../pages/forms/details/FormDetails.vue";
import Forms from "../pages/forms/Forms.vue";
import Liability from "../pages/qualifications/Liability.vue";
import PracticePermit from "../pages/qualifications/PracticePermit.vue";
import WorkPsychology from "../pages/qualifications/WorkPsychology.vue";
import CareerPsychology from "../pages/qualifications/CareerPsychology.vue";
import SchoolPsychology from "../pages/qualifications/SchoolPsychology.vue";
import ClinicalPsychology from "../pages/qualifications/ClinicalPsychology.vue";
import SubjectAreas from "../pages/qualifications/SubjectAreas.vue";
import RegisteredEvents from "../pages/events/RegisteredEvents.vue";
import OfferDetails from "../pages/marketplace/details/OfferDetails.vue";
import ArticleDetails from "../pages/news/details/ArticleDetails.vue";
import MarketPlace from "../pages/marketplace/MarketPlace.vue";
import AcademiesSearch from "../pages/marketplace/AcademiesSearch.vue";
import AcademiesDetails from "../pages/marketplace/details/AcademiesDetails.vue";
import News from "../pages/news/News.vue";
import EventSearch from "../pages/events/EventSearch.vue";
import EventDetails from "../pages/events/details/EventDetails.vue";
import Home from "../pages/Home.vue";
import Login from "../pages/presteps/Login.vue";
import ClientChooser from "../pages/presteps/ClientChooser.vue";
import Profile from "../pages/profile/Profile.vue";
import ProfileRO from "../pages/profileRO/ProfileRO.vue";
import Subpage from "../pages/Subpage.vue";
import NotFound from "../pages/NotFound.vue";
import PersonalAddress from "../pages/address/person/PersonalAddress.vue";
import CompanyAddress from "../pages/address/company/CompanyAddress.vue";
import Studies from "../pages/studies/Studies.vue";
import FurtherEducation from "../pages/further_education/FurtherEducation.vue";
import Education from "../pages/education/Education.vue";
import Communication from "../pages/communication/Communication.vue";
import Settings from "../pages/settings/Settings.vue";
import NotedContacts from "../pages/contacts/NotedContacts.vue";
import SavedContacts from "../pages/contacts/SavedContacts.vue";
import WhoswhoSearch from "../pages/whoswho/WhoswhoSearch.vue";
import BecomeMentor from "../pages/mentoring/BecomeMentor.vue";
import EventRegistration from "../pages/events/components/EventRegistration.vue";
import RegMaskTest from "../pages/RegMaskTest.vue";
import SearchMentor from "../pages/mentoring/SearchMentor.vue";
import MentorDetails from "../pages/mentoring/MentorDetails/MentorDetails.vue";
import MentorsMentorings from "../pages/mentoring/MentorsMentorings.vue";
import MenteesMentorings from "../pages/mentoring/MenteesMentorings.vue";
import PendingMentorRegistration from "../pages/mentoring/PendingMentorRegistration.vue";
import MentoringDetails from "../pages/mentoring/MentoringDetails/MentoringDetails.vue";
import ApplyToMentor from "../pages/mentoring/ApplyToMentor.vue";

import store from "../store/index.js";
import Impressum from "../pages/impressum/Impressum.vue";
import StudiesRO from "../pages/studiesRO/StudiesRO.vue";

// We register the router for our Vue application
Vue.use(VueRouter);

/**
 * The routes are a list of possible pages that the user can navigate to. Each page has a path
 * that will be shown in the browsers URL bar, a name (used internally for routing) and the
 * corresponding VUE component that is shown when this page is active. Meta data can help us
 * defining optional stuff like the transition animation that shall be shown when navigating
 * to this route.
 * We can also define child routes. This means that all sub-routes will have a consecutive path
 * (e.g. if parent has path "/page" and child has path "studies", the resulting URL for the child
 * is "/page/studies". In a parent component, we must specify a <router-view> into which all subroutes
 * will be inserted. The parent component will still be rendered on child-routes, but inside of him
 * the childs page will be rendered as well. We used this mechanism to build the subpages: The Subpage.vue
 * component implements some stuff that is only shown for subpages, like a back-button.
 * For the people here that are familiar with Nuxt.js: This is the raw concept behind "layouts".
 * Whenver you see a this.$router.push({name: "XXX"}) you must specify some rout with {name: "XXX"}
 * here in the router.
 * Routes can have path variables, such as the route with name "person-address". It has the
 * path "address/person/:id" which means that, in order to route to this route, you must provide
 * a parameter "id" as follows:this.$router.push({name: "XXX", params: {id: "Y"}}). The ID parameter
 * will then be visible within the URL. This has the advantage that you can save this URL and if you
 * go directly to this URL some other day, the page still knows what data to load (in this case,
 * which address).
 */
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {transition: "fade-in-left"},
  },
  {
    path: "/redirect/fhnews",
    name: "fhnews",
    component: Home,
    beforeEnter() {
      window.open(i18n.t("links.fhnews"));
    },
  },
  {
    path: "/redirect/fhmaster",
    name: "fhmaster",
    component: Home,
    beforeEnter() {
      window.open(i18n.t("links.fhmaster"));
    },
  },
  {
    path: "/redirect/fhjobs",
    name: "fhjobs",
    component: Home,
    beforeEnter() {
      window.open(i18n.t("links.fhjobs"));
    },
  },
  {
    path: "/redirect/fhlohn",
    name: "fhlohn",
    component: Home,
    beforeEnter() {
      window.open(i18n.t("links.fhlohn"));
    },
  },
  {
    path: "/redirect/mentoring-agreement",
    name: "mentoringAgreement",
    component: Home,
    beforeEnter() {
      window.open("https://www.fhschweiz.ch/customer/files/3285/20-11-12-Mentoring-Vereinbarung_mit-Feldern.pdf");
    },
  },
  {
    path: "/redirect/administration",
    name: "administration",
    component: Home,
    beforeEnter() {
      const mail = store.getters["config/getMail"];
      window.location.href = `mailto:${mail}`;
    },
  },
  {
    path: "/redirect/alumni",
    name: "alumni",
    component: Home,
    beforeEnter() {
      // const lang = store.getters["language/getActiveLanguage"];
      let lang = "de";
      const active_lang = localStorage.getItem("bom_lang");
      if (active_lang) {
        lang = JSON.parse(active_lang).code;
      }
      const client = store.getters["user/getClient"];
      const file = `${window.bomconfig.url_alumni_ausweis}#/${client.client_id}/${lang}`;
      window.open(file);
    },
  },
  {
    path: "/redirect/membercard",
    name: "membercard",
    component: Home,
    beforeEnter() {
      // const lang = store.getters["language/getActiveLanguage"];
      let lang = "de";
      const active_lang = localStorage.getItem("bom_lang");
      if (active_lang) {
        lang = JSON.parse(active_lang).code;
      }
      const client = store.getters["user/getClient"];
      const file = `${window.bomconfig.url_membercard}#/${client.client_id}/${lang}`;
      window.open(file);
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {transition: "fade-in-left"},
  },
  {
    path: "/choose-client",
    name: "client-chooser",
    component: ClientChooser,
  },
  {
    path: "/regmask-test",
    name: "regmask-test",
    component: RegMaskTest,
  },
  {
    path: "/:client/page",
    component: Subpage,
    children: [
      {
        path: "profile",
        name: "profile-address",
        component: Profile,
      },
      {
        path: "profile",
        name: "profile-address-readOnly",
        component: ProfileRO,
      },
      {
        path: "profile/studies",
        name: "studies",
        component: Studies,
      },
      {
        path: "profile/studies/ro",
        name: "studiesRO",
        component: StudiesRO,
      },
      {
        path: "addresses/person/:id",
        name: "person-address",
        component: PersonalAddress,
      },
      {
        path: "addresses/person/:id",
        name: "person-address-readOnly",
        component: PersonalAddress,
        meta: {readOnly: "true"},
      },
      {
        path: "addresses/company/:id",
        name: "company-address",
        component: CompanyAddress,
      },
      {
        path: "addresses/company/new/:id",
        name: "company-address-new",
        component: CompanyAddress,
        meta: { created: "true" },
      },
      {
        path: "addresses/company/:id",
        name: "company-address-readOnly",
        component: CompanyAddress,
        meta: {readOnly: "true"},
      },
      {
        path: "profile/qualifications/details/:id",
        name: "furtherEducation",
        component: FurtherEducation,
      },
      {
        path: "profile/qualifications/details/:id",
        name: "furtherEducation-readOnly",
        component: FurtherEducation,
        meta: {readOnly: "true"},
      },
      {
        path: "profile/educations/details/:id",
        name: "education",
        component: Education,
      },
      {
        path: "profile/educations/details/:id",
        name: "education-readonly",
        component: Education,
        meta: {readOnly: "true"},
      },
      {
        path: "profile/communication",
        name: "communication",
        component: Communication,
      },
      {
        path: "events/search",
        name: "eventSearch",
        component: EventSearch,
      },
      {
        path: "events/registered-events",
        name: "registered-events",
        component: RegisteredEvents,
      },
      {
        path: "events/details/:id",
        name: "event-details",
        component: EventDetails,
      },
      {
        path: "events/:id/registration",
        name: "event-registration",
        component: EventRegistration,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
      },
      {
        path: "addresses/search",
        name: "whoswhoSearch",
        component: WhoswhoSearch,
      },
      {
        path: "profile/noted-contacts",
        name: "notedContacts",
        component: NotedContacts,
      },
      {
        path: "profile/saved-contacts",
        name: "savedContacts",
        component: SavedContacts,
      },
      /*      {
        path: "mentoring/become-mentor",
        name: "becomeMentor",
        component: BecomeMentor,
      }, */
      {
        path: "mentoring/redirect/becomeMentor",
        name: "becomeMentor",
        component: Home,
        beforeEnter() {
          const lang = store.getters["language/getLanguage"];
          window.open(`/BOM/Mentor/RegMask#/?id=${BECOME_MENTOR}&module=mentor&client=26&language=${lang}`);
        },
      },
      {
        path: "mentoring/search-mentor",
        name: "searchMentor",
        component: SearchMentor,
      },
      {
        path: "mentoring/mentor-details/:id",
        name: "mentorDetails",
        component: MentorDetails,
      },
      {
        path: "mentoring/mentors-mentorings",
        name: "mentorsMentorings",
        component: MentorsMentorings,
      },
      {
        path: "mentoring/mentees-mentorings",
        name: "menteesMentorings",
        component: MenteesMentorings,
      },
      {
        path: "mentoring/pending-registration",
        name: "pendingMentoringRegistration",
        component: PendingMentorRegistration,
      },
      {
        path: "mentoring/mentees-mentorings/details/:id",
        name: "menteeMentoringDetails",
        component: MentoringDetails,
        meta: { viewer: "mentee" },
      },
      {
        path: "mentoring/mentors-mentorings/details/:id",
        name: "mentorMentoringDetails",
        component: MentoringDetails,
        meta: { viewer: "mentor" },
      },
      /* {
        path: "mentoring/apply-to-mentor/:id",
        name: "applyToMentor",
        component: ApplyToMentor,
      }, */
      {
        path: "mentoring/apply-to-mentor/:id",
        name: "applyToMentor",
        component: Home,
        beforeEnter(to) {
          const lang = store.getters["language/getLanguage"];
          window.open(`/BOM/Mentor/RegMask#/?id=${encodeURIComponent(to.params.id)}&module=mentor&client=26&language=${lang}`);
        },
      },
      {
        path: "impressum",
        name: "impressum",
        component: Impressum,
      },
      {
        path: "academies/search",
        name: "academiesSearch",
        component: AcademiesSearch,
      },
      {
        path: "academies/details/:id",
        name: "academies-details",
        component: AcademiesDetails,
      },
      {
        path: "market-place",
        name: "marketPlace",
        component: MarketPlace,
      },
      {
        path: "market-place/:id",
        name: "offerDetails",
        component: OfferDetails,
      },
      {
        path: "news",
        name: "news",
        component: News,
      },
      {
        path: "news/:id",
        name: "articleDetails",
        component: ArticleDetails,
      },
      {
        path: "forms",
        name: "forms",
        component: Forms,
      },
      {
        path: "forms/:id",
        name: "formDetails",
        component: FormDetails,
      },
      {
        path: "qualifications/liability",
        name: "liability",
        component: Liability,
      },
      {
        path: "qualifications/practice-permit",
        name: "practicePermit",
        component: PracticePermit,
      },
      {
        path: "qualifications/work-psychology",
        name: "workPsychology",
        component: WorkPsychology,
      },
      {
        path: "qualifications/career-psychology",
        name: "careerPsychology",
        component: CareerPsychology,
      },
      {
        path: "qualifications/school-psychology",
        name: "schoolPsychology",
        component: SchoolPsychology,
      },
      {
        path: "qualifications/clinical-psychology",
        name: "clinicalPsychology",
        component: ClinicalPsychology,
      },
      {
        path: "qualifications/subject-areas",
        name: "subjectAreas",
        component: SubjectAreas,
      },
    ],
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <v-col
    cols="12"
    sm="6"
    lg="6"
  >
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          class="transition-swing"
          :elevation="flat ? 0 : (hover ? 12 : 2)"
          outlined
          @click="cardClicked"
          :ripple="!!action"
          style="height: 100%"
          :flat="flat"
        >
          <div class="topright">
            <slot name="topright"></slot>
          </div>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
              >
               <squared-image
                 :image="image"
               />
              </v-col>
              <v-col
                cols="8"
              >
                <h2 class="pb-4 pt-2">{{title}}</h2>
                <slot />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
import SquaredImage from "../Images/SquaredImage.vue";

/**
 * This component is a generic image card. An image card has an image on the left, a title on the
 * right and then specifies a slot in which subcomponents can be inserted.
 * As an input property, the image card specifies an "action" function. It will be executed
 * once the user clicks on the card. This can be used to route to a different page once the user
 * clicks on the card.ö
 */
export default {
  name: "ImageCard",
  path: "components.cards.image_card",
  components: { SquaredImage },
  props: {
    title: { type: String, required: true }, // Title that is displayed on the card
    image: { type: String, default: "", required: true }, // Image that is displayed on the left of the card.
    action: { type: Function, required: false, default: undefined }, // Function that is executed once the image card is clicked
    flat: { type: Boolean, default: false },
  },
  methods: {
    /**
     * When the card gets clicked, check if the user provided an action function. If he did,
     * execute the given action.
     */
    cardClicked() {
      if (this.action) {
        this.action();
      }
    },
  },
};
</script>

<style scoped>
  .topright{
    position:absolute;
    top:0;
    right:0;
    padding: 10px;
  }
</style>

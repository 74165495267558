import ArchiveService from "../services/ArchiveService.js";
import MapUserMixin from "./ConstMappers/MapUserMixin.js";
import default_file from "../assets/files/empty.pdf";

export default {
  mixins: [MapUserMixin],
  props: {
    defaultFile: { type: String, default: default_file },
    filename: { type: String, default: "file" },
    legacyMarker: { type: String, required: false },
  },
  data: () => ({
    current_file: undefined,
    uploaded_file: undefined,

    loading_archive: true,
    had_file_initially: false,
    has_file: false,

    doOnSave: () => {},
  }),
  computed: {
    file() {
      if (this.has_file) { return this.uploaded_file || this.current_file; }
      return undefined;
    },
    // Must be implemented
  },
  created() {
    ArchiveService.fetchFileWithTag(this.bom_object, this.tag, this.legacyMarker)
      .then((file) => {
        if (file) {
          const filename = ArchiveService.getFileNameWithTag(this.bom_object, this.tag);
          this.had_file_initially = !filename.startsWith(this.USER_DELETED_FILE_NAME);
          this.has_file = this.had_file_initially;
          this.current_file = file;
        }
      })
      .catch((err) => {
        console.warn("Could not fetch file", err);
      })
      .finally(() => {
        this.loading_archive = false;
      });
  },
  methods: {
    fileSelected(file) {
      if (!file) {
        this.reset();
        this.doOnSave = () => {};
        return;
      }
      const file_reader = new FileReader();
      file_reader.readAsDataURL(file);
      file_reader.onload = () => { this.uploaded_file = file_reader.result; };
      this.has_file = true;
      this.doOnSave = () => ArchiveService.uploadNewFileWithTag(this.bom_object, this.tag, this.uploaded_file, "file");
    },
    removeFile() {
      this.uploaded_file = undefined;
      this.has_file = false;
      this.doOnSave = () => ArchiveService.uploadNewFileWithTag(this.bom_object, this.tag, this.defaultFile, this.USER_DELETED_FILE_NAME);
    },
    save() {
      this.doOnSave();
    },
    reset() {
      this.has_file = this.had_file_initially;
      this.uploaded_file = undefined;
    },
    downloadFile() {
      const a = document.createElement("a");
      a.style.cssText = "display: none; width: 0; height: 0; pointer-events: none; opacity: 0;";
      a.href = this.file;
      a.download = this.filename;
      a.click();
    },
  },
};

import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./messages.js";

// Register i18n as a vue plugin
Vue.use(VueI18n);

// Configure i18n
export default new VueI18n({
  locale: "de", // set locale
  messages,
});

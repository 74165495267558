<template>
    <div>
      <page-title
        :title="$t('title')"
      />
      <v-row dense>
        <v-col cols="12">
          <education-section />
        </v-col>
        <v-col cols="12">
          <qualification-section />
        </v-col>
      </v-row>
    </div>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import EducationSection from "./sections/EducationSection.vue";
import QualificationSection from "./sections/FurtherEducationSection.vue";

/**
 * The "studies" page just shows the two sections, educations and qualifications.
 */
export default {
  name: "Studies",
  path: "pages.studies.studies",
  components: {QualificationSection, EducationSection, PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Mein Studium",
      },
      fr: {
        title: "Mes études",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <image-card
    :title="offer | name"
    :image="image"
    :action="routeToOffer"
  >
    <p>
      <strong>{{$t("date")}}:</strong> {{ offer | from_date }}
    </p>
    <p>
      {{ offer | tagcat("Marktplatz") }}
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-event.png";

/**
 * The MarketplaceOfferCard component displays a marketplace card with an image, title, start date
 * and tag name.
 */

export default {
  name: "MarketplaceOfferCard",
  path: "components.cards.marketplace_offer_card",
  components: {ImageCard},
  props: {
    offer: {
      type: Object, // BomInstance
      required: true,
    },
  },
  data() {
    return {
      image: default_image,
    };
  },
  methods: {
    // when clicking on it, it leads to the OfferDetails.vue site with the name "offerDetails"
    routeToOffer() {
      this.$router.push({
        name: "offerDetails",
        params: {
          id: this.offer.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        date: "Datum",
      },
      fr: {
        date: "Date",
      },
    },
  },
};
</script>

<template>
    <fragment>
      <text-input
        :box-probs="boxProbs"
        :label="$t('facebook')"
        field="facebook.description"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('linkedin')"
        field="linkedin.description"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('xing')"
        field="xing.description"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('studies')"
        field="studies.description"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('highschool')"
        field="highschool.description"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('graduation_year')"
        field="graduation_year.description"
      />
    </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";

export default {
  name: "MenteeDetailsSection",
  path: "pages.mentoring.mentoring_details.sections.mentee_details_section",
  components: { TextInput },
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        facebook: "Facebook",
        linkedin: "LinkedIn",
        xing: "Xing",
        studies: "Studiengang",
        highschool: "Hochschule",
        graduation_year: "Abschlussjahr",
      },
      fr: {
        facebook: "Facebook",
        linkedin: "LinkedIn",
        xing: "Xing",
        studies: "Cours d'études",
        highschool: "Université",
        graduation_year: "Année de fin d'études",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-dialog
    :value="value"
    @change="$emit('change', $event)"
    max-width="490"
  >
    <v-card>
      <v-card-title>
        {{ $t('enter_url') }}
      </v-card-title>

      <v-card-text class="text-left">
        {{ $t('valid_url') }}
        <v-text-field
          clearable
          persistent-hint
          hint="https://company.crmforyou.ch/files/logo.png"
          v-model="url"
          :label="$t('url')"
          :error-messages="error"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          depressed
          @click="$emit('input', false)"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="$emit('close', url)"
        >
          {{ $t('done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UrlDialog",
  path: "components.edit.edit_box_children.html_editor.url_dialog",
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    url: "",
  }),
  watch: {
    value() {
      this.url = "";
    },
  },
  computed: {
    error() {
      if (this.url && !this.url.startsWith("https://")) {
        return "The URL must start with 'https://'";
      }
      return undefined;
    },
  },
  i18n: {
    messages: {
      de: {
        enter_url: "Geben Sie hier die URL ein, die Sie einfügen möchten",
        valid_url: "Eine URL startet mit 'https://'***",
        cancel: "Abbrechen",
        done: "Fertig",
      },
      fr: {
        enter_url: "Entrez l'url que vous souhaitez ajouter ici",
        valid_url: "Une URL commence par «https: //» ***",
        cancel: "Avorter",
        done: "Terminé",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('work_psychology')"
    />
    <collapsible-group>
      <collapsible :title="$t('work_psychology')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="work_psychology_channels"
              :empty-message="work_psychology_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import Collapsible from "../../components/Structure/Collabsible.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "WorkPsychology",
  path: "pages.qualifications.work_psychology",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      work_psychology_channels: [
        {
          name: this.$t("personal_search"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Personalsuche", tag_number: "22520",
        },
        {
          name: this.$t("personal_selection"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Personalauswahl", tag_number: "22521",
        },
        {
          name: this.$t("aptitude_diagnostics"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Eignungsdiagnostik", tag_number: "22522",
        },
        {
          name: this.$t("career_advice"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Laufbahnberatung", tag_number: "22523",
        },
        {
          name: this.$t("coaching_consulting"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Coaching / Beratung", tag_number: "22524",
        },
        {
          name: this.$t("personnel_development"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Personalentwicklung", tag_number: "22525",
        },
        {
          name: this.$t("personnel_systems_instruments"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Personalsysteme / -instrumente", tag_number: "22526",
        },
        {
          name: this.$t("leadership_management"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Führung / Management", tag_number: "22527",
        },
        {
          name: this.$t("organizational_development"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Organisationsentwicklung", tag_number: "22528",
        },
        {
          name: this.$t("work_design"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Arbeitsgestaltung", tag_number: "22529",
        },
        {
          name: this.$t("occupational_health_safety"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Arbeits- und Gesundheitsschutz", tag_number: "22530",
        },
        {
          name: this.$t("education_training"), tag_category: "Arbeit- und Organisationspsychologie", tag_name: "Ausbildungen / Trainings", tag_number: "22531",
        },
      ],
      work_psychology_message: this.$t("work_psychology_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        work_psychology: "Arbeit- und Organisationspycholopgie",
        personal_search: "Personalsuche",
        personal_selection: "Personalauswahl",
        aptitude_diagnostics: "Eignungsdiagnostik",
        career_advice: "Laufbahnberatung",
        coaching_consulting: "Coaching / Beratung",
        personnel_development: "Personalentwicklung",
        personnel_systems_instruments: "Personalsysteme / -instrumente",
        leadership_management: "Führung / Management",
        organizational_development: "Organisationsentwicklung",
        work_design: "Arbeitsgestaltung",
        occupational_health_safety: "Arbeits- und Gesundheitsschutz",
        education_training: "Ausbildungen / Trainings",
        work_psychology_message: "Keine Arbeit- und Organisationspychologie vorhanden",
      },
      fr: {
        work_psychology: "Psychologie du travail et de l'organisation",
        personal_search: "Recherche de personnel",
        personal_selection: "Sélection du personnel",
        aptitude_diagnostics: "Aptitude diagnostics",
        career_advice: "Conseil carrière",
        coaching_consulting: "Coaching / Conseil",
        personnel_development: "Développement du personnel",
        personnel_systems_instruments: "Systèmes / instruments du personnel",
        leadership_management: "Leadership / Management",
        organizational_development: "Développement organisationnel",
        work_design: "Conception de travail",
        occupational_health_safety: "Santé et sécurité au travail",
        education_training: "Éducation / formation",
        work_psychology_message: "Pas de psychologie du travail ou de l'organisation",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <input-wrapper
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <v-text-field
      :value="value"
      :readonly="true"
      :label="label"
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import TextInputMixin from "../../../mixins/TextInputMixin.js";

export default {
  name: "DynamicText",
  path: "components.edit.edit_box_children.dynamic_text",
  components: {InputWrapper},
  mixins: [TextInputMixin],
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <conditional-tag-section
      tag-number="55203"
      tag-category="Publikation"
      tag-name="Telefonnummer"
      :bom-object="boxProbs.editObject"
      :text="$t('hidden_phone_numbers')"
    >
      <text-input
        :box-probs="boxProbs"
        :label="$t('phone_number')"
        field="phone_number"
        phone
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('mobile_number')"
        field="mobile_number"
        phone
      />
    </conditional-tag-section>
    <conditional-tag-section
      tag-number="55201"
      tag-category="Publikation"
      tag-name="E-Mail"
      :bom-object="boxProbs.editObject"
      :text="$t('hidden_email')"
    >
      <text-input
        :box-probs="boxProbs"
        :label="$t('mail')"
        field="mail"
        email
      />
    </conditional-tag-section>
    <text-input
      :box-probs="boxProbs"
      :label="$t('office_function')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="office_function"
    />
    <tag-selector
      :box-probs="boxProbs"
      :label="$t('position')"
      tag-category="Firmenposition"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";
import TagSelector from "../../../../components/Edit/EditBoxChildren/TagSelector.vue";
import ConditionalTagSection from "../../../../components/LogicComponents/ConditionalTagSection.vue";

export default {
  name: "ContactDetailsSection",
  path: "pages.address.company.sections.contact_details_section",
  mixins: [EditBoxChildMixin],
  components: {
    ConditionalTagSection,
    TextInput,
    TagSelector,
  },
  i18n: {
    messages: {
      de: {
        hidden_phone_numbers: "Telefonnumer ist nicht sichtbar.",
        hidden_email: "E-Mail ist nicht sichtbar.",
        phone_number: "Telefon",
        mobile_number: "Mobile",
        mail: "E-Mail",
        office_function: "Funktion",
        position: "Position",
      },
      fr: {
        hidden_phone_numbers: "Le numéro de téléphone n'est pas visible.",
        hidden_email: "L'e-mail n'est pas visible.",
        phone_number: "Téléphone",
        mobile_number: "Mobile",
        mail: "E-Mail",
        office_function: "Fonction",
        position: "Position",
      },
    },
  },
};
</script>

<style scoped>

</style>

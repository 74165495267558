<template>
  <html-input
    :box-probs="boxProbs"
    :label="$t('shared_note')"
    field="description"
  />
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import HtmlInput from "../../../../components/Edit/EditBoxChildren/HtmlInput.vue";

export default {
  name: "NoteSection",
  path: "pages.mentoring.mentoring_details.sections.note_section",
  components: { HtmlInput },
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        shared_note: "Gemeinsam bearbeitbare Notiz",
      },
      fr: {
        shared_note: "Note commune modifiable",
      },
    },
  },
};
</script>

<style scoped>

</style>

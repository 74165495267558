<template>
    <div>
      <page-title
        :title="$t('title')"
      />
      <page-subtitle
        :subtitle="$t('personal_address')"
      />
      <card-group
        :loading="!user"
      >
        <person-address-card-r-o
          :address="user"
        />
      </card-group>
      <page-subtitle
        :subtitle="$t('company_address')"
      />
      <card-group
        :loading="company_addresses_loading"
        :empty="company_addresses.length === 0"
      >
        <company-address-card-r-o
          v-for="address in company_addresses"
          :key="address.id"
          :address="address"
        />
      </card-group>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import PersonAddressCardRO from "../../components/Cards/PersonAddressCardRO.vue";
import CompanyAddressCardRO from "../../components/Cards/CompanyAddressCardRO.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import MapUserMixin from "../../mixins/ConstMappers/MapUserMixin.js";

const now = new Date();

/**
 * The "profile" page shows the current user and all of his company address relations. The user can press
 * on each of the address to go into the detail view.
 *
 * Mixins: MapUserMixin
 */
export default {
  name: "Profile",
  path: "pages.profile.profile",
  mixins: [MapUserMixin],
  components: {
    CardGroup,
    CompanyAddressCardRO,
    PersonAddressCardRO,
    PageTitle,
    PageSubtitle,
  },
  data() {
    return {
      company_addresses: [], // All of the users company addresses (addresses in address-relations of type "company")
      company_addresses_loading: true, // Indicats whether the business addresses are still loading
      new_address_loading: false, // Indicates whether a new address is being created
    };
  },
  computed: {
    /**
     * Maps the users own address from the user store module to a local computed called "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  /**
   * On created, we fetch the logged-in users own address including the address relations. Then,
   * we filter the company addresses within all address relations that are currently active.
   * Note that we do NOT have to load our user address since we already have it within the store.
   */
  created() {
    this.$bom.addresses.one
      .ofLoggedInUser()
      .including("AddressRelationsPlugin")
      .fetch()
      .then(({address}) => {
        this.company_addresses = address.AddressRelationsPlugin.all
          .filter((address_relation) => {
            const already_started = !address_relation.from || address_relation.from < now;
            const not_yet_ended = !address_relation.to || address_relation.to > now;
            const is_business_address = this.isContactAddress(address_relation);
            return already_started && not_yet_ended && is_business_address;
          })
          .map((address_relation) => address_relation.address);
        this.company_addresses_loading = false;
      });
  },
  methods: {
    /**
     * Maps the action "saveUser" from the user store module to a local method "saveUser"
     */
    ...mapActions("user", {
      saveUser: "saveUser",
    }),
    /**
     * Little helper function that checks if a given address relation is of type "Geschäftsadresse"
     * @param {AddressRelation} address_relation
     * @returns {boolean}
     */
    isContactAddress(address_relation) {
      return this.USER_OWN_COMPANY_ADDRESS_TYPES.includes(address_relation.type_number);
    },
    /**
     * When the user wants to create a new address, we create a new CompanyAddress. Then, we link
     * that company address to the current user with the help of the "createNewContactAddressForCompany".
     * This crates a new ContactAddress between the user and the newly created company, and automatically
     * adds this ContactAddress to the users address relations of type "Company".
     * Then, we save the users address to make the change persistant. Finally, we navigate to the
     * details view of the freshly created created address.
     */
    newCompanyAddress() {
      this.new_address_loading = true;
      let created_address_id = "";
      this.$bom.addresses.create("Company")
        .then(({address}) => {
          address.TagsPlugin.create("Publikation", "55203", "Telefonnummer");
          address.TagsPlugin.create("Publikation", "55201", "E-Mail");
          address.TagsPlugin.create("Publikation", "658", "WEB");
          return address.save();
        })
        .then((address) => {
          return address.id;
        })
        .then((company_id) => {
          return this.user.AddressRelationsPlugin.createNewContactAddressForCompany(company_id);
        })
        .then(async (adr_rel) => {
          const address = adr_rel.address;
          address.TagsPlugin.create("Publikation", "55203", "Telefonnummer");
          address.TagsPlugin.create("Publikation", "55201", "E-Mail");
          address.TagsPlugin.create("Publikation", "658", "WEB");
          await address.save();
          created_address_id = adr_rel.address.id;
          adr_rel.start();
          return this.saveUser();
        })
        .then(() => {
          this.$router.push({
            name: "company-address-new",
            params: {
              id: created_address_id,
            },
          });
        });
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Mein Profil",
        personal_address: "Persönliche Adresse",
        company_address: "Geschäftsadressen",
      },
      fr: {
        title: "Mon profil",
        personal_address: "Adresse personnelle",
        company_address: "Adresses professionelles",
      },
      it: {
        title: "Il mio profilo",
        personal_address: "Indirizzo personale",
        company_address: "Indirizzo professionale",
      },
    },
  },
};
</script>

<style scoped>
  .add-button {
    margin: 0 auto;
    display: block;
  }
</style>

<template>
    <div
      class="ma-lg-8"
    >
      <vue-page-transition name="fade">
        <router-view />
      </vue-page-transition>
      <floating-back-button />
    </div>
</template>

<script>
import FloatingBackButton from "../components/UI-Elements/FloatingBackButton.vue";

/**
 * This page surrounds all subpages of the application, like "studies" or "addresses".
 * It defines a new router view and a floating button that routes one step back if pressed.
 * Further, it also defines some animations whenever the page is switched.
 */
export default {
  name: "Subpage",
  path: "pages.subpage",
  components: { FloatingBackButton },
};
</script>

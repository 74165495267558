<template>
  <fragment>
    <page-title
      :title="$t('title')"
      />
    <page-subtitle
      :subtitle="$t('job')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="jobTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <marketplace-offer-card v-for="item in items" :offer="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('internship')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="internshipTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <marketplace-offer-card v-for="item in items" :offer="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('ad')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="adTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <marketplace-offer-card v-for="item in items" :offer="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>
  </fragment>
</template>

<script>
import TagsWithLazyLoadingButton from "../../components/SearchMechanisms/TagsWithLoadingButton.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import MarketplaceOfferCard from "../../components/Cards/MarketplaceOfferCard.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";

/**
 * This page shows all the Marketplace Offers. Which can be filtered by the <tag-search> with the tags we
 * hardcoded below.
 */

export default {
  name: "MarketPlace",
  path: "pages.marketplace.market_place",
  components: {
    TagsWithLazyLoadingButton,
    PageSubtitle,
    MarketplaceOfferCard,
    PageTitle,
  },
  data() {
    return {
      module: "marketplace",
      objectType: "instances",
      jobTag: { name: this.$t("job"), number: "26802" },
      internshipTag: {name: this.$t("internship"), number: "26803" },
      adTag: {name: this.$t("ad"), number: "26804" },
    };
  },
  i18n: {
    messages: {
      de: {
        title: "Marktplatz",
        job: "Stellenangebote",
        internship: "Praktika",
        ad: "Inserate",
      },
      fr: {
        title: "Marché",
        job: "Annonces d'emploi",
        internship: "Stages",
        ad: "Annonces publicitaires",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <fragment>
      <text-input
        field="search_address_registration_1.info3"
        :label="$t('search_address_registration_1.info3')"
        :box-probs="boxProbs"
      />
      <address-id-input
        field="search_address_registration_1.location_address_id"
        :selectable-type-numbers="[21641]"
        :label="$t('search_address_registration_1.location_address_id')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_registration_1.tags.degree"
        tag-category="Ausbildungs-Stufe"
        module="education"
        :label="$t('search_address_registration_1.tags.degree')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_registration_1.tags.subject"
        tag-category="Fachrichtung"
        module="education"
        :label="$t('search_address_registration_1.tags.subject')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_registration_1.tags.specialization"
        tag-category="Vertiefungsrichtung"
        module="education"
        :label="$t('search_address_registration_1.tags.specialization')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address_registration_1.end_year"
        :label="$t('search_address_registration_1.end_year')"
        numeric
        :max-length="4"
        :box-probs="boxProbs"
      />
    </fragment>
</template>

<script>
import TextInput from "../../../components/Edit/EditBoxChildren/TextInput.vue";
import SearchSectionMixin from "../../../mixins/SearchSectionMixin.js";
import TagNumberInput from "../../../components/Edit/EditBoxChildren/TagNumberInput.vue";
import AddressIdInput from "../../../components/Edit/EditBoxChildren/AddressIdInput.vue";

export default {
  name: "EducationSearchSection",
  path: "pages.whoswho.sections.education_search_section",
  components: {AddressIdInput, TagNumberInput, TextInput},
  mixins: [SearchSectionMixin],
  i18n: {
    messages: {
      de: {
        search_address_registration_1: {
          info3: "Ausbildungs-Diplom",
          tags: {
            degree: "Ausbildungs-Stufe",
            subject: "Ausbildung Fachrichtung",
            specialization: "Ausbildung Vertiefungsrichtung",
          },
          location_address_id: "Ausbildung Studienort",
          end_year: "Ausbildung Abschlussjahr",
        },
      },
      fr: {
        search_address_registration_1: {
          info3: "Diplôme de formation",
          tags: {
            degree: "Niveau de formation",
            subject: "Domaine de formation",
            specialization: "Spécialisation de la formation",
          },
          location_address_id: "Lieu d'étude de la formation",
          end_year: "Fin de formation",
        },
      },
    },
  },
};
</script>

<style scoped>

</style>

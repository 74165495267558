<template>
  <p>
    RMMultiDropDown
  </p>
</template>

<script>
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMMultiDropDown",
  path: "components.reg_mask.reg_mask_field.r_m_multi_drop_down",
  mixins: [RegMaskFieldMixin],
};
</script>

<style scoped>

</style>

import bom from "@polygon-software/bom-framework";
import i18n from "../languages/i18n.js";

// Here we initialize the bom-framework
const prod = window.bomconfig.production;
const api_url = prod ? "https://apiclient.fhconnect.ch" : "https://apiclienttest.fhconnect.ch";
const api_token = "A3PJXypzuQ0nmxZ/pVVStekCrwLJFlytF7lX/Lyob/E=";
const language = i18n.locale;
const client_name = "LoginClient";
const client_id = "1";
const cookie_name = undefined;

bom.log_level = "warning";
bom.disablePreconditions();

bom.init(api_url, api_token, {
  language,
  client_name,
  client_id,
  cookie_name,
});

export default bom;

import bom from "../plugins/bom.js";
import router from "../router/index.js";
import store from "../store/index.js";
import eventBus from "../plugins/eventBus.js";
import i18n from "../languages/i18n.js";
/**
 * The authentication service ensures that only logged-in users are able to view certain pages.
 * The auth service forces users that are not logged in to the login page and ensures that the user
 * has always a valid client choosen, otherwise it routes the user to the client-chooser page.
 */

/**
 * Returns the query strings that are active on the current page, hence if the user is on the url
 * "https://fhconnect.ch/page?a=1&b=2 this function returns a=1&b=2.
 * @returns {string}
 */
function getQueryString() {
  const vue_route = window.location.hash.substr(1);
  if (vue_route.includes("?")) {
    return vue_route.substring(vue_route.indexOf("?") + 1);
  }
  return "";
}

/**
 * Returns an object containing the current query parameters based on the users url, hence if the user
 * is on the url "https://fhconnect.ch/page?a=1&b=2 this function returns {a: 1, b: 2}.
 * @returns {Object.<string, string>}
 */
function getQueryObject() {
  const query_string = getQueryString();
  const params = new URLSearchParams(query_string);
  const query_object = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of params.entries()) {
    query_object[pair[0]] = pair[1];
  }
  return query_object;
}

/**
 * Returns the current location of the user within the application. This is needed since, when the
 * user is logged-out and therefore forced back to the login page, we want to forward him back to his
 * initial location after he has logged-in.
 * If the user already has a redirection_path parameter in its url, this one is taken as the location to go.
 * Otherwise, his current location is assessed and saved as the path to which he wants to come back.
 * Note that the /login and /choose-client path can't be redirected since this could lead to infinite
 * redirection recursion, hence these paths are replaced with "home".
 * @returns {string}
 */
function getRedirectPath() {
  const vue_route = window.location.hash.substr(1);
  const query_string = getQueryString();
  if (query_string) {
    const url_params = new URLSearchParams(query_string);
    const redirect = url_params.get("redirect");
    if (redirect) {
      return redirect;
    }
  }
  const route_path = query_string ? vue_route.replace(query_string, "") : vue_route;
  const non_redirect_routes = ["/login", "/choose-client"];
  if (non_redirect_routes.includes(route_path)) {
    return "/";
  }
  return route_path;
}

/**
 * The login function calls the login method from the bom-framework, which expects an iframe
 * with name "login-iframe" to be present on the page. The login state is then saved to the
 * auth store module.
 * @returns {Promise<boolean>}
 */
function login() {
  return bom.login("login-iframe")
    .then(() => {
      store.commit("auth/setLoginState", true);
      return true;
    })
    .catch((err) => {
      store.commit("auth/setLoginState", false);
      throw new Error(err);
    });
}

/**
 * This method logs the user in using the bom-framework and saves the login state to the auth
 * store module.
 */
function logout() {
  bom.logout();
  store.commit("auth/setLoginState", false);
  router.push({ name: "login" });
  sessionStorage.clear();
  eventBus.$emit("snackbar", {
    text: i18n.t("manual_logout"),
    color: "success",
  });
}

/**
 * This code is only executed when Vue starts for the first time. If we detect that the user is not
 * logged in, we force route him to the login page but remember his original URL. His original URL
 * is passed to the login- or client-chooser-screen using a "query" parameter called "redirection_path", which
 * these screens will use to bring the user back to his originally intended location.
 */
if (bom.logged_in) {
  store.commit("auth/setLoginState", true);
  router.replace({
    name: "client-chooser",
    query: {
      ...getQueryObject(),
      redirect: getRedirectPath(),
    },
  });
} else {
  store.commit("auth/setLoginState", false);
  router.push({
    name: "login",
    query: {
      ...getQueryObject(),
      redirect: getRedirectPath(),
    },
  });
}

/**
 * This code is only executed when Vue starts for the first time. This code registers an event
 * listener to react on automatic logout of the Bom framework
 */
bom.onAutoLogout(() => {
  store.commit("auth/setLoginState", false);
  eventBus.$emit("snackbar", {
    text: i18n.t("auto_logout"),
    color: "error",
  });
});

/**
 * Here we register routing guards that are checked and executed whenever the user navigates to
 * a new page. If the user tries to access a page that is NOT the login page without being logged
 * in, we force him to the login page. If he IS logged in but tries to navigate to the login page,
 * we redirection_path him to "home".
 */
router.beforeEach((to, from, next) => {
  if (from.name === to.name && from.query.redirect) {
    next({...to, query: {...from.query, redirect: from.query.redirect }});
  }
  if (to.name !== "login" && !bom.logged_in) {
    console.warn("NavigationGuard: User is not logged-in");
    return next({
      name: "login",
      query: {
        ...getQueryObject(),
        redirect: getRedirectPath(),
      },
    });
  }
  if (to.name === "login" && bom.logged_in) {
    console.warn("NavigationGuard: Force to  home");
    return next({name: "home"});
  }
  return next();
});

export default {
  login,
  logout,
};

<template>
  <input-wrapper
    v-show="show"
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <v-text-field
      v-model="value"
      :rules="rules"
      :counter="show_counter"
      :label="label"
      :required="mandatory"
      :readonly="!boxProbs.editmode"
      :loading="false"
      :disabled="boxProbs.editmode && readonly"
      :type="numeric ? 'number' : 'text'"
      :name="Math.random()"
      :autocomplete="Math.random()"
      placeholder="-"
      @click="openLink"
      :class="{cursorPointer: isClickableLink}"
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import TextInputMixin from "../../../mixins/TextInputMixin.js";

/**
 * The text input simply saves whatever the user writes into the text field
 * into the corresponding field
 */
export default {
  name: "TextInput",
  path: "components.edit.edit_box_children.text_input",
  components: {InputWrapper},
  mixins: [TextInputMixin],
};
</script>

<style scoped>
  .cursorPointer {
    cursor: pointer !important;
  }
  .cursorPointer >>> * {
    cursor: pointer !important;
  }
</style>

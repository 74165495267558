import JointEditObject from "../services/JointEditObject.js";

export default {
  data: () => ({
    myPersonArticle: undefined,
    myEducationsArticle: undefined,
    myHighschoolArticle: undefined,
    all_possible_tags: [],
  }),
  computed: {
    joint_articles() {
      return new JointEditObject({
        person: this.myPersonArticle,
        education: this.myEducationsArticle,
        highschool: this.myHighschoolArticle,
      });
    },
    type_tags() {
      return this.all_possible_tags.filter((tag) => tag.category === "Art des Mentoring");
    },
    regionTags() {
      return this.all_possible_tags.filter((tag) => tag.category === "Region");
    },
    branchTags() {
      return this.all_possible_tags.filter((tag) => tag.category === "Branchen");
    },
    subjectTags() {
      return this.all_possible_tags.filter((tag) => tag.category === "Themenfelder");
    },
  },
  methods: {
    fetchArticleWithTag(tag) {
      return this.$bom.mentoring.articles.all
        .toInstance(this.mentor.id)
        .onClient("26")
        .withTags(tag)
        .take(1)
        .fetch()
        .then(({articles}) => {
          return articles[0];
        });
    },
    fetchMyPersonArticle() {
      return this.fetchArticleWithTag("20443")
        .then((article) => {
          this.myPersonArticle = article;
        });
    },
    fetchMyEducationsArticle() {
      return this.fetchArticleWithTag("55259")
        .then((article) => {
          this.myEducationsArticle = article;
        });
    },
    fetchMyHighschoolArticle() {
      return this.fetchArticleWithTag("11317")
        .then((article) => {
          this.myHighschoolArticle = article;
        });
    },
  },
};

<template>
  <v-row dense>
    <input-wrapper
      sm="12"
      md="6"
      v-if="!boxProbs.editmode"
    >
      <v-btn
        v-if="file"
        @click="downloadFile"
      >
        {{$t("download")}}
        <v-icon
          right
          dark
          small
        >
          mdi-download
        </v-icon>
      </v-btn>
      <p v-else>
        {{ $t("no_document") }}
      </p>
    </input-wrapper>
    <input-wrapper
      sm="12"
      md="6"
      v-if="boxProbs.editmode && !readonly"
    >
      <v-file-input
        show-size
        prepend-icon=""
        append-icon="mdi-upload"
        color="primary"
        :loading="loading || loading_archive"
        :label="has_file ? $t('replace') : $t('upload')"
        @change="fileSelected($event)"
      />
      <v-btn
        v-if="had_file_initially"
        @click="removeFile"
      >
        {{$t("delete")}}
        <v-icon
          right
          dark
          small
        >
          mdi-delete
        </v-icon>
      </v-btn>
    </input-wrapper>
  </v-row>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import ArchiveMixin from "../../../mixins/ArchiveMixin.js";
import default_file from "../../../assets/files/empty.pdf";
import InputWrapper from "./InputWrapper.vue";

export default {
  name: "PdfUploader",
  path: "components.edit.edit_box_children.pdf_uploader",
  components: {InputWrapper},
  mixins: [InputMixin, ArchiveMixin],
  props: {
    defaultFile: { type: String, default: default_file },
    filename: { type: String, default: "cv" },
    tag: { type: Object, required: true },
  },
  computed: {
    bom_object() {
      return this.boxProbs.editObject;
    },
  },
  i18n: {
    messages: {
      de: {
        upload: "Neues Dokument Hochladen",
        replace: "Dokument Ersetzen",
        delete: "Dokument Löschen",
        download: "Download",
        no_document: "Kein Dokument verfügbar",
      },
      fr: {
        upload: "Télécharger un nouveau document",
        replace: "Remplacer le document",
        delete: "Supprimer le document",
        download: "Télécharger",
        no_document: "Aucun document disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

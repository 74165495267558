<template>
  <div>
    <page-title
      :title="$t('career_psychology')"
    />
    <collapsible-group>
      <collapsible :title="$t('career_psychology')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="career_psychology_channels"
              :empty-message="career_psychology_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "CareerPsychology",
  path: "pages.qualifications.career_psychology",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      career_psychology_channels: [
        {
          name: this.$t("career_choice"), tag_category: "Fachrichtung", tag_name: "Berufswahl, Erstausbildung", tag_number: "22533",
        },
        {
          name: this.$t("professional_reorientation"), tag_category: "Fachrichtung", tag_name: "Berufliche Neuorientierung", tag_number: "22534",
        },
        {
          name: this.$t("training_questions"), tag_category: "Fachrichtung", tag_name: "Weiterbildungsfragen", tag_number: "22535",
        },
        {
          name: this.$t("outplacement"), tag_category: "Fachrichtung", tag_name: "Outplacement, Newplacement", tag_number: "22536",
        },
        {
          name: this.$t("professional_career_questions"), tag_category: "Fachrichtung", tag_name: "Berufs- und Laufbahnfragen 50+", tag_number: "22537",
        },
        {
          name: this.$t("professional_reintegration"), tag_category: "Fachrichtung", tag_name: "Berufliche Wiedereingliederung", tag_number: "22538",
        },
        {
          name: this.$t("learning_coaching"), tag_category: "Fachrichtung", tag_name: "Lerncoaching", tag_number: "22539",
        },
        {
          name: this.$t("application_coaching"), tag_category: "Fachrichtung", tag_name: "Bewerbungscoaching", tag_number: "22540",
        },
        {
          name: this.$t("field_activity"), tag_category: "Fachrichtung", tag_name: "Tätigkeitsfeld Diagnostik und Beratung", tag_number: "22541",
        },
      ],
      career_psychology_message: this.$t("career_psychology_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        career_psychology: "Laufbahn- und Rehabilitationspsychologie",
        career_choice: "Berufswahl, Erstausbildung",
        professional_reorientation: "Berufliche Neuorientierung",
        training_questions: "Weiterbildungsfragen",
        outplacement: "Outplacement, Newplacement",
        professional_career_questions: "Berufs- und Laufbahnfragen 50+",
        professional_reintegration: "Berufliche Wiedereingliederung",
        learning_coaching: "Lerncoaching",
        application_coaching: "Bewerbungscoaching",
        field_activity: "Tätigkeitsfeld Diagnostik und Beratung",
        career_psychology_message: "Keine Laufbahn- und Rehabilitationspsychologie vorhanden",
      },
      fr: {
        career_psychology: "Psychologie de carrière et de réadaptation",
        career_choice: "Choix de carrière, formation initiale",
        professional_reorientation: "Réorientation professionnelle",
        training_questions: "Questions de formation",
        outplacement: "Outplacement, nouvel emplacement",
        professional_career_questions: "Questions professionnelles et professionnelles 50+",
        professional_reintegration: "Réinsertion professionnelle",
        learning_coaching: "L'apprentissage du coaching",
        application_coaching: "Coaching d'application",
        field_activity: "Domaine d'activité diagnostic et conseil",
        career_psychology_message: "Aucune psychologie de carrière et de réadaptation disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

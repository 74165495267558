<template>
  <fragment>
    <input-wrapper
      sm="10"
      md="6"
    >
      <image-uploader
        :box-probs="boxProbs"
        :label="$t('profile_image')"
        :filename="$t('profile_image')"
        :default-file="default_image"
        legacy-marker="profilbild"
      />
    </input-wrapper>
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import ImageUploader from "../../../../components/Edit/EditBoxChildren/ImageUploader.vue";
import InputWrapper from "../../../../components/Edit/EditBoxChildren/InputWrapper.vue";
import company_image from "../../../../assets/images/placeholders/std-business.png";

export default {
  name: "ContactImageSection",
  path: "pages.address.company.sections.contact_image_section",
  components: { InputWrapper, ImageUploader },
  mixins: [EditBoxChildMixin],
  data() {
    return {
      default_image: company_image, // Default image for a company
    };
  },
  i18n: {
    messages: {
      de: {
        profile_image: "Profilbild",
      },
      fr: {
        profile_image: "Photo de profil",
      },
    },
  },
};
</script>

<style scoped>

</style>

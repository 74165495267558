<template>
  <component :is="address_card_type" :address="address">
    <slot />
  </component>
</template>

<script>
import PersonCard from "./PersonAddressCard.vue";
import CompanyCard from "./CompanyAddressCard.vue";

export default {
  name: "DynamicAddressCard",
  path: "components.cards.dynamic_address_card",
  components: {CompanyCard, PersonCard},
  props: {
    address: { type: Object, required: true },
  },
  computed: {
    address_card_type() {
      switch (this.address.type) {
        case ("Person"): {
          return "person-card";
        }
        case ("Firma"): {
          return "company-card";
        }
        case ("Kontakt"): {
          return "company-card";
        }
        default: {
          return "person-card";
        }
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-subtitle
      :subtitle="$t('title')"
    />
    <card-group
      :loading="loading_educations"
      :empty="educations.length === 0"
    >
      <education-card
        v-for="education in educations"
        :key="education.id"
        :education="education"
      />
    </card-group>
    <add-button
      v-if="!loading_educations"
      class="mx-auto d-block"
      @click="newEducation"
      :loading="new_education_loading"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AddButton from "../../../components/UI-Elements/AddButton.vue";
import PageSubtitle from "../../../components/UI-Elements/PageSubtitle.vue";
import CardGroup from "../../../components/Cards/CardGroup.vue";
import EducationCard from "../../../components/Cards/EducationCard.vue";

export default {
  name: "EducationSection",
  path: "pages.studies.sections.education_section",
  components: {
    EducationCard, CardGroup, PageSubtitle, AddButton,
  },
  data() {
    return {
      educations: [], // The educations of the logged-in user
      loading_educations: true, // Indicates whether the educations are still loading
      new_education_loading: false, // Indicates whether a new education is being created
      education_instance_id: "",
    };
  },
  /**
   * On created, we fetch the educations of the logged in users and save them to the local variable
   */
  created() {
    const promise_list = [];
    promise_list.push(
      this.$bom.education.references.all
        .ofLoggedInUser()
        .including("AddressRelationsPlugin", "TagsPlugin", "InstancePlugin")
        .fetch(),
    );
    promise_list.push(
      this.$bom.education.instances.all
        .take(1)
        .fetch(),
    );
    Promise.all(promise_list).then((data) => {
      this.educations = data[0].references;
      this.loading_educations = false;
      const instance = data[1].instances[0];
      this.education_instance_id = instance.id;
    });
  },
  computed: {
    ...mapGetters("user", {
      user_id: "getUserId",
    }),
  },
  methods: {
    newEducation() {
      this.new_education_loading = true;
      this.$bom.education.references.create(this.education_instance_id, this.user_id)
        .then(({reference}) => {
          this.routeToEducation(reference.id, {editmode: "true"});
        });
    },
    routeToEducation(education_id, query = {}) {
      this.$router.push({
        name: "education",
        params: {
          id: education_id,
        },
        query,
      });
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Meine Ausbildung",
      },
      fr: {
        title: "Ma formation",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <div>
      <page-title
        :title="$t('title')"
      />
      <p>
        {{ $t("description") }}
      </p>
      <v-btn
        color="primary"
        @click="$router.push({name: 'home'})"
      >
        {{ $t("home") }}
      </v-btn>
    </div>
</template>

<script>
import PageTitle from "../components/UI-Elements/PageTitle.vue";

/**
 * This is a generic page if the route the user navigated to is not valid.
 */
export default {
  name: "NotFound",
  path: "pages.not_found",
  components: {PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Seite nicht gefunden",
        description: "Diese Seite existiert leider nicht. Bitte gehen Sie zurück  zur Startseite.",
        home: "Zur Startseite",
      },
      fr: {
        title: "Page non trouvée",
        description: "Malheureusement cette page n'existe pas. Veuillez retourner à la page d'accueil.",
        home: "Vers la page d'accueil",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <h1 class="page-title">
    {{title}}
  </h1>
</template>

<script>
/**
 * Title of a page, it is a simple h2
 */
export default {
  name: "PageTitle",
  path: "components.ui_elements.page_title",
  // properties in the child -> what we are allowed to use in the parent when we use this component
  // from child to parent via props AND from parent to child via events (event starts with an @)
  props: {
    // Title text
    title: { type: String, required: true, default: "" },
  },
};
</script>

<style scoped>
  h1.page-title {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
    margin-bottom: 30px;
  }
</style>

<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>
        mdi-file
      </v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>
        {{ archiveRelation.archive.name }}
      </v-list-item-title>
    </v-list-item-content>

    <v-btn
      color="primary"
      small
      outlined
      text
      @click="downloadFile"
      :loading="loading"
    >
      {{ $t('download') }}
      <v-icon small right>
        mdi-download
      </v-icon>
    </v-btn>
    <v-btn
      v-if="deletable"
      :text="!confirm"
      :outlined="!confirm"
      class="ml-2"
      small
      @click="deleteFile"
      :loading="loading"
      color="error"
    >
      {{ confirm ? $t('confirm') : $t('delete') }}
      <v-icon small right>
        mdi-delete
      </v-icon>
    </v-btn>
  </v-list-item>
</template>

<script>
export default {
  name: "FileDownloadCard",
  path: "components.cards.file_download_card",
  props: {
    archiveRelation: { type: Object, required: true },
    deletable: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    loading: false,
    confirm: false,
  }),
  methods: {
    async downloadFile() {
      this.loading = true;
      if (!this.archiveRelation.archive.FilePlugin) {
        await this.archiveRelation.archive.loadFilePlugin();
      }
      this.archiveRelation.archive.FilePlugin.download();
      this.loading = false;
    },
    async deleteFile() {
      if (!this.confirm) {
        this.confirm = true;
        return;
      }
      this.archiveRelation.end();
      this.$emit("save");
    },
  },
  i18n: {
    messages: {
      de: {
        download: "Download",
        delete: "Löschen",
        confirm: "Bestätigen",
      },
      fr: {
        download: "Télécharger",
        delete: "Supprimer",
        confirm: "Confirmer",
      },
    },
  },
};
</script>

<style scoped>

</style>

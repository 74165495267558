<template>
  <v-menu
    bottom
    left
    :offset-y="true"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item      >
        <v-list-item-title
          class="clickable-list-item"
          @click="logout"
        >
          {{ $t("confirm_logout") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

/**
 * The logout button simply logs the current user out and routes the user back to the login screen
 */
export default {
  name: "Logout",
  path: "components.navigation.topbar_components.logout",
  methods: {
    /**
     * Log the user out and route him back to the login page
     */
    logout() {
      this.$auth.logout();
    },
  },
  i18n: {
    messages: {
      de: {
        confirm_logout: "Jetzt ausloggen",
      },
      fr: {
        confirm_logout: "Se déconnecter maintenant",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('apply_to_mentor')"
    />
    <div v-html="description"/>
    <reg-mask
      :load-regmask-for="registration"
      :loading-progress.sync="loadingProgress"
      :success-message="$t('success_message')"
      @saved="onSaved"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import RegMask from "../../components/RegMask/RegMask.vue";
import DetailPageMixin from "../../mixins/DetailPageMixin.js";

export default {
  name: "ApplyToMentor",
  path: "pages.mentoring.apply_to_mentor",
  mixins: [DetailPageMixin],
  components: { RegMask, PageTitle },
  data: () => ({
    mentor: {},
    registration: {},
    loadingProgress: 0,
    description: "",
    prev_client: undefined,
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  created() {
    this.prev_client = this.$bom.client;
    this.$bom.client = {
      client_name: "fhmentoring",
      client_id: "26",
    };
    this.$bom.mentoring.instances.one
      .onClient("26")
      .withId(this.id)
      .including("ReferencesPlugin")
      .fetch()
      .then(({instance}) => {
        this.loadingProgress = 10;
        this.mentor = instance;
        this.description = instance.description;
        return this.mentor.ReferencesPlugin.createAndSave(this.user.id, {client_id: "26"});
      })
      .then((reference) => {
        this.loadingProgress = 15;
        this.$nextTick().then(() => { this.registration = reference; });
      });
  },
  beforeDestroy() {
    this.$bom.client = this.prev_client;
  },
  methods: {
    onSaved() {
      new Promise((resolve) => setTimeout(resolve, 3000))
        .then(() => {
          this.$router.push({name: "menteesMentorings"});
        });
    },
  },
  i18n: {
    messages: {
      de: {
        apply_to_mentor: "Bei Mentor/-in anmelden",
        success_message: "Anmeldung erfolgreich! Sie erhalten eine Benachrichtigung, sobald ihre Anfrage akzeptiert wurde.",
      },
      fr: {
        apply_to_mentor: "Inscrivez-vous avec un mentor",
        success_message: "Inscription réussie! Vous recevrez une notification dès que votre demande aura été acceptée.",
      },
    },
  },
};
</script>

<style scoped>

</style>

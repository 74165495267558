<template>
    <input-wrapper v-show="show">
      <v-text-field
        v-model="value"
        :rules="rules"
        :counter="show_counter"
        :label="label"
        required
        :readonly="!boxProbs.editmode"
        :loading="loading"
        :disabled="boxProbs.editmode && readonly"
        :type="numeric ? 'number' : 'text'"
        placeholder="-"
        @click="openLink"
        :class="{cursorPointer: isClickableLink}"
      />
    </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import InputMixin from "../../../mixins/InputMixin.js";
import textInputMixin from "../../../mixins/TextInputMixin.js";

/**
 * This component lets the user write a value into a foreign key of a given category and number.
 * Whatever the user writes into the text-field will be stored as the "name" attribute within
 * the specified foreign-key.
 *
 * Mixins: InputMixin, textInputMixin
 */
export default {
  name: "ForeignkeyTextInput",
  path: "components.edit.edit_box_children.foreignkey_text_input",
  components: {InputWrapper},
  mixins: [InputMixin, textInputMixin],
  props: {
    // Category of foreign key to write to
    fkcategory: { type: String, required: true },
    // Number of foreign key to write to
    fknumber: { type: String, required: true },
  },
  computed: {
    /**
     * Using the fkcategory and fknumber props, we can identify the foreign-key into which
     * the "name" shall be written. If no such foreign key is present, we simply create a new one.
     * @returns {undefined|ForeignKey}
     */
    foreign_key() {
      if (this.boxProbs.editObject && this.boxProbs.editObject.ForeignKeysPlugin) {
        const found_fk = this.boxProbs.editObject.ForeignKeysPlugin.all.find((fk) => {
          return fk.number === this.fknumber;
        });
        if (!found_fk) {
          return this.boxProbs.editObject.ForeignKeysPlugin.create(this.fkcategory, this.fknumber, "");
        }
        return found_fk;
      }
      return undefined;
    },
    /**
     * The "value" variable is bound to the text input field. When a new text is written, the "set(X)"
     * function is executed, reading the value executes the "get()" method.
     */
    value: {
      /**
       * To get the current value, we read out the "field" attribute from the foreign key.
       * @returns {string|*}
       */
      get() {
        if (this.foreign_key) {
          return this.foreign_key[this.field];
        }
        return "";
      },
      /**
       * When new text is inputted, we write it into the "field" value of the foreign key.
       * @param val
       */
      set(val) {
        if (this.foreign_key) {
          this.foreign_key[this.field] = val;
        }
      },
    },
  },
};
</script>

<style scoped>
.cursorPointer {
  cursor: pointer !important;
}
.cursorPointer >>> * {
  cursor: pointer !important;
}
</style>

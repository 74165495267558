<template>
  <v-footer
    padless

  >
    <v-col
      class="text-center font-weight-light subtitle-2"
      cols="12"
    >
      <strong>
        {{ new Date().getFullYear() }} © FH SCHWEIZ, v.{{application_version}}
      </strong>
       —
      <a
        href="https://polygon-software.ch"
        target="_blank"
        class="credit"
      >
        {{$t('text')}}: PolygonSoftware GmbH
      </a>
      & Bambus Software AG
    </v-col>
  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";

/**
 * The page footer. It shows some static test and inserts the application version.
 */
export default {
  name: "PageFooter",
  path: "components.footer.page_footer",
  computed: {
    /**
     * Maps the applicationVersion getter from the base store to a local computed "applicaton_version"
     */
    ...mapGetters({
      application_version: "applicationVersion",
    }),
  },
  i18n: {
    messages: {
      de: {
        text: "Entwicklung",
      },
      fr: {
        text: "Développement",
      },
    },
  },
};
</script>

<style scoped>
  a.credit {
    text-decoration: none;
  }
</style>

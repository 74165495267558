import { render, staticRenderFns } from "./RegMaskSection.vue?vue&type=template&id=20bfb82a&scoped=true&"
import script from "./RegMaskSection.vue?vue&type=script&lang=js&"
export * from "./RegMaskSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20bfb82a",
  null
  
)

export default component.exports
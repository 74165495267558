<template>
  <v-navigation-drawer
    app
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-system-bar
      height="64"
      color="primary"
    />
    <v-list>
      <v-list-item>
        <v-list-item-avatar
          color="primary"
          size="56"
        >
          <v-img
            class="avatar"
            :src="user_image"
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item
        @click="toUsersAddress"
        link
      >
        <v-list-item-content>
          <v-list-item-title class="title">
            {{user.first_name}} {{user.last_name}}
          </v-list-item-title>
          <v-list-item-subtitle>{{user.mail}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action
          v-if="!hasAddressReadOnlyModule"
        >
          <v-icon>mdi-pen</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
      nav
      dense
    >
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="$router.push({name: item.route, params: item.params})"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";

/**
 * The sidebar menu shows the users profile image and some short links to differnet pages.
 */
export default {
  name: "SideMenu",
  path: "components.navigation.side_menu",
  props: {
    // Controls the open-state of the sidebar menu
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      drawer_status: null, // Controlls the v-navigation drawer. This value is kept in-sync with this.value
    };
  },
  computed: {
    /**
     * Map user, user_image and user_id from the user store module to local computed properties
     */
    ...mapGetters("user", {
      user: "getUser",
      user_image: "getUserImage",
      user_id: "getUserId",
      client: "getClient",
    }),
    ...mapGetters("config", {
      hasAddressModule: "hasAddressModule",
      hasAddressReadOnlyModule: "hasAddressReadOnlyModule",
      hasEventModule: "hasEventModule",
      hasMentorModule: "hasMentorModule",
      hasMenteeModule: "hasMenteeModule",
      hasNewsModule: "hasNewsModule",
      hasMarketplaceModule: "hasMarketplaceModule",
      hasEducationModule: "hasEducationModule",
      hasQualificationModule: "hasQualificationModule",
    }),
    items() {
      const client = this.client.client_id;
      return [ // Menu items
        { text: this.$t("home"), icon: "mdi-home", route: "home" },
        ...this.profileItem,
        ...this.profileItemRO,
        ...this.whosWhoItem,
        ...this.eventItem,
        {
          text: this.$t("settings"), icon: "mdi-cog", route: "settings", params: { client },
        },
        {
          text: this.$t("impressum"), icon: "mdi-information", route: "impressum", params: { client },
        },
      ];
    },
    profileItem() {
      const client = this.client.client_id;
      if (this.hasAddressModule) {
        return [{
          text: this.$t("profile"), icon: "mdi-account", route: "profile-address", params: { client },
        }];
      }
      return [];
    },
    profileItemRO() {
      const client = this.client.client_id;
      if (this.hasAddressReadOnlyModule) {
        return [{
          text: this.$t("profile"), icon: "mdi-account", route: "profile-address-readOnly", params: { client },
        }];
      }
      return [];
    },
    whosWhoItem() {
      const client = this.client.client_id;
      if (this.hasWhosWhoModule) {
        return [{
          text: this.$t("whos_who"), icon: "mdi-account-box", route: "whoswhoSearch", params: { client },
        }];
      }
      return [];
    },
    eventItem() {
      const client = this.client.client_id;
      if (this.hasEventModule) {
        return [{
          text: this.$t("whatsup"), icon: "mdi-calendar", route: "eventSearch", params: { client },
        }];
      }
      return [];
    },
  },
  methods: {
    /**
     * If the user clicks on his own profile information, route to the "PersonAddress.vue" page
     * with the users own address id as a parameter
     */
    toUsersAddress() {
      if (this.hasAddressReadOnlyModule) {
        this.$router.push({
          name: "person-address-readOnly",
          params: {
            id: this.user_id,
            client: this.client.client_id,
          },
        });
      } else {
        this.$router.push({
          name: "person-address",
          params: {
            id: this.user_id,
            client: this.client.client_id,
          },
        });
      }
    },
  },
  i18n: {
    messages: {
      de: {
        home: "Home",
        profile: "Mein Profil",
        whos_who: "Who's Who",
        whatsup: "Was geht ab",
        services: "Services",
        settings: "Einstellungen",
        impressum: "Impressum",
      },
      fr: {
        home: "Page d'accueil",
        profile: "Mon profil",
        whos_who: "Carnet de contacts",
        whatsup: "Événements actuels",
        services: "Services",
        settings: "Réglages",
        impressum: "Impressum",
      },
    },
  },
};
</script>

<style scoped>
  .avatar {
    border: 1px solid var(--primary) !important;
  }
</style>

<template>
  <div>
    <h4 class="text--black font-weight-bold mt-4" :class="{'text--primary': valid}">{{ group.title }}</h4>
    <v-radio-group
      v-model="selectedOption"
      :error-messages="errors"
      @change="onRadioSelected"
    >
      <v-radio
        v-for="option in group.options"
        :key="option.id"
        :label="option.name"
        :value="option"
        :disabled="loading"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators/index.js";
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMRadioGroup",
  path: "components.reg_mask.reg_mask_field.r_m_radio_group",
  mixins: [RegMaskFieldMixin],
  data: () => ({
    selectedOption: undefined,
    loading: false,
  }),
  computed: {
    errors() {
      const errors = [];
      const field = this.$v.selectedOption;
      if (field.$invalid) {
        if (!field.required) {
          errors.push(this.$t("required"));
        }
      }
      return errors;
    },
  },
  methods: {
    syncShadowState() {
      this.loading = false;
      this.selectedOption = this.group.options.find((option) => option.selected);
    },
    onRadioSelected() {
      this.loading = true;
      this.selectedOption.select()
        .then(() => {
          this.syncShadowState();
        });
    },
  },
  validations() {
    return {
      selectedOption: {
        ...(this.group.required && { required }),
      },
    };
  },
  i18n: {
    messages: {
      de: {
        required: "Es muss eine Option ausgewählt werden",
      },
      fr: {
        required: "Une option doit être sélectionnée",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <v-row dense>
      <v-col cols="12">
        <password-section />
      </v-col>
      <v-col cols="12">
        <visibility-section
          class="mt-8"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import PasswordSection from "./sections/PasswordSection.vue";
import VisibilitySection from "./sections/VisibilitySection.vue";

/**
 * The "settings" page just shows the two sections, password change and visibility.
 */
export default {
  name: "Settings",
  path: "pages.settings.settings",
  components: {PasswordSection, VisibilitySection, PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Meine Einstellungen",
      },
      fr: {
        title: "Mes régagles",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <collapsible-group>
      <collapsible :title="academie | name">
        <edit-box
          :editable="false"
          :edit-object="academie"
        >
          <template v-slot:default="boxProbs">
            <academies-details-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible :title="$t('register')">
<!--        <div v-if="academie_registration_ended">-->
<!--          <br>-->
<!--          <p>-->
<!--            {{ $t('registration_ended') }}-->
<!--          </p>-->
<!--        </div>-->
<!--        <div v-else-if="academie_registration_impossible">-->
<!--          <br>-->
<!--          <p>-->
<!--            {{ $t('academie_full') }}-->
<!--          </p>-->
<!--        </div>-->
        <div v-if="!users_academie_registration && user_academie_fetched">
          <v-btn class="mt-3"
            @click="registerForAcademie"
          >
            {{ $t('register_button') }}
          </v-btn>
        </div>
        <div v-else-if="users_academie_registration">
          <edit-box
            :editable="true"
            :edit-object="users_academie_registration"
          >
            <template v-slot:default="boxProbs">
              <static-text
                :box-probs="boxProbs"
                label=""
                :text="$t('already_registered_text')"
              />
            </template>
          </edit-box>
          <v-btn
            color="primary"
            @click="endRegistration"
            style="margin-right: 10px"
          >
            {{ $t('end_registration_button') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="registerForAcademie"
          >
            {{ $t('edit_registration_button') }}
          </v-btn>
        </div>
        <v-progress-linear v-else indeterminate/>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import collapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import AcademiesDetailsSection from "./AcademiesDetailsSection.vue";
import StaticText from "../../../components/Edit/EditBoxChildren/StaticText.vue";
import MapTagNumberMixin from "../../../mixins/ConstMappers/MapTagNumberMixin.js";

export default {
  name: "AcademiesDetails",
  path: "pages.marketplace.details.academies_details",
  components: {
    StaticText,
    AcademiesDetailsSection,
    EditBox,
    collapsible,
    collapsibleGroup,
    PageTitle,
  },
  mixins: [DetailPageMixin, MapTagNumberMixin], // so that we have the ID for this page
  data() {
    return {
      academie: undefined,
      users_academie_registration: undefined,
      registered_addresses: undefined,
      loading_registration: false,
      user_academie_fetched: false,
    };
  },
  computed: {
    /**
     * We map the getter "getUserId" form the user store module to a local computed property
     */
    ...mapGetters("user", {
      user_id: "getUserId",
    }),
    ...mapGetters("language", {
      language: "getLanguage",
    }),
    client_id() {
      return this.academie?.ClientPlugin.client_id;
    },
    /**
     * This address is only editable if it is the users own address
     * @returns {boolean}
     */
    registered_user_search: {
      get() {
        return this.$route.query.search_term || "";
      },
      set(term) {
        this.$router.replace({query: { ...this.$route.query, search_term: term }});
      },
    },
    academie_registration_ended() {
      return this.academie.IncidentPlugin.registration_end !== null && this.academie.IncidentPlugin.registration_end <= new Date();
    },
    academie_registration_impossible() {
      return !(this.academie.IncidentPlugin.registration_possible || this.academie.IncidentPlugin.waiting_queue_possible);
    },
  },
  methods: {
    /**
     * We map the method "fetchUser" from the user store module to a local method
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
    endRegistration() {
      this.users_academie_registration.end();
      this.users_academie_registration.save();
      this.$router.back();
    },
    registerForAcademie() {
      const encoded_academie_id = encodeURIComponent(this.academie.id);
      window.open(`/BOM/Academies/RegMask/#/?id=${encoded_academie_id}&module=marketplace&client=${this.client_id}&language=${this.language}`);
    },
    fetchAcademie() {
      return this.$bom.marketplace.instances.one
        .withId(this.id)
        .including("AddressRelationsPlugin", "IncidentPlugin")
        .fetch()
        .then(({instance}) => {
          this.academie = instance;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // fetch all academies on that the user is logged and take just that academie on which we are with the id
    fetchMyAcademieRegistration() {
      return this.$bom.marketplace.references.all // take all Academies in which the user is logged in
        .ofLoggedInUser() // Academie registrations of logged in user only
        .onClient(this.client_id)
        .toInstance(this.id)
        .including("TagsPlugin")
        // .startingBefore(new Date())
        .endingAfter(new Date())
        .fetch()
        .then(({references}) => {
          this.user_academie_fetched = true;
          // if the clicked academie is that academie for which the user is logged in -> then put it into the users_academie_registration
          this.users_academie_registration = references[0];
        });
    },
  },
  created() {
    window.removeEventListener("message", (event) => {
    }, false);

    this.fetchAcademie().then(() => {
      this.fetchMyAcademieRegistration();
    });
  },
  i18n: {
    messages: {
      de: {
        title: "Event Informationen",
        register: "Anmeldung",
        register_button: "Online-Anmeldung",
        edit_registration_button: "Anmeldung bearbeiten",
        end_registration_button: "Anmeldung beenden",
        already_registered_text: "Du bist bereits angemeldet.",
        hidden: "Verborgen",
        visible: "Öffentlich sichtbar",
        registered_users: "Angemeldete Personen",
        no_registered_users: "Keine angemeldete Personen",
        registration_ended: "Keine Anmeldung möglich, der Anmeldeschluss ist bereits vorbei.",
        event_full: "Keine Anmeldung möglich, der Event ist ausgebucht.",
      },
      fr: {
        title: "Informations sur l'événement",
        register: "Enregistrement",
        register_button: "Enregistrement en ligne",
        edit_registration_button: "Modifier l'inscription",
        end_registration_button: "Terminer l'inscription",
        already_registered_text: "Tu es déjà enregistré.",
        hidden: "Caché",
        visible: "Visible au public",
        registered_users: "Personnes inscrites",
        no_registered_users: "Aucune personne inscrite",
        registration_ended: "Pas d'inscription possible, la date limite d'inscription est déjà dépassée.",
        event_full: "Pas d'inscription possible, l'événement est complet.",
      },
      it: {
        title: "Informazioni sull’evento",
        register: "Iscrizione",
        register_button: "Iscrizione online",
        edit_registration_button: "Modificare l’iscrizione",
        end_registration_button: "Fine l’iscrizione",
        already_registered_text: "Ti sei già iscritto.",
        hidden: "Nascosto",
        visible: "Visibile pubblicamente",
        registered_users: "Persone iscritte ",
        no_registered_users: "Nessuna persona iscritta ",
        registration_ended: "Nessuna iscrizione possibile, il termine d’iscrizione è scaduto.",
        event_full: " Nessuna iscrizione possibile, l’evento è al completo.",
      },
    },
  },
};
</script>

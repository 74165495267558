<template>
  <!-- 'address' is the required input for the person-card, we get the address from the props-->
  <!--event propagation .stop so that the address card isn't opened when the icon is clicked-->
  <div v-if="!loading">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          @click.stop="notedIconClicked"
          class="mr-1"
          v-bind="attrs"
          v-on="on">
          {{ notedIcons }}
        </v-icon>
      </template>
      <span v-if="!addressNoted">{{ $t('notice') }}</span>
      <span v-if="addressNoted">{{ $t('remove_notice') }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs}">
        <v-icon
          @click.stop="savedIconClicked"
          v-bind="attrs"
          v-on="on">
          {{ savedIcons }}
        </v-icon>
      </template>
      <span v-if="!addressSaved">{{ $t('contact') }}</span>
      <span v-if="addressSaved">{{ $t('remove_contact') }}</span>
    </v-tooltip>
  </div>
  <div v-else>
    <v-progress-circular indeterminate style="width: 15px" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddRemoveAddressRelationMixin from "../../mixins/AddRemoveAddressRelationMixin.js";
import MapAddressRelationTypesMixin from "../../mixins/ConstMappers/MapAddressRelationTypesMixin.js";

export default {
  name: "NotedSavedIcons",
  path: "components.icon.noted_saved_icons",
  mixins: [MapAddressRelationTypesMixin, AddRemoveAddressRelationMixin],
  data() {
    return {
      NOTED: "Gemerkt",
      CONTACT: "Kontakt",
    };
  },
  props: {
    // The BomCompanyAddress/BomContactAddress of the company that shall pass forward
    address: {type: Object, required: true},
  },
  computed: {
    ...mapGetters("user", {
      loading: "isLoading",
    }),
    addressNoted() {
      return this.addressRelationExists(this.NOTED);
    },
    addressSaved() {
      return this.addressRelationExists(this.CONTACT);
    },
    notedIcons() {
      if (this.addressNoted) {
        return "mdi-account-plus";
      }
      return "mdi-account-plus-outline";
    },
    savedIcons() {
      if (this.addressSaved) {
        return "mdi-account-star";
      }
      return "mdi-account-star-outline";
    },
  },

  methods: {
    ...mapActions("user", {
      saveUser: "saveUser",
    }),
    notedIconClicked() {
      if (this.addressNoted) {
        this.removeAddressFromAddressRelation(this.NOTED);
        this.saveUser();
      } else {
        this.addAddressToAddressRelation(this.NOTED);
        this.saveUser();
      }
    },
    savedIconClicked() {
      if (this.addressSaved) {
        this.removeAddressFromAddressRelation(this.CONTACT);
        this.saveUser();
      } else {
        this.addAddressToAddressRelation(this.CONTACT);
        this.saveUser();
      }
    },
  },
  i18n: {
    messages: {
      de: {
        notice: "zu gemerkten Kontakte hinzufügen",
        remove_notice: "von gemerkten Kontakte entfernen",
        contact: "zu meinen Kontakte hinzufügen",
        remove_contact: "von meinen Kontakte entfernen",
      },
      fr: {
        notice: "Ajouter aux contacts mémorisés",
        remove_notice: "Supprimer des contacts mémorisés",
        contact: "Ajouter à mes contacts",
        remove_contact: "Supprimer de mes contacts",
      },
    },
  },
};
</script>

<style scoped>

</style>

/* eslint-disable no-shadow */
/**
 * The config store holds variables that can be modified after the app has been built.
 */

const state = () => ({
  production: window.bomconfig.production,
  clients_config: window.bomconfig.clients,
});

const getters = {
  isProductionBuild(state) {
    return state.production;
  },
  _getEmptyClientConfig() {
    return {
      id: "",
      name: "",
      color: "#0e3c5c",
      mail: "kontakt@fhschweiz.ch",
      modules: {},
    };
  },
  _getClients(state) {
    return state.clients_config ?? [];
  },
  getClientConfig(state, getters, rootState, rootGetters) {
    const { client_id } = rootGetters["user/getClient"] || {};
    const client_config = getters._getClients.find((client) => `${client.id}` === client_id) || {};
    return {
      ...getters._getEmptyClientConfig,
      ...client_config,
    };
  },
  getColor(state, getters) {
    return getters.getClientConfig.color;
  },
  getMail(state, getters) {
    return getters.getClientConfig.mail;
  },
  _hasModule(state, getters) {
    return (module_name) => !!getters.getClientConfig.modules[module_name];
  },
  hasEventModule(state, getters) {
    return getters._hasModule("event");
  },
  hasMentorModule(state, getters) {
    return getters._hasModule("mentor");
  },
  hasMenteeModule(state, getters) {
    return getters._hasModule("mentee");
  },
  hasNewsModule(state, getters) {
    return getters._hasModule("news");
  },
  hasFormsModule(state, getters) {
    return getters._hasModule("forms");
  },
  hasMarketplaceModule(state, getters) {
    return getters._hasModule("marketplace");
  },
  hasEducationModule(state, getters) {
    return getters._hasModule("education");
  },
  hasFurtherEducationModule(state, getters) {
    return getters._hasModule("further_education");
  },
  hasStudiesReadOnly(state, getters) {
    return getters._hasModule("studiesReadOnly");
  },
  hasAddressModule(state, getters) {
    return getters._hasModule("address");
  },
  hasAddressReadOnlyModule(state, getters) {
    return getters._hasModule("addressReadOnly");
  },
  hasWhosWhoModule(state, getters) {
    return getters._hasModule("whoswho");
  },
  hasAlumniModule(state, getters) {
    return getters._hasModule("alumni");
  },
  hasMembercardModule(state, getters) {
    return getters._hasModule("membercard");
  },
  hasQualificationModule(state, getters) {
    return getters._hasModule("qualification");
  },
  hasAcademiesModule(state, getters) {
    return getters._hasModule("academies");
  },
};

export default {
  namespaced: true,
  state,
  getters,
};

<template>
  <v-switch
    :readonly="!boxProbs.editmode"
    :input-value="is_noted"
    @change="addOrRemoveContact"
    :label="is_noted ? labelTrue : labelFalse"
    :loading="loading || boxProbs.saving"
    :disabled="boxProbs.editmode && readonly"
  />
</template>

<script>
import {mapGetters} from "vuex";
import AddRemoveAddressRelationMixin from "../../../mixins/AddRemoveAddressRelationMixin.js";
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import MapAddressRelationTypesMixin from "../../../mixins/ConstMappers/MapAddressRelationTypesMixin.js";

export default {
  name: "AddressRelationOnOffSwitch",
  path: "components.edit.edit_box_children.address_relation_on_off_switch",
  mixins: [EditBoxChildMixin, MapAddressRelationTypesMixin, AddRemoveAddressRelationMixin],
  props: {
    address: {type: Object, required: true},
    addressRelationType: {type: String, required: true},
    labelTrue: { type: String, required: true},
    labelFalse: { type: String, required: true},
  },
  computed: {
    ...mapGetters("user", {
      loading: "isLoading",
    }),
    /**
     * is_noted checks out whether the contact is noted/saved or not
     * return true or false
     */
    is_noted() {
      return this.addressRelationExists(this.addressRelationType);
    },
  },
  methods: {
    addOrRemoveContact() {
      if (this.is_noted) {
        this.removeAddressFromAddressRelation(this.addressRelationType);
      } else {
        this.addAddressToAddressRelation(this.addressRelationType);
      }
    },
  },
};
</script>

<style scoped>

</style>

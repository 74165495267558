<template>
  <v-btn
    fab
    dark
    color="primary"
    @click="$emit('click', $event)"
    :loading="loading"
  >
    <v-icon dark>
      mdi-plus
    </v-icon>
  </v-btn>
</template>

<script>
/**
 * Button with a "plus" symbol
 */
export default {
  name: "AddButton",
  path: "components.ui_elements.add_button",
  props: {
    // Indicates whether the button shall display itself in loading-mode
    loading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <collapsible-group>
      <collapsible :title="$t('private')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <visibility-switches
              :box-probs="boxProbs"
              :visibility-tag="private_tags"
            />
          </template>
        </edit-box>
      </collapsible>
        <collapsible :title="company.company_name || $t('company_name')" v-for="company in company_addresses" :key="company.company_name">
          <edit-box
            :editable="true"
            :edit-object="company"
            @saved="fetchUser"
          >
            <template v-slot:default="boxProbs">
              <visibility-switches
                :box-probs="boxProbs"
                :visibility-tag="company_tags"
              />
            </template>
          </edit-box>
        </collapsible>
      <collapsible :title="$t('education')" v-if="educations.length>0">
        <edit-box
          :editable="true"
          :edit-object="education"
          @saved="fetchUser"
        >
          <template v-slot:default="{editObject , saving, editmode, eventname}">
            <visibility-switches
              v-for="key in education._keys"
              :key="key"
              :box-probs="{editObject: editObject[key] , saving, editmode, eventname}"
              :visibility-tag="education_tags"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible :title="$t('further_education')" v-if="further_educations.length > 0">
        <edit-box
          :editable="true"
          :edit-object="further_education"
          @saved="fetchUser"
        >
          <template v-slot:default="{editObject , saving, editmode, eventname}">
            <visibility-switches
              v-for="key in further_education._keys"
              :key="key"
              :box-probs="{editObject: editObject[key] , saving, editmode, eventname}"
              :visibility-tag="further_educations_tag"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VisibilitySwitches from "../../components/Edit/EditBoxChildren/VisibilitySwitches.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../components/Structure/Collabsible.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import MapUserMixin from "../../mixins/ConstMappers/MapUserMixin.js";
import JointEditObject from "../../services/JointEditObject.js";

const now = new Date();

export default {
  name: "Visibility",
  path: "pages.visibility.visibility",
  mixins: [MapUserMixin],
  components: {
    EditBox,
    Collapsible,
    CollapsibleGroup,
    VisibilitySwitches,
  },
  data() {
    return {
      educations: [],
      further_educations: [],
      company_addresses: [], // All of the users company addresses (addresses in address-relations of type "company")
      private_tags: [
        {
          tag_category: "Publikation",
          tag_name: "WEB",
          tag_number: "658",
          tag_filter: "fullname",
        },
        {
          tag_category: "Publikation",
          tag_name: "E-Mail",
          tag_number: "55201",
          tag_filter: "mail",
        },
        {
          tag_category: "Publikation",
          tag_name: "Telefonnummer",
          tag_number: "55203",
          tag_filter: "phonenumber",
        },
        {
          tag_category: "Publikation",
          tag_name: "Geburtsdatum",
          tag_number: "21586",
          tag_filter: "birthday",
        },
      ],
      company_tags: [
        {
          tag_category: "Publikation",
          tag_name: "WEB",
          tag_number: "658",
          tag_filter: "companyplace",
        },
        {
          tag_category: "Publikation",
          tag_name: "E-Mail",
          tag_number: "55201",
          tag_filter: "mail",
        },
        {
          tag_category: "Publikation",
          tag_name: "Telefonnummer",
          tag_number: "55203",
          tag_filter: "phonenumber",
        },
      ],
      education_tags: [
        {
          tag_category: "Publikation",
          tag_name: "WEB",
          tag_number: "658",
          tag_filter: "education",
        },
      ],
      further_educations_tag: [
        {
          tag_category: "Publikation",
          tag_name: "WEB",
          tag_number: "658",
          tag_filter: "furthereducation",
        },
      ],
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
    /**
     * Since we have to edit multiple tags in one BomObject, we create a loop by creating a JointEditObject
     * for both the education and further education
    */
    education() {
      const obj = {};
      this.educations.forEach((ed, index) => {
        obj[index] = ed;
      });
      return new JointEditObject(obj);
    },
    further_education() {
      const obj = {};
      this.further_educations.forEach((ed, index) => {
        obj[index] = ed;
      });
      return new JointEditObject(obj);
    },
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),

    /**
     * Little helper function that checks if a given address relation is of type "Geschäftsadresse"
     * @param {AddressRelation} address_relation
     * @returns {boolean}
     */
    isContactAddress(address_relation) {
      return this.USER_OWN_COMPANY_ADDRESS_TYPES.includes(address_relation.type_number);
    },

    /**
     * Fetches all the educations or further educations of the logged in user
     */
    fetchEducations(module, edu) {
      this.$bom[module].references.all
        .ofLoggedInUser()
        .including("TagsPlugin", "ReferencePlugin")
        .fetch()
        .then(({references}) => {
          references.forEach((study) => this[edu].push(study));
        });
    },
  },
  created() {
    /**
     * We filter the company addresses within all address relations that are currently active.
     * Note that we do NOT have to load our user address since we already have it within the store.
     */
    const company_addresses = this.user.AddressRelationsPlugin.all
      .filter((address_relation) => {
        const already_started = !address_relation.from || address_relation.from < now;
        const not_yet_ended = !address_relation.to || address_relation.to > now;
        const is_business_address = this.isContactAddress(address_relation);
        return already_started && not_yet_ended && is_business_address;
      })
      .map((address_relation) => address_relation.address);
    Promise.all(company_addresses.map((addr) => addr.loadTagsPlugin()))
      .then(() => {
        this.company_addresses = company_addresses;
      });

    this.fetchEducations("education", "educations");
    this.fetchEducations("qualification", "further_educations");
  },
};
</script>

<style scoped>

</style>

<template>
  <v-col cols="12">
    <image-uploader
      :box-probs="boxProbs"
      :label="$t('profile_image')"
      :filename="$t('profile_image')"
      subobject="bom_object"
      legacy-marker="profilbild"
    />
  </v-col>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import ImageUploader from "../../../../components/Edit/EditBoxChildren/ImageUploader.vue";

export default {
  name: "SharedImageUploadSection",
  path: "pages.mentoring.mentoring_details.sections.shared_image_upload_section",
  components: {ImageUploader},
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        profile_image: "Profilbild",
      },
      fr: {
        profile_image: "Photo de profil",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-subtitle
      :subtitle="$t('title')"
    />
    <card-group
      :loading="loading_qualifications"
      :empty="qualifications.length === 0"
    >
      <qualification-card-r-o
        v-for="qualification in qualifications"
        :key="qualification.id"
        :qualification="qualification"
      />
    </card-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageSubtitle from "../../../components/UI-Elements/PageSubtitle.vue";
import CardGroup from "../../../components/Cards/CardGroup.vue";
import QualificationCardRO from "../../../components/Cards/QualificationCardRO.vue";

export default {
  name: "FurtherEducationSectionRO",
  path: "pages.studies.sections.further_education_section",
  components: {
    QualificationCardRO, CardGroup, PageSubtitle,
  },
  data() {
    return {
      qualifications: [], // The qualifications of the logged-in user
      loading_qualifications: true, // Indicates whether the qualifications are still loading
      new_qualification_loading: false, // Indicates whether a new qualification is being created
      qualification_instance_id: "",
      qualification_id: "",
    };
  },
  /**
   * On created, we fetch the qualifications of the logged in users and save them to the local variable
   */
  created() {
    const promise_list = [];
    promise_list.push(
      this.$bom.qualification.references.all
        .ofLoggedInUser()
        .including("AddressRelationsPlugin", "TagsPlugin", "InstancePlugin")
        .fetch(),
    );
    promise_list.push(
      this.$bom.qualification.instances.all
        .take(1)
        .fetch(),
    );
    Promise.all(promise_list).then((data) => {
      this.qualifications = data[0].references;
      this.loading_qualifications = false;
      const instance = data[1].instances[0];
      this.qualification_instance_id = instance.id;
    });
  },
  computed: {
    ...mapGetters("user", {
      user_id: "getUserId",
    }),
  },
  methods: {
    newQualification() {
      this.new_qualification_loading = true;
      this.$bom.qualification.references.create(this.qualification_instance_id, this.user_id)
        .then(({reference}) => {
          this.routeToFurtherEducation(reference.id, {editmode: "true"});
        });
    },
    routeToFurtherEducation(qualification_id, query = {}) {
      this.$router.push({
        name: "furtherEducation",
        params: {
          id: qualification_id,
        },
        query,
      });
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Meine Weiterbildung",
      },
      fr: {
        title: "Ma formation continue",
      },
    },
  },
};
</script>

<style scoped>

</style>

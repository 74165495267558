<template>
  <div>
    <page-title
      :title="title"
    />
    <collapsible-group>

      <collapsible :title="$t('mentor_profile')">
        <edit-box
          :editable="can_edit"
          :edit-object="mentor"
        >
          <template v-slot:default="boxProbs">
            <mentor-image-upload-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
        <edit-box
          :editable="false"
          :edit-object="mentor_address"
        >
          <template v-slot:default="boxProbs">
            <mentor-profile-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>

      <collapsible :title="$t('social_media')">
        <edit-box
          :editable="can_edit"
          :edit-object="mentor"
        >
          <template v-slot:default="boxProbs">
            <mentor-social-media-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>

      <collapsible :title="$t('more')">
        <edit-box
          :editable="can_edit"
          :edit-object="joint_articles"
        >
          <template v-slot:default="boxProbs">
            <mentor-info-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>

      <collapsible
        :title="$t('mentoring_details')"
      >
        <edit-box
          :editable="can_edit"
          :edit-object="mentor"
        >
          <template v-slot:default="boxProbs">
            <mentor-tags-section
              :box-probs="boxProbs"
              :tags="type_tags"
              :title="$t('mentoring_type')"
            />
            <mentor-tags-section
              :box-probs="boxProbs"
              :tags="regionTags"
              :title="$t('mentoring_location')"
            />
            <mentor-tags-section
              :box-probs="boxProbs"
              :tags="subjectTags"
              :title="$t('mentoring_subjects')"
            />
            <mentor-tags-section
              :box-probs="boxProbs"
              :tags="branchTags"
              :title="$t('mentoring_branch')"
            />
          </template>
        </edit-box>
      </collapsible>

      <collapsible :title="$t('cv')">
        <edit-box
          :editable="can_edit"
          :edit-object="mentor"
        >
          <template v-slot:default="boxProbs">
            <mentor-cv-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>

      <collapsible :title="$t('register')" v-if="!can_edit">
        <br>
        <v-btn
          color="primary"
          @click="$router.push({name: 'applyToMentor', params: { id }})"
        >
          {{ $t('apply_for_mentorship') }}
          <v-icon
            right
            dark
          >
            mdi-login-variant
          </v-icon>
        </v-btn>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import MentorMixin from "../../../mixins/MentorMixin.js";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import CollapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import MentorProfileSection from "./Sections/MentorProfileSection.vue";
import MentorImageUploadSection from "./Sections/MentorImageUploadSection.vue";
import MentorInfoSection from "./Sections/MentorInfoSection.vue";
import MentorTagsSection from "./Sections/MentorTagsSection.vue";
import MentorSocialMediaSection from "./Sections/MentorSocialMediaSection.vue";
import MentorCvSection from "./Sections/MentorCvSection.vue";
import MentorDetailsMixin from "../../../mixins/MentorDetailsMixin.js";
import TempMandantSwitch from "../../../mixins/TempMandantSwitch.js";

export default {
  name: "MentorDetails",
  path: "pages.mentoring.mentor_details.mentor_details",
  components: {
    MentorCvSection,
    MentorSocialMediaSection,
    MentorTagsSection,
    MentorInfoSection,
    MentorImageUploadSection,
    MentorProfileSection,
    EditBox,
    Collapsible,
    CollapsibleGroup,
    PageTitle,
  },
  mixins: [DetailPageMixin, MentorMixin, MentorDetailsMixin, TempMandantSwitch],
  data: () => ({
    mentor: undefined,
  }),
  computed: {
    ...mapGetters("mentoring", {
      usersMentorInstance: "getOwnMentorInstance",
    }),
    title() {
      const mentor_name = this.mentor ? this.mentor.name : "";
      return `${this.$t("mentor")}: ${mentor_name}`;
    },
    can_edit() {
      return this.id === this.usersMentorInstance?.id;
    },
    mentor_address() {
      if (!this.mentor) { return {}; }
      return this.mentor.AddressRelationsPlugin?.Verantwortlich[0].address;
    },
  },
  created() {
    this.mentor = { id: this.id };
    this.fetchMentor();
    this.fetchMyPersonArticle();
    this.fetchMyEducationsArticle();
    this.fetchMyHighschoolArticle();
  },
  methods: {
    fetchMentor() {
      this.$bom.mentoring.instances.one
        .withId(this.id)
        .onClient("26")
        .including("TagsPlugin", "AddressRelationsPlugin", "ArchiveRelationsPlugin", "ForeignKeysPlugin")
        .fetch()
        .then(({instance}) => {
          this.mentor = instance;
          return this.mentor.TagsPlugin.getAllPossibleTags();
        })
        .then((tags) => {
          this.all_possible_tags = tags;
        });
    },
  },
  i18n: {
    messages: {
      de: {
        mentor: "Mentor/-in",
        mentor_profile: "Mentor/-in-Profil",
        social_media: "Social Media",
        fields: "Mentoringbereiche",
        more: "Weitere Angaben",
        mentoring_details: "Details zum Mentoring",
        mentoring_type: "Art des Mentorings",
        mentoring_location: "Orte des Mentorings",
        mentoring_subjects: "Mentoring Themen",
        mentoring_branch: "Mentoring Branchen",
        cv: "CV",
        register: "Anmeldung",
        apply_for_mentorship: "Bei diesem/dieser Mentor/-in bewerben",
      },
      fr: {
        mentor: "Mentor",
        mentor_profile: "Mentor-Profil",
        social_media: "Social Media",
        fields: "Zones de mentorat",
        more: "Plus d'information",
        mentoring_details: "Détails sur le mentorat",
        mentoring_type: "Type de mentorat",
        mentoring_location: "Lieux de mentorat",
        mentoring_subjects: "Thèmes de mentorat",
        mentoring_branch: "Industries du mentorat",
        cv: "CV",
        register: "Inscription",
        apply_for_mentorship: "Postulez à ce mentor",
      },
    },
  },
};
</script>

<style scoped>

</style>

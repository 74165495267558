<template>
    <generic-modal
      :value="value"
      @input="$emit('input', $event)"
    >
      <v-card>
        <v-card-title>
          <page-subtitle
            :subtitle="$t('accept_reject_mentoring')"
          />
        </v-card-title>
        <template v-if="!loading">
          <v-card-text>
            <v-row>
              <static-section-title
                :title="$t('mentees_proposals')"
              />
              <text-input
                :box-probs="positions_box_props"
                :label="$t('mentoring_wished_start')"
                field="begin_pos.description"
                sm="6"
                md="6"
              />
              <text-input
                :box-probs="positions_box_props"
                :label="$t('mentoring_wished_end')"
                field="end_pos.description"
                sm="6"
                md="6"
              />
            </v-row>
            <v-row>
              <static-section-title
                :title="$t('metors_approvals')"
              />
              <date-picker
                :box-probs="mentoring_box_props"
                :label="$t('effective_start')"
                :max-date="max_date"
                :min-date="min_date"
                field="from"
                sm="6"
                md="6"
              />
              <date-picker
                :box-probs="mentoring_box_props"
                :label="$t('effective_end')"
                :max-date="max_date"
                :min-date="min_date"
                field="to"
                sm="6"
                md="6"
              />
            </v-row>
            <p v-if="!mentee_has_mail">{{ $t("no_mail_mentee") }}</p>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="success"
              @click="acceptMentoring"
              :disabled="!input_dates_set"
            >
              {{$t("accept")}}
            </v-btn>
            <v-btn
              color="error"
              @click="rejectMentoring"
              class="ml-2"
            >
              {{$t("reject")}}
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <div style="min-height: 100px">
            <loading-circle />
          </div>
        </template>
      </v-card>
    </generic-modal>
</template>

<script>
import MentoringDetailsMixin from "../../mixins/MentoringDetailsMixin.js";
import PageSubtitle from "../UI-Elements/PageSubtitle.vue";
import DatePicker from "../Edit/EditBoxChildren/DatePicker.vue";
import LoadingCircle from "../UI-Elements/LoadingCircle.vue";
import TextInput from "../Edit/EditBoxChildren/TextInput.vue";
import StaticSectionTitle from "../Edit/EditBoxChildren/StaticSectionTitle.vue";
import GenericModal from "./GenericModal.vue";
import MapTagNumberMixin from "../../mixins/ConstMappers/MapTagNumberMixin.js";

export default {
  name: "AcceptRejectMentoringModal",
  path: "components.modals.accept_reject_mentoring_modal",
  components: {
    GenericModal,
    StaticSectionTitle,
    TextInput,
    LoadingCircle,
    DatePicker,
    PageSubtitle,
  },
  mixins: [MentoringDetailsMixin, MapTagNumberMixin],
  props: {
    value: { type: Boolean, required: true },
    id: { type: String, required: true },
  },
  data: () => ({
    loading: true,
    mentoring: undefined,
  }),
  computed: {
    mentoring_box_props() {
      return {
        editObject: this.mentoring,
        editmode: true,
        eventName: "accept-reject-mentoring",
      };
    },
    positions_box_props() {
      return {
        editObject: this.mentoring_positions,
        editmode: false,
        eventName: "accept-reject-positions",
      };
    },
    input_dates_set() {
      return this.mentoring.from && this.mentoring.to;
    },
    mentee_has_mail() {
      return !!this.mentoring.AddressPlugin.address?.mail;
    },
    min_date() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 10);
      return date;
    },
    max_date() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      return date;
    },
  },
  methods: {
    acceptMentoring() {
      this.mentoring.TagsPlugin.find({number: this.TAG_NUMBER_PENDING}).remove();
      this.mentoring.TagsPlugin.create("Aufgabe Status", this.TAG_NUMBER_ACCEPTED, "Angenommen");
      this.saveMentoring(this.$t("mentoring_accepted"));
      if (this.mentee_has_mail) {
        location.href = `mailto:${this.mentoring.AddressPlugin.address.mail}?subject=${this.$t("accept_mail_subject")}&body=${this.$t("accept_mail_body")}`;
      }
    },
    rejectMentoring() {
      this.mentoring.TagsPlugin.find({number: this.TAG_NUMBER_PENDING}).remove();
      this.mentoring.TagsPlugin.create("Aufgabe Status", this.TAG_NUMBER_REJECTED, "Abgelehnt");
      this.saveMentoring(this.$t("mentoring_rejected"));
      if (this.mentee_has_mail) {
        location.href = `mailto:${this.mentoring.AddressPlugin.address.mail}?subject=${this.$t("reject_mail_subject")}&body=${this.$t("reject_mail_body")}`;
      }
    },
    saveMentoring(message) {
      this.loading = true;
      this.mentoring.save()
        .then(() => {
          this.$eventBus.$emit("snackbar", {
            text: message,
            color: "success",
          });
          this.$emit("input", false);
          this.$eventBus.$emit("rerender");
        })
        .catch((error) => {
          console.error(error);
          this.$eventBus.$emit("snackbar", {
            text: this.$t("unable_to_save"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
  /**
   * Watches the "value" object, and it fetches the mentoring instance once the modal is opened
   * ( = when 'value' is true)
   */
    value() {
      if (this.value) {
        this.fetchMentoring()
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
  i18n: {
    messages: {
      de: {
        accept_reject_mentoring: "Mentoring akzeptieren/ablehnen",
        mentoring_wished_start: "Gewünschter Start des Mentoring",
        mentoring_wished_end: "Gewünschtes Ende des Mentoring",
        effective_start: "Effektiver Start des Mentorings*",
        effective_end: "Effektives Ende des Mentorings*",
        mentees_proposals: "Vorschläge des Mentees",
        metors_approvals: "Von Mentor/-in gesetzter Zeitraum (muss ausgefüllt werden)",
        accept: "Mentoring akzeptieren",
        reject: "Mentoring ablehnen",
        mentoring_accepted: "Mentoring erfolgreich akzeptiert",
        mentoring_rejected: "Mentoring erfolgreich abgelehnt",
        reject_mail_subject: "Mentoring Abgelehnt",
        accept_mail_subject: "Mentoring Akzeptiert",
        reject_mail_body: "Hallo %0D%0AVielen Dank für Deine Mentoring Anfrage. Leider muss ich Deine Mentoring Anfrage ablehnen. %0D%0ALiebe Grüsse",
        accept_mail_body: "Hallo %0D%0AVielen Dank für Deine Mentoring Anfrage. Gerne habe ich Deine Mentoring Anfrage akzeptiert. %0D%0ALiebe Grüsse",
        no_mail_mentee: "Leider ist für den/die Mentee keine Mail Adresse hinterlegt.",
        unable_to_save: "Ein Fehler ist aufgetreten - speichern nicht erfolgreich",
      },
      fr: {
        accept_reject_mentoring: "Accepter / rejeter le mentorat",
        mentoring_wished_start: "Début souhaité du mentorat",
        mentoring_wished_end: "Fin souhaitée du mentorat",
        effective_start: "Début efficace du mentorat*",
        effective_end: "Fin effective du mentorat*",
        mentees_proposals: "Suggestions des mentorés",
        metors_approvals: "Période fixée par le mentor (à remplir)",
        accept: "Accepter le mentorat",
        reject: "Rejeter le mentorat",
        mentoring_accepted: "Mentorat accepté avec succès",
        mentoring_rejected: "Mentorat rejeté avec succès",
        reject_mail_subject: "Mentorat refusé",
        accept_mail_subject: "Mentorat accepté",
        reject_mail_body: "Bonjour %0D%0AMerci pour ta demande de mentorat. Malheuresement, je dois rejeter ta demande de mentorat. %0D%0ACordialement ",
        accept_mail_body: "Bonjour %0D%0AMerci pour ta demande de mentorat. Je suis heureux de accepter ta demande de mentorat. %0D%0ACordialement ",
        no_mail_mentee: "Malheureusement, il n'y a pas d'adresse e-mail pour le mentoré.",
        unable_to_save: "Une erreur est survenue - échec de l'enregistrement",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-col
    :cols="cols"
    :sm="sm"
    :md="md"
  >
    <slot />
  </v-col>
</template>

<script>

/**
 * The input wrapper component is simply a vuetify column from the grid system that ensures that
 * multiple input elements are shown next to each other if the screen is wide enough.
  */
export default {
  name: "InputWrapper",
  path: "components.edit.edit_box_children.input_wrapper",
  props: {
    // Controlls the row size on small and desktop. 12 = full-width
    cols: { type: String, default: "12" },
    // Controlls the row size on large phones. 6 = half-width
    sm: { type: String, default: "6" },
    // Controlls the row size on tablets and desktop. 4 = 1/3-width
    md: { type: String, default: "4" },
  },
};
</script>

<style scoped>

</style>

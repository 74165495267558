<template>
  <v-col cols="12" class="py-0 my-0 tag-input">
    <v-checkbox
      v-if="boxProbs.editmode || !hideIfNotSet || is_tag_set"
      :input-value="is_tag_set"
      @change="addOrRemoveTag"
      :label="is_tag_set ? labelTrue : labelFalse"
      :readonly="!boxProbs.editmode"
      :loading="loading"
      :disabled="boxProbs.editmode && readonly"
      :true-value="true"
      :false-value="false"
      class="my-0 py-0"
    />
  </v-col>
</template>

<script>
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import TagOnOffMixin from "../../../mixins/TagOnOffMixin.js";

export default {
  name: "TagOnOffCheckbox",
  path: "components.edit.edit_box_children.tag_on_off_checkbox",
  mixins: [EditBoxChildMixin, TagOnOffMixin],
  props: {
    labelTrue: { type: String, required: true},
    labelFalse: { type: String, required: true},
    hideIfNotSet: { type: Boolean, default: false, required: false },
  },
};
</script>
<style scoped>
  .tag-input >>> .v-input__control {
    max-height: 25px;
    overflow: hidden;
  }
</style>

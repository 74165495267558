<template>
  <mentor-info-section
    :box-probs="boxProbs"
  />
</template>

<script>
import MentorInfoSection from "../../MentorDetails/Sections/MentorInfoSection.vue";
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";

export default {
  name: "MentorDetailsSection",
  path: "pages.mentoring.mentoring_details.sections.mentor_details_section",
  components: { MentorInfoSection },
  mixins: [EditBoxChildMixin],
};
</script>

<style scoped>

</style>

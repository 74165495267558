<template>
  <fragment>
    <v-row class="d-flex align-stretch">
      <slot/>
      <v-col cols="12" class="loading-wrapper" v-if="loading || empty">
        <loading-circle
          v-if="loading"
          :message="loadingMessage || $t('loading')"
        />
        <p v-if="!loading && empty" class="empty"
        >
          {{ emptyMessage || $t("nodata") }}
        </p>
      </v-col>
    </v-row>
    <div v-intersect.quiet="onIntersect"
    ></div>
  </fragment>
</template>

<script>
import LoadingCircle from "../UI-Elements/LoadingCircle.vue";

/**
 * The CardGroup component groups a bunch of XXXCards.vue together. It ensures that all cards line
 * up nicely next to each other and get smaller and bigger depending on the users screen.
 * The Vuetify grid system (https://vuetifyjs.com/en/components/grids/) is used to ensure that
 * on mobile, only one card is shown on the horizontal axis, while on bigger screens, multiple cards
 * are next to each other to make use of the whole screen width.
 * The CardGroup also has some nice helper. The "loading" prop controls a loading circle that
 * indicates that the cards are still not loaded, while the "empty" prop display a message if no
 * cards are part of the group. Both the loading and the empty message can be controlled through
 * input properties, but in most cases, the default values will be sufficient.
 */
export default {
  name: "CardGroup",
  path: "components.cards.card_group",
  components: {LoadingCircle},
  props: {
    // Indicates whether the cards are still loading, controls a loading circle
    loading: { type: Boolean, required: false, default: false },
    // Indicates that no cards are part of the group, shows a message to the user
    empty: { type: Boolean, required: false, default: false },
    // Message that is shown during loading
    loadingMessage: { type: String, required: false, default: "" },
    // Message that is shown when no cards are part of the group
    emptyMessage: { type: String, required: false, default: "" },
  },
  methods: {
    onIntersect() {
      this.$nextTick(() => {
        if (!this.loading && !this.empty) {
          this.$emit("lazy-loading");
        }
      });
    },
  },
  i18n: {
    messages: {
      de: {
        loading: "Einträge werden geladen",
        nodata: "Keine Einträge gefunden",
      },
      fr: {
        loading: "Les entrées sont chargées",
        nodata: "Aucune entrée trouvée",
      },
    },
  },
};
</script>

<style scoped>
div.loading-wrapper {
  width: 100%;
  position: relative;
  padding-top: 20px;
}

p.empty {
  text-align: center;
}
</style>

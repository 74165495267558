<template>
  <fragment>
    <slot v-if="is_tag_set" />
    <input-wrapper v-else>
      <p v-text="text" />
    </input-wrapper>
  </fragment>
</template>

<script>
import InputWrapper from "../Edit/EditBoxChildren/InputWrapper.vue";

export default {
  name: "ConditionalTagSection",
  path: "components.logic_components.conditional_tag_section",
  components: {InputWrapper},
  props: {
    text: {type: String, required: false, default: ""},
    tagName: { type: String, required: true},
    tagCategory: { type: String, required: true},
    tagNumber: { type: String, required: true},
    bomObject: { type: Object, required: true},
  },
  computed: {
    /**
     * is_tag_set checks out whether the tag is set in the editobject.TagsPlugin
     * return true or false
     */
    is_tag_set() {
      if (this.bomObject?.TagsPlugin) {
        return this.bomObject.TagsPlugin.exists({
          name: this.tagName,
          number: this.tagNumber,
          category: this.tagCategory,
        });
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>

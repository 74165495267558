<template>
  <fragment>
    <v-col cols="12" v-for="channel in qualifications_channels" :key="channel.tag_number">
      <h3 class="primary--text">{{ channel.name }}</h3>
      <tag-on-off-switch
        :box-probs="boxProbs"
        :tag-category="channel.tag_category"
        :tag-name="channel.tag_name"
        :tag-number="channel.tag_number"
        :label-false="$t('no')"
        :label-true="$t('yes')"
      />
    </v-col>
    <v-col cols="12" v-if="!loading_tags && !has_channels">
      <p>{{ emptyMessage }}</p>
    </v-col>
    <v-col cols="12" v-if="loading_tags" class="pt-4">
      <loading-circle />
    </v-col>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import TagOnOffSwitch from "../../../components/Edit/EditBoxChildren/TagOnOffSwitch.vue";
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import LoadingCircle from "../../../components/UI-Elements/LoadingCircle.vue";

export default {
  name: "QualificationsSwitchesSection",
  path: "components.edit.edit_box_children.qualifications_switches",
  components: {LoadingCircle, TagOnOffSwitch},
  props: {
    qualificationsChannels: { // tag to be added or removed to the bomObject depending on the state
      type: Array, // Array of tag category, name, number and it's filter to get the name
      required: true,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
  },
  mixins: [EditBoxChildMixin],
  data() {
    return {
      loading_tags: true,
      valid_tag_numbers: [],
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
    qualifications_channels() {
      return this.qualificationsChannels.filter((channel) => this.valid_tag_numbers.includes(channel.tag_number));
    },
    has_channels() {
      return this.qualifications_channels.length > 0;
    },
  },
  created() {
    this.user.TagsPlugin.getAllPossibleTags()
      .then((tags) => {
        this.valid_tag_numbers = tags.map((tag) => tag.number);
      })
      .finally(() => {
        this.loading_tags = false;
      });
  },
  i18n: {
    messages: {
      de: {
        no: "Nein",
        yes: "Ja",
      },
      fr: {
        no: "Non",
        yes: "Oui",
      },
    },
  },
};
</script>

<style scoped>

</style>

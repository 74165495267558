<template>
    <div>
      <page-title
        :title="$t('my_mentorings')"
      />

      <page-subtitle
        :subtitle="$t('pendent_mentoring')"
      />
      <card-group
        :loading="loading"
        :loading-message="$t('loading_message')"
        :empty="pending_mentorings.length === 0"
        :empty-message="$t('empty_message')"
      >
        <mentor-mentoring-card
          v-for="mentoring in pending_mentorings"
          :key="mentoring.id"
          :mentoring="mentoring"
          pending
        />
      </card-group>

      <page-subtitle
        :subtitle="$t('future_mentorings')"
      />
      <card-group
        :loading="loading"
        :loading-message="$t('loading_message')"
        :empty="future_accepted_mentorings.length === 0"
        :empty-message="$t('empty_message')"
      >
        <mentor-mentoring-card
          v-for="mentoring in future_accepted_mentorings"
          :key="mentoring.id"
          :mentoring="mentoring"
        />
      </card-group>

      <page-subtitle
        :subtitle="$t('current_mentorings')"
      />
      <card-group
        :loading="loading"
        :loading-message="$t('loading_message')"
        :empty="current_accepted_mentorings.length === 0"
        :empty-message="$t('empty_message')"
      >
        <mentor-mentoring-card
          v-for="mentoring in current_accepted_mentorings"
          :key="mentoring.id"
          :mentoring="mentoring"
        />
      </card-group>

      <collapsible-group>
        <collapsible :title="$t('past_mentorings')">
          <card-group
            :loading="loading"
            :loading-message="$t('loading_message')"
            :empty="past_accepted_mentorings.length === 0"
            :empty-message="$t('empty_message')"
          >
            <mentor-mentoring-card
              v-for="mentoring in past_accepted_mentorings"
              :key="mentoring.id"
              :mentoring="mentoring"
              flat
            />
          </card-group>
        </collapsible>
        <collapsible :title="$t('rejected_mentorings')">
          <card-group
            :loading="loading"
            :loading-message="$t('loading_message')"
            :empty="rejected_mentorings.length === 0"
            :empty-message="$t('empty_message')"
          >
            <mentor-mentoring-card
              v-for="mentoring in rejected_mentorings"
              :key="mentoring.id"
              :mentoring="mentoring"
              flat
            />
          </card-group>
        </collapsible>
      </collapsible-group>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import MentorMentoringCard from "../../components/Cards/MentorMentoringCard.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../components/Structure/Collabsible.vue";
import MapTagNumberMixin from "../../mixins/ConstMappers/MapTagNumberMixin.js";

export default {
  name: "MentorsMentorings",
  path: "pages.mentoring.mentors_mentorings",
  mixins: [MapTagNumberMixin],
  components: {
    Collapsible,
    CollapsibleGroup,
    PageSubtitle,
    MentorMentoringCard,
    CardGroup,
    PageTitle,
  },
  data: () => ({
    loading: true,
    past_accepted_mentorings: [],
    current_accepted_mentorings: [],
    future_accepted_mentorings: [],
    pending_mentorings: [],
    rejected_mentorings: [],
  }),
  computed: {
    ...mapGetters("mentoring", {
      mentorInstance: "getOwnMentorInstance",
    }),
  },
  created() {
    Promise.all([
      this.fetchFutureAcceptedMentorings(),
      this.fetchPendingMentorings(),
      this.fetchCurrentAcceptedMentorings(),
    ]).then(() => {
      this.loading = false;
      return Promise.all([
        this.fetchPastAcceptedMentorings(),
        this.fetchRejectedMentorings(),
      ]);
    });
  },
  methods: {
    fetchCurrentAcceptedMentorings() {
      return this.$bom.mentoring.references.all
        .onClient("26")
        .toInstance(this.mentorInstance.id)
        .withTags(this.TAG_NUMBER_ACCEPTED) // Angenommen
        .withoutTags(this.TAG_NUMBER_TEMPORARY)
        .startingBefore("today")
        .endingAfter("today")
        .including("ArchiveRelationsPlugin", "AddressPlugin")
        .fetch()
        .then(({ references }) => {
          this.current_accepted_mentorings = references;
        });
    },
    fetchFutureAcceptedMentorings() {
      return this.$bom.mentoring.references.all
        .onClient("26")
        .toInstance(this.mentorInstance.id)
        .withTags(this.TAG_NUMBER_ACCEPTED) // Angenommen
        .withoutTags(this.TAG_NUMBER_TEMPORARY)
        .startingAfter("today")
        .including("ArchiveRelationsPlugin", "AddressPlugin")
        .fetch()
        .then(({ references }) => {
          this.future_accepted_mentorings = references;
        });
    },
    fetchPastAcceptedMentorings() {
      return this.$bom.mentoring.references.all
        .onClient("26")
        .toInstance(this.mentorInstance.id)
        .withTags(this.TAG_NUMBER_ACCEPTED) // Angenommen
        .withoutTags(this.TAG_NUMBER_TEMPORARY)
        .endingBefore("today")
        .including("ArchiveRelationsPlugin", "AddressPlugin")
        .fetch()
        .then(({ references }) => {
          this.past_accepted_mentorings = references;
        });
    },
    fetchRejectedMentorings() {
      return this.$bom.mentoring.references.all
        .onClient("26")
        .toInstance(this.mentorInstance.id)
        .withTags(this.TAG_NUMBER_REJECTED) // Abgelehnt
        .withoutTags(this.TAG_NUMBER_TEMPORARY)
        .including("ArchiveRelationsPlugin", "AddressPlugin")
        .fetch()
        .then(({ references }) => {
          this.rejected_mentorings = references;
        });
    },
    fetchPendingMentorings() {
      return this.$bom.mentoring.references.all
        .onClient("26")
        .toInstance(this.mentorInstance.id)
        .withTags(this.TAG_NUMBER_PENDING) // Pendent
        .withoutTags(this.TAG_NUMBER_TEMPORARY)
        .including("ArchiveRelationsPlugin", "AddressPlugin", "PositionsPlugin")
        .fetch()
        .then(({ references }) => {
          this.pending_mentorings = references;
        });
    },
  },
  i18n: {
    messages: {
      de: {
        my_mentorings: "Mentees verwalten",
        loading_message: "Mentorings werden geladen",
        empty_message: "Keine Mentorings gefunden",
        past_mentorings: "Vergangene Mentorings",
        current_mentorings: "Laufende Mentorings",
        future_mentorings: "Zukünftige Mentorings",
        pendent_mentoring: "Pendente Mentorings",
        rejected_mentorings: "Abgelehnte Mentorings",
      },
      fr: {
        my_mentorings: "Gérer les mentorés",
        loading_message: "Les mentorats sont chargés",
        empty_message: "Aucun mentorat trouvé",
        past_mentorings: "Mentorats passés",
        current_mentorings: "Mentorat en cours",
        future_mentorings: "Mentorat futur",
        pendent_mentoring: "Mentorat en attente",
        rejected_mentorings: "Mentorat rejeté",
      },
    },
  },
};
</script>

<style scoped>

</style>

/**
 * This file is executed when our application starts.
 * Here, we configure all plugins, create and mount vue
 */
import "@babel/polyfill"; // Polyfills add modern functions to older browsers
import "./assets/main.css"; // Our main CSS styling file
import "./plugins/filters.js"; // All vue filters

import Vue from "vue"; // Vue.js itself
import VuePageTransition from "vue-page-transition"; // Plugin that allows for animated page transitions
import Vuelidate from "vuelidate"; // Plugin that allows us to validate input fields
import Fragment from "vue-fragment"; // Invisible component that can be used to allow multiple root elements

import VueGtag from "vue-gtag"; // The authentication service ensures that no page is accessed if the user is not logged in
import eventBus from "./plugins/eventBus.js"; // A global event system for cross-component event communication
import bom from "./plugins/bom.js"; // plugin that imports and configures the bom framework

import router from "./router/index.js"; // The router manages all our pages and subpages
import i18n from "./languages/i18n.js"; // This tool allows us to make our application multi-langual
import store from "./store/index.js"; // The store is a place to share data between all our components
import vuetify from "./plugins/vuetify.js"; // Vuetify is the component library we use, similar to primevue
import App from "./App.vue"; // Import the first .vue file which will be the starting point of our application

import GlobalMixin from "./mixins/global/GlobalMixin.js";
import auth from "./services/auth.js"; // The authentication service ensures that no page is accessed if the user is not logged in

Vue.config.productionTip = false;

// Using Vue.prototype we can add variables or whole modules to the Vue instance
// that will be accessible within Vue instances using this.$X
Vue.prototype.$bom = bom;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$auth = auth;

Vue.prototype.$config = {
  LINE_INPUT_MAX_LENGTH: 60,
};

// Using Vue.use we can register vue plugins from NPM
Vue.use(Vuelidate);
Vue.use(Fragment.Plugin);
Vue.use(VuePageTransition);
Vue.use(VueGtag, {
  config: { id: "UA-38343293-3" },
}, router);

// Import global mixins
Vue.mixin(GlobalMixin);

// We create a new Vue instance and include the router, store, vuetify and i18n
// As the render function, we provide the App.vue component and specify to mount
// the component to the <div id="app"> that is found in the /public/index.html file
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

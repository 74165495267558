/**
 * The EditBoxChild mixin is implemented by all readonly components that can be placed within an EditBox.
 * This mixin handles the communication between the components and the EditBox itself.
 */
export default {
  props: {
    // The input object that the EditBox passes into its slot.
    // To see what data is passed as a boxProbs, have a look at the EditBox.vue component and see
    // what data is being bound to the <slot> component.
    // It contains (among others): editObject, editmode, saving, eventname
    boxProbs: { type: Object, required: true },
    // Controls whether the item can be edited. Most items can be edited, but some (like the user name)
    // are readonly.
    readonly: { type: Boolean, default: false },
  },
  watch: {
    /**
     * Watch changes within the editObject. If it changes (most probably since it reloaded after
     * save) call the editObjectUpdate method.
     */
    "boxProbs.editObject": {
      handler() {
        this.editObjectUpdate();
      },
      immediate: true,
    },
  },
  computed: {
    /**
     * The loading computed property controls the components loading state. It shall be in loading
     * state when the editObject is not yet available or when the editBox is saving changes.
     * @returns {boolean}
     */
    loading() {
      return !this.boxProbs.editObject || this.boxProbs.saving;
    },
  },
  /**
   * On created, register event listeners that react to the reset- and save events emitted by
   * the editBox.
   */
  created() {
    this.$eventBus.$on(`${this.boxProbs.eventname}-reset`, this.reset);
    this.$eventBus.$on(`${this.boxProbs.eventname}-save`, this.save);
  },
  /**
   * Before destroy, de-register the event listeners.
   */
  beforeDestroy() {
    this.$eventBus.$off(`${this.boxProbs.eventname}-reset`, this.reset);
    this.$eventBus.$off(`${this.boxProbs.eventname}-save`, this.save);
  },
  methods: {
    /**
     * Methods that is executed when the editBox emits the "save" event.
     * @abstract
     */
    save() {},
    /**
     * Methods that is executed when the editBox emits the "reset" event.
     * @abstract
     */
    reset() {},
    /**
     * Methods that is executed when the input editObject changes
     * @abstract
     */
    editObjectUpdate() {},
  },
};

<template>
  <dynamic-address-card :address="address">
    <noted-saved-icons :address="address"/>
  </dynamic-address-card>
</template>

<script>
import NotedSavedIcons from "../Icon/NotedSavedIcons.vue";
import DynamicAddressCard from "./DynamicAddressCard.vue";

export default {
  name: "ForeignPersonCard",
  path: "components.cards.foreign_person_card",
  components: {
    DynamicAddressCard,
    NotedSavedIcons,
  },
  props: {
    // The BomCompanyAddress/BomContactAddress of the company that shall be displayed here
    address: {type: Object, required: true},
  },
};
</script>

<style scoped>

</style>

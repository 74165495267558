<template>
  <details-plane-card
    :title="address | fullname"
    :action="routeToAddress"
  >
    <div class="topright">
      <slot></slot>
    </div>
    <p class="mb-1">
      {{address | registered_persons }}
    </p>
  </details-plane-card>
</template>

<script>
import DetailsPlaneCard from "./DetailsPlaneCard.vue";

/**
 * The compact person address card shows compact information about a person. On top is the name of the person,
 * and on the second line is the address, mobile and email shown.
 * The compact person address card is clickable and routes to the PersonAddress.vue page.
 */
export default {
  name: "CompactPersonAddressCard",
  path: "components.cards.compact_person_address_card",
  components: {
    DetailsPlaneCard,
  },
  props: {
    // The BomCompanyAddress/BomContactAddress of the company that shall be displayed here
    address: { type: Object, required: true },
  },
  methods: {
    routeToAddress() {
      this.$router.push({ name: "person-address", params: { id: this.address.id } });
    },
  },
};
</script>

<style scoped>
  .topright{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }
</style>

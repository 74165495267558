<template>
  <v-text-field
    v-model="text"
    :label="group.title"
    @keydown.enter="onFieldWrite"
    @focusout="onFieldWrite"
    :loading="loading"
    :error-messages="errors"
  />
</template>

<script>
import { required } from "vuelidate/lib/validators/index.js";
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMInputLine",
  path: "components.reg_mask.reg_mask_field.r_m_input_line",
  mixins: [RegMaskFieldMixin],
  data: () => ({
    text: "",
    loading: false,
  }),
  computed: {
    errors() {
      const errors = [];
      const field = this.$v.text;
      if (field.$invalid) {
        if (!field.required) {
          errors.push(this.$t("required"));
        }
      }
      return errors;
    },
  },
  methods: {
    syncShadowState() {
      this.text = this.group.content;
      this.loading = false;
    },
    onFieldWrite() {
      this.loading = true;
      this.group.write(this.text).then(() => {
        this.syncShadowState();
      });
    },
  },
  validations() {
    return {
      text: {
        ...(this.group.required && { required }),
      },
    };
  },
  i18n: {
    messages: {
      de: {
        required: "Das Feld muss ausgefüllt werden",
      },
      fr: {
        required: "Ce champ doit être rempli",
      },
    },
  },
};
</script>

<style scoped>

</style>

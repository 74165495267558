<template>
  <h2 class="page-subtitle">
    {{subtitle}}
  </h2>
</template>

<script>
/**
 * Subtitle of a page, it is a simple h2
 */
export default {
  name: "PageSubtitle",
  path: "components.ui_elements.page_subtitle",
  props: {
    // Subtitle text
    subtitle: { type: String, required: true, default: "" },
  },
};
</script>

<style scoped>
  h2.page-subtitle {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
    margin-bottom: 20px;
  }
</style>

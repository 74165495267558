<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <!-- in components=child it is defined what should be done to execute an event (eg by entering, by changing smth etc)
     v-model: update user_search to a new parameter when we search something
     @enter: event with name "enter" is executed in the child (eg @enter @change @input etc) and execute fetchEvents -->
    <search
      v-model="user_search"
      @enter="search"
    />
    <search-button
      @click="search"
    />

    <!-- REGISTERED EVENTS -->
    <page-subtitle
      :subtitle="$t('registered_events')"
      class="mt-4"
    />
    <registered-academies-section/>

    <!-- FURTHER EVENTS -->
    <page-subtitle
      :subtitle="$t('title')"
      class="mt-4"
    />
    <card-group
      :loading="loading_academies"
      :loading-message="$t('loading_message')"
      :empty="academies.length === 0"
      :empty-message="$t('empty_message')"
      @lazy-loading="onIntersect"
    >
      <academies-card
        v-for="academies in academies"
        :key="academies.id"
        :academies="academies"
      />
    </card-group>
  </div>
</template>

<script>
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";
import RegisteredAcademiesSection from "./components/RegisteredAcademiesSection.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import Search from "../../components/SearchInputs/Search.vue";
import SearchButton from "../../components/SearchInputs/SearchButton.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import AcademiesCard from "../../components/Cards/AcademiesCard.vue";

export default {
  name: "MarketPlaceSearch",
  path: "pages.market-place.search",
  components: {
    RegisteredAcademiesSection,
    PageSubtitle,
    AcademiesCard,
    CardGroup,
    SearchButton,
    Search,
    PageTitle,
  },
  created() {
    this.fetchEvents();
  },
  data() {
    return {
      academies: [],
      loading_academies: false,
      total_number_of_academies: undefined,
    };
  },
  methods: {
    search() {
      this.academies = [];
      this.fetchEvents();
    },
    fetchEvents() {
      this.loading_academies = true;
      let bom_query = this.$bom.marketplace.instances.all // bom-framework
        .skip(this.academies.length) // skip the amount of events that have already been loaded
        .take(6) // take 6 events from bom
        .sortedBy("name");

      if (this.user_search) { // when the user enter something in the search componente
        bom_query = bom_query.contains(this.user_search);
      }

      bom_query.fetch()
        .then(({
          instances,
          items_in_database,
        }) => {
          this.total_number_of_academies = items_in_database;
          this.academies = this.academies.concat(instances);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_academies = false; // put it as false so that it will not load anymore events
        });
    },
    onIntersect() {
      if (!this.loading_academies && this.academies.length < this.total_number_of_academies) {
        this.fetchEvents();
      }
    },
  },
  computed: {
    user_search: {
      get() {
        return this.$route.query.search_term || "";
      },
      set(term) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search_term: term,
          },
        });
      },
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Academies",
        loading_message: "Academies werden geladen",
        empty_message: "Keine Academies gefunden",
        registered_events: "Angemeldete Academies",
      },
      fr: {
        title: "Académies",
        loading_message: "Les Académies sont en cours de chargement",
        empty_message: "Aucun Académies suivant trouvé",
        registered_events: "Académies inscrit",
      },
      it: {
        title: "Accademie",
        loading_message: "Gli Accademie vengono caricati",
        empty_message: "Nessun Accademie trovato",
        registered_events: "Accademie previsti",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-switch
    :readonly="!boxProbs.editmode"
    :input-value="is_tag_set"
    @change="addOrRemoveTag(!!$event)"
    :label="is_tag_set ? labelTrue : labelFalse"
    :loading="loading"
    :disabled="boxProbs.editmode && readonly"
  />
</template>

<script>
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";

export default {
  name: "TagOnOffSwitch",
  path: "components.edit.edit_box_children.tag_on_off_switch",
  mixins: [EditBoxChildMixin],
  props: {
    tagName: { type: String, required: true},
    tagCategory: { type: String, required: true},
    tagNumber: { type: String, required: true},
    labelTrue: { type: String, required: true},
    labelFalse: { type: String, required: true},
  },
  computed: {
    /**
     * is_tag_set checks out whether the tag is set in the boxProps.editobject.TagsPlugin
     * return true or false
     */
    is_tag_set() {
      if (this.boxProbs.editObject.TagsPlugin) {
        return this.boxProbs.editObject.TagsPlugin.exists({
          name: this.tagName,
          number: this.tagNumber,
          category: this.tagCategory,
        });
      }
      return false;
    },
  },
  async created() {
    if (!this.boxProbs.editObject.TagsPlugin) {
      await this.boxProbs.editObject.loadTagsPlugin();
    }
  },
  methods: {
    addOrRemoveTag(addTag) {
      if (addTag) {
        this.boxProbs.editObject.TagsPlugin.create(this.tagCategory, this.tagNumber, this.tagName);
      } else {
        this.boxProbs.editObject.TagsPlugin.remove({ name: this.tagName, number: this.tagNumber, category: this.tagCategory });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div
    class="regmask-placeholder-container"
  >
    <v-skeleton-loader
      class="regmask-placeholder"
      type="article, list-item-two-line, article, list-item-two-line, actions"
    />
    <loading-circle :message="$t('loading_regmask')" class="loader"/>
  </div>
</template>

<script>
import LoadingCircle from "../UI-Elements/LoadingCircle.vue";

export default {
  name: "RegMaskPlaceholder",
  path: "components.reg_mask.reg_mask_placeholder",
  components: {LoadingCircle},
  i18n: {
    messages: {
      de: {
        loading_regmask: "Anmeldemaske wird geladen",
      },
      fr: {
        loading_regmask: "Le masque de login est chargé",
      },
    },
  },
};
</script>

<style scoped>
  .regmask-placeholder-container {
    position: relative;
  }
  .regmask-placeholder {
    opacity: 0.5;
  }
  .loader {
    position: absolute;
    top: 150px;
    width: 100%;
    font-weight: bold;
    font-size: 2rem;
  }
</style>

<template>
  <input-wrapper>
    <v-select
      multiple
      chips
      deletable-chips
      return-object
      item-text="name"
      :value="value"
      :items="tags"
      :label="label"
      :loading="loading"
      @input="$emit('input', $event)"
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "../Edit/EditBoxChildren/InputWrapper.vue";

export default {
  name: "MultiTagSelector",
  path: "components.search_inputs.multi_tag_selector",
  components: {InputWrapper},
  props: {
    value: { type: Array, required: true },
    tags: { type: Array, required: true },
    label: { type: String, required: true },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('subject_areas')"
    />
    <collapsible-group>
      <collapsible :title="$t('subject_areas')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="subject_areas_channels"
              :empty-message="subject_areas_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "SubjectAreas",
  path: "pages.qualifications.subject_areas",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      subject_areas_channels: [
        {
          name: this.$t("forensics"), tag_category: "Fachrichtung", tag_name: "Forensik", tag_number: "22552",
        },
        {
          name: this.$t("neuropsychology"), tag_category: "Fachrichtung", tag_name: "Neuropsychologie", tag_number: "22553",
        },
        {
          name: this.$t("writing_psychology"), tag_category: "Fachrichtung", tag_name: "Schriftpsychologie", tag_number: "21654",
        },
        {
          name: this.$t("sports_psychology"), tag_category: "Fachrichtung", tag_name: "Sportpsychologie", tag_number: "22554",
        },
        {
          name: this.$t("traffic_psychology"), tag_category: "Fachrichtung", tag_name: "Verkehrspsychologie", tag_number: "22555",
        },
        {
          name: this.$t("gerontology"), tag_category: "Fachrichtung", tag_name: "Gerontologie", tag_number: "22556",
        },
        {
          name: this.$t("emergency_psychology"), tag_category: "Fachrichtung", tag_name: "Notfallpsychologie", tag_number: "22557",
        },
        {
          name: this.$t("school_psychology"), tag_category: "Fachrichtung", tag_name: "Schulpsychologie", tag_number: "22558",
        },
        {
          name: this.$t("trauma_psychology"), tag_category: "Fachrichtung", tag_name: "Traumapsychologie", tag_number: "22559",
        },
        {
          name: this.$t("animal_psychology"), tag_category: "Fachrichtung", tag_name: "Tierpsychologie", tag_number: "22560",
        },
      ],
      subject_areas_message: this.$t("subject_areas_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        subject_areas: "Fachrichtungen",
        forensics: "Forensik",
        neuropsychology: "Neuropsychologie",
        writing_psychology: "Schriftpsychologie",
        sports_psychology: "Sportpsychologie",
        traffic_psychology: "Verkehrspsychologie",
        gerontology: "Gerontologie",
        emergency_psychology: "Notfallpsychologie",
        school_psychology: "Schulpsychologie",
        trauma_psychology: "Traumapsychologie",
        animal_psychology: "Tierpsychologie",
        subject_areas_message: "Keine Fachrichtungen vorhanden",
      },
      fr: {
        subject_areas: "Sujets",
        forensics: "Forensics",
        neuropsychology: "Neuropsychologie",
        writing_psychology: "Écrit la psychologie",
        sports_psychology: "Psychologie du sport",
        traffic_psychology: "Psychologie de la circulation",
        gerontology: "Gérontologie",
        emergency_psychology: "Psychologie d'urgence",
        school_psychology: "Psychologie scolaire",
        trauma_psychology: "Psychologie des traumatismes",
        animal_psychology: "Psychologie animale",
        subject_areas_message: "Aucune spécialisation disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

import default_image from "../assets/images/placeholders/std-private.png";
import ProfileImageService from "../services/ProfileImageService.js";

const today = new Date();

export default {
  data() {
    return {
      image: default_image,
    };
  },
  computed: {
    subjects() {
      if (!this.mentor) { return []; }
      return this.mentor.TagsPlugin.Themenfelder;
    },
    current_registrations() {
      if (!this.mentor) { return []; }
      return this.mentor.ReferencesPlugin.all
        .filter((ref) => ref.from < today && ref.to > today);
    },
    isAvailable() {
      return this.current_registrations.length < this.mentor.IncidentPlugin.max_registrations;
    },
    availableAfter() {
      const nextEndingReference = this.current_registrations.reduce((previous, current) => {
        return (current.to && previous.to < current.to) ? previous : current;
      }, {});
      return nextEndingReference.to || this.$t("unknown");
    },
  },
  methods: {
    loadImage() {
      ProfileImageService.fetchProfileImage(this.mentor)
        .then((file) => {
          this.image = file || default_image;
        });
    },
  },
};

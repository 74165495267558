<template>
  <input-wrapper
    v-show="show"
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :disabled="!boxProbs.editmode"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatted_date"
          :label="label"
          readonly
          :loading="loading"
          v-bind="attrs"
          v-on="on"
          placeholder="-"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        :max="maxDateString"
        :min="minDateString"
        @change="$refs.menu.save(date)"
        :first-day-of-week="1"
        :locale="language"
      ></v-date-picker>
    </v-menu>
  </input-wrapper>
</template>

<script>
import {utcToZonedTime} from "date-fns-tz";
import {format} from "date-fns";
import DatePickerMixin from "../../../mixins/DatePickerMixin.js";
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

/**
 * The DatePicker component lets the user input a date. First, the user is prompted with only the year
 * selector, then the month, and lastly the day.
 *
 * Mixins: InputMixin, DatePickerMixin
 */
export default {
  name: "DatePicker",
  path: "components.edit.edit_box_children.date_picker",
  components: {InputWrapper},
  mixins: [InputMixin, DatePickerMixin],
  props: {
    isBirthday: {type: Boolean, required: false, default: false},
  },
  computed: {
    /**
     * Returns the date in a format that is easily readable to the end user, like "01.11.1997"
     * @returns {string}
     */
    formatted_date() {
      if (this.value) {
        if (window?.bomconfig?.timeshift === "zoned") {
          return format(utcToZonedTime(this.value, "Europe/Bern"), "dd.MM.yyyy");
        }
        if (this.isBirthday) {
          this.value.setTime(this.value.getTime() + 60 * 60 * 1000);
        }
        return format(this.value, "dd.MM.yyyy");
      }
      return "";
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <v-btn
      v-if="!deleted"
      block
      :color=" confirm_deletion ? 'error' : undefined"
      @click="deleteClicked"
      :loading="deleting"
    >
      {{confirm_deletion ? $t("confirm_deletion") : $t("delete") }}
      <v-icon
        right
        dark
        small
      >
        mdi-delete
      </v-icon>
    </v-btn>
    <success-alert
      v-model="deleted"
      :message="redirect? $t('deleted_redirect') : $t('deleted')"
    />
  </fragment>
</template>

<script>
import SuccessAlert from "../Alerts/SuccessAlert.vue";

/**
 * Button that needs confirmation before emitting a "delete" event. After successfull deletion, it
 * redirection_path the user to a new page.
 */
export default {
  name: "DeleteButton",
  path: "components.ui_elements.delete_button",
  components: {SuccessAlert},
  data() {
    return {
      confirm_deletion: false, // Whether confirmation for deletion is awaited
    };
  },
  props: {
    // Indicates whether the item whose deletion is controlled is being deleted
    deleting: { type: Boolean, required: true, default: false },
    // Indicates whether the item whose deletion is controlled has been deleted
    deleted: { type: Boolean, required: true, default: false },
    // Location to which the user shall be redirected after successfull deletion of the item
    redirect: { type: String, required: false, default: "" },
  },
  methods: {
    /**
     * Function that is executed whenever delete button is clicked. Requests confirmation for the
     * first time, emits a "delete" event when clicked again.
     */
    deleteClicked() {
      if (!this.confirm_deletion) {
        this.confirm_deletion = true;
        return;
      }
      this.$emit("delete");
    },
  },
  watch: {
    /**
     * Watches the "deleted" attribute. When item is deleted and redirection path is provided, show
     * user a message that he will be redirected, then redirection_path him to new page.
     */
    deleted() {
      if (this.deleted && this.redirect) {
        new Promise((resolve) => setTimeout(resolve, 3000))
          .then(() => {
            this.$router.push({name: this.redirect});
          });
      }
    },
  },
  i18n: {
    messages: {
      de: {
        delete: "Löschen",
        confirm_deletion: "Löschen bestätigen",
        deleted_redirect: "Löschen erfolgreich. Sie werden in Kürze umgeleited.",
        deleted: "Löschen erfolgreich.",
      },
      fr: {
        delete: "Supprimer",
        confirm_deletion: "Confirmer la suppression",
        deleted_redirect: "Suppression réussie. Vous serez redirigé sous peu.",
        deleted: "Suppression réussie.",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <v-col
      cols="12"
      sm="6"
      lg="6"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :elevation="(hover ? 12 : 2)"
            @click="openLink"
            style="height: 100%"
          >
            <v-img
              :src="image"
            >
            </v-img>
          </v-card>
        </template>
      </v-hover>
    </v-col>
</template>

<script>

/**
 * This component is a full image card displaying an image and no text. When clicking on the card, a
 * link is opened in a new window. This can be used for advertisements.
 */
export default {
  name: "FullImageCard",
  path: "components.cards.full_image_card",
  props: {
    /**
     * Image that is displayed on the card
     */
    image: { type: String, required: true },
    /**
     * Link that will be opened when clicking on card
     */
    link: { type: String, required: true },
  },
  methods: {
    /**
     * When the card gets clicked, the link will be opened in a new window.
     */
    openLink() {
      window.open(this.link);
    },
  },
};
</script>

<style scoped>

</style>

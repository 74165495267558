<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <collapsible-group>
      <collapsible :title="$t('correspondance')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <correspondance-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible :title="$t('infochannels')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <info-channels-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../components/Structure/Collabsible.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CorrespondanceSection from "./sections/CorrespondanceSection.vue";
import InfoChannelsSection from "./sections/InfoChannelsSection.vue";

/**
 * The "communications" screen is a medium to specify if you want to receive paper-mail from
 * fhconnect to one of your addresses.
 *
 * For example, under "Correspondance", you can specify that you'd like to receive their montly magazine to your main
 * address or to one of your company-addresses. This section is rather simple, since it just let's you
 * choose one of your addresses (Personal, Contact) and saves it as an address relation of type "Post", "EMail" or "Bill".
 *
 * Under "Info-Channels", you can set flags whether you want to receive mails about certain topics. Each of
 * these flags is nothing more than a Tag that is either present or not present on the users address. If the tag
 * is present, he gets the mails, if it is not present, he does not get them.
 *
 * TODO: This screen is not yet finished implementing. The Address-Chooser does not yet work and the info-channels switch is not implemented.
 */
export default {
  name: "Communication",
  path: "pages.communication.communication",
  components: {
    InfoChannelsSection,
    CorrespondanceSection,
    EditBox,
    Collapsible,
    CollapsibleGroup,
    PageTitle,
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        title: "Kommunikation",
        correspondance: "Korrespondenz",
        infochannels: "Info-Kanäle",
      },
      fr: {
        title: "Communication",
        correspondance: "Correspondance",
        infochannels: "Circuits d'information",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <collapsible-group>
      <collapsible :title="form | name">
        <edit-box
          :editable="false"
          :edit-object="form"
        >
          <template v-slot:default="boxProbs">
            <html-input
              :box-probs="boxProbs"
              :label="$t('description')"
              field="description"
              md="12"
              sm="12"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible
        v-if="has_archive"
        :title="$t('file_sharing')"
      >
        <file-download-card
          v-for="archive_relation in archive_relations"
          :key="archive_relation.id"
          :archive-relation="archive_relation"
          :deletable="false"
        />
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import HtmlInput from "../../../components/Edit/EditBoxChildren/HtmlInput.vue";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import CollapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import FileDownloadCard from "../../../components/Cards/FileDownloadCard.vue";

export default {
  name: "FormDetails",
  path: "pages.forms.details.form_details",
  components: {
    FileDownloadCard,
    HtmlInput,
    EditBox,
    Collapsible,
    CollapsibleGroup,
    PageTitle,
  },
  mixins: [DetailPageMixin],
  data() {
    return {
      form: undefined,
      archive_relations: [],
    };
  },
  computed: {
    has_archive() {
      return this.archive_relations !== undefined && this.archive_relations?.length > 0;
    },
  },
  methods: {
    fetchForm() {
      return this.$bom.marketplace.instances.one
        .withId(this.id)
        .including("ArchiveRelationsPlugin")
        .fetch()
        .then(({instance}) => {
          this.form = instance;
          this.extractArchiveRelations();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    extractArchiveRelations() {
      const now = new Date();
      this.archive_relations = this?.form?.ArchiveRelationsPlugin.all
        .filter((archive_rel) => !archive_rel.from || archive_rel.from < now)
        .filter((archive_rel) => !archive_rel.to || archive_rel.to > now);
    },
  },
  created() {
    this.fetchForm();
  },
  i18n: {
    messages: {
      de: {
        title: "Details",
        description: "Beschreibung",
        file_sharing: "Dokumente herunterladen",
      },
      fr: {
        title: "Détails",
        description: "Description",
        file_sharing: "Télécharger des documents",
      },
    },
  },
};
</script>

<template>
    <fragment>
          <tag-selector
            :box-probs="boxProbs"
            :label="$t('subject')"
            tag-category="Fachrichtung"
          />
          <tag-selector
            :box-probs="boxProbs"
            :label="$t('specialization')"
            tag-category="Vertiefungsrichtung"
          />
          <tag-selector
            :box-probs="boxProbs"
            :label="$t('level')"
            tag-category="Ausbildungs-Stufe"
          />
          <address-selector
            :box-probs="boxProbs"
            :label="$t('location')"
            relation-name="Ort"
            relation-number="21641"
            show-address="last_name"
            :selectable-type-numbers="[21641]"
          />
          <text-input
            :box-probs="boxProbs"
            :label="$t('thesis')"
            :max-length="$config.LINE_INPUT_MAX_LENGTH"
            field="info3"
          />
          <v-row class="ma-0">
            <year-picker
              :box-probs="boxProbs"
              :label="$t('start')"
              :max-date="endDate"
              field="from"
            />
            <year-picker
              :box-probs="boxProbs"
              :label="$t('end')"
              :min-date="startDate"
              field="to"
            />
          </v-row>
    </fragment>
</template>

<script>

import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import MapForeignKeyNumbersMixin from "../../../../mixins/ConstMappers/MapForeignKeyNumbersMixin.js";
import YearPicker from "../../../../components/Edit/EditBoxChildren/YearPicker.vue";
import AddressSelector from "../../../../components/Edit/EditBoxChildren/AddressSelector.vue";
import TagSelector from "../../../../components/Edit/EditBoxChildren/TagSelector.vue";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";

/**
 * Mixins: EditBoxChildMixin, MapForeignKeyNumbersMixin
 */
export default {
  name: "EducationPersonSection",
  path: "pages.education.education",
  mixins: [EditBoxChildMixin, MapForeignKeyNumbersMixin],
  components: {
    YearPicker,
    AddressSelector,
    TagSelector,
    TextInput,
  },
  computed: {
    endDate() {
      return this.boxProbs.editObject?.to;
    },
    startDate() {
      return this.boxProbs.editObject?.from;
    },
  },
};
</script>

<style scoped>

</style>

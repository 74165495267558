import {get} from "lodash";
import messages from "../../languages/messages.js";

export default {
  created() {
    if (!this.$options.path) { return; }
    Object.entries(messages).forEach(([lang, msgs]) => {
      this.$i18n.mergeLocaleMessage(lang, get(msgs, this.$options.path, {}));
    });
  },
};

<template>
  <image-card
    :title="qualification | timeperiod"
    :image="image"
    :action="routeToFurtherEducation"
  >
    <p>
      {{qualification | tagcat("Weiterbildungs-Stufe")}}
    </p>
    <p>
      {{qualification | adrrelcat("Ort", "last_name")}}
    </p>
    <p>
      {{qualification | adrrelcat("Ort", "city")}}
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-weiterbildung.png";

/**
 * The qualification card summarizes an qualification of a user. On the left, only a default image is shown,
 * while on the right, some information about the education is displayed. Clicking the card routes
 * to the FurtherEducation.vue page.
 */
export default {
  name: "QualificationCard",
  path: "components.cards.qualification_card",
  components: {ImageCard},
  data() {
    return {
      // Holds the image that is shown on the left of the card
      image: default_image,
    };
  },
  props: {
    // The qualification that shall be displayed here. This is a BomReference
    qualification: { type: Object, required: true },
  },
  methods: {
    /**
     * Routes to the FurtherEducation.vue page with the ID of the education as a parameter.
     */
    routeToFurtherEducation() {
      this.$router.push({
        name: "furtherEducation",
        params: {
          id: this.qualification.id,
        },
      });
    },
  },
};
</script>

<style scoped>
  p {
    margin-bottom: 4px !important;
  }
</style>

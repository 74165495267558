import default_image from "../assets/images/placeholders/std-private.png";
import ProfileImageService from "../services/ProfileImageService.js";

/**
 * The AddressCardMixin shares functionalities between ImageCards that show differnet kind of addresses,
 * such as PersonAddress or CompanyAddress.
 */
export default {
  props: {
    // The BomCompanyAddress/BomContactAddress of the company that shall be displayed here
    address: { type: Object, required: true },
  },
  data() {
    return {
      // The image that is shown on the left of the card.
      image: default_image,
      // Specifies where to route to when the card is clicked
      route_to: "error",
    };
  },
  /**
   * on created, load the profile image of the given address
   */
  created() {
    ProfileImageService.fetchProfileImage(this.address)
      .then((file) => {
        if (file) { this.image = file; }
      });
  },
  methods: {
    /**
     * Routes to the "CompanyAddress.vue page with the addresses ID as a parameter
     */
    routeTo() {
      this.$router.push({
        name: this.route_to,
        params: {
          id: this.address.id,
        },
      });
    },
  },
};

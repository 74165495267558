<template>
  <v-snackbar
    top
    right
    v-model="snackbar"
    :timeout="timeout || 3000"
    :color="color || 'primary'"
    multi-line
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarService",
  path: "components.snackbars.snackbar_service",
  data: () => ({
    snackbar: false,
    text: undefined,
    timeout: undefined,
    color: undefined,
  }),
  created() {
    this.$eventBus.$on("snackbar", this.showSnackBar);
  },
  beforeDestroy() {
    this.$eventBus.$off("snackbar", this.showSnackBar);
  },
  methods: {
    showSnackBar(options) {
      this.snackbar = true;
      this.text = options.text;
      this.timeout = options.timeout;
      this.color = options.color;
    },
  },
};
</script>

<style scoped>

</style>

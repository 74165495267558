<template>
  <div>
    <page-title
      :title="$t('edit_mentor')"
    />
    <reg-mask
      :load-regmask-for="registration"
      :loading-progress.sync="loadingProgress"
      :success-message="$t('success_message')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import RegMask from "../../components/RegMask/RegMask.vue";

export default {
  name: "PendingMentorRegistration",
  path: "pages.mentoring.pending_mentor_registration",
  components: {RegMask, PageTitle},
  data: () => ({
    loadingProgress: 0,
  }),
  computed: {
    ...mapGetters("mentoring", {
      registration: "getOpenMentorRegistration",
    }),
  },
  i18n: {
    messages: {
      de: {
        edit_mentor: "Offene Anmeldung bearbeiten",
        success_message: "Ihre Anmeldung zum/zur Mentor/-in wurde erfolgreich geändert!",
      },
      fr: {
        edit_mentor: "Modifier l'inscription ouverte",
        success_message: "Votre inscription en tant que mentor a été modifiée avec succès!",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <!-- @click: executed by clicking the v-btn button -> event "click" will be executed -->
  <v-btn
    color="primary"
    @click="$emit('click')"
    :disabled="disabled"
  >
    {{label || $t("default_label")}}
    <v-icon
      right
      dark
    >
      mdi-magnify
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SearchButton",
  path: "components.search_inputs.search_button",
  props: {
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
  },
  i18n: {
    messages: {
      de: {
        default_label: "Suchen",
      },
      fr: {
        default_label: "Rechercher",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <image-card
    :image="image"
    :title="title"
  >
    <p
      class="mb-1"
      v-for="(link, index) in links"
      :key="link.route + index"
    >
      <router-link
        class="subtitle-1 font-weight-bold text-decoration-none"
        :to="{name: link.route, params: (link.params || {}), query: (link.query || {})}"
        v-show="!link.hide"
      >
        {{link.text}}
      </router-link>
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";

/**
 * The menu-card component receives an array of link items that are then displayed as clickable links
 * on the right of the card. Clicking a link routes to a new page. To create the link, the <router-link>
 * component is used.
 */
export default {
  name: "MenuCard",
  path: "components.cards.menu_card",
  components: {ImageCard},
  props: {
    // Title of the card, displayed on the right
    title: { type: String, required: true },
    // Image that is displayed on the left of the card
    image: { type: String, default: "", required: false },
    // Array of link objects in the form [{text: string, route: string, hide: boolean}, ...]
    links: { type: Array, required: true },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-list>
      <file-download-card
        v-for="archive_relation in archive_relations"
        :key="archive_relation.id"
        :archive-relation="archive_relation"
        @save="saveMentoring"
      />
    </v-list>
    <div>
      <v-btn
        v-if="!new_file_flow"
        @click="new_file_flow = true"
        block
        elevation="2"
        color="primary"
      >
        {{ $t('upload_new_file') }}
      </v-btn>
      <v-row v-else>
        <v-col sm="12" md="6">
          <v-file-input
            show-size
            prepend-icon=""
            append-icon="mdi-upload"
            color="primary"
            :label="$t('select_file')"
            @change="addNewFile($event)"
          />
        </v-col>
        <v-col sm="12" md="6">
          <div class="d-flex justify-space-between">
            <v-text-field
              v-model="filename"
              :label="$t('filename')"
              hide-details="auto"
            />
            <v-btn
              color="primary"
              :disabled="!uploaded_file || !filename"
              @click="createNewArchive"
              :loading="new_file_creation_pending"
            >
              {{ $t("upload") }}
              <v-icon small right>
                mdi-upload
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FileDownloadCard from "../../../../components/Cards/FileDownloadCard.vue";

export default {
  name: "FileSharingSection",
  path: "pages.mentoring.mentoring_details.sections.file_sharing_section",
  components: {FileDownloadCard},
  props: {
    mentoring: { type: Object, required: true },
  },
  data: () => ({
    new_file_flow: false,
    new_file_creation_pending: false,
    archive_relations: [],
    filename: "",
    filetype: "",
    uploaded_file: undefined,
  }),
  created() {
    this.extractArchiveRelations();
  },
  methods: {
    extractArchiveRelations() {
      const now = new Date();
      this.archive_relations = this?.mentoring?.ArchiveRelationsPlugin.all
        .filter((archive_rel) => !archive_rel.from || archive_rel.from < now)
        .filter((archive_rel) => !archive_rel.to || archive_rel.to > now)
        .filter((archive_rel) => archive_rel.archive.name !== "profilbild")
        .filter((archive_rel) => archive_rel.archive.name !== "Anfrage Mentee an Mentor_Anfrage auf Standby");
    },
    addNewFile(file) {
      if (!file) { return; }
      this.filetype = `.${file.type.split("/")[1]}`;
      const file_reader = new FileReader();
      file_reader.readAsDataURL(file);
      file_reader.onload = () => {
        this.uploaded_file = file_reader.result;
      };
    },
    async createNewArchive() {
      this.new_file_creation_pending = true;
      const temp_client = this.$bom.client;
      this.$bom.client = {
        client_id: "26",
        client_name: "mentoring",
      };
      const archive_relation = await this.mentoring.ArchiveRelationsPlugin.createNewArchive(new Date());
      this.$bom.client = temp_client;
      const archive = archive_relation.archive;
      const FilePlugin = archive.FilePlugin;
      archive_relation.archive.TagsPlugin.addPublicationWebTag();
      FilePlugin.file = this.uploaded_file;
      FilePlugin.filename = `${this.filename}${this.filetype}`;
      archive.name = `${this.filename}${this.filetype}`;
      archive.description = "document";
      archive.save();
      await this.mentoring.save();
      this.new_file_creation_pending = false;
      this.new_file_flow = false;
      this.filename = "";
      this.filetype = "";
      this.extractArchiveRelations();
    },
    saveMentoring() {
      this.extractArchiveRelations();
      return this.mentoring.save();
    },
  },
  i18n: {
    messages: {
      de: {
        upload_new_file: "Neues File hochladen",
        select_file: "File auswählen",
        upload: "Hochladen",
        filename: "Dateiname",
      },
      fr: {
        upload_new_file: "Télécharger un nouveau fichier",
        select_file: "Sélectionner un fichier",
        upload: "Télécharger",
        filename: "Nom du fichier",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('clinical_psychology')"
    />
    <collapsible-group>
      <collapsible :title="$t('clinical_psychology')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="clinical_psychology_channels"
              :empty-message="clinical_psychology_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "ClinicalPsychology",
  path: "pages.qualifications.clinical_psychology",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      clinical_psychology_channels: [
        {
          name: this.$t("psychotherapy_psychodynamic"), tag_category: "Fachrichtung", tag_name: "Psychotherapie psychodynamisch", tag_number: "22547",
        },
        {
          name: this.$t("psychotherapy_cognitive_behavioral"), tag_category: "Fachrichtung", tag_name: "Psychotherapie kognitiv behavioral", tag_number: "22548",
        },
        {
          name: this.$t("psychotherapy_humanistic"), tag_category: "Fachrichtung", tag_name: "Psychotherapie humanistisch (inkl. Körper)", tag_number: "22549",
        },
        {
          name: this.$t("systemic_psychotherapy"), tag_category: "Fachrichtung", tag_name: "Psychotherapie systemisch", tag_number: "22550",
        },
        {
          name: this.$t("animal_human"), tag_category: "Fachrichtung", tag_name: "Tier und Mensch", tag_number: "22551",
        },
      ],
      clinical_psychology_message: this.$t("school_psychology_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        clinical_psychology: "Klinische Psychologie",
        psychotherapy_psychodynamic: "Psychotherapie psychodynamisch",
        psychotherapy_cognitive_behavioral: "Psychotherapie kognitiv behavioral",
        psychotherapy_humanistic: "Psychotherapie humanistisch (inkl. Körper)",
        systemic_psychotherapy: "Psychotherapie systemisch",
        animal_human: "Tier und Mensch",
        clinical_psychology_message: "Keine klinische Psychologie vorhanden",
      },
      fr: {
        clinical_psychology: "Psychologie clinique",
        psychotherapy_psychodynamic: "Psychothérapie psychodynamique",
        psychotherapy_cognitive_behavioral: "Psychothérapie cognitivo-comportementale",
        psychotherapy_humanistic: "Psychothérapie humaniste (y compris le corps)",
        systemic_psychotherapy: "Psychothérapie systémique",
        animal_human: "Animal et humain",
        clinical_psychology_message: "Aucune psychologie clinique disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <fragment>
      <side-menu v-model="sidebar_open" v-if="user && logged_in"/>
      <topbar @drawer="sidebar_open = !sidebar_open"/>
    </fragment>
</template>

<script>
import {mapGetters} from "vuex";
import Topbar from "./Topbar.vue";
import SideMenu from "./SideMenu.vue";

/**
 * The NavigationWrapper unites the sidebar and topbar component.
 */
export default {
  name: "Navigation",
  path: "components.navigation.navigation_wrapper",
  components: { SideMenu, Topbar },
  data() {
    return {
      sidebar_open: false, // Controls the open state of the sidebar
    };
  },
  computed: {
    /**
     * Map the currently logged in user from the user store module to a local computed property
     */
    ...mapGetters("user", {
      user: "getUser",
    }),

    ...mapGetters("auth", {
      logged_in: "isLoggedIn",
    }),

    permanent_number: {
      get() {
        return sessionStorage.getItem("permanent_number");
      },
      set(new_number) {
        sessionStorage.setItem("permanent_number", new_number);
      },
    },
  },

};
</script>

<style scoped>

</style>

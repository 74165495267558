<template>
  <div class="login-container">
    <div class="loading">
      <loading-circle
        :message="$t('wait')"
      />
    </div>
    <iframe
      id="login-iframe"
      name="login-iframe"
      @load="iframeloaded = true"
      v-show="iframeloaded"
    />
  </div>
</template>

<script>
import LoadingCircle from "../../components/UI-Elements/LoadingCircle.vue";

/**
 * The login page promts the user with a login iframe. It uses the bom-framework login method
 * to identify whether the user logged-in successfully, and if so redirects him to the client
 * chooser page.
 */
export default {
  name: "Login",
  path: "pages.presteps.login",
  components: {LoadingCircle},
  data() {
    return {
      iframeloaded: false,
    };
  },
  mounted() {
    this.$auth.login()
      .then(() => {
        this.$router.push({name: "client-chooser", query: this.$route.query, params: this.$route.params});
      })
      .catch((err) => {
        console.error(err);
      });
  },
  i18n: {
    messages: {
      de: {
        wait: "Bitte warten Sie einen Moment...",
      },
      fr: {
        wait: "Veuillez patienter un moment svp...",
      },
    },
  },
};
</script>

<style scoped>
  div.login-container {
    position: relative;
    width: 340px;
    height: 450px;
  }
  div.loading {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
  }
  iframe#login-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    border: none;
    border-radius: 6px;
    display: block;
  }
</style>

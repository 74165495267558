<template>
  <div class="mb-5">
      <div
      class="editor-container pa-3 pb-0"
      @mouseenter="onMouseChange(true)"
      @mouseleave="onMouseChange(false)"
      @focusin="onFocusChange(true)"
      @focusout="onFocusChange(false)"
    >
      <editor-menu-bar
        v-if="!readonly"
        v-slot="{ commands, isActive }"
        :editor="editor"
      >
        <div class="d-flex pb-2">
          <v-card
            v-show="!readonly"
            elevation="0"
            class="light-border"
          >
            <v-icon
              @click="commands.undo"
            >
              mdi-undo
            </v-icon>
            <v-icon
              style="border: none;"
              @click="commands.redo"
            >
              mdi-redo
            </v-icon>
          </v-card>
          <v-card
            v-show="!readonly"
            elevation="0"
            class="light-border ml-3"
          >
            <v-icon
              :color="isActive.bold() ? 'black' : undefined"
              @click="commands.bold"
            >
              mdi-format-bold
            </v-icon>

            <v-icon
              :color="isActive.italic() ? 'black' : undefined"
              @click="commands.italic"
            >
              mdi-format-italic
            </v-icon>

            <v-icon
              :color="isActive.underline() ? 'black' : undefined"
              @click="commands.underline"
            >
              mdi-format-underline
            </v-icon>

            <v-icon
              :color="isActive.strike() ? 'black' : undefined"
              @click="commands.strike"
            >
              mdi-format-strikethrough
            </v-icon>

            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="!isActive.paragraph() ? 'black' : undefined"
                  v-on="on"
                >
                  mdi-format-header-pound
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="h in 6"
                  :key="h"
                  class="py-1 px-0 text-center"
                  style="min-height: 24px"
                  @click="commands.heading({ level: h })"
                >
                  <v-icon
                    :color="isActive.heading({ level: h }) ? 'black' : undefined"
                    small
                  >
                    {{ `mdi-format-header-${h}` }}
                  </v-icon>
                </v-list-item>
                <v-list-item
                  class="py-1 px-0 text-center"
                  style="min-height: 24px"
                >
                  <v-icon
                    :color="isActive.paragraph() ? 'black' : undefined"
                    small
                    @click="commands.paragraph"
                  >
                    mdi-format-paragraph
                  </v-icon>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-icon
              :color="isActive.bullet_list() ? 'black' : undefined"
              @click="commands.bullet_list"
            >
              mdi-format-list-bulleted
            </v-icon>

            <v-icon
              :color="isActive.ordered_list() ? 'black' : undefined"
              @click="commands.ordered_list"
            >
              mdi-format-list-numbered
            </v-icon>

            <v-icon
              :color="isActive.code() ? 'black' : undefined"
              @click="commands.code"
            >
              mdi-code-tags
            </v-icon>

            <!-- setLinkUrl(commands.link, linkUrl)-->
            <v-icon
              :color="isActive.link() ? 'black' : undefined"
              @click="isActive.link() ? commands.link({}) : showPrompt(commands.link, closeLinkPrompt)"
            >
              mdi-link-variant
            </v-icon>

            <v-icon
              :color="isActive.blockquote() ? 'black' : undefined"
              @click="commands.blockquote"
            >
              mdi-format-quote-close
            </v-icon>

            <v-icon
              style="border: none;"
              :color="isActive.image() ? 'black' : undefined"
              @click="showPrompt(commands.image, closeImagePrompt)"
            >
              mdi-file-image-outline
            </v-icon>
          </v-card>
        </div>
      </editor-menu-bar>
      <editor-content
        :editor="editor"
        class="pa-2 pb-0"
      />
      <p
        v-for="error in errorMessages"
        :key="error"
        class="error--text pa-0 ma-0 font-weight-light font-italic body-2"
      >
        {{error}}
      </p>
      <url-dialogue
        v-model="dialog"
        @close="close_function"
      />
    </div>
    <v-progress-linear indeterminate height="2px" v-show="loading"/>
  </div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  Heading,
  OrderedList,
  BulletList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  Image,
  ListItem,
  History,
} from "tiptap-extensions";
import UrlDialogue from "./UrlDialog.vue";

export default {
  name: "HtmlEditor",
  path: "components.edit.edit_box_children.html_editor.html_editor",
  components: {
    UrlDialogue,
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: { type: String, default: "" },
    errorMessages: { type: Array, default: () => ([]) },
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      editor: null,
      dialog: false,
      command: null,
      close_function: () => {},
      mouse_in: false,
      focus_in: false,
      changed: false,
    };
  },
  watch: {
    value() {
      this.editor.setContent(this.value);
    },
    readonly() {
      this.editor.setOptions({
        editable: !this.disabled && !this.readonly,
      });
    },
    disabled() {
      this.editor.setOptions({
        editable: !this.disabled && !this.readonly,
      });
    },
  },
  created() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        new Blockquote(),
        new CodeBlock(),
        new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Bold(),
        new Code(),
        new Italic(),
        new Link(),
        new Image(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      editable: !this.disabled && !this.readonly,
      onUpdate: this.onUpdate,
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {

    /**
     * Handles changing of the users input focus from/to the editor content
     */
    onFocusChange(focus_in) {
      this.focus_in = focus_in;
      if (this.focus_in) {
        this.changed = true;
      }
      this.notifyParent();
    },

    /**
     * Handles changing of the mouse being in-out of the editor
     */
    onMouseChange(mouse_in) {
      this.mouse_in = mouse_in;
      this.notifyParent();
    },

    /**
     * Emits a focus-out event when the mouse is no longer hovering over the editor and the
     * cursor is not focusing inside the edito-box
     */
    notifyParent() {
      if (!this.mouse_in && !this.focus_in && this.changed) {
        this.$emit("focusout");
        this.changed = false;
      }
    },

    /**
     * Emits the input event whenever the editor content changes
     */
    onUpdate() {
      this.$emit("input", this.getContent());
    },

    /**
       * Fetch the HTML value from the editor instance
       *
       * @function this.getContent
       * @return {string} - HTML value written inside the editor
       *
       * @example const HTML = this.getContent();
       */
    getContent() {
      return this.editor.getHTML();
    },

    /**
       * Show a new url input prompt to the user and call the according
       * function on user input
       *
       * @function this.showPrompt
       * @param {function} command        - Editor function that allows setting new params to a format elemetn
       * @param {function} close_function - Custom function that will call command with the user provided arguments
       *
       * @example this.showPrompt(commands.link, closeLinkPrompt)
       */
    showPrompt(command, close_function) {
      this.dialog = true;
      this.command = command;
      this.close_function = close_function;
    },

    /**
       * Function that binds the users input into an HTML image tag  using the editors command function
       *
       * @function this.closeImagePrompt
       * @param {string} src - image source of img-HTML tag, provided by the user via Prompt
       *
       * @example this.showPrompt(commands.image, closeImagePrompt)
       */
    closeImagePrompt(src) {
      this.dialog = false;
      if (src) {
        this.command({ src });
      }
    },

    /**
       * Function that binds the users input into an HTML link tag  using the editors command function
       *
       * @function this.closeLinkPrompt
       * @param {string} href - Href of a-HTML tag, provided by the user via Prompt
       *
       * @example this.showPrompt(commands.link, closeLinkPrompt)
       */
    closeLinkPrompt(href) {
      this.dialog = false;
      if (href) {
        this.command({ href });
      }
    },
  },
};
</script>

<style scoped>
  .light-border {
    border: 1px solid #dddddd;
  }
  .v-icon {
    border-right: 1px solid #cdcdcd;
    padding-right: 7px;
    margin: 4px 0 4px 7px;
    cursor: pointer;
  }
  .v-icon:hover {
    color: black;
  }
  .v-list-item > .v-icon {
    border: none;
  }
  .editor-container {
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    outline: none;
  }
  .editor-container:active, .editor-container:focus-within {
    border: 1px solid var(--primary);
  }
  .editor-container >>> .ProseMirror {
    white-space: pre-line;
  }
  .editor-container >>> .ProseMirror:focus {
    outline: none;
  }
  .editor-container >>> .ProseMirror:focus img {
    outline: solid #c6c6c6 1px;
  }
</style>

<template>
  <v-col cols="12" class="py-0">
    <h4>
      {{ title }}
    </h4>
  </v-col>
</template>

<script>
export default {
  name: "StaticSectionTitle",
  path: "components.edit.edit_box_children.static_section_title",
  props: {
    title: { type: String, required: true },
  },
};
</script>

<style scoped>

</style>

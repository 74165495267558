<template>
    <div>
      <page-title
        :title="$t('title')"
      />
      <v-row dense>
        <v-col cols="12">
          <education-section-r-o />
        </v-col>
        <v-col cols="12">
          <further-education-section-r-o />
        </v-col>
      </v-row>
    </div>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import EducationSectionRO from "./sections/EducationSectionRO.vue";
import FurtherEducationSectionRO from "./sections/FurtherEducationSectionRO.vue";

/**
 * The "studies" page just shows the two sections, educations and qualifications.
 */
export default {
  name: "Studies",
  path: "pages.studies.studies",
  components: {FurtherEducationSectionRO, EducationSectionRO, PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Mein Studium",
      },
      fr: {
        title: "Mes études",
      },
      it: {
        title: "I miei studi",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <image-card
    :title="article | name"
    :image="image"
    :action="routeToArticle"
  >
    <p>
      <strong>{{$t("date")}}:</strong> {{ article | from_date }}
    </p>
    <p>
      {{ article | tagcat("News") }}
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-event.png";

/**
 * The NewsCard component displays a news card with an image, the name and the start date.
 */
export default {
  name: "NewsCard",
  path: "components.cards.news_card",
  components: {ImageCard},
  props: {
    article: {
      type: Object, // BomInstance
      required: true,
    },
  },
  data() {
    return {
      image: default_image,
    };
  },
  methods: {
    // when clicking on it, it leads to the ArticleDetails.vue site with the name "article-details"
    routeToArticle() {
      this.$router.push({
        name: "articleDetails",
        params: {
          id: this.article.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        date: "Datum",
      },
      fr: {
        date: "Date",
      },
    },
  },
};
</script>

<style scoped>

</style>

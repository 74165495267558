<template>
  <input-wrapper
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <v-text-field
      :value="text"
      :readonly="true"
    />
  </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

export default {
  name: "StaticText",
  path: "components.edit.edit_box_children.static_text",
  components: {InputWrapper},
  mixins: [InputMixin],
  props: {
    text: { type: String, required: true},
  },
};
</script>

<style scoped>

</style>

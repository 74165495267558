<template>
    <input-wrapper v-show="show">
      <v-autocomplete
        v-model="value"
        :items="items"
        item-text="last_name"
        item-value="id"
        :label="label"
        auto-select-first
        :readonly="!boxProbs.editmode"
        :loading="loading || items.length === 0"
        :disabled="boxProbs.editmode && readonly"
        placeholder=" "
      />
    </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";
import AddressSelectorMixin from "../../../mixins/AddressSelectorMixin.js";

/**
 * The AddressSelector component loads all addresses from the API that that are involved in
 * an address relation of the specified type (selectable_type_number). The user can select
 * in an autocomplete-component (https://vuetifyjs.com/en/components/autocompletes/#usage)
 * When the user selects one of the addresses, the address is placed as an address-relation
 * on the editObject as an address-relation of type "relationNumber", "relationName".
 *
 * Mixins: InputMixin, AddressSelectorMixin
 */
export default {
  name: "AddressSelector",
  path: "components.edit.edit_box_children.address_selector",
  components: {InputWrapper},
  mixins: [InputMixin, AddressSelectorMixin],
  methods: {
    /**
     * When the editObject is updated, we fetch all addresses that are involved in address relations
     * of the given type numbers and assign the addresses to the "items"-array. These addresses
     * will then be selectable within the v-autocomplete component.
     */
    editObjectUpdate() {
      if (!this.boxProbs.editObject) { return; }
      this.initial_address = this.address;
      this.initial_address_relation = this.address_relation;
      this.$bom.addresses.all
        .thatAreInvolvedInRelationOfType(...this.selectableTypeNumbers)
        .fetch()
        .then(({addresses}) => {
          this.items = addresses;
        });
    },
  },
};
</script>

<style scoped>

</style>

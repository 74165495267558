<template>
  <v-alert
    dense
    outlined
    type="success"
    :value="value"
    @input="$emit('input', $event)"
    dismissible
  >
    {{message}}
  </v-alert>
</template>

<script>

import alert from "../../mixins/AlertMixin.js";

/**
 * The SuccessAlert component is a green, dismissable box that shows a success message to the user.
 * It is a wrapper for https://vuetifyjs.com/en/components/alerts/
 *
 * Mixins: Alert.js
 */
export default {
  name: "SuccessAlert",
  path: "components.alerts.success_alert",
  mixins: [alert],
};
</script>

<style scoped>

</style>

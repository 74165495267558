<template>
  <!-- @input: as soon as you write something in this component v-text-field then @input will be executed
   @keyup.enter: executed by clicking enter -> event "enter" will be executed -->
  <v-text-field
    :label="label || $t('default_label')"
    :value="value"
    @input="$emit('input', $event)"
    @keyup.enter="$emit('enter')"
    :loading="loading"
  />
</template>

<script>
export default {
  name: "Search",
  path: "components.search_inputs.search",
  props: {
    label: { type: String, required: false, default: "" },
    value: { type: String, required: true },
    loading: { type: Boolean, required: false },
  },
  i18n: {
    messages: {
      de: {
        default_label: "Suche",
      },
      fr: {
        default_label: "Rechercher",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.company_name')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="company_name"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.city')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="city"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.street')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="street"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.postcode')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="postcode"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.country')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="country"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.mail')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="mail"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.phone_number')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="phone_number"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";

export default {
  name: "CompanySection",
  path: "pages.address.person.sections.company_section",
  mixins: [EditBoxChildMixin],
  components: {
    TextInput,
  },
};
</script>

<style scoped>

</style>

import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  created() {
    this.syncShadowState();
  },
  props: {
    group: { type: Object, required: true },
  },
  methods: {
    syncShadowState() { },
  },
  computed: {
    errors() {
      return [];
    },
    valid() {
      return this.errors.length === 0;
    },
  },
  watch: {
    valid: {
      handler(valid) {
        this.$emit("update:valid", valid);
      },
      immediate: true,
    },
  },
};

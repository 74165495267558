<template>
  <v-tooltip top >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        dark
        class="pl-2"
        v-bind="attrs"
        v-on="on"
      >
        mdi-alert-circle-outline
      </v-icon>
    </template>
    <span>{{ $t("tooltip") }}</span>
  </v-tooltip>
</template>

<script>

/**
 * Adds an info icon with a tooltip.
 */

export default {
  name: "InfoIcon",
  path: "components.ui_elements.info_icon",
  i18n: {
    messages: {
      de: {
        tooltip: "Sofern Ihr Name verborgen ist, sind Sie im Who is Who nicht auffindbar.",
      },
      fr: {
        tooltip: "Si votre nom est caché, vous n'êtes pas trouvé dans le Who is Who.",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-banner
    single-line
    v-model="visible"
    class="devbanner"
    color="warning"
  >
    <v-sheet
      height="25"
      color="warning"
      class="d-flex align-center text-center justify-center"
    >
      <v-icon
        color="black"
      >
        mdi-alert
      </v-icon>
      <h4 class="pt-1 pl-2 font-weight-regular">
        <strong>Warning:</strong> This is a development build. v.{{application_version}}.
      </h4>
      <v-checkbox
        v-model="debug"
        label="Debug Mode"
        class="ml-6 debug"
        color="black"
      />
      <template v-slot:actions="{ dismiss }">
        <v-btn
          text
          color="primary"
          @click="dismiss"
        >
          Dismiss
        </v-btn>
        <v-btn
          text
          color="primary"
        >
          Retry
        </v-btn>
      </template>
    </v-sheet>
  </v-banner>
</template>

<script>
import {mapGetters} from "vuex";
import {
  get, set, isObject, isArray, cloneDeep,
} from "lodash";
import messages from "../../languages/messages.js";
import eventBus from "../../plugins/eventBus.js";

const original_messages = cloneDeep(messages);

export default {
  name: "Devbanner",
  path: "components.banners.devbanner",
  data: () => ({
    visible: true,
    debug: false,
  }),
  created() {
    this.visible = !this.$store.getters["config/isProductionBuild"];
  },
  watch: {
    debug(debugging_active) {
      if (debugging_active) {
        this.debugLocales();
      } else {
        this.normalLocales();
      }
      eventBus.$emit("rerender");
    },
  },
  computed: {
    /**
     * Maps the applicationVersion getter from the base store to a local computed "applicaton_version"
     */
    ...mapGetters({
      application_version: "applicationVersion",
    }),
  },
  methods: {
    debugLocales() {
      Object.keys(messages).forEach((lang) => {
        this.setLocaleRecursive(lang);
      });
    },
    setLocaleRecursive(path) {
      const sub_messages = get(messages, path);
      if (isObject(sub_messages)) {
        Object.keys(sub_messages).forEach((key) => {
          this.setLocaleRecursive(`${path}.${key}`);
        });
        return;
      }
      if (isArray(sub_messages)) {
        sub_messages.forEach((val, i) => {
          this.setLocaleRecursive(`${path}[${i}]`);
        });
        return;
      }
      set(messages, path, path);
    },
    normalLocales() {
      Object.entries(original_messages).forEach(([lang, msg]) => {
        this.$i18n.setLocaleMessage(lang, msg);
      });
    },
  },
};
</script>

<style scoped>
.devbanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.devbanner >>> .v-banner__wrapper {
  padding: 5px !important;
}
.debug >>> .v-label {
  color: black;
  font-size: 16px;
}
.debug >>> .v-input--selection-controls__input * {
  font-size: 20px;
}
</style>

import * as USER from "../../constants/USER.js";

export default {
  data() {
    return {
      USER_OWN_COMPANY_ADDRESS_TYPES: USER.OWN_COMPANY_ADDRESS_TYPES,
      USER_DELETED_FILE_NAME: USER.DELETED_FILE_NAME,
    };
  },
};

import { utcToZonedTime } from "date-fns-tz";
import {format} from "date-fns";
import Vue from "vue";
import i18n from "../languages/i18n.js";

/**
 * Vue filters can be used to format stylings. A vue filter is just a function that will
 * be applied when called from a template: <p>{{ "test" | capitalize }}</p> will
 * call the "capitalize" vue filter with the argument "test", so capitalize("test"), and
 * render the results.
 */

/**
 * Capitalizes its input.
 * Example: {{"test" | capitalize}} -> Test
 */
Vue.filter("capitalize", (str) => {
  if (!str) { return ""; }
  return str.charAt(0).toUpperCase() + str.slice(1);
});

/**
 * give the name of the event back
 * Example: {{event | name}} -> Wein....
 */
Vue.filter("name", (instance) => {
  if (!instance) { return ""; }
  return instance.name;
});

/**
 * give the name of the event back
 * Example: {{event | location}} -> ....
 */
Vue.filter("location", (instance) => {
  if (!instance || !instance.AddressRelationsPlugin || !instance.AddressRelationsPlugin.Ort) {
    return undefined;
  }
  return instance.AddressRelationsPlugin.Ort[0].address;
});

/**
 * give the last name of the location and the location city
 * Example: {{event | last_name_city}} -> ....
 */
Vue.filter("last_name_city", (address) => {
  if (!address) { return ""; }
  let address_string = "";
  if (address.last_name) {
    address_string += `${address.last_name}, `;
  }
  if (address.city) {
    address_string += ` ${address.city}, `;
  }
  address_string = address_string.slice(0, -2);
  return address_string;
});

/**
 * give the Verantwortliche form an event back
 * Example: {{event | responsible}} -> Joel Barmettler....
 */
Vue.filter("responsible", (instance) => {
  if (!instance || !instance.ClientPlugin || !instance.ClientPlugin.client_name) {
    return undefined;
  }
  return instance.ClientPlugin.client_name;
});

/**
 * Reads out the full name of an address
 * Example: {{address | fullname}} -> Joel Barmettler
 */
Vue.filter("fullname", (address) => {
  if (!address) { return ""; }
  const first_name = address.first_name || "";
  const last_name = address.last_name || "";
  return `${first_name} ${last_name}`;
});

/**
 * Reads out the company name of an address
 * Example: {{address | companyname}} -> PolygonSolutions
 */
Vue.filter("companyname", (address) => {
  if (!address) { return ""; }
  const company_name = address.company_name || "";
  return `${company_name}`;
});

/**
 * Reads out the company name of an address
 * Example: {{address | companyplace}} -> 8008 Zürich
 */
Vue.filter("companyplace", (address) => {
  if (!address) { return ""; }
  const company_postcode = address.postcode || "";
  const company_city = address.city || "";
  return `${company_postcode} ${company_city}`.trim();
});

/**
 * Reads out the degree, subject and specialization of a education
 * Example: {{address | education}} -> MSc Soziale Arbeit (Diagnostik & Beratung)
 */
Vue.filter("education", (reference) => {
  if (!reference) { return ""; }
  const degrees = reference.TagsPlugin?.["Ausbildungs-Stufe"]?.[0].name || "";
  const subjects = reference.TagsPlugin?.Fachrichtung?.[0].name || "";
  const specializations = reference.TagsPlugin?.Vertiefungsrichtung?.[0].name ? `(${reference.TagsPlugin.Vertiefungsrichtung[0].name})` : "";
  return `${degrees} ${subjects} ${specializations}`.trim(); // has to be trimmed so when all strings are empty, an empty string is returned, and not some empty spaces
});

/**
 * Reads out the degree and specialization of a further education
 * Example: {{address | education}} -> CAS speci
 */
Vue.filter("furthereducation", (reference) => {
  if (!reference) { return ""; }
  const degrees = reference.TagsPlugin?.["Weiterbildungs-Stufe"]?.[0].name || "";
  const specializations = reference.info2 || "";
  return `${degrees} ${specializations}`.trim();
});

/**
 * Reads out the mail of an address
 * Example: {{address | mail}} -> fhconnect@polygon-software.ch
 */
Vue.filter("mail", (address) => {
  if (!address) { return ""; }
  const mail = address.mail || "";
  return `${mail}`;
});

/**
 * Reads out the phone number of an address
 * Example: {{address | phonenumber}} -> +41565784278
 */
Vue.filter("phonenumber", (address) => {
  if (!address) { return ""; }
  const phone_number = address.phone_number || "";
  return `${phone_number}`;
});

/**
 * Reads out the mobile number of an address
 * Example: {{address |  mobiblenumber}} -> +41795784278
 */
Vue.filter("mobilenumber", (address) => {
  if (!address) { return ""; }
  const mobile_number = address.mobile_number || "";
  return `${mobile_number}`;
});

/**
 * Reads out the birth date of an address
 * Example: {{address |  birthday}} -> 10.25.1999
 */
Vue.filter("birthday", (address) => {
  if (!address) { return ""; }
  if (address.from === null) {
    return "";
  }
  // const birthday = to_bom_timezone(address.from) || "";
  // return `${birthday.slice(9, 11)}.${birthday.slice(6, 8)}.${birthday.slice(1, 5)}`;
  return format(to_bom_timezone(address.from), "dd.MM.yyyy");
});

/**
 * Summarizes an address
 * Example: {{address | summary}} -> PolygonSoftware, Barmettler Joel, Thurgauerstrasse 111, 8057 Zürich
 */
Vue.filter("summary", (address) => {
  if (!address) { return ""; }
  let address_string = "";
  if (address.first_name) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.first_name}, `;
  }
  if (address.last_name) {
    address_string += `${address.last_name}, `;
  }
  if (address.company_name) {
    address_string += `${address.company_name}, `;
  }
  if (address.street) {
    address_string += `${address.street}, `;
  }
  if (address.street_addition) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.street_addition}, `;
  }
  if (address.postcode) {
    address_string += `${address.postcode}, `;
  }
  if (address.city) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.city}, `;
  }
  address_string = address_string.slice(0, -2);
  return address_string;
});

/**
 * Summarizes an address with an e-mail
 * Example: {{address | summary_with_mail}} -> PolygonSoftware, Barmettler Joel, Thurgauerstrasse 111, 8057 Zürich, joel.barmettler@polygon-software.ch
 */
Vue.filter("summary_with_mail", (address) => {
  if (!address) { return ""; }
  let address_string = "";
  if (address.first_name) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.first_name}, `;
  }
  if (address.last_name) {
    address_string += `${address.last_name}, `;
  }
  if (address.company_name) {
    address_string += `${address.company_name}, `;
  }
  if (address.street) {
    address_string += `${address.street}, `;
  }
  if (address.street_addition) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.street_addition}, `;
  }
  if (address.postcode) {
    address_string += `${address.postcode}, `;
  }
  if (address.city) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.city}, `;
  }
  if (address.mail) {
    address_string += `${address.mail}, `;
  }
  address_string = address_string.slice(0, -2);
  return address_string;
});

/**
 * Summarizes an address with an e-mail
 * Example: {{address | summary_only_mail}} -> joel.barmettler@polygon-software.ch
 */
Vue.filter("summary_only_mail", (address) => {
  if (!address) { return ""; }
  let address_string = "";
  if (address.mail) {
    address_string += `${address.mail}, `;
  } else {
    address_string += "-  ";
  }
  address_string = address_string.slice(0, -2);
  return address_string;
});

/**
 * Summarizes information from a registered persons in event details
 * Example: {{address | registered_persons}} -> 4665 Oftringen
 */
Vue.filter("registered_persons", (address) => {
  if (!address) { return ""; }
  let address_string = "";
  if (address.company_name) {
    address_string += `${address.company_name}, `;
  }
  if (address.postcode) {
    address_string += `${address.postcode}, `;
  }
  if (address.city) {
    address_string = address_string.slice(0, -2);
    address_string += ` ${address.city}, `;
  }
  address_string = address_string.slice(0, -2);
  return address_string;
});

/**
 * Returns an empty string if the value is null or undefined
 * Example: {{undefined | none}} ->
 */
Vue.filter("none", (content) => {
  if (!content) { return ""; }
  return content;
});

/**
 * Returns years specified in the "from" and "to" of the input object
 * Example: {{AddressRelation | timeperiod}} -> 2018 - 2020
 */
Vue.filter("timeperiod", (content) => {
  if (!content) { return "-"; }
  if (content.from && content.to) {
    const from = content.from.getFullYear();
    const to = content.to.getFullYear();
    return `${from} ${i18n.t("to")} ${to}`;
  }
  if (content.from) {
    const from = content.from.getFullYear();
    return `${from} ${i18n.t("to")} ${i18n.t("today")}`;
  }
  return ` ${i18n.t("unknown_time")}`;
});

/**
 * Reads out the first tag of the provided tag category and returns its name (or other property, if specified)
 * Example: {{address | tagcat("Publikation")}} -> WEB
 * Example: {{address | tagcat("Publikation", "number")}} -> 456
 */
Vue.filter("tagcat", (content, tag_category, property = "name") => {
  if (!content || !content.TagsPlugin || !content.TagsPlugin[tag_category]) { return "-"; }
  return content.TagsPlugin[tag_category][0][property];
});

/**
 * Reads out the first address relation of the provided type and returns its name (or other property, if specified)
 * Example: {{address | addrelcat("Verantwortlich" )}} -> "Joel"
 * Example: {{address | addrelcat("Verantwortlich", "last_name" )}} -> "Barmettler"
 */
Vue.filter("adrrelcat", (content, address_relation_type, property = "name") => {
  if (!content || !content.AddressRelationsPlugin || !content.AddressRelationsPlugin[address_relation_type]) { return "-"; }
  return content.AddressRelationsPlugin[address_relation_type][0].address[property];
});

function to_bom_timezone(date) {
  if (!date) { return date; }
  if (window?.bomconfig?.timeshift === "zoned") {
    return utcToZonedTime(date, "Europe/Bern");
  }
  return date;
}

/**
 * Returns the timerange in which the event takes place
 * Example: {{event | timerange}} -> 3 cases
 * 1. case: Only from date (no to date): 16.01.2020 17:00
 * 2. case: From and to date are on same day: 16.01.2020 17:00 - 18:00
 * 3. case: From and to date are on different days: 16.01.2020 17:00 - 17.01.2020 18:30
 */
Vue.filter("timerange", (instance) => {
  if (!instance) { return undefined; }
  // Case 0: No date is set, timerange is unknown
  if (!instance.to && !instance.from) { return "-"; }
  // Case 1: Only from date (no to date) -> 16.01.2020 17:00
  let fromTime;
  if (instance.from) {
    fromTime = to_bom_timezone(instance.from);
  }
  if (!instance.to) {
    return format(fromTime, "yyyy.MM.dd HH:MM");
  }
  const toTime = to_bom_timezone(instance.to);
  // Case 2: From-and to date are on same day -> 16.01.2020 17:00 - 18:00
  if (format(fromTime, "yyyy.MM.dd") === format(toTime, "yyyy.MM.dd")) {
    return `${format(fromTime, "yyyy.MM.dd HH:mm")} - ${format(toTime, "HH:mm")}`;
  }
  // Case 3: From-and to date are on different days: 16.01.2020 17:00 - 17.01.2020 18:30
  return `${format(fromTime, "yyyy.MM.dd HH:m")} - ${format(toTime, "yyyy.MM.dd HH:mm")}`;
});

/**
 * Returns just the date of the begin in which the event takes place
 * Example: {{event | from_date}} -> 16.01.2020
 */
Vue.filter("from_date", (instance) => {
  if (!instance) { return undefined; }
  return format(to_bom_timezone(instance.from), "dd.MM.yyyy");
});

/**
 * Returns the date of the registration deadline for an event
 * Example: {{event | from_date}} -> 16.01.2020
 */
Vue.filter("registration_deadline", (instance) => {
  if (!instance) { return undefined; }
  return format(to_bom_timezone(instance.registration_end), "dd.MM.yyyy");
});

/**
 * Returns the date in a right format
 * Example: {{event | date_format}} -> 16.01.2020
 */
Vue.filter("date_format", (date) => {
  if (!date) { return undefined; }
  return format(to_bom_timezone(date), "dd.MM.yyyy");
});

<template>
  <fragment>
    <page-title
      :title="$t('title')"
    />
    <page-subtitle
      :subtitle="$t('application_and_templates')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="applicationsTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <form-card v-for="item in items" :form="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('statutes_and_guidelines')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="statutesTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <form-card v-for="item in items" :form="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('annual_statistics')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="annualTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <form-card v-for="item in items" :form="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('general_assembly')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="generalTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <form-card v-for="item in items" :form="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('technical_title_form')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="technicalTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <form-card v-for="item in items" :form="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>
  </fragment>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import TagsWithLazyLoadingButton from "../../components/SearchMechanisms/TagsWithLoadingButton.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";
import FormCard from "../../components/Cards/FormCard.vue";

/**
 * This page shows all the different Forms, there are five section each with a different tag.
 * The cards can't be searched through, but more can be loaded with the plus button.
 */

export default {
  name: "Forms",
  path: "pages.forms.forms",
  components: {
    FormCard,
    PageSubtitle,
    TagsWithLazyLoadingButton,
    PageTitle,
  },
  data() {
    return {
      module: "marketplace",
      objectType: "instances",
      applicationsTag: { name: this.$t("application_and_templates"), number: "22772" },
      statutesTag: {name: this.$t("statutes_and_guidelines"), number: "22773" },
      annualTag: {name: this.$t("annual_statistics"), number: "22774" },
      generalTag: {name: this.$t("general_assembly"), number: "22775" },
      technicalTag: {name: this.$t("technical_title_form"), number: "22776" },
    };
  },
  i18n: {
    messages: {
      de: {
        title: "Formulare",
        application_and_templates: "Anträge & Vorlagen",
        statutes_and_guidelines: "Statuten & Richtlinien",
        annual_statistics: "Jahresberichte",
        general_assembly: "Mitgliederversammlung",
        technical_title_form: "Fachtitelformulare",
      },
      fr: {
        title: "Formulaires",
        application_and_templates: "Demandes & Modèles",
        statutes_and_guidelines: "Statutes & Directives",
        annual_statistics: "Rapports annuels",
        general_assembly: "Assemlée générale",
        technical_title_form: "Formulaires de titre professionnel",
      },
    },
  },
};
</script>

<style scoped>

</style>

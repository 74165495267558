<template>
  <input-wrapper
    v-show="show"
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :disabled="!boxProbs.editmode"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatted_date"
          :label="label"
          readonly
          :loading="loading"
          v-bind="attrs"
          v-on="on"
          placeholder="-"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        :max="maxDateString"
        :min="minDateString"
        @input="save"
        :locale="language"
        reactive
        no-title
      ></v-date-picker>
    </v-menu>
  </input-wrapper>
</template>

<script>
import DatePickerMixin from "../../../mixins/DatePickerMixin.js";
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

/**
 * The YearPicker component lets the user input a year and only shows the chosen year.
 *
 * Mixins: InputMixin, DatePickerMixin
 */
export default {
  name: "YearPicker",
  path: "components.edit.edit_box_children.year_picker",
  components: {InputWrapper},
  mixins: [InputMixin, DatePickerMixin],
  methods: {
    /**
     * This save makes sure the date picker is stopped after the year has been asked, so the month and day won't be prompted.
    */
    save(date) {
      if (typeof date === "string") {
        const beginning_of_year_date = `${date.substr(0, 4)}-01-01`; // make sure date is saved as 1. January & whatever year was chosen
        this.$refs.menu.save(beginning_of_year_date);
        this.$refs.picker.activePicker = "YEAR";
        this.menu = false;
      }
    },
  },
  computed: {
    /**
     * Only returns the year (e.g. "2020") without month and day.
     * @returns {string}
     */
    formatted_date() {
      if (this.value) {
        const [year] = this.value.toISOString()
          .substr(0, 4)
          .split("-");
        return year;
      }
      return "";
    },
  },
};
</script>

<style scoped>

</style>

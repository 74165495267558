<template>
  <div>
    <div
      class="login-container"
      v-if="userLoading || clients_loading || (mentor_loading && client)"
    >
      <loading-circle
        style="margin-top: 100px"
        :message="$t('wait')"
      />
    </div>

    <div v-else>
      <h3
        class="pt-6 pl-3"
      >
        {{$t("title")}}
      </h3>
      <v-list shaped>
        <v-list-item-group
          v-model="selected_index"
          color="primary"
        >
          <v-list-item
            v-for="client in clients"
            :key="client.client_id"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-school
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="client.client_name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn
        color="primary"
        dark
        block
        v-if="selected_index !== undefined"
        class="text-none"
        @click="continue_with_selection"
      >
        {{$t("continue", {name: selected_client.client_name})}}
        <v-icon
          right
          dark
        >
          mdi-arrow-right-bold
        </v-icon>
      </v-btn>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingCircle from "../../components/UI-Elements/LoadingCircle.vue";

/**
   * This screen forces the user to choose one of his possible clients. A client represents a University
   * from which the user graduated. Some data from his profile as well as most data within the application
   * are specific to the users client: The client "University of Zurich" might offer some events next week,
   * but the client "Fachhochschule Luzern" might not. Hence, the user must choose for which client he wishes
   * to browse the application. He can chance the client again afterwards.
   * The clients he can choose from are the universities he graduated from.
   */
export default {
  name: "ClientChooser",
  path: "pages.presteps.client_chooser",
  components: {LoadingCircle},
  data() {
    return {
      selected_index: undefined, // Which index of the clients-array the user pressed on
      mentoring_promise: undefined, // Holds promise that resolves when mentor-specific data was loaded
      mentor_loading: true, // Indicates that mentors data is still loading
      clients_loading: true,
    };
  },
  computed: {
    /**
       * We map getters from the user store module to local computed properties, especially the loading
       * state (whether the user is currently still loading), his valid clients and his chosen client
       */
    ...mapGetters("user", {
      userLoading: "isLoading",
      clients: "getValidClients",
      client: "getClient",
    }),
    /**
       * We map the users login state from the auth store module to a local computed "logged_in"
       */
    ...mapGetters("auth", {
      logged_in: "isLoggedIn",
    }),
    /**
       * Returns the location to where the user shall be redirected after login
       */
    redirection_path() {
      return this.$route.query.redirect || "/";
    },
    /**
       * Returns the client at the currently active index of the clients array
       * @returns {string}
       */
    selected_client() {
      return this.clients[this.selected_index];
    },
    query_without_redirect() {
      const query = {...this.$route.query};
      delete query.redirect;
      return query;
    },
  },
  methods: {
    /**
       * Map the methods "fetchUser", "initializeClient" and "switchClient" from the user store module
       * to local methods
       */
    ...mapActions("user", {
      fetchUser: "fetchUser",
      initializeClient: "initializeClient",
      switchClient: "switchClient",
      fetchAllClients: "fetchAllClients",
    }),
    /**
       * Map getters from mentoring store
       */
    ...mapActions("mentoring", {
      fetchBecomeMentorInstance: "fetchBecomeMentorInstance",
      fetchOpenMentorRegistration: "fetchOpenMentorRegistration",
      fetchOwnMentorInstance: "fetchOwnMentorInstance",
      fetchOwnMenteeRegistrations: "fetchOwnMenteeRegistrations",
    }),
    /**
       * If the user presses continue, activate the currently selected client and route to
       * the path the user came from (redirection_path, learn more about this in the auth-service)
       */
    continue_with_selection() {
      this.switchClient(this.selected_client)
        .then(() => {
          this.routeForward();
        });
    },
    fetchMentorings() {
      return this.fetchBecomeMentorInstance()
        .then(() => {
          return Promise.all([
            this.fetchOpenMentorRegistration(),
            this.fetchOwnMentorInstance(),
            this.fetchOwnMenteeRegistrations(),
          ]);
        })
        .then(() => {
          this.mentor_loading = false;
        });
    },
    routeForward() {
      const user_promise = this.fetchUser();
      Promise.all([user_promise, this.mentoring_promise])
        .then(() => {
          this.$router.push({path: this.redirection_path, query: this.query_without_redirect});
        });
    },
  },
  /**
   * On create, we fetch the users address and initialize the users client. If a default client
   * could be loaded from the local storage, we immediately forward the user to his originally
   * intended page and don't prompt him with the client switch - he can switch his client later on.
   */
  created() {
    this.mentoring_promise = this.fetchMentorings();
    this.fetchAllClients()
      .then(() => {
        this.clients_loading = false;
        return this.initializeClient();
      })
      .then((client_loaded_from_storage) => {
        if (client_loaded_from_storage) {
          this.routeForward();
        }
      });
  },
  i18n: {
    messages: {
      de: {
        title: "Wählen Sie Ihre Organisation",
        continue: "Mit '{name}' anmelden",
        wait: "Bitte warten Sie einen Moment...",
      },
      fr: {
        title: "Choisissez votre organisation d'anciens élèves",
        continue: "S'inscrire avec '{name}'",
        wait: "Veuillez patienter un moment svp...",
      },
    },
  },
};
</script>

<style scoped>
  div.login-container {
    position: relative;
    width: 340px;
    height: 450px;
  }
</style>

<template>
  <r-m-input-wrapper :group="group">
    <component
      :is="group.name"
      :group="group"
      @update:valid="$emit('update:valid', $event)"
    />
  </r-m-input-wrapper>
</template>

<script>
import RegMaskFieldMixin from "../../../../mixins/RegMaskFieldMixin.js";
import RMCheckBox from "../RMCheckBox.vue";
import RMCheckBoxGroup from "../RMCheckBoxGroup.vue";
import RMCondition from "../RMCondition.vue";
import RMDropdown from "../RMDropdown.vue";
import RMInputField from "../RMInputField.vue";
import RMInputLine from "../RMInputLine.vue";
import RMMultiDropDown from "../RMMultiDropDown.vue";
import RMMultiInputLine from "../RMMultiInputLine.vue";
import RMRadioGroup from "../RMRadioGroup.vue";
import RMStaticText from "../RMStaticText.vue";
import RMInputWrapper from "../Wrapper/RMInputWrapper.vue";

export default {
  name: "RMItemResolver",
  path: "components.reg_mask.reg_mask_field.resolver.r_m_Item_resolver",
  mixins: [RegMaskFieldMixin],
  components: {
    RMInputWrapper,
    RMCheckBox,
    RMCheckBoxGroup,
    RMCondition,
    RMDropdown,
    RMInputField,
    RMInputLine,
    RMMultiDropDown,
    RMMultiInputLine,
    RMRadioGroup,
    RMStaticText,
  },
};
</script>

/**
 * This file consists of helper functions for the BomAddress.
 * For example, it is quite a task to read out the profile image form an address, because we have to:
 * 1. Load the Archive Relations
 * 2. Load for each ArchiveRelation the TagsPlugin
 * 3. Find the ArchiveRelation with the tag that indicates "profileimage"
 * 4. Load the FilePlugin of this ArchiveRelation
 * 5. Return the file from the FilePlugin
 * But, since we need to do this stuff all the time here (namely, for each user address that we show
 * in the user interface) we have extracted this functionality to this ProfileImageService.
 * However, in the future, it would be nice to move these functions directly into the bom-framework.
 * But, due to lack of time and testing-capacity, we have them here for now.
 */

/**
 * Returns true if the address has Archives within its ArchiveRelationsPlugin
 * @param {BomObject} bom_object
 * @returns {boolean}
 */
function hasArchives(bom_object) {
  return bom_object.ArchiveRelationsPlugin && bom_object.ArchiveRelationsPlugin.all.length > 0;
}

function getArchiveWithTag(bom_object, tag) {
  const has_archives = hasArchives(bom_object);
  if (!has_archives) { return undefined; }
  return bom_object.ArchiveRelationsPlugin.all.find((archive_relation) => {
    return archive_relation.archive.TagsPlugin && archive_relation.archive.TagsPlugin.exists(tag);
  });
}

function getFileWithTag(bom_object, tag) {
  const archive_with_tag = getArchiveWithTag(bom_object, tag);
  if (!archive_with_tag) { return undefined; }
  if (archive_with_tag.archive.FilePlugin) {
    return archive_with_tag.archive.FilePlugin.download_url;
  }
  return undefined;
}

function getFileNameWithTag(bom_object, tag) {
  const archive_with_tag = getArchiveWithTag(bom_object, tag);
  if (!archive_with_tag) { return undefined; }
  if (archive_with_tag.archive.FilePlugin) {
    return archive_with_tag.archive.FilePlugin.filename;
  }
  return undefined;
}

async function createEmptyArchiveWithTag(bom_object, tag) {
  const created_archive_relation = await bom_object.ArchiveRelationsPlugin.createNewArchive(new Date());
  const created_archive = created_archive_relation.archive;
  created_archive.TagsPlugin.addPublicationWebTag();
  created_archive.TagsPlugin.addPublicationWebAnonymousTag();
  created_archive.TagsPlugin.add(tag);
  return created_archive_relation;
}

async function uploadNewFileWithTag(bom_object, tag, file, filename) {
  let archive_relation = getArchiveWithTag(bom_object, tag);
  if (!archive_relation) {
    archive_relation = await createEmptyArchiveWithTag(bom_object, tag);
  }
  if (!archive_relation.archive.FilePlugin) {
    await archive_relation.archive.loadFilePlugin();
  }
  const file_plugin = archive_relation.archive.FilePlugin;
  file_plugin.file = file;
  file_plugin.filename = `${filename}${file_plugin.filetype}`;
  return archive_relation.archive.save();
}

async function fixLegacyArchives(bom_object, tag, legacy_marker) {
  const arch_rel_with_legacy_marker = bom_object.ArchiveRelationsPlugin.all.filter((arch_rel) => {
    return arch_rel.archive.description === legacy_marker && !arch_rel.archive.TagsPlugin.find(tag);
  });
  console.warn("Found the following legacy archives", arch_rel_with_legacy_marker);
  const fixing_promises = [];
  arch_rel_with_legacy_marker.forEach((legacy_arch_rel) => {
    console.warn("Fixing archive", legacy_arch_rel.archive);
    legacy_arch_rel.archive.TagsPlugin.create(tag.category, tag.number, tag.name);
    fixing_promises.push(legacy_arch_rel.archive.save());
  });
  return Promise.all(fixing_promises);
}

async function fetchFileWithTag(bom_object, tag, legacy_marker = "") {
  const file = getFileWithTag(bom_object, tag);
  if (file) { return file; }
  if (bom_object.loadArchiveRelationsPlugin) {
    await bom_object.loadArchiveRelationsPlugin();
  }
  const has_archives = hasArchives(bom_object);
  if (!has_archives) { return undefined; }
  const archive_promises = bom_object.ArchiveRelationsPlugin.all.map((arch) => {
    if (!arch.archive.TagsPlugin) { return arch.archive.loadTagsPlugin(); }
    return new Promise(() => {});
  });
  await Promise.all(archive_promises);
  if (legacy_marker) {
    await fixLegacyArchives(bom_object, tag, legacy_marker);
  }
  const arch_rel_with_right_file = bom_object.ArchiveRelationsPlugin.all.find((arch) => {
    return arch.archive.TagsPlugin.find(tag);
  });
  if (arch_rel_with_right_file) {
    await arch_rel_with_right_file.archive.loadFilePlugin();
    return arch_rel_with_right_file.archive.FilePlugin.download_url;
  }
  return undefined;
}

export default {
  hasArchives,
  getArchiveWithTag,
  getFileWithTag,
  getFileNameWithTag,
  createEmptyArchiveWithTag,
  uploadNewFileWithTag,
  fetchFileWithTag,
};

<template>
    <card-group
      :empty="addresses.length === 0"
      :empty-message="emptyMessage"
    >
      <foreign-person-card
        v-for="address in addresses"
        :key="address.id"
        :address="address"
      />
    </card-group>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardGroup from "../../components/Cards/CardGroup.vue";
import ForeignPersonCard from "../../components/Cards/ForeignPersonCard.vue";

const today = new Date();

export default {
  name: "RelatedAddressSection",
  path: "pages.contacts.related_address_section",
  components: {ForeignPersonCard, CardGroup},
  props: {
    emptyMessage: {type: String, required: true, default: ""},
    relationType: { type: String, required: true },
  },
  created(){
    console.log("sdsd")
    this.fetchUser();
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
    addresses() {
      return this.user.AddressRelationsPlugin.all
        .filter((adr_rel) => adr_rel.type_number === this.relationType && (!adr_rel.to || adr_rel.to > today))
        .map((adr_rel) => adr_rel.address);
    },
  },
  methods: {
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        no_addresses_found: "Noch keine Adressen gefunden",
      },
      fr: {
        no_addresses_found: "Aucune adresse trouvée pour le moment",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <!-- @change: looks when something changes -> both events ("input" and "switch") will be executed in the parent -->
  <v-switch
    :true-value="true"
    :false-value="false"
    :input-value="value"
    @change="switchChanged($event)"
    :label="value ? $t('search_on_all_clients') : $t('search') + client.client_name"
  />
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AllClientsSwitch",
  path: "components.search_inputs.all_clients_switch",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("user", {
      client: "getClient",
    }),
  },
  methods: {
    switchChanged(event) {
      this.$emit("input", event);
      this.$emit("switch");
    },
  },
  i18n: {
    messages: {
      de: {
        search_on_all_clients: "schweizweite Suche",
        search: "Suche in ",
      },
      fr: {
        search_on_all_clients: "Rechercher dans toute la Suisse",
        search: "Rechercher dans ",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <page-title
      :title="$t('title')"
    />
    <page-subtitle
      :subtitle="$t('project')"
      />
    <tags-with-lazy-loading-button
      :selected-tag="projectTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <news-card v-for="item in items" :article="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('politics')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="politicsTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <news-card v-for="item in items" :article="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>

    <page-subtitle
      :subtitle="$t('association')"
    />
    <tags-with-lazy-loading-button
      :selected-tag="associationTag"
      :module="module"
      :object-type="objectType">
      <template v-slot="{items}">
        <news-card v-for="item in items" :article="item" :key="item.number"/>
      </template>
    </tags-with-lazy-loading-button>
  </fragment>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import NewsCard from "../../components/Cards/NewsCard.vue";
import TagsWithLazyLoadingButton from "../../components/SearchMechanisms/TagsWithLoadingButton.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";

/**
 * This page shows all the News Articles. Which can be filtered by the <tag-search> with the tags we
 * hardcoded below.
 */
export default {
  name: "News",
  path: "pages.news.news",
  components: {
    PageSubtitle,
    TagsWithLazyLoadingButton,
    NewsCard,
    PageTitle,
  },
  data() {
    return {
      module: "news",
      objectType: "instances",
      projectTag: { name: this.$t("project"), number: "22191" },
      politicsTag: {name: this.$t("politics"), number: "26800" },
      associationTag: {name: this.$t("association"), number: "26801" },
    };
  },
  i18n: {
    messages: {
      de: {
        title: "News",
        project: "Projekte",
        politics: "Berufspolitik",
        association: "Verband",
      },
      fr: {
        title: "Nouvelles",
        project: "Projets",
        politics: "Politique professionnelle",
        association: "Association",
      },
    },
  },
};
</script>

<style scoped>

</style>

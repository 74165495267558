<template>
  <v-col cols="12">
    <h3 class="pb-2">{{ title }}</h3>
    <tag-on-off-checkbox
      hide-if-not-set
      :box-probs="boxProbs"
      v-for="tag in tags"
      :key="tag.name"
      :tag-name="tag.name"
      :tag-category="tag.category"
      :tag-number="tag.number"
      :label-true="tag.name"
      :label-false="tag.name"
    />
  </v-col>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TagOnOffCheckbox from "../../../../components/Edit/EditBoxChildren/TagOnOffCheckbox.vue";

export default {
  name: "MentorTagsSection",
  path: "pages.mentoring.mentor_details.sections.mentor_tags_section",
  components: {TagOnOffCheckbox},
  mixins: [EditBoxChildMixin],
  props: {
    title: { type: String, required: true },
    tags: { type: Array, required: true, default: () => [] },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col sm="12" md="6">
      <page-subtitle
        :subtitle="mentor_name"
      />
      <edit-box
        v-if="mentor"
        :editable="mentorEditable"
        :edit-object="mentor_with_address"
      >
        <template v-slot:default="boxProbs">
          <shared-image-upload-section
            :box-probs="boxProbs"
          />
          <shared-profile-section
            :box-probs="boxProbs"
          />
        </template>
      </edit-box>
      <loading-circle v-else />
    </v-col>

    <v-col sm="12" md="6">
      <page-subtitle
        :subtitle="mentee_name"
      />
      <edit-box
        v-if="mentoring"
        :editable="menteeEditable"
        :edit-object="mentee_with_address"
      >
        <template v-slot:default="boxProbs">
          <shared-image-upload-section
            :box-probs="boxProbs"
          />
          <shared-profile-section
            :box-probs="boxProbs"
          />
        </template>
      </edit-box>
      <loading-circle v-else />
    </v-col>
  </v-row>
</template>

<script>
import LoadingCircle from "../../../../components/UI-Elements/LoadingCircle.vue";
import PageSubtitle from "../../../../components/UI-Elements/PageSubtitle.vue";
import EditBox from "../../../../components/Edit/EditBox.vue";
import SharedImageUploadSection from "./SharedImageUploadSection.vue";
import SharedProfileSection from "./SharedProfileSection.vue";
import JointEditObject from "../../../../services/JointEditObject.js";

export default {
  name: "MentorMenteeSection",
  path: "pages.mentoring.mentoring_details.sections.mentor_mentee_section",
  components: {
    SharedProfileSection, SharedImageUploadSection, EditBox, PageSubtitle, LoadingCircle,
  },
  props: {
    mentor: { type: Object, required: true },
    mentoring: { type: Object, required: true },
    mentorEditable: { type: Boolean, default: false },
    menteeEditable: { type: Boolean, default: false },
  },
  computed: {
    mentee_address() {
      return this.mentoring?.AddressPlugin?.address;
    },
    mentor_address() {
      return this.mentor?.AddressRelationsPlugin?.Verantwortlich[0]?.address;
    },
    mentor_with_address() {
      return new JointEditObject({
        bom_object: this.mentor,
        address: this.mentor_address,
      });
    },
    mentee_with_address() {
      return new JointEditObject({
        bom_object: this.mentoring,
        address: this.mentee_address,
      });
    },
    mentor_name() {
      if (!this.mentor_address) { return this.$t("mentor"); }
      return `${this.$t("mentor")}: ${this.mentor_address?.first_name} ${this.mentor_address?.last_name}`;
    },
    mentee_name() {
      if (!this.mentee_address) { return this.$t("mentee"); }
      return `${this.$t("mentee")}: ${this.mentee_address?.first_name} ${this.mentee_address?.last_name}`;
    },
  },
  i18n: {
    messages: {
      de: {
        mentor: "Mentor/-in",
        mentee: "Mentee",
      },
      fr: {
        mentor: "Mentor",
        mentee: "Mentoré",
      },
    },
  },
};
</script>

<style scoped>

</style>

/**
 * The store consists of different modules (like sub-stores) that each contain a specific state
 * with its getters, mutations and actions. The main-store here is kept very small, we mainly import
 * and define the stores submodules here.
 */

// Import Vue and Vuex (Store)
import Vue from "vue";
import Vuex from "vuex";

// Import all store modules
import language from "./modules/language.js";
import user from "./modules/user.js";
import auth from "./modules/auth.js";
import mentoring from "./modules/mentoring.js";
import config from "./modules/config.js";
import global from "./modules/global.js";

// Register Vuex as a Vue plugin
Vue.use(Vuex);

// Here, we create a new store that will be used by Vue
export default new Vuex.Store({
  state: {
    // eslint-disable-next-line no-undef
    application_version: APPLICATION_VERSION || "0",
  },
  getters: {
    applicationVersion(state) {
      return state.application_version;
    },
  },
  mutations: { },
  actions: { },
  // Define our store modules
  modules: {
    language,
    user,
    auth,
    mentoring,
    config,
    global,
  },
});

<template>
    <div>
      <page-title
        :title="$t('title')"
      />

      <page-subtitle
        :subtitle="$t('publisher')"
      />
      <p v-html="$t('addresses.fhschweiz')" />

      <page-subtitle
        :subtitle="$t('consulting_and_sales')"
      />
      <p v-html="$t('addresses.consulting_and_sales')" />

      <page-subtitle
        :subtitle="$t('design_and_development')"
      />
      <p v-html="$t('addresses.bambus')" />
      <p v-html="$t('addresses.polygon')" />
    </div>
</template>

<script>
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";

export default {
  name: "Impressum",
  path: "pages.impressum.impressum",
  components: {PageSubtitle, PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Impressum",
        publisher: "Herausgeber",
        consulting_and_sales: "Beratung und Verkauf",
        design_and_development: "Gestaltung und Programmierung",
        addresses: {
          fhschweiz: "<strong>FH SCHWEIZ</strong><br>Dachverband Absolventinnen und Absolventen Fachhochschulen<br>8005 Zürich<br>043 244 74 55<br>mailbox(at)fhschweiz.ch",
          consulting_and_sales: "<strong>Timothy Walder, timothy.walder@fhschweiz.ch</strong>",
          bambus: "<strong>Bambus Software AG</strong><br>Sternmatt 6<br>6010 Kriens, LU<br>041 444 11 11<br>bambus@bambus.ch<br>www.bambus-software.ch",
          polygon: "<strong>PolygonSolutions GmbH</strong><br>Thurgauerstrasse 117<br>8152 Glattpark, ZH<br>076 281 85 82<br>kontakt@polygon-software.ch<br>www.polygon-software.ch",
        },
      },
      fr: {
        title: "Impressum",
        publisher: "Editeur",
        consulting_and_sales: "Conseil et vente",
        design_and_development: "Conception et programmation",
        addresses: {
          fhschweiz: "<strong>FH SCHWEIZ</strong><br>Dachverband Absolventinnen und Absolventen Fachhochschulen<br>8005 Zürich<br>043 244 74 55<br>mailbox(at)fhschweiz.ch",
          consulting_and_sales: "<strong>Timothy Walder, timothy.walder@fhschweiz.ch</strong>",
          bambus: "<strong>Bambus Software AG</strong><br>Sternmatt 6<br>6010 Kriens, LU<br>041 444 11 11<br>bambus@bambus.ch<br>www.bambus-software.ch",
          polygon: "<strong>PolygonSolutions GmbH</strong><br>Thurgauerstrasse 117<br>8152 Glattpark, ZH<br>076 281 85 82<br>kontakt@polygon-software.ch<br>www.polygon-software.ch",
        },
      },
    },
  },
};
</script>

<template>
  <fragment>
    <input-wrapper
      sm="10"
      md="6"
    >
      <pdf-uploader
        :box-probs="boxProbs"
        label="CV"
        filename="cv"
        :tag="cv_tag"
        legacy-marker="cv"
      />
    </input-wrapper>
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import InputWrapper from "../../../../components/Edit/EditBoxChildren/InputWrapper.vue";
import PdfUploader from "../../../../components/Edit/EditBoxChildren/PdfUploader.vue";
import {CV} from "../../../../constants/TAG_NUMBERS.js";

export default {
  name: "MentorCvSection",
  path: "pages.mentoring.mentor_details.sections.mentor_cv_section",
  components: {PdfUploader, InputWrapper },
  mixins: [EditBoxChildMixin],
  data: () => ({
    cv_tag: {category: "Report-Verwendung", number: CV, name: "CV (Lebenslauf)"},
  }),
};
</script>

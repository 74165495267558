export default class JointEditObject {
  constructor(edit_objects = {}) {
    this._keys = [];
    Object.entries(edit_objects).forEach(([name, edit_object]) => {
      this[name] = edit_object;
      this._keys.push(name);
    });
  }

  add(name, edit_object) {
    this._keys.push(name);
    this[name] = edit_object;
  }

  _applyAll(function_name) {
    return Promise.all(
      this._keys.map((key) => {
        if (this && this.hasOwnProperty(key) && this[key] && this[key].hasOwnProperty(function_name)) {
          return this[key][function_name]();
        }
        return null;
      }),
    );
  }

  save() {
    return this._applyAll("save");
  }

  delete() {
    return this._applyAll("delete");
  }
}

<template>
  <div>
    <card-group
      :loading="loading_academies"
      :loading-message="$t('loading_message')"
      :empty="registered_academies.length === 0"
      :empty-message="$t('empty_message')"
    >
      <academies-card
        v-for="academies in registered_academies"
        :key="academies.id"
        :academies="academies"
      />
    </card-group>
  </div>
</template>

<script>
import CardGroup from "../../../components/Cards/CardGroup.vue";
import AcademiesCard from "../../../components/Cards/AcademiesCard.vue";

/**
 * This is the registered cards component, that loads all the events that the logged in user is registered to,
 * per default on all clients. It can be implemented with or without the client switch.
 */
export default {
  name: "RegisteredAcademiesSection",
  path: "pages.market-place.components.registered_academies_section",
  components: {
    AcademiesCard,
    CardGroup,
  },
  data() {
    return {
      registered_academies: [],
      loading_academies: false,
      user_id: "getUser",
      user_registered_academies: [],
    };
  },
  computed: {
  },
  methods: {
    fetchUsersRegistrations() {
      return this.$bom.marketplace.references.all
        .ofLoggedInUser() // Event registrations of logged in user only
        .startingBefore(new Date())
        .endingAfter(new Date())
        .including("InstancePlugin") // Include the event instance in the response
        .fetch()
        .then(({references}) => {
          // Store the IDs of all events to which the user has a registration
          this.user_registered_academies = references.map((reference) => reference.InstancePlugin.instance.id);
        });
    },
    fetchEvents() {
      this.loading_academies = true;
      const bom_query = this.$bom.marketplace.instances.all
        .including("AddressRelationsPlugin", "ReferencesPlugin")
        .sortedBy("name");

      return bom_query.fetch()
        .then(({instances}) => {
          this.registered_academies = instances.filter((instance) => this.user_registered_academies.includes(instance.id));
        })
        .finally(() => {
          this.loading_academies = false;
        });
    },
  },
  async created() {
    await this.fetchUsersRegistrations();
    await this.fetchEvents();
  },
  i18n: {
    messages: {
      de: {
        title: "Angemeldete Academies",
        loading_message: "Academies werden geladen",
        empty_message: "Keine Academies gefunden",
      },
      fr: {
        title: "Académies enregistrés",
        loading_message: "Les Académies sont en cours de chargement",
        empty_message: "Aucun Académies suivant trouvé",
      },
      it: {
        title: "Accademie registrato",
        loading_message: "Gli Accademie vengono caricati",
        empty_message: "Nessun Accademie trovato",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <dropdown-manual-input
      :box-probs="boxProbs"
      :label="$t('address.salutation')"
      :items="$t('address.salutations')"
      field="salutation"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.firstname')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="first_name"
      mandatory
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.lastname')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="last_name"
      mandatory
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.street')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="street"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.street_addition')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="street_addition"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.mailbox')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="mailbox"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.postcode')"
      :maxlength="8"
      field="postcode"
      numeric
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.city')"
      :maxlength="$config.LINE_INPUT_MAX_LENGTH"
      field="city"
    />
    <dropdown-auto-input
      :box-probs="boxProbs"
      :label="$t('address.country')"
      field="country"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.phone')"
      phone
      field="phone_number"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.mobile')"
      phone
      field="mobile_number"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.mail')"
      email
      field="mail"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address.website')"
      website
      field="website"
    />
    <date-picker
      :box-probs="boxProbs"
      :label="$t('address.birthday')"
      field="from"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import DropdownManualInput from "../../../../components/Edit/EditBoxChildren/DropdownManualInput.vue";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";
import DropdownAutoInput from "../../../../components/Edit/EditBoxChildren/DropdownAutoInput.vue";
import DatePicker from "../../../../components/Edit/EditBoxChildren/DatePicker.vue";

export default {
  name: "MentorProfileSection",
  path: "pages.mentoring.mentor_details.sections.mentor_profile_section",
  components: {
    DatePicker,
    DropdownAutoInput,
    TextInput,
    DropdownManualInput,
  },
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        salutation: "Anrede",
        firstname: "Vorname",
        lastname: "Name",
      },
      fr: {
        salutation: "Salutation",
        firstname: "Prénom",
        lastname: "Nom de famille",
      },
    },
  },
};
</script>

<style scoped>

</style>

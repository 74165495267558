<template>
  <compact-person-address-card :address="address">
    <noted-saved-icons :address="address"/>
  </compact-person-address-card>
</template>

<script>
import NotedSavedIcons from "../Icon/NotedSavedIcons.vue";
import CompactPersonAddressCard from "./CompactPersonAddressCard.vue";

export default {
  name: "CompactPersonCard",
  path: "components.cards.compact_person_card",
  components: {
    CompactPersonAddressCard,
    NotedSavedIcons,
  },
  props: {
    // The BomCompanyAddress/BomContactAddress of the company that shall be displayed here
    address: {type: Object, required: true},
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-checkbox
      class="ma-0"
      :color="valid ? 'primary' : 'black'"
      :label="group.title"
      :true-value="true"
      :false-value="false"
      :disabled="loading"
      :input-value="checked"
      @change="onCheckboxClick"
    />
    <p
      v-for="error in errors"
      :key="error"
      class="error--text pa-0 ma-0 font-weight-light font-italic body-2"
    >
      {{error}}
    </p>
    <v-divider class="my-2"/>
  </div>
</template>

<script>
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMCondition",
  path: "components.reg_mask.reg_mask_field.r_m_condition",
  mixins: [RegMaskFieldMixin],
  data: () => ({
    loading: false,
    checked: false,
  }),
  computed: {
    errors() {
      const errors = [];
      const field = this.$v.checked;
      if (field.$invalid) {
        if (!field.accepted) {
          errors.push(this.$t("must_be_accepted"));
        }
      }
      return errors;
    },
  },
  methods: {
    syncShadowState() {
      this.checked = this.group.selected;
      this.loading = false;
    },
    onCheckboxClick() {
      let promise;
      this.loading = true;
      if (this.checked) {
        promise = this.group.deselect();
      } else {
        promise = this.group.select();
      }
      promise.then(() => {
        this.syncShadowState();
      });
    },
  },
  validations() {
    return {
      checked: {
        accepted: (val) => !!val,
      },
    };
  },
  i18n: {
    messages: {
      de: {
        must_be_accepted: "Diese Bedingung muss akzeptiert werden",
      },
      fr: {
        must_be_accepted: "Cette condition doit être acceptée",
      },
    },
  },
};
</script>

<style scoped>
  div.v-input--checkbox >>> div.v-messages {
    height: 0 !important;
    position: absolute;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('page-title',{attrs:{"title":_vm.$t('title')}}),_c('page-subtitle',{attrs:{"subtitle":_vm.$t('application_and_templates')}}),_c('tags-with-lazy-loading-button',{attrs:{"selected-tag":_vm.applicationsTag,"module":_vm.module,"object-type":_vm.objectType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('form-card',{key:item.number,attrs:{"form":item}})})}}])}),_c('page-subtitle',{attrs:{"subtitle":_vm.$t('statutes_and_guidelines')}}),_c('tags-with-lazy-loading-button',{attrs:{"selected-tag":_vm.statutesTag,"module":_vm.module,"object-type":_vm.objectType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('form-card',{key:item.number,attrs:{"form":item}})})}}])}),_c('page-subtitle',{attrs:{"subtitle":_vm.$t('annual_statistics')}}),_c('tags-with-lazy-loading-button',{attrs:{"selected-tag":_vm.annualTag,"module":_vm.module,"object-type":_vm.objectType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('form-card',{key:item.number,attrs:{"form":item}})})}}])}),_c('page-subtitle',{attrs:{"subtitle":_vm.$t('general_assembly')}}),_c('tags-with-lazy-loading-button',{attrs:{"selected-tag":_vm.generalTag,"module":_vm.module,"object-type":_vm.objectType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('form-card',{key:item.number,attrs:{"form":item}})})}}])}),_c('page-subtitle',{attrs:{"subtitle":_vm.$t('technical_title_form')}}),_c('tags-with-lazy-loading-button',{attrs:{"selected-tag":_vm.technicalTag,"module":_vm.module,"object-type":_vm.objectType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('form-card',{key:item.number,attrs:{"form":item}})})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
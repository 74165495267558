<template>
  <div>
    <page-title
      :title="$t('liability')"
    />
    <collapsible-group>
      <collapsible :title="$t('liability')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="liability_channels"
              :empty-message="liability_message"/>
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "Liability",
  path: "pages.qualifications.liability",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      liability_channels: [
        {
          // todo: add tag_number
          name: this.$t("liability"), tag_category: "", tag_name: "SBAP Kollektiv-Haftpflicht", tag_number: "",
        },
      ],
      liability_message: this.$t("liability_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        liability: "SBAP Kollektiv-Haftpflicht",
        liability_message: "Keine SBAP Kollektiv-Haftpflicht vorhanden",
      },
      fr: {
        liability: "SBAP Responsabilité collective",
        liability_message: "Pas d'assurance responsabilité collective SBAP",
      },
    },
  },
};
</script>

<style scoped>

</style>

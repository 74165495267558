/* eslint-disable no-shadow */
/**
 * The authentication store module handles the users login state
 */

const state = () => ({
  logged_in: undefined, // Signifies whether the user as a valid authentication cookie
});

const getters = {
  /**
   * Returns the login value of the current user
   * @param state
   * @returns {boolean}
   */
  isLoggedIn(state) {
    return !!state.logged_in;
  },
};

const mutations = {
  /**
   * Overwrites the users login state
   * @param state
   * @param {boolean} logged_in - new login state of user
   */
  setLoginState(state, logged_in) {
    state.logged_in = logged_in;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
    <fragment>
      <text-input
        field="search_address.first_name"
        :label="$t('search_address.first_name')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address.name"
        :label="$t('search_address.name')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address.street"
        :label="$t('search_address.street')"
        :box-probs="boxProbs"
      />
      <text-input
        numeric
        field="search_address.postcode"
        :label="$t('search_address.postcode')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address.city"
        :label="$t('search_address.city')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address.mail"
        :label="$t('search_address.mail')"
        :box-probs="boxProbs"
      />
    </fragment>
</template>

<script>
import TextInput from "../../../components/Edit/EditBoxChildren/TextInput.vue";
import SearchSectionMixin from "../../../mixins/SearchSectionMixin.js";

export default {
  name: "PrivateSearchSection",
  path: "pages.whoswho.sections.private_search_section",
  mixins: [SearchSectionMixin],
  components: {TextInput},
};
</script>

<style scoped>

</style>

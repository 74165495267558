<template>
  <image-card
    :title="form | name"
    :image="image"
    :action="routeToForms"
  >
    <p>
      <strong>{{$t("date")}}:</strong> {{ form | from_date }}
    </p>
    <p>
      {{ form | tagcat("Formulare") }}
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-event.png";

/**
 * The FormCard component displays a forms card with an image, title, start date and tag name.
 */
export default {
  name: "FormCard",
  path: "components.cards.form_card",
  components: {ImageCard},
  props: {
    /**
     * A form BomInstance
     */
    form: { type: Object, required: true },
  },
  data() {
    return {
      image: default_image,
    };
  },
  methods: {
    routeToForms() {
      this.$router.push({
        name: "formDetails",
        params: {
          id: this.form.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        date: "Datum",
      },
      fr: {
        date: "Date",
      },
    },
  },
};
</script>

<template>
  <v-expansion-panels
    :value="openSections"
    @change="$emit('update:open-sections', $event)"
    accordion
    multiple
  >
    <slot />
  </v-expansion-panels>
</template>

<script>
export default {
  name: "RegMaskBody",
  path: "components.reg_mask.reg_mask_body",
  props: {
    openSections: { type: Array, default: () => ([]), required: true },
  },
};
</script>

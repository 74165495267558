<template>
  <div>
    <page-title
      :title="$t('permission')"
    />
    <collapsible-group>
      <collapsible :title="$t('permission')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="permit_channels"
              :empty-message="permit_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "PracticePermit",
  path: "pages.qualifications.practice_permit",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      permit_channels: [
        {
          name: this.$t("permission"), tag_category: "Praxisbewilligung", tag_name: "Praxisbewilligung", tag_number: "17600",
        },
      ],
      permit_message: this.$t("permit_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        permission: "Praxisbewilligung",
        permit_message: "Keine Praxisbewilligung vorhanden",
      },
      fr: {
        permission: "Licence de pratique",
        permit_message: "Aucun permis d'exercice disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

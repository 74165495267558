/* eslint-disable no-shadow */
import bom from "../../plugins/bom.js";
import eventBus from "../../plugins/eventBus.js";
import ProfileImageService from "../../services/ProfileImageService.js";
import default_user_image from "../../assets/images/placeholders/std-private.png";
import {OWN_COMPANY_ADDRESS_TYPES} from "../../constants/USER.js";

/**
 * The user store module stores all data regarding the currently logged-in user. Most importantly,
 * this module stores the users address (as received from the bom-framework), the users profile image
 * and the users set of available clients.
 */

const state = () => ({
  user: undefined, // Users address as returned by the bom-framework
  user_id: undefined,
  user_image: default_user_image, // Users profile image in base64, can be directly used as a :src attribute of <img> tags
  loading: false, // Indicates whether the store is currently fetching the users address
  client: undefined, // The currently active client for this user
  all_clients: [],
});

const getters = {
  /**
   * Return whether the users address is currently loading
   * @param state
   * @returns {boolean}
   */
  isLoading(state) {
    return state.loading;
  },
  /**
   * Returns the users bom address (BomProfileAddress)
   * @param state
   * @returns {BomProfileAddress}
   */
  getUser(state) {
    return state.user;
  },
  /**
   * Returns a list of clients for which the user is registered. This are the universities the user
   * is registered to, like "Universität Zürich" or "ZHaW".
   * We filter out the client with id "1" since it is only used for login purposes. We return a list
   * of Client objects from the Framework.
   * @param state
   * @returns {{client_id: string, client_name: string}[]}
   */
  getValidClients(state) {
    return state.all_clients.filter((client) => client.client_id !== "1");
  },
  /**
   * Returns the currently selected client
   * @param state
   * @returns {{client_id: string, client_name: string}}
   */
  getClient(state) {
    return state.client;
  },
  isOnMentoringClient(state) {
    return state.client.client_id === "26";
  },
  /**
   * Returns the user image as a base64 string
   * @param state
   * @returns {string}
   */
  getUserImage(state) {
    return state.user_image || default_user_image;
  },
  /**
   * ID of user address
   * @param state
   * @returns {undefined|string}
   */
  getUserId(state) {
    if (!state.user) { return undefined; }
    return state.user.id;
  },
  /**
   * ID of users own address but including all the ID of all addresses mentioned in
   * the users AddressRelations that are of type "Firma"
   * @param state
   * @returns {string[]}
   */
  getAllUserIds(state) {
    if (!state.user) { return []; }
    return state.user.AddressRelationsPlugin.all
      .filter((address_relation) => OWN_COMPANY_ADDRESS_TYPES.includes(address_relation.type_number))
      .map((address_relation) => address_relation.address.id);
  },
};

const mutations = {
  /**
   * Set a new loading state
   * @param state
   * @param {boolean} loading
   * @private
   */
  _setLoading(state, loading) {
    state.loading = loading;
  },
  /**
   * Sets a new user address
   * @param state
   * @param {BomProfileAddress} address
   * @private
   */
  _setUser(state, address) {
    state.user = address;
    state.user_id = address.id;
  },
  /**
   * Set a new active client. We store the clients ID in the local store such that the user
   * automatically has one client pre-selected when he reloads the application.
   * @param state
   * @param {{client_name: string, client_id: string, select: function}} client - Client object form bom-framework
   * @private
   */
  _setClient(state, client) {
    client.select();
    state.client = client;
    sessionStorage.setItem("client_id", state.client.client_id);
    eventBus.$emit("rerender");
  },
  /**
   * Reads out the users user image from his address. The profile image is stored as an ArchiveRelation.
   * However, the ProfileImageService takes care of reading out the correct Archive.
   * @param state
   * @param  {string} file
   * @private
   */
  _updateUserImage(state, file) {
    if (file) {
      state.user_image = file;
    } else {
      state.user_image = ProfileImageService.getProfileImage(state.user);
    }
  },
  /**
   * Sets the array of all available clients for the currently logged in user
   * @param state
   * @param {{client_id: string, client_name: string}[]} all_clients
   * @private
   */
  _setAllClients(state, all_clients) {
    state.all_clients = all_clients;
  },
  /**
   * Sets id of users address
   * @param state
   * @param {String} user_id
   * @private
   */
  _setUserId(state, user_id) {
    state.user_id = user_id;
  },
};

const actions = {
  fetchAllClients({ commit }) {
    return bom.addresses.one
      .ofLoggedInUser()
      .fetch()
      .then(({address}) => {
        const all_clients = address.ClientsPlugin.all;
        commit("_setAllClients", all_clients);
        commit("_setUserId", address.id);
      });
  },

  /**
   * Switches to a new client and reloads users address since some parts of the user address are client
   * dependent. For example, the user has a new profile image for each client.
   * @param commit
   * @param dispatch
   * @param {{client_name: string, client_id: string, select: function}} client - Client object form bom-framework
   * @returns {Promise<undefined>}
   */
  switchClient({commit, dispatch}, client) {
    commit("_setClient", client);
    return dispatch("fetchUser")
  },
  /**
   * This action tries to pre-select one of the users clients. If the user has already selected a client
   * once before, we can read out the client id from the local storage and auto-select the corresponding
   * client. If he only has one client anyways, we can also pre-select it.
   * The return value indicates whether a client could be auto-selected or not.
   * @param state
   * @param commit
   * @returns {boolean}
   */
  initializeClient({state, commit}) {
    const permanent_client_id = sessionStorage.getItem("client_id");
    if (permanent_client_id !== null) {
      const valid_clients = getters.getValidClients(state);
      const selected_client = valid_clients.find((client) => client.client_id === permanent_client_id);
      if (selected_client) {
        commit("_setClient", selected_client);
        return true;
      }
      if (valid_clients.length === 1) {
        commit("_setClient", valid_clients[0]);
        return true;
      }
    }
    return false;
  },
  /**
   * Saves the users address using the bom-framework, then reloads the updated user address.
   * The new user address is returned. If the saving failed, an error is returned, e.g.
   * the promise is rejected.
   * @param commit
   * @param dispatch
   * @param state
   * @returns {Promise<Object> | Promise<Error>}
   */
  saveUser({commit, dispatch, state}) {
    commit("_setLoading", true);
    return state.user.save()
      .then(() => {
        return dispatch("fetchUser");
      });
  },
  /**
   * Fetches the address of the logged in user. It automatically sets the users profile image and
   * handles the loading state.
   * @param commit
   * @returns {Promise<Object> | Promise<Error>}
   */
  fetchUser({commit}) {
    commit("_setLoading", true);
    return bom.addresses.one
      .ofLoggedInUser()
      .including("ArchiveRelationsPlugin", "TagsPlugin", "AddressRelationsPlugin")
      .fetch()
      .then(({address}) => {
        return ProfileImageService.fetchProfileImage(address).then((file) => ({ address, file }));
      })
      .then(({ address, file }) => {
        commit("_setUser", address);
        commit("_updateUserImage", file);
        commit("_setLoading", false);
        return address;
      })
      .catch((err) => {
        commit("_setLoading", false);
        console.error(err);
        return err;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{title}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-skeleton-loader
          v-if="loading"
          type="table-heading, sentences, paragraph"
        />
        <slot v-else />
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>

/**
 * This component simply wraps the vuetify expansion pannels and makes for an easier interface
 * The title is shown inside of the expansion pannel header
 */
export default {
  name: "Collapsible",
  path: "components.structure.collabsible",
  props: {
    // Text to show within the expansion pannel
    title: { type: String, required: true, default: "" },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style>
  .v-expansion-panel-header {
    border-bottom: 2px solid var(--primary) !important;
    font-size: 1.2rem !important;
    font-weight: bolder;
    background-color: white;
  }
  .v-expansion-panel-header--active {
    color: var(--primary) !important;
    background-color: #f3f6ff;
  }
</style>

<template>
  <div
    class="menu-avatar"
    :style="style_avatar"
  >
    <div
      v-if="image"
      :style="style"
      class="menu-image"
    ></div>
  </div>
</template>

<script>

/**
 * Small component that generates a perfeclty square and round image.
 */
export default {
  name: "SquaredImage",
  path: "components.images.squared_image",
  props: {
    /**
     * Image that shall be displayed
     */
    image: { type: String, required: true },
    /**
     * Radius of the border
     */
    radius: { type: Number, required: false, default: () => 50 },
    /**
     * opacity of the border [0.0, 1.0]
     */
    opacity: { type: Number, required: false, default: () => 0.8 },
  },
  computed: {
    /**
     * Wraps the base64 encoded image in an url-function that is required by CSS
     * @returns {string}
     */
    image_url() {
      return `url(${this.image})`;
    },
    style() {
      if (window.bomconfig?.image?.no_repeat) {
        return `border-radius: ${this.radius}%; background: ${this.image_url}; opacity: ${this.opacity}; background-repeat: no-repeat !important;`;
      }
      return `border-radius: ${this.radius}%; background: ${this.image_url}; opacity: ${this.opacity};`;
    },
    style_avatar() {
      if (window.bomconfig?.image?.background) {
        return `border-radius: ${this.radius}%; background-color: ${window.bomconfig.image.background} !important;`;
      }
      return `border-radius: ${this.radius}%;`;
    },
  },
};
</script>

<style scoped>
  .menu-avatar {
    position: relative;
    background-color: var(--primary);
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
  }
  @media screen and (min-width: 1475px) {
    .menu-avatar {
      width: 134px;
      height: 134px;
      padding-bottom: 0;
      margin: 0 auto;
    }
  }
  .menu-image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: contain !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
  }
</style>

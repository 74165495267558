<template>
  <v-expansion-panels
    accordion
    flat
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{$t('private')}}
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row>
          <private-search-section
            :query="query"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{$t('business')}}
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row>
          <business-search-section
            :query="query"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{$t('education')}}
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row>
          <education-search-section
            :query="query"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{$t('qualification')}}
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row>
          <qualification-search-section
            :query="query"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import SearchSectionMixin from "../../mixins/SearchSectionMixin.js";
import PrivateSearchSection from "./Sections/PrivateSearchSection.vue";
import BusinessSearchSection from "./Sections/BusinessSearchSection.vue";
import EducationSearchSection from "./Sections/EducationSearchSection.vue";
import QualificationSearchSection from "./Sections/QualificationSearchSection.vue";

export default {
  name: "ExtendedSearch",
  path: "pages.whoswho.extended_search",
  mixins: [SearchSectionMixin],
  components: {
    QualificationSearchSection,
    EducationSearchSection,
    BusinessSearchSection,
    PrivateSearchSection,
  },
  i18n: {
    messages: {
      de: {
        private: "Privat",
        business: "Geschäft",
        education: "Ausbildung",
        qualification: "Weiterbildung",
      },
      fr: {
        private: "Privé",
        business: "Entreprise",
        education: "Formation",
        qualification: "Formation continue",
      },
    },
  },
};
</script>

<style scoped>

</style>

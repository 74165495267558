var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('collapsible-group',[_c('collapsible',{attrs:{"title":_vm.$t('private')}},[_c('edit-box',{attrs:{"editable":true,"edit-object":_vm.user},on:{"saved":_vm.fetchUser},scopedSlots:_vm._u([{key:"default",fn:function(boxProbs){return [_c('visibility-switches',{attrs:{"box-probs":boxProbs,"visibility-tag":_vm.private_tags}})]}}])})],1),_vm._l((_vm.company_addresses),function(company){return _c('collapsible',{key:company.company_name,attrs:{"title":company.company_name || _vm.$t('company_name')}},[_c('edit-box',{attrs:{"editable":true,"edit-object":company},on:{"saved":_vm.fetchUser},scopedSlots:_vm._u([{key:"default",fn:function(boxProbs){return [_c('visibility-switches',{attrs:{"box-probs":boxProbs,"visibility-tag":_vm.company_tags}})]}}],null,true)})],1)}),(_vm.educations.length>0)?_c('collapsible',{attrs:{"title":_vm.$t('education')}},[_c('edit-box',{attrs:{"editable":true,"edit-object":_vm.education},on:{"saved":_vm.fetchUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var editObject = ref.editObject;
var saving = ref.saving;
var editmode = ref.editmode;
var eventname = ref.eventname;
return _vm._l((_vm.education._keys),function(key){return _c('visibility-switches',{key:key,attrs:{"box-probs":{editObject: editObject[key] , saving: saving, editmode: editmode, eventname: eventname},"visibility-tag":_vm.education_tags}})})}}],null,false,3760922521)})],1):_vm._e(),(_vm.further_educations.length > 0)?_c('collapsible',{attrs:{"title":_vm.$t('further_education')}},[_c('edit-box',{attrs:{"editable":true,"edit-object":_vm.further_education},on:{"saved":_vm.fetchUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var editObject = ref.editObject;
var saving = ref.saving;
var editmode = ref.editmode;
var eventname = ref.eventname;
return _vm._l((_vm.further_education._keys),function(key){return _c('visibility-switches',{key:key,attrs:{"box-probs":{editObject: editObject[key] , saving: saving, editmode: editmode, eventname: eventname},"visibility-tag":_vm.further_educations_tag}})})}}],null,false,2632757177)})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
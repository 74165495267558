<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
    :hint="hint"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :type="show ? 'text' : 'password'"
    @click:append="show = !show"
    :error-count="4"
  ></v-text-field>
</template>

<script>
/**
 * This component handles the input fields specifically for entering passwords. It has a function to hide the letters (with show),
 * to count the input characters, to add rules the input field must follow and to give hints.
 */
export default {
  name: "PasswordInput",
  path: "components.ui_elements.password_input",
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    rules: { type: Array, required: false },
    hint: { type: String, required: false },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>

</style>

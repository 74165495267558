<template>
    <div>
      <page-title
        :title="$t('title')"
      />
      <collapsible-group>
        <collapsible :title="$t('companydata')">
          <edit-box
            :editable="can_edit"
            :edit-object="new_created ? company_address : contact_address"
            :start-in-edit-mode="new_created"
            @saved="companyAddressSaved"
          >
            <template v-slot:default="boxProbs">
              <company-data-section
                :box-probs="boxProbs"
                :new-created="new_created"
              />
            </template>
          </edit-box>
        </collapsible>
        <collapsible :title="$t('companyaddress')">
          <edit-box
            :editable="can_edit"
            :edit-object="new_created ? company_address : contact_address"
            :start-in-edit-mode="new_created"
            @saved="companyAddressSaved"
          >
            <template v-slot:default="boxProbs">
              <company-address-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>
        <collapsible :title="$t('contactdetails')">
          <edit-box
            :editable="can_edit"
            :edit-object="contact_address"
            :start-in-edit-mode="new_created"
          >
            <template v-slot:default="boxProbs">
              <contact-details-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>
        <collapsible :title="$t('companylogo')">
          <edit-box
            :editable="can_edit"
            :edit-object="contact_address"
            :start-in-edit-mode="new_created"
          >
            <template v-slot:default="boxProbs">
              <contact-image-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>
      </collapsible-group>
      <br>
      <delete-button
        v-if="!readOnly"
        :deleted="deleted"
        :deleting="deleting"
        redirect="profile-address"
        @delete="endAddressRelation"
      />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import collapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import CompanyDataSection from "./sections/CompanyDataSection.vue";
import CompanyAddressSection from "./sections/CompanyAddressSection.vue";
import ContactDetailsSection from "./sections/ContactDetailsSection.vue";
import ContactImageSection from "./sections/ContactImageSection.vue";
import DeleteButton from "../../../components/UI-Elements/DeleteButton.vue";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";

/**
 * The "CompanyAddress page displays properties of a.) CompanyAddress and b.) ContactAddress.
 * The concept is as follwos: A Company has an address, like "PolygonSolutions GmbH, Thurgauerstrasse 111"
 * An employee of Polygon has a ContactAddress to Polygon, with additional information like his
 * position within the company, or even another street in which is office building is located.
 *
 * Therefore, in this screen, some data must be written to the company address (like the company name)
 * and some parts must be written to the user-specific contact address (like the users position within
 * the company).
 */
export default {
  name: "CompanyAddress",
  path: "pages.address.company.company_address",
  mixins: [DetailPageMixin],
  components: {
    DeleteButton,
    ContactImageSection,
    ContactDetailsSection,
    CompanyAddressSection,
    CompanyDataSection,
    EditBox,
    collapsible,
    collapsibleGroup,
    PageTitle,
  },
  data() {
    return {
      contact_address: undefined, // Contact address, connects a Person-Address with a Company-Address
      company_address: undefined, // Company address, represents a company
    };
  },
  computed: {
    /**
     * We map the getters "getAllUserIds" and "getUser" from the user store module to local
     * computed properties
     */
    ...mapGetters("user", {
      all_user_ids: "getAllUserIds",
      user: "getUser",
    }),
    /**
     * The address can only be edited if the ID of the contact address is listed in the users
     * AddressRelations, hence it is HIS contact address
     * @returns {boolean}
     */
    can_edit() {
      if (this.$route.meta.readOnly) {
        return false;
      } if (this.all_user_ids.includes(this.id) && !this.deleted) {
        return true;
      }
      return false;
    },
    new_created() {
      return this.$route.meta.created === "true";
    },
    readOnly() {
      return this.$route.meta.readOnly;
    },
  },
  /**
   * On created, we fetch the address with the ID from the URL. The variable "this.id" is imported
   * within the mixin and reads out the "id" path parameter from the URL.
   * Once we fetched the contact address, we also fetch its corresponding CompanyAddress using
   * the "getCompanyAddress" function provided by the bom-framework.
   */
  created() {
    this.$bom.addresses.one
      .withId(this.id)
      .including("ForeignKeysPlugin", "ArchiveRelationsPlugin", "TagsPlugin")
      .fetch()
      .then(({address}) => {
        this.contact_address = address;
        return this.contact_address.getCompanyAddress();
      })
      .then(({address}) => {
        this.company_address = address;
      })
      .catch((error) => console.error(error));
  },
  methods: {
    /**
     * We map the functions "fetchUser" and "saveUser" from the user store module to local functions
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
      saveUser: "saveUser",
    }),
    /**
     * When the user wants to delete a CompanyAddress, we just end his address relation. This way,
     * we don't loose data but by ending his relation, we can show that the user no longer works at this
     * company. After changing the address relation, we must save the users address to make the change
     * persistent.
     * TODO: A bug in the bom-framework causes that ending the address relation is not saved!
     */
    endAddressRelation() {
      this.deleting = true;
      const now = new Date();
      const adr_rel_to_remove = this.user.AddressRelationsPlugin.all.find((adr_rel) => {
        return adr_rel.from < now && !adr_rel.to && adr_rel.address.id === this.contact_address.id;
      });
      adr_rel_to_remove.to = now;
      this.saveUser().then(() => {
        this.deleted = true;
      });
      this.$bom.clearCache("addresses");
    },
    async companyAddressSaved() {
      if (this.new_created) {
        this.contact_address.copyFrom(
          this.company_address,
          "street",
          "street_addition",
          "postcode",
          "city",
          "country",
          "website",
          "company_addition",
        );
        this.contact_address.copyFrom(
          this.user,
          "first_name",
          "last_name",
        );
        if (this.company_address.TagsPlugin.Branche && this.company_address.TagsPlugin.Branche.length > 0) {
          this.contact_address.TagsPlugin.add(this.company_address.TagsPlugin.Branche[0]);
        }
        await this.contact_address.save();
      }
      this.$bom.clearCache("addresses");
      this.fetchUser();
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Firmenadresse",
        companydata: "Firmendaten",
        companyaddress: "Firmenadresse",
        contactdetails: "Kontaktdetails",
        companylogo: "Firmenlogo",
      },
      fr: {
        title: "Adresse de l'entreprise",
        companydata: "Données des entreprises",
        companyaddress: "Adresse de l'entreprise",
        contactdetails: "Coordonnées",
        companylogo: "Logo",
      },
    },
  },
};
</script>

<style scoped>

</style>

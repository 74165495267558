<template>
  <div>
    <h4 class="text--black font-weight-bold mt-6 pa-1" :class="{'text--primary': valid}">{{ group.title }}</h4>
    <html-editor
      v-model="text"
      :disabled="loading"
      :error-messages="errors"
      :loading="loading"
      @focusout="onFieldWrite"
    />
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators/index.js";
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";
import HtmlEditor from "../../Edit/EditBoxChildren/HTMLEditor/HtmlEditor.vue";

export default {
  name: "RMInputField",
  path: "components.reg_mask.reg_mask_field.r_m_input_field",
  components: {HtmlEditor},
  mixins: [RegMaskFieldMixin],
  data: () => ({
    text: "",
    loading: false,
  }),
  computed: {
    errors() {
      const errors = [];
      const field = this.$v.text;
      if (field.$invalid) {
        if (!field.required) {
          errors.push(this.$t("required"));
        }
      }
      return errors;
    },
  },
  methods: {
    syncShadowState() {
      this.text = this.group.content;
      this.loading = false;
    },
    onFieldWrite() {
      this.loading = true;
      this.group.write(this.text).then(() => {
        this.syncShadowState();
      });
    },
  },
  validations() {
    return {
      text: {
        ...(this.group.required && { required }),
      },
    };
  },
  i18n: {
    messages: {
      de: {
        required: "Das Feld muss ausgefüllt werden",
        min_length: "Der Text unterschreitet die mindeste Anzahl an benötigten Zeichen.",
        max_length: "Der Text überschreitet die maximal Anzahl zugelassener Zeichen ({n}/{max}).",
      },
      fr: {
        required: "Le champ doit être rempli",
        min_length: "Le texte n'atteint pas le nombre minimum de caractères requis.",
        max_length: "Le texte dépasse le nombre maximum de caractères autorisé ({n}/{max}).",
      },
    },
  },
};
</script>

<style scoped>

</style>

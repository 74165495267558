<template>
  <input-wrapper>
    <v-autocomplete
      v-model="value"
      :name="Math.random()"
      :items="tags"
      item-text="name"
      item-value="number"
      :autocomplete="Math.random()"
      :label="label"
      auto-select-first
      :readonly="!boxProbs.editmode"
      :loading="loadingTags"
      :disabled="boxProbs.editmode && readonly"
      placeholder=" "
    />
  </input-wrapper>
</template>

<script>
import InputWrapper from "./InputWrapper.vue";
import InputMixin from "../../../mixins/InputMixin.js";

export default {
  name: "TagNumberInput",
  path: "components.edit.edit_box_children.tag_number_input",
  components: {InputWrapper},
  mixins: [InputMixin],
  props: {
    module: { type: String, required: true },
    tagCategory: { type: String, required: true },
  },
  data: () => ({
    tags: [],
    loadingTags: false,
  }),
  created() {
    let query;
    if (this.module === "addresses") {
      query = this.$bom.addresses;
    } else {
      query = this.$bom[this.module].references;
    }
    this.loadingTags = true;
    query.all
      .take(1)
      .including("TagsPlugin")
      .fetch()
      .then((response) => {
        return response.data[0].TagsPlugin.getAllPossibleTags(this.tagCategory);
      })
      .then((tags) => {
        this.tags = tags;
      }).
      finally(() => {
        this.loadingTags = false;
    })
  },
};
</script>

<style scoped>

</style>

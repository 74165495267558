<template>
  <p>
    RMMultiInputLine
  </p>
</template>

<script>
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMMultiInputLine",
  path: "components.reg_mask.reg_mask_field.r_m_multi_input_line",
  mixins: [RegMaskFieldMixin],
};
</script>

<style scoped>

</style>

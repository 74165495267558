<template>
  <v-btn
    class="floating-back-button fade-in"
    color="primary"
    elevation="5"
    @click="$router.back()"
    dark
    fab
  >
    <v-icon>mdi-arrow-left</v-icon>
  </v-btn>
</template>

<script>

/**
  * The floating back button component is just a back button that is shown for all subpages. If it
  * is clicked, it simulates the browsers back functionality.
  */
export default {
  name: "FloatingBackButton",
  path: "components.ui_elements.floating_back_button",
};
</script>

<style scoped>
  .floating-back-button {
    bottom: 16px;
    right: -66px;
    position: fixed !important;
    z-index: 10;
  }
  .fade-in {
    animation: fadeIn ease 1.5s;
    -webkit-animation: fadeIn ease 1.5s;
    -moz-animation: fadeIn ease 1.5s;
    -o-animation: fadeIn ease 1.5s;

    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;

    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
  }
  @keyframes fadeIn {
    0% {right: -66px;}
    100% {right: 16px;}
  }

  @-moz-keyframes fadeIn {
    0% {right: -66px;}
    100% {right: 16px;}
  }

  @-webkit-keyframes fadeIn {
    0% {right: -66px;}
    100% {right: 16px;}
  }

  @-o-keyframes fadeIn {
    0% {right: -66px;}
    100% {right: 16px;}
  }
</style>

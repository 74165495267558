<template>
  <div>
    <page-title
      :title="$t('title', { name: event.name })"
    />
    <reg-mask
      :load-regmask-for="registration"
      :loading-progress.sync="loadingProgress"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import RegMask from "../../../components/RegMask/RegMask.vue";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";

export default {
  name: "EventRegistration",
  path: "pages.events.components.event_registration",
  components: {RegMask, PageTitle},
  mixins: [DetailPageMixin],
  data: () => ({
    event: { name: "" },
    registration: {},
    loadingProgress: 0,
  }),
  async created() {
    this.event = await this.fetchEvent();
    this.loadingProgress = 5;
    const client_id = this.event.ClientPlugin.client_id;
    const existing_registration = await this.fetchExistingRegistration(client_id);
    if (existing_registration) {
      this.registration = existing_registration;
    } else {
      this.registration = await this.createNewReference(client_id);
    }
    this.loadingProgress = 15;
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    async fetchEvent() {
      const {instance} = await this.$bom.event.instances.one
        .withId(this.id)
        .fetch();
      return instance;
    },
    async fetchExistingRegistration(client_id) {
      const { references } = await this.$bom.event.references.all
        .ofLoggedInUser()
        .onClient(client_id)
        .endingAfter("today")
        .take(1)
        .toInstance(this.id)
        .fetch();
      return references[0];
    },
    async createNewReference(client_id) {
      const { reference } = await this.$bom.event.references.create(this.id, this.user.id, { client_id });
      return reference;
    },
    onSaved() {
      new Promise((resolve) => setTimeout(resolve, 3000))
        .then(() => {
          this.$router.push({name: "RegisteredEvents"});
        });
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Anmeldung: {name}",
        success_message: "Anmeldung erfolgreich! Sie können ihre Anmeldung jederzeit unter 'Meine Events' bearbeiten.",
      },
      fr: {
        title: "Inscription: {name}",
        success_message: "Inscription réussie! Vous pouvez modifier votre inscription à tout moment dans la rubrique 'Mes événements'.",
      },
    },
  },
};
</script>

<style scoped>

</style>

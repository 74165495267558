import {MENTORING} from "../constants/MANDANTS.js";

export default {
  data: () => ({
    originalMandant: undefined,
  }),
  created() {
    this.originalMandant = this.$bom.client;
    this.$bom.client = MENTORING;
  },
  beforeDestroy() {
    this.$bom.client = this.originalMandant;
  },
};

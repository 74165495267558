<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <registered-event-section
    />

  </div>
</template>

<script>
import RegisteredEventSection from "./components/RegisteredEventSection.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "RegisteredEvents",
  path: "pages.events.registered_events",
  components: {
    RegisteredEventSection,
    PageTitle,
  },
  i18n: {
    messages: {
      de: {
        title: "Angemeldete Events",
      },
      fr: {
        title: "Événements enregistrés",
      },
    },
  },
};
</script>

<style scoped>

</style>

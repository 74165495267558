<template>
  <div>
    <page-subtitle
      :subtitle="$t('title')"
    />
    <visibility></visibility>
  </div>
</template>

<script>

import PageSubtitle from "../../../components/UI-Elements/PageSubtitle.vue";
import Visibility from "../../visibility/Visibility.vue";

export default {
  name: "VisibilitySection",
  path: "pages.settings.sections.visibility_section",
  components: {
    Visibility,
    PageSubtitle,
  },
  data() {
    return {
      password: null, // The educations of the logged-in user
    };
  },
  i18n: {
    messages: {
      de: {
        title: "Sichtbarkeit",
      },
      fr: {
        title: "Visibilité",
      },
    },
  },
};
</script>

<style scoped>

</style>

/**
 * The Detail-Page mixin is implemented by all pages that show details about a BomObject.
 * For example, the AddressDetails.vue Page shows details about an address.
 * Since these details pages are always characterized by an ID url parameter, this mixin
 * handles reading out the ID url parameter.
 */
export default {
  data() {
    return {
      deleting: false, // Describes whether the element is being deleted
      deleted: false, // Describes whether the element has been deleted
    };
  },
  computed: {
    /**
     * Reads out the items ID from the URL route parameters
     * @returns {*}
     */
    id() {
      return this.$route.params.id;
    },
  },
};

<template>
  <fragment>
    <text-input
      :box-probs="boxProbs"
      :label="$t('organisator')"
      field="ClientPlugin.client_name"
      md="6"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('address')"
      field="AddressRelationsPlugin.Ort[0].address.last_name"
      md="6"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('location')"
      field="AddressRelationsPlugin.Ort[0].address.city"
      md="6"
    />
    <html-input
      :box-probs="boxProbs"
      :label="$t('description')"
      field="description"
      md="12"
      sm="12"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../components/Edit/EditBoxChildren/TextInput.vue";
import DatePicker from "../../../components/Edit/EditBoxChildren/DatePicker.vue";
import HtmlInput from "../../../components/Edit/EditBoxChildren/HtmlInput.vue";

export default {
  name: "AcademiesDetailsSection",
  path: "pages.events.details.AcademiesDetails_section",
  mixins: [EditBoxChildMixin],
  components: {
    HtmlInput,
    TextInput,
  },
  computed: {
    registration_deadline() {
      if (this.boxProbs.editObject.IncidentPlugin.registration_end === null) {
        return "from";
      }
      return "registration_end";
    },
  },
  i18n: {
    messages: {
      de: {
        organisator: "Organisator",
        address: "Adresse",
        location: "Ort",
        date: "Datum",
        registration_deadline: "Anmeldeschluss",
        description: "Beschreibung",
      },
      fr: {
        organisator: "Organisateur",
        address: "Adresse",
        location: "Endroit",
        date: "Date",
        registration_deadline: "Date limite d'inscription",
        description: "La description",
      },
      it: {
        organisator: "Organizzatore",
        address: "Indirizzo",
        location: "Luogo",
        date: "Data",
        registration_deadline: "Termine d’iscrizione",
        description: "Descrizione",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-subtitle
      :subtitle="$t('title')"
    />
    <v-form
      ref="passwordForm"
      v-model="valid"
      lazy-validation>
      <v-row
        sm="6"
        cols="12"
      >
        <input-wrapper
          cols="12"
          sm="6"
        >
          <password-input
            :label="$t('old')"
            :rules="[rules.required]"
            v-model="old_password"
          />
        </input-wrapper>
      </v-row>
      <v-row>
        <input-wrapper
          cols="12"
          sm="6"
        >
          <password-input
            :label="$t('new1')"
            v-model="new_password"
            :rules="[rules.required, rules.min, rules.capital]"
            :hint="$t('restrictions')"
          />
        </input-wrapper>

        <v-col
          cols="12"
          sm="6"
        >
          <password-input
            :label="$t('new2')"
            v-model="new_password2"
            :rules="password_2_rules"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      color="primary"
      @click="changePassword"
      class="mb-2"
      :disabled="!valid"
    >
      {{ $t("save") }}
    </v-btn>
    <v-btn
      @click="rerendersPage"
      class="ml-2 mb-2"
    >
      {{ $t("cancel") }}
    </v-btn>
    <br>
    <error-alert v-model="has_error" :message="$t('save_error')"/>
    <error-alert v-model="incorrect_password" :message="$t('incorrect_password')"/>
    <success-alert v-model="has_saved" :message="$t('save_success')" :timeout="3000"/>
  </div>
</template>

<script>

import PageSubtitle from "../../../components/UI-Elements/PageSubtitle.vue";
import PasswordInput from "../../../components/UI-Elements/PasswordInput.vue";
import SuccessAlert from "../../../components/Alerts/SuccessAlert.vue";
import ErrorAlert from "../../../components/Alerts/ErrorAlert.vue";
import InputWrapper from "../../../components/Edit/EditBoxChildren/InputWrapper.vue";

/**
 * The Password Section allows you to change your current password to a new one. In order to do that
 * the user has to enter their current password and enter the new password twice. These new passwords
 * have to match and have to comply to certain criterion.
 *
 * The Password is changed using a BOM framework function that handles all the logic and keeps the data
 * private. For more information consult the BOM framework documentation.
 */
export default {
  name: "PasswordSection",
  path: "pages.settings.sections.password_section",
  components: {
    InputWrapper,
    PasswordInput,
    PageSubtitle,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      old_password: "",
      new_password: "",
      new_password2: "",
      valid: true, // checks whether all the password requirements are met (used to enable/disable the save button)
      saving: false, // Indicates that the password is currently being saved
      has_error: false, // Indicates that there was an error while saving the password. Controls an ErrorAlert component
      incorrect_password: false, // Indicates that there the entered password was incorrect. Controls an ErrorAlert component
      rules: { // these are all the rules the passwords have to adhere to
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 8 || this.$t("min_length"), // has to have a minimum length of 8 characters
        capital: (v) => v.toLowerCase() !== v || this.$t("capital_letter"), // needs at least one capital letter
      },
    };
  },
  methods: {
    changePassword() {
      this.saving = true;
      this.has_error = false;
      this.incorrect_password = false;
      this.$bom.changeUserPassword(this.old_password, this.new_password, this.new_password2)
        .then(() => {
          this.$router.replace({
            name: this.$route.name,
            query: { success: "true" },
          });
          this.rerendersPage();
        })
        .catch((err) => {
          if (err.code === 472) {
            this.incorrect_password = true; // catches the incorrect password error since that can only be verified by the framework
          } else {
            this.has_error = true;
          }
        })
        .finally(() => {
          this.saving = false;
        });
      // removes all alerts when search button is pressed
    },
    rerendersPage() {
      this.$eventBus.$emit("rerender"); // reloads the page without remounting it
    },
    validate() {
      this.$refs.passwordForm.validate(); // checks if the password inputs are valid so the button can be enabled/disabled
    },
  },
  computed: {
    /* Function for the dynamic rules of the second password. If the passwords don't match and the first password is adjusted
    * so that they match again, the 'do not match' warning is removed from the second password input.
     */
    password_2_rules() {
      const rules = [];
      if (this.new_password !== this.new_password2) {
        const rule = (v) => (!!v && v) === this.new_password || this.$t("not_a_match");
        rules.push(rule);
      }
      return rules;
    },
    has_saved: {
      get() { return this.$route.query.success === "true"; },
      set(value) { this.$router.replace({name: this.$route.name, query: { success: `${value}` }}); },
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Passwort ändern",
        old: "Aktuelles Passwort",
        restrictions: "Mindestens 8 Zeichen, davon mindestens ein Grossbuchstabe und ein Sonderzeichen",
        new1: "Neues Passwort",
        new2: "Neues Passwort bestätigen",
        save: "Speichern",
        cancel: "Abbrechen",
        save_error: "Das Passwort konnte nicht geändert werden. Überprüfen Sie bitte die Eingabefelder oder versuchen Sie es später erneut.",
        save_success: "Das Passwort wurde erfolgreich gespeichert.",
        not_a_match: "Die eingegebenen Passwörter stimmen nicht überein.",
        incorrect_password: "Das aktuelle Passwort ist nicht korrekt.",
        required: "Erforderlich",
        min_length: "Mind. 8 Zeichen.",
        capital_letter: "Mind. ein Grossbuchstabe",
      },
      fr: {
        title: "Changer le mot de passe",
        old: "Mot de passe instantané",
        restrictions: "Au min 8 charactères, dont au moins une majuscule et un caractère spécial",
        new1: "Nouveau mot de passe",
        new2: "Confirmer le nouveau mot de passe",
        save: "Sauver",
        cancel: "Avorter",
        save_error: "Le mot de passe n'a pas pu être changé. Veuillez vérifier les champs de saisie ou réessayer plus tard.",
        save_success: "Le mot de passe a été enregistrées avec succès.",
        not_a_match: "Les mots de passe ne correspondent pas.",
        incorrect_password: "Le mot de passe que vous avez entré est incorrect.",
        required: "Exigé",
        min_length: "Au moins 8 caractères.",
        capital_letter: "Au moins une lettre majuscule.",
      },
    },
  },
};
</script>

<style scoped>

</style>

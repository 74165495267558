<template>
  <image-card
    :title="mentoring.name"
    :image="image"
    :action="routeToMentoring"
    :flat="flat"
  >
    <template slot="topright" v-if="pending">
      <v-icon class="text--primary">
        mdi-timer-sand
      </v-icon>
    </template>
    <template slot="default">
      <p>
        <strong>{{ $t('timerange') }}:</strong> {{ mentoring | timerange }}
      </p>
    </template>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-private.png";
import ProfileImageService from "../../services/ProfileImageService.js";

export default {
  name: "MenteeMentoringCard",
  path: "components.cards.mentee_mentoring_card",
  components: { ImageCard },
  props: {
    mentoring: { type: Object, required: true },
    pending: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
  },
  data: () => ({
    image: default_image,
  }),
  created() {
    ProfileImageService.fetchProfileImage(this.mentoring)
      .then((file) => {
        this.image = file || default_image;
      });
  },
  methods: {
    routeToMentoring() {
      this.$router.push({
        name: "menteeMentoringDetails",
        params: {
          id: this.mentoring.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        timerange: "Zeitraum",
      },
      fr: {
        timerange: "Période",
      },
    },
  },
};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./ClientSwitch.vue?vue&type=template&id=36609d76&scoped=true&"
import script from "./ClientSwitch.vue?vue&type=script&lang=js&"
export * from "./ClientSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36609d76",
  null
  
)

export default component.exports
/* eslint-disable no-shadow */
/**
 * The global store module handles some user interface states.
 */

const state = () => ({
  blockingLoading: false, // If this variable is set to true, the whole application will be blocked and a loading indicator is displayed
  message: "", // Message that is displayed when blockingLoading is set to true
});

const getters = {
  /**
   * Returns the application is currently in a blocking loading state. This is usually only the case
   * if the user switched its client or if he changed his password.
   * @param state
   * @returns {boolean}
   */
  getBlockingLoading(state) {
    return state.blockingLoading;
  },
  /**
   * Returns message that shall be displayed during blocked loading
   * @param state
   * @returns {string}
   */
  getMessage(state) {
    return state.message;
  },
};

const mutations = {
  /**
   * Starts a blocked loading
   * @param state
   * @private
   */
  _startBlockingLoading(state) {
    state.blockingLoading = true;
  },
  /**
   * Sets the blocked loading message
   * @param state
   * @param message
   * @private
   */
  _setMessage(state, message) {
    state.message = message;
  },
  /**
   * Stops the blocked loading
   * @param state
   * @private
   */
  _stopBlockingLoading(state) {
    state.blockingLoading = false;
  },
};

const actions = {
  /**
   * Method that starts a blocked loading process with a status message
   * @param commit
   * @param {string} message
   */
  loadAndBlock({commit}, message) {
    commit("_setMessage", message);
    commit("_startBlockingLoading");
  },
  /**
   * Method that stops a blocked loading process and resets the status message
   * @param commit
   */
  unblock({commit}) {
    commit("_setMessage", "");
    commit("_stopBlockingLoading");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <image-uploader
    :box-probs="boxProbs"
    :label="$t('profile_image')"
    :filename="$t('profile_image')"
    legacy-marker="profilbild"
  />
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import ImageUploader from "../../../../components/Edit/EditBoxChildren/ImageUploader.vue";

export default {
  name: "MentorImageUploadSection",
  path: "pages.mentoring.mentor_details.sections.mentor_image_upload_section",
  components: {ImageUploader},
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        profile_image: "Profilbild",
      },
      fr: {
        profile_image: "Photo de profil",
      },
    },
  },
};
</script>

<style scoped>

</style>

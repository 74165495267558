/**
 * The alert mixin is used by the different Alert-Components.
 */
export default {
  props: {
    // Controls whether the alert box is shown or hidden
    value: { type: Boolean, required: true },
    // The message that is shown to the user
    message: { type: String, required: true },
    // Automatically hide alert X miliseconds after showing
    timeout: { type: Number, required: false, default: 0 },
  },
  watch: {
    value: {
      handler(changed_to_visible) {
        if (changed_to_visible && this.timeout) {
          setTimeout(() => {
            this.$emit("input", false);
          }, this.timeout);
        }
      },
      immediate: true,
    },
  },
  // watcher for timeout & value
};

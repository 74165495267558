<template>
  <v-select
    return-object
    v-model="selected"
    :items="items"
    :label="group.title"
    item-text="name"
    :hint="group.required ? $t('required') : ''"
    :loading="loading"
    @change="onDropdownSelect"
    :error-messages="errors"
  />
</template>

<script>
import { required } from "vuelidate/lib/validators/index.js";
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMDropdown",
  path: "components.reg_mask.reg_mask_field.r_m_dropdown",
  mixins: [RegMaskFieldMixin],
  data: () => ({
    items: [],
    selected: undefined,
    loading: false,
  }),
  computed: {
    errors() {
      const errors = [];
      const field = this.$v.selected;
      if (field.$invalid) {
        if (!field.required) {
          errors.push(this.$t("required"));
        }
      }
      return errors;
    },
  },
  methods: {
    syncShadowState() {
      this.loading = false;
      this.items = this.group.options;
      this.selected = this.items.find((option) => option.selected);
    },
    onDropdownSelect() {
      this.loading = true;
      this.selected.select()
        .then(() => {
          this.syncShadowState();
        });
    },
  },
  validations() {
    return {
      selected: {
        ...(this.group.required && { required }),
      },
    };
  },
  i18n: {
    messages: {
      de: {
        required: "Es muss eine Option ausgewählt werden",
      },
      fr: {
        required: "Une option doit être sélectionnée",
      },
    },
  },
};
</script>

<style scoped>

</style>

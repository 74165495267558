import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0e3c5c",
        secondary: "#09263b",
        accent: "#82B1FF",
        error: "#bd1b1b",
        info: "#2196F3",
        success: "#1b8921",
        warning: "#FFC107",
      },
    },
  },
});

import InputMixin from "./InputMixin.js";

/**
 * The text input field mixin is used for simple textInputFields that save their input value into
 * the respective field in the editObject. Therefore, it inherits form InputMixin.
 * The TextInputMixin offers a set of validation rules that determine whether the user input
 * is valid.
 *
 * Mixins: InputMixin
 */
export default {
  mixin_path: "text_input_mixin",
  mixins: [InputMixin],
  props: {
    // User input is mandatory (e.g. can't be left empty)
    mandatory: { type: Boolean, default: false },
    // User input must be a valid e-mail address
    email: { type: Boolean, default: false },
    // User input must be valid phone number
    phone: { type: Boolean, default: false },
    // User input must be a website
    website: { type: Boolean, default: false },
    // User input must be a number
    numeric: { type: Boolean, default: false },
    // User input must be at most X characters
    maxLength: { type: Number, default: -1 },
  },
  computed: {
    /**
     * Shows a counter if the users input starts to approach the maximal valid length
     * @returns {any}
     */
    show_counter() {
      const edit_mode = this.boxProbs.editmode;
      const maxLength_set = this.maxLength > -1;
      const few_left = this.maxLength - this.value.length < 5;
      return edit_mode && maxLength_set && few_left ? this.maxLength : undefined;
    },
    /**
     * Returns a set of rules that shall be used to validate the inputs
     * @returns {function[]}
     */
    rules() {
      if (!this.boxProbs.editmode) { return []; }
      const rules = [];
      if (this.mandatory) { rules.push((v) => !!v || this.$t("text_input_mixin.mandatory")); }
      if (this.maxLength > -1) { rules.push((v) => v.length <= this.maxLength || this.$t("text_input_mixin.maxLength", {n: this.maxLength})); }
      if (this.email) { rules.push((v) => !v || this.$bom.validation_regex.mail_regex.test(v) || this.$t("text_input_mixin.email")); }
      if (this.phone) { rules.push((v) => !v || this.$bom.validation_regex.phone_number.test(v) || this.$t("text_input_mixin.phone")); }
      if (this.website) { rules.push((v) => !v || this.$bom.validation_regex.website_regex.test(v) || this.$t("text_input_mixin.website")); }
      if (this.numeric) { rules.push((v) => !isNaN(v) || this.$t("numeric")); }
      return rules;
    },
    isClickableLink() {
      return !!(!this.boxProbs.editmode && this.website && this.value);
    },
  },
  methods: {
    openLink() {
      if (this.isClickableLink) {
        if (!(this.value.startsWith("http://") || this.value.startsWith("https://"))) {
          window.open(`https://${this.value}`);
        } else {
          window.open(this.value);
        }
      }
    },
  },
};

<template>
  <fragment>
    <user-address-selector
      :box-probs="boxProbs"
      :label="$t('correspondance')"
      relation-name="Korrespondenz Adresse"
      :relation-number="ADDRESS_RELATION_TYPE_KORRESPONDENZ_ADDRESSE"
      :selectable-type-numbers="[ADDRESS_RELATION_TYPE_GESCHAEFTSADRESSE]"
      :include-base-address="true"
    />
    <user-address-selector
      :box-probs="boxProbs"
      :label="$t('newsletter')"
      relation-name="Korrespondenz Newsletter"
      :relation-number="ADDRESS_RELATION_TYPE_KORRESPONDENZ_NEWSLETTER"
      :selectable-type-numbers="[ADDRESS_RELATION_TYPE_GESCHAEFTSADRESSE]"
      :include-base-address="true"
      show-address="summary_only_mail"
    />
    <user-address-selector
      :box-probs="boxProbs"
      :label="$t('bill')"
      relation-name="Rechnungsadresse"
      :relation-number="ADDRESS_RELATION_TYPE_RECHNUNGSADRESSE"
      :selectable-type-numbers="[ADDRESS_RELATION_TYPE_GESCHAEFTSADRESSE]"
      :include-base-address="true"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import UserAddressSelector from "../../../components/Edit/EditBoxChildren/UserAddressSelector.vue";
import MapAddressRelationTypesMixin from "../../../mixins/ConstMappers/MapAddressRelationTypesMixin.js";

export default {
  name: "CorrespondanceSection",
  path: "pages.communication.sections.correspondance_section",
  components: {UserAddressSelector},
  mixins: [EditBoxChildMixin, MapAddressRelationTypesMixin],
  i18n: {
    messages: {
      de: {
        correspondance: "Korrespondenz",
        newsletter: "Newsletter",
        bill: "Rechnung",
      },
      fr: {
        correspondance: "Correspondance",
        newsletter: "Newsletter",
        bill: "Facture",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <input-wrapper v-show="show">
    <v-autocomplete
      v-model="value"
      :items="items"
      :item-text="display"
      item-value="id"
      :label="label"
      auto-select-first
      :readonly="!boxProbs.editmode"
      :loading="loading || items.length === 0"
      :disabled="boxProbs.editmode && readonly"
      placeholder=" "
    />
  </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";
import AddressSelectorMixin from "../../../mixins/AddressSelectorMixin.js";

/**
 * The userAddressSelector lets the user select one of his own addresses that are of type "selectableTypeNumbers"
 * and saves the selected address as a new address relation of the given type.
 */
export default {
  name: "UserAddressSelector",
  path: "components.edit.edit_box_children.user_address_selector",
  components: {InputWrapper},
  mixins: [InputMixin, AddressSelectorMixin],
  props: {
    // Controls whether the user can also select his base address
    includeBaseAddress: { type: Boolean, default: false },
  },
  methods: {
    /**
     * When the editObject is updated, find all addresses that can be selected, e.g. addresses
     * that appear in address-relations that are of a "selectableTypeNumbers"
     */
    editObjectUpdate() {
      if (!this.boxProbs.editObject) { return; }
      this.initial_address = this.address;
      this.initial_address_relation = this.address_relation;
      this.items = this.boxProbs.editObject.AddressRelationsPlugin.all
        .filter((adr_rel) => this.selectableTypeNumbers.includes(adr_rel.type_number))
        .map((adr_rel) => adr_rel.address);
      if (this.includeBaseAddress) {
        this.items.push(this.boxProbs.editObject);
      }
    },
  },
};
</script>

<style scoped>

</style>

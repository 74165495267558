<template>
  <image-card
    :title="mentor | name"
    :image="image"
    :action="routeToMentor"
  >
    <template slot="topright">
      <v-icon
        :color="isAvailable ? 'success' : 'error'"
      >
        {{ isAvailable ? 'mdi-account-check' : 'mdi-account-cancel' }}
      </v-icon>
    </template>
    <template slot="default">
      <p v-if="!isAvailable" class="text--primary">
        {{$t("booked_till")}}: <strong>{{ availableAfter | date_format }}</strong>
      </p>
      <ul>
        <li
          v-for="subject in subjects"
          :key="subject.id"
          class="px-0 mb-0"
        >
          {{ subject.name }}
        </li>
      </ul>
    </template>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import MentorMixin from "../../mixins/MentorMixin.js";

export default {
  name: "MentorCard",
  path: "components.cards.mentor_card",
  components: {ImageCard},
  mixins: [MentorMixin],
  props: {
    mentor: { type: Object, required: true },
  },
  created() {
    this.loadImage();
  },
  methods: {
    routeToMentor() {
      this.$router.push({
        name: "mentorDetails",
        params: {
          id: this.mentor.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        booked_till: "Ausgebucht bis",
        unknown: "Unbekannt",
      },
      fr: {
        booked_till: "Réservé jusqu'à",
        unknown: "Inconnu",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <image-card
      :title="education | timeperiod"
      :image="image"
      :action="routeToEducation"
    >
      <p>
        {{education | tagcat("Ausbildungs-Stufe")}} {{education | tagcat("Fachrichtung")}}
      </p>
      <p>
        {{education | adrrelcat("Ort", "last_name")}}
      </p>
      <p>
        {{education | adrrelcat("Ort", "city")}}
      </p>
    </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-ausbildung.png";

/**
 * The education card summarizes an education of a user. On the left, only a default image is shown,
 * while on the right, some information about the education is displayed. Clicking the card routes
 * to the Education.vue page.
 */
export default {
  name: "EducationCard",
  path: "components.cards.education_card",
  components: {ImageCard},
  data() {
    return {
      // Holds the image that is shown on the left of the card
      image: default_image,
    };
  },
  props: {
    // The education that shall be displayed here. This is a BomReference
    education: { type: Object, required: true },
  },
  methods: {
    /**
     * Routes to the Education.vue page with the ID of the education as a parameter.
     */
    routeToEducation() {
      this.$router.push({
        name: "education-readonly",
        params: {
          id: this.education.id,
        },
      });
    },
  },
};
</script>

<style scoped>
  p {
    margin-bottom: 4px !important;
  }
</style>

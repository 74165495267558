<template>
  <fragment>
    <text-input
      :box-probs="boxProbs"
      :label="$t('mentoring_wished_start')"
      field="begin_pos.description"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('mentoring_wished_end')"
      field="end_pos.description"
    />
    <html-input
      :box-probs="boxProbs"
      :label="$t('message_to_mentor')"
      field="message_pos.description"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";
import HtmlInput from "../../../../components/Edit/EditBoxChildren/HtmlInput.vue";

export default {
  name: "MentoringDetailsSection",
  path: "pages.mentoring.mentoring_details.sections.mentoring_details_section",
  components: {
    HtmlInput,
    TextInput,
  },
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        profile_image: "Profilbild",
        mentoring_wished_start: "Gewünschter Start des Mentoring",
        mentoring_wished_end: "Gewünschtes Ende des Mentoring",
        message_to_mentor: "Nachricht an Mentor/-in",
      },
      fr: {
        profile_image: "Photo de profil",
        mentoring_wished_start: "Début souhaité du mentorat",
        mentoring_wished_end: "Fin souhaitée du mentorat",
        message_to_mentor: "Message au mentor",
      },
    },
  },
};
</script>

<style scoped>

</style>

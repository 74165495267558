import { render, staticRenderFns } from "./MenteeMentoringCard.vue?vue&type=template&id=d25f9cea&scoped=true&"
import script from "./MenteeMentoringCard.vue?vue&type=script&lang=js&"
export * from "./MenteeMentoringCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d25f9cea",
  null
  
)

export default component.exports
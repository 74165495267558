<template>
  <div>
    <!-- @switch: event with name "switch" is executed in the child (eg @enter @change @input etc) and execute fetchEvents -->
    <all-clients-switch
      v-model="all_clients"
      @switch="fetchEvents"
    />
    <card-group
      :loading="loading_events"
      :loading-message="$t('loading_message')"
      :empty="registered_events.length === 0"
      :empty-message="$t('empty_message')"
    >
      <event-card
        v-for="event in registered_events"
        :key="event.id"
        :event="event"
      />
    </card-group>

  </div>
</template>

<script>
import AllClientsSwitch from "../../../components/SearchInputs/AllClientsSwitch.vue";
import CardGroup from "../../../components/Cards/CardGroup.vue";
import EventCard from "../../../components/Cards/EventCard.vue";

/**
 * This is the registered cards component, that loads all the events that the logged in user is registered to,
 * per default on all clients. It can be implemented with or without the client switch.
 */
export default {
  name: "RegisteredEventSection",
  path: "pages.events.components.registered_event_section",
  components: {
    AllClientsSwitch,
    EventCard,
    CardGroup,
  },
  data() {
    return {
      registered_events: [],
      loading_events: false,
      user_id: "getUser",
      user_registered_events: [],
    };
  },
  computed: {
    all_clients: {
      get() {
        return this.$route.query.show_all === "true";
      },
      set(choice) {
        if (choice) {
          this.$router.replace({query: { ...this.$route.query, show_all: "true"}});
        } else {
          this.$router.replace({query: { ...this.$route.query, show_all: "false"}});
        }
      },
    },
  },
  methods: {
    fetchUsersRegistrations() {
      return this.$bom.event.references.all
        .onAllClients()
        .ofLoggedInUser() // Event registrations of logged in user only
        .startingBefore(new Date())
        .endingAfter(new Date())
        .instanceStartingAfter("today") // Event must start in the future
        .including("InstancePlugin") // Include the event intance in the response
        .fetch()
        .then(({references}) => {
          // Store the IDs of all events to which the user has a registration
          this.user_registered_events = references.map((reference) => reference.InstancePlugin.instance.id);
        });
    },
    fetchEvents() {
      this.loading_events = true;
      let bom_query = this.$bom.event.instances.all
        .startingAfter(new Date())
        .including("AddressRelationsPlugin", "ReferencesPlugin");

      if (this.all_clients) { // when the user change the "switcher" to "zu allen Events schweizweit"
        bom_query = bom_query.onAllClients();
      }

      return bom_query.fetch()
        .then(({instances}) => {
          this.registered_events = instances.filter((instance) => this.user_registered_events.includes(instance.id));
        })
        .finally(() => {
          this.loading_events = false;
        });
    },
  },
  async created() {
    await this.fetchUsersRegistrations();
    await this.fetchEvents();
  },
  i18n: {
    messages: {
      de: {
        title: "Angemeldete Events",
        loading_message: "Events werden geladen",
        empty_message: "Keine Events gefunden",
      },
      fr: {
        title: "Événements enregistrés",
        loading_message: "Les événements sont en cours de chargement",
        empty_message: "Aucun événement suivant trouvé",
      },
    },
  },
};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./RegisteredAcademiesSection.vue?vue&type=template&id=a84b4d56&scoped=true&"
import script from "./RegisteredAcademiesSection.vue?vue&type=script&lang=js&"
export * from "./RegisteredAcademiesSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a84b4d56",
  null
  
)

export default component.exports
<template>
    <div v-if="blocking">
      <v-overlay
        :value="true"
        opacity="0.85"
        color="white"
        z-index="7"
      />
      <div class="overlay-box">
        <h1 class="overlay-title">{{message}}</h1>
        <loading-circle
          style="z-index: 6; margin-top: 50px;"
          color="primary"
        />
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingCircle from "./LoadingCircle.vue";

/**
 * The bloacking loading component overlays the whole screen and shows a loading circle and a loading
 * message that it reads from the store.
 */
export default {
  name: "BlockingLoading",
  path: "components.ui_elements.blocking_loading",
  components: {LoadingCircle},
  computed: {
    ...mapGetters("global", {
      message: "getMessage",
      blocking: "getBlockingLoading",
    }),
  },
};
</script>

<style scoped>
  div.overlay-box {
    z-index: 10;
    position: fixed;
    top: 200px;
    left: 0;
    width: 100%;
  }
  h1.overlay-title {
    color: var(--primary);
    text-align: center;
  }
</style>

<template>
  <fragment>
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.street')"
      :maxlength="30"
      field="address.street"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.street_addition')"
      :maxlength="30"
      field="address.street_addition"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.mailbox')"
      :maxlength="30"
      field="address.mailbox"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.postcode')"
      :maxlength="4"
      field="address.postcode"
      numeric
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.city')"
      :maxlength="30"
      field="address.city"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.phone')"
      phone
      field="address.phone_number"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.mobile')"
      phone
      field="address.mobile_number"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.mail')"
      email
      field="address.mail"
    />
    <text-input
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.website')"
      website
      field="address.website"
    />
    <date-picker
      v-if="!reduced"
      :box-probs="boxProbs"
      :label="$t('address.birthday')"
      field="address.from"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";
import DatePicker from "../../../../components/Edit/EditBoxChildren/DatePicker.vue";

export default {
  name: "SharedProfileSection",
  path: "pages.mentoring.mentoring_details.sections.shared_profile_section",
  components: {
    DatePicker,
    TextInput,
  },
  mixins: [EditBoxChildMixin],
  props: {
    reduced: { type: Boolean, default: false },
  },
  i18n: {
    messages: {
      de: {
        salutation: "Anrede",
        firstname: "Vorname",
        lastname: "Name",
      },
      fr: {
        salutation: "Salutation",
        firstname: "Prénom",
        lastname: "Nom",
      },
    },
  },
};
</script>

<style scoped>

</style>

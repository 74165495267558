<template>
  <div>
    <page-title
      title="RegMask test"
    />
    <reg-mask
      :load-regmask-for="registration"
      :loading-progress.sync="loadingProgress"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "../components/UI-Elements/PageTitle.vue";
import RegMask from "../components/RegMask/RegMask.vue";

export default {
  name: "RegMaskTest",
  path: "pages.reg_mask_test",
  components: {RegMask, PageTitle},
  data: () => ({
    instance: { name: "" },
    registration: {},
    loadingProgress: 0,
    id: "6586318#a8yEJuqxJFxjAzSpv0CzjoOvoRzIWxSFXV5ZLox40=",
  }),
  async created() {
    this.instance = await this.fetchInstance();
    this.loadingProgress = 5;
    this.registration = await this.createNewReference("27");
    this.loadingProgress = 15;
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    async fetchInstance() {
      const {instance} = await this.$bom.mentoring.instances.one
        .withId(this.id)
        .fetch();
      return instance;
    },
    async createNewReference(client_id) {
      const { reference } = await this.$bom.mentoring.references.create(this.id, this.user.id, { client_id });
      return reference;
    },
  },
};
</script>

<style scoped>

</style>

import JointEditObject from "../services/JointEditObject.js";

export default {
  computed: {
    is_pending() {
      return !!this.mentoring?.TagsPlugin.find({number: "4280"});
    },
    positions() {
      return this.mentoring?.PositionsPlugin?.all || [];
    },
    mentoring_positions() {
      return new JointEditObject({
        mentoring: this.mentoring,
        begin_pos: this.posWithName("Gewünschter Beginn des Mentorings"),
        end_pos: this.posWithName("Gewünschtes Ende des Mentorings"),
        message_pos: this.posWithName("Nachricht an Mentor"),
        facebook: this.posWithName("Facebook Profil-Link"),
        linkedin: this.posWithName("Linkedin Profil-Link"),
        xing: this.posWithName("Xing Profil-Link"),
        studies: this.posWithName("Studiengang"),
        highschool: this.posWithName("Hochschule"),
        graduation_year: this.posWithName("(erwartetes) Abschlussjahr"),
        shared_note: this.posWithName("Geteilte Notiz"),
      });
    },
  },
  methods: {
    posWithName(name) {
      return this.positions.find((pos) => pos.name.toLowerCase() === name.toLowerCase());
    },
    fetchMentoring() {
      return this.$bom.mentoring.references.one
        .withId(this.id)
        .onClient("26")
        .including("TagsPlugin", "InstancePlugin", "AddressPlugin", "ArchiveRelationsPlugin", "PositionsPlugin")
        .fetch()
        .then(({reference}) => {
          this.mentoring = reference;
        });
    },
    fetchMentor() {
      return this.$bom.mentoring.instances.one
        .onClient("26")
        .withId(this.mentoring.InstancePlugin.instance.id)
        .including("ArticlesPlugin", "AddressRelationsPlugin", "ArchiveRelationsPlugin", "TagsPlugin")
        .fetch()
        .then(({instance}) => {
          this.mentor = instance;
          return this.mentor.TagsPlugin.getAllPossibleTags();
        })
        .then((tags) => {
          this.all_possible_tags = tags;
        });
    },
  },
};

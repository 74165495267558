<template>
  <fragment>
    <v-col cols="12" v-for="tag in visibilityTag" :key="tag.tag_number">
      <v-row class="pl-2">
        <h3 class="primary--text">{{ display(tag.tag_number, tag.tag_filter) }}</h3>
        <info-icon v-if="tag.tag_filter === 'fullname'"/>
      </v-row>
      <tag-on-off-switch
        :box-probs="boxProbs"
        :tag-category="tag.tag_category"
        :tag-name="tag.tag_name"
        :tag-number="tag.tag_number"
        :label-false="$t('hidden')"
        :label-true="$t('visible')"
      />
    </v-col>
  </fragment>
</template>

<script>
import InfoIcon from "../../UI-Elements/InfoIcon.vue";
import TagOnOffSwitch from "./TagOnOffSwitch.vue";
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";

export default {
  name: "VisibilitySwitches",
  path: "components.edit.edit_box_children.visibility_switches",
  components: {
    InfoIcon,
    TagOnOffSwitch,
  },
  props: {
    visibilityTag: { // tag to be added or removed to the bomObject depending on the state
      type: Array, // Array of tag category, name, number and it's filter to get the name
      required: true,
    },
  },
  mixins: [EditBoxChildMixin],
  methods: {
    /**
     * Display  makes sure  to display the tag name correctly. If the tag name is empty, it
     * replaces it with the correct placeholder.
     * @param tag_number
     * @param filter_name
     * @returns {String} either the tag name or its placeholder
     */
    display(tag_number, filter_name) {
      const address = this.boxProbs.editObject;
      if ((tag_number === "21586") && (this.$options.filters[filter_name](address) !== "")) {
        let birthday = address.from;
        if (birthday.getHours() === 23) {
          birthday.setHours(birthday.getHours() + 1);
        }
      }
      const name = this.$options.filters[filter_name](address) !== "" ? this.$options.filters[filter_name](address) : "-";
      return `${this.$t(filter_name)}: ${name}`;
    },
  },

};
</script>

<style scoped>

</style>

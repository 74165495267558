<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <collapsible-group>
      <collapsible :title="$t('personalities')">
        <edit-box
          :editable="can_edit"
          :edit-object="address"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <personalities-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <fragment v-if="!can_edit">
        <collapsible
          :title="company.company_name || $t('company_name')"
          v-for="company in company_addresses" :key="company.id"
        >
          <edit-box
            :editable="can_edit"
            :edit-object="company"
            @saved="fetchUser"
          >
            <template v-slot:default="boxProbs">
              <company-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>
      </fragment>
      <collapsible :title="$t('socialmedia')">
        <edit-box
          :editable="can_edit"
          :edit-object="address"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <socialmedia-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible :title="$t('profileimage')">
        <edit-box
          :editable="sameID"
          :edit-object="address"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <profile-image-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible
        v-for="(education, index) in educations" :key="`education-${index}`"
        :title="`${$t('education')} - ${education.TagsPlugin.Fachrichtung[0].name}`">
        <edit-box
          :editable="can_edit"
          :edit-object="education"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <education-person-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible
        v-for="(further_education, index) in further_educations" :key="`further_educations-${index}`"
        :title="`${$t('further_education')} - ${further_education.info2}`">
        <edit-box
          :editable="can_edit"
          :edit-object="further_education"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <further-education-person-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible v-if="!sameID" :title="$t('contact_note')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <div class="p-d-flex p-flex-column ml-3">
              <div>
                <address-relation-on-off-switch
                  :box-probs="boxProbs"
                  :address="address"
                  address-relation-type="Kontakt"
                  :label-false="$t('no_contact')"
                  :label-true="$t('contact')"
                />
              </div>
              <div>
                <address-relation-on-off-switch
                  :box-probs="boxProbs"
                  :address="address"
                  address-relation-type="Gemerkt"
                  :label-false="$t('not_noticed')"
                  :label-true="$t('noticed')"
                />
              </div>
            </div>
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddressRelationOnOffSwitch
  from "../../../components/Edit/EditBoxChildren/AddressRelationOnOffSwitch.vue";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import collapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import PersonalitiesSection from "./sections/PersonalitiesSection.vue";
import SocialmediaSection from "./sections/SocialmediaSection.vue";
import ProfileImageSection from "./sections/ProfileImageSection.vue";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import CompanySection from "./sections/CompanySection.vue";
import MapUserMixin from "../../../mixins/ConstMappers/MapUserMixin.js";
import Education from "../../education/Education.vue";
import EducationPersonSection from "./sections/EducationSection.vue";
import FurtherEducationPersonSection from "./sections/FurtherEducationSection.vue";

const now = new Date();
/**
 * The PersonalAddress page shows data from a regular BomPersonAddress.
 */
export default {
  name: "PersonalAddress",
  path: "pages.address.person.personal_address",
  mixins: [DetailPageMixin, MapUserMixin],
  components: {
    FurtherEducationPersonSection,
    EducationPersonSection,
    CompanySection,
    AddressRelationOnOffSwitch,
    ProfileImageSection,
    SocialmediaSection,
    PersonalitiesSection,
    EditBox,
    collapsible,
    collapsibleGroup,
    PageTitle,
  },
  data() {
    return {
      address: undefined, // Fetched BomPersonAddress
      company_addresses: [],
      educations: [],
      further_educations: [],
    };
  },
  computed: {
    /**
     * We map the getter "getUserId" form the user stoer module to a local computed property
     */
    ...mapGetters("user", {
      user_id: "getUserId",
      user: "getUser",
    }),
    /**
     * This address is only editable if the meta parameter "readOnly" is not true and it is the users own address
     * @returns {boolean}
     */
    can_edit() {
      if (this.$route.meta.readOnly) {
        return false;
      } if (this.id === this.user_id) {
        return true;
      }
      return false;
    },
    sameID() {
      return this.id === this.user_id;
    },
    readOnly() {
      return this.$route.meta.readOnly;
    },
  },
  /**
   * On created, we fetch the address with the ID from the URL. The variable "this.id" is imported
   * within the mixin and reads out the "id" path parameter from the URL.
   */
  created() {
    const bom_query = this.$bom.addresses.one
      .withId(this.id)
      .including("ForeignKeysPlugin", "ArchiveRelationsPlugin", "AddressRelationsPlugin");
    if (this.can_edit) {
      bom_query.ofLoggedInUser();
    }
    bom_query.fetch()
      .then(({address}) => {
        this.address = address;
        this.company_addresses = address.AddressRelationsPlugin.all
          .filter((address_relation) => {
            const already_started = !address_relation.from || address_relation.from < now;
            const not_yet_ended = !address_relation.to || address_relation.to > now;
            const is_business_address = this.isContactAddress(address_relation);
            return already_started && not_yet_ended && is_business_address;
          })
          .map((address_relation) => address_relation.address);
        this.$bom.education.references.all
          .queryFor({address_id: this.address.id})
          .including("TagsPlugin", "AddressRelationsPlugin")
          .onAllClients()
          .fetch()
          .then(({references}) => {
            this.educations = references;
          });
        this.$bom.qualification.references.all
          .queryFor({address_id: this.address.id})
          .including("TagsPlugin", "AddressRelationsPlugin")
          .onAllClients()
          .fetch()
          .then(({references}) => {
            this.further_educations = references;
          });
      });
  },
  methods: {
    /**
     * Little helper function that checks if a given address relation is of type "Geschäftsadresse"
     * @param {AddressRelation} address_relation
     * @returns {boolean}
     */
    isContactAddress(address_relation) {
      return this.USER_OWN_COMPANY_ADDRESS_TYPES.includes(address_relation.type_number);
    },
    /**
     * We map the method "fetchUser" from the user store module to a local method
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        title: "Personenadresse",
        personalities: "Personalien",
        company_address: "Geschäftsadresse",
        socialmedia: "Social Media",
        profileimage: "Profilbild",
        contact_note: "Kontakt und Gemerkt",
        no_contact: "Kein Kontakt",
        contact: "Mein Kontakt",
        not_noticed: "Nicht gemerkt",
        noticed: "Gemerkter Kontakt",
      },
      fr: {
        title: "Adresse des personnes",
        personalities: "Données personnelles",
        company_address: "Adresses professionelles",
        socialmedia: "Réseaux sociaux",
        profileimage: "Photo de profil",
        contact_note: "Contact et Mémorisés",
        no_contact: "Pas de contact",
        contact: "Mon contact",
        not_noticed: "Pas mémorisé",
        noticed: "Contact mémorisé",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-menu
    bottom
    left
    :offset-y="true"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group
        :value="selected_index"
        color="primary"
      >
        <v-list-item
          v-for="language in available_languages"
          :key="language.code"
          @click="setLanguage(language)"
        >
          <v-list-item-title
            class="clickable-list-item"
            @click="setLanguage(language)"
          >
            {{ language.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

/**
 * This component handles switching the language of the whole application. It shows a dropdown
 * of the available languages and sets a new language in the language store module if the user
 * selects a new one
 */
export default {
  name: "LanguageSwitch",
  path: "components.navigation.topbar_components.language_switch",
  data() {
    return {
      selected_index: undefined, // Selected index from the dropdown component
    };
  },
  watch: {
    /**
     * Watch the current language from the language store module. If the langauge changes, calculate
     * the new selected index for the dropdown.
     */
    language: {
      handler() {
        this.selected_index = this.available_languages || []
          .map(((language) => language.code))
          .indexOf(this.active_language);
      },
      immediate: true,
    },
  },
  computed: {
    /**
     * Map the current language and the available languages from the language store module to local
     * computed properties
     */
    ...mapGetters("language", {
      active_language: "getActiveLanguage",
      available_languages: "getAvailableLanguages",
    }),
  },
  methods: {
    /**
     * Map the langauge setter from the language store module to a local method
     */
    ...mapMutations("language", {
      setLanguage: "setLanguage",
    }),
  },
};
</script>

<style scoped>

</style>

<template xmlns:input-wrapper="http://www.w3.org/1999/html">
  <v-form
    v-model="search_valid"
  >
    <page-title
      :title="$t('title')"
    />
    <v-row>
      <extended-search :query="query"/>
      <search-chips
        :query="query"
        @chips-change="number_of_chips = $event.length"
      />
      <text-input
        field="search"
        :label="$t('search')"
        :box-probs="boxProbs"
        sm="12"
        md="12"
      />
    </v-row>
    <all-clients-switch
      v-model="all_clients"
    />
    <search-button
      class="mt-3"
      :disabled="!search_valid || number_of_chips === 0 && boxProbs.editObject && boxProbs.editObject.search === ''"
      @click="fetchAddresses('regular')"
    />
    <error-alert :message="$t('search_error')" v-model="has_error" class="mt-4"/>

    <!-- :loading: load all events, loadingEvents is false, by loading it is true, at the end it is false
     :empty: if no events -> return a message -->
    <card-group
      :loading="loadingAddresses"
      :loading-message="$t('loading_message')"
      :empty="addresses.length === 0"
      :empty-message="$t('empty_message')"
      @lazy-loading="fetchAddresses('lazy')"
    >
      <foreign-person-card
        v-for="person in addresses"
        :key="person.id"
        :address="person"
      />
    </card-group>
  </v-form>
</template>

<script>
import {cloneDeep, set} from "lodash";
import {GESCHAEFTSADRESSE} from "@/constants/ADDRESS_RELATION_TYPES.js";
import {EDUCATION_MODULE_ID, QUALIFICATION_MODULE_ID} from "@/constants/MODULE_IDS.js";
import ForeignPersonCard from "../../components/Cards/ForeignPersonCard.vue";
import ExtendedSearch from "./ExtendedSearch.vue";
import SearchChips from "../../components/SearchInputs/SearchChips.vue";
import TextInput from "../../components/Edit/EditBoxChildren/TextInput.vue";
import MapAddressRelationTypesMixin
  from "../../mixins/ConstMappers/MapAddressRelationTypesMixin.js";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import SearchButton from "../../components/SearchInputs/SearchButton.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import AllClientsSwitch from "../../components/SearchInputs/AllClientsSwitch.vue";
import ErrorAlert from "../../components/Alerts/ErrorAlert.vue";

export default {
  name: "WhoswhoSearch",
  path: "pages.whoswho.whoswho_search",
  mixins: [MapAddressRelationTypesMixin],
  components: {
    ErrorAlert,
    AllClientsSwitch,
    TextInput,
    SearchChips,
    ExtendedSearch,
    ForeignPersonCard,
    PageTitle,
    SearchButton,
    CardGroup,
  },
  data() {
    return {
      has_error: false,
      current_client: undefined,
      search_valid: true,
      take: 6,
      skip: 0,
      items_in_database: 6,
      old_query_string: "",
      number_of_chips: 0,
      query: {
        search: undefined,
        search_address: {
          name: undefined,
          first_name: undefined,
          street: undefined,
          postcode: undefined,
          city: undefined,
          mail: undefined,
        },
        search_address_relation: {
          relation_type_id: GESCHAEFTSADRESSE,
          city: undefined,
          tags: {
            position: undefined,
          },
          company_name: undefined,
          company_tag_id: undefined,
          company_function: undefined,
        },
        search_address_registration_1: {
          module_id: EDUCATION_MODULE_ID,
          info3: undefined,
          tags: {
            degree: undefined,
            subject: undefined,
            specialization: undefined,
          },
          location_address_id: undefined,
          end_year: undefined,
        },
        search_address_registration_2: {
          module_id: QUALIFICATION_MODULE_ID,
          info3: undefined,
          info4: undefined,
          tags: {
            degree: undefined,
          },
          location_address_id: undefined,
          end_year: undefined,
        },
      },
      addresses: [],
      loadingAddresses: false,
      boxProbs: {
        editObject: this.query,
        editmode: true,
        saving: false,
        eventname: "",
      },
    };
  },
  computed: {
/*    boxProbs() {
      return {
        editObject: this.query,
        editmode: true,
        saving: false,
        eventname: "",
      };
    },*/
    all_clients: {
      get() {
        return this.$route.query.all_clients === "true";
      },
      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: {...this.$route.query, all_clients: `${value}`},
        });
      },
    },
    cleaned_search_query() {
      const query = cloneDeep(this.query);
      if (this.all_clients) {
        query.client_id = "alle";
      }

      const subobjects = [
        "search_address_relation",
        "search_address_registration_1",
        "search_address_registration_2",
      ];
      subobjects.forEach((subobject_name) => {
        query[subobject_name].tag = this.tagsToString(query[subobject_name].tags);
        delete query[subobject_name].tags;
      });

      if (this.emptyQueryObject(query.search_address)) {
        delete query.search_address;
      }

      if (this.emptyQueryObject(query.search_address_relation, "relation_type_id")) {
        delete query.search_address_relation;
      }

      query.search_address_registrations = [];
      [1, 2].forEach((i) => {
        const search_address_registration_i = `search_address_registration_${i}`;
        if (!this.emptyQueryObject(query[search_address_registration_i], "module_id")) {
          query.search_address_registrations.push(query[search_address_registration_i]);
        }
        delete query[search_address_registration_i];
      });
      return query;
    },
  },
  watch: {
    query: {
      handler(query) {
        this.$router.push({
          name: this.$route.name,
          query: {...this.$route.query, q: JSON.stringify(query)},
        });
        this.$set(this.boxProbs, "editObject", query);
        this.$set(this.boxProbs, "editmode", true);
        this.$set(this.boxProbs, "saving", false);
        this.$set(this.boxProbs, "eventname", "");
      },
      deep: true,
    },
  },
  created() {
    const query = this.$route.query.q;
    if (query) {
      this.query = JSON.parse(query);
    }
    this.$set(this.boxProbs, "editObject", this.query);
  },
  methods: {
    tagsToString(tag_object) {
      return Object.values(tag_object)
        .filter((tag) => !!tag)
        .join(",");
    },
    emptyQueryObject(query_object, ...always_present_keys) {
      return !Object.entries(query_object).some(([key, value]) => {
        return !always_present_keys.includes(key) && value;
      });
    },
    appendMembershipQuery(query) {
      const enriched_query = cloneDeep(query);
      enriched_query.search_address_registrations.push({
        module_id: "21707",
        from_end: "heute",
      });

      return enriched_query;
    },
    fetchAddresses(input) {
      if (input === "regular") {
        this.addresses = [];
      }
      this.loadingAddresses = true;
      const query = this.cleaned_search_query;
      const query_string = JSON.stringify(query);
      if (query_string !== this.old_query_string) {
        this.skip = 0;
        this.items_in_database = 6;
        this.old_query_string = query_string;
      }
      if (this.items_in_database < this.skip) {
        return;
      }
      const query_and_membership = this.appendMembershipQuery(query);
      this.$bom.addresses.all // bom-framework
        .queryFor(query_and_membership)
        .including("ArchiveRelationsPlugin", "AddressRelationsPlugin")
        .take(this.take) // take the first 6 addresses from bom
        .skip(this.skip)
        .fetch()
        .then((data) => {
          this.items_in_database = data.items_in_database;
          this.addresses = [...this.addresses, ...data.addresses]; // save events as instances
          this.skip = this.addresses.length;
        })
        .catch((error) => {
          console.error(error);
          this.has_error = true;
        })
        .finally(() => {
          this.loadingAddresses = false; // put it as false so that it will not load anymore events
        });
    },
  },

  i18n: {
    messages: {
      de: {
        search_error: "Bei der Suche ist ein Fehler aufgetreten",
        search: "Suchbegriff",
        title: "Who's Who Search",
        loading_message: "Adressen werden geladen",
        empty_message: "Keine Adressen gefunden",
      },
      fr: {
        search_error: "Une erreur s'est produite pendant la recherche",
        search: "Critère de recherche",
        title: "Qui est qui",
        loading_message: "Les adresses sont en cours de chargement",
        empty_message: "Aucune adresse trouvée",
      },
    },
  },
};
</script>

<style scoped>

</style>

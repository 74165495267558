import * as FOREIGN_KEY_NUMBERS from "../../constants/FOREIGN_KEY_NUMBERS.js";

export default {
  data() {
    return {
      FOREIGN_KEY_NUMBER_FACEBOOK: FOREIGN_KEY_NUMBERS.FACEBOOK,
      FOREIGN_KEY_NUMBER_XING: FOREIGN_KEY_NUMBERS.XING,
      FOREIGN_KEY_NUMBER_LINKEDIN: FOREIGN_KEY_NUMBERS.LINKEDIN,
      FOREIGN_KEY_NUMBER_TWITTER: FOREIGN_KEY_NUMBERS.TWITTER,
      FOREIGN_KEY_NUMBER_WEITERE: FOREIGN_KEY_NUMBERS.WEITERE,
    };
  },
};

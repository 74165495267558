<template>
    <div>
      <page-title
        :title="$t('search_mentor')"
      />
      <v-row>
        <multi-tag-selector
          v-model="selected_theme_tags"
          :label="$t('theme')"
          :tags="theme_tags"
          :loading="loadingOptions"
        />
        <multi-tag-selector
          v-model="selected_branch_tags"
          :label="$t('branch')"
          :tags="branch_tags"
          :loading="loadingOptions"
        />
        <multi-tag-selector
          v-model="selected_region_tags"
          :label="$t('region')"
          :tags="region_tags"
          :loading="loadingOptions"
        />
      </v-row>
      <v-row>
        <input-wrapper sm="12" md="12">
          <search
            v-model="search_term"
            :label="$t('search_name')"
          />
        </input-wrapper>
        <input-wrapper sm="12" md="12">
          <v-switch
            v-model="filterUnavailable"
            :label="$t('only_available')"
            :true-value="true"
            :false-value="false"
          />
        </input-wrapper>
      </v-row>
      <search-button
        :label="$t('search_now')"
        :disabled="no_filter_chosen"
        @click="search"
      />
      <card-group
        :loading="loadingMentors"
        :loading-message="$t('loading_message')"
        :empty="mentors.length === 0"
        :empty-message="$t('empty_message')"
      >
        <mentor-card
          v-for="mentor in mentors_to_show"
          :key="mentor.id"
          :mentor="mentor"
        />
      </card-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import MultiTagSelector from "../../components/SearchInputs/MultiTagSelector.vue";
import Search from "../../components/SearchInputs/Search.vue";
import InputWrapper from "../../components/Edit/EditBoxChildren/InputWrapper.vue";
import SearchButton from "../../components/SearchInputs/SearchButton.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import MentorCard from "../../components/Cards/MentorCard.vue";

const today = new Date();

export default {
  name: "SearchMentor",
  path: "pages.mentoring.search_mentor",
  components: {
    MentorCard,
    CardGroup,
    SearchButton,
    InputWrapper,
    Search,
    MultiTagSelector,
    PageTitle,
  },
  data: () => ({
    loadingOptions: true,
    loadingMentors: false,

    search_term: "",
    filterUnavailable: false,

    theme_tags: [],
    branch_tags: [],
    region_tags: [],

    selected_theme_tags: [],
    selected_branch_tags: [],
    selected_region_tags: [],

    mentors: [],
  }),
  computed: {
    ...mapGetters("mentoring", {
      becomeMentorInstance: "getBecomeMentorInstance",
    }),
    no_filter_chosen() { // has to be true if no filter is chosen --> if all filters are empty
      return this.search_term.length === 0 && this.selected_theme_tags.length === 0 && this.selected_branch_tags.length === 0 && this.selected_region_tags.length === 0;
    },
    mentors_to_show() {
      if (!this.filterUnavailable) { return this.mentors; }
      return this.mentors.filter(this.isMentorAvailable);
    },
    /**
     * active tags returns a list of all tags concatenated with the union symbol "||" (so if multiple tags are selected, all
     * the mentors which have at least one of these tags will be shown)
     */
    active_tags() {
      // combine tags of same category with OR (||)
      const theme_tags = this.selected_theme_tags
        .map((tag) => tag.number)
        .reduce((all_tags, tag) => all_tags.concat(tag, "||"), [])
        .slice(0, -1);
      const branch_tags = this.selected_branch_tags
        .map((tag) => tag.number)
        .reduce((all_tags, tag) => all_tags.concat(tag, "||"), [])
        .slice(0, -1);
      const region_tags = this.selected_region_tags
        .map((tag) => tag.number)
        .reduce((all_tags, tag) => all_tags.concat(tag, "||"), [])
        .slice(0, -1);
      let and_or_tags = [];
      // combine tags of different category with AND (&&)
      [theme_tags, branch_tags, region_tags].forEach((category) => {
        console.log(category, category.length);
        if (category.length > 0) {
          and_or_tags = and_or_tags.concat(category, "&&");
        }
      });
      // force Angebots tag ("29421")
      and_or_tags = and_or_tags.concat("29421");
      return and_or_tags;
    },
  },
  async created() {
    if (!this.becomeMentorInstance.TagsPlugin) {
      await this.becomeMentorInstance.loadTagsPlugin();
    }
    const available_tags = await this.becomeMentorInstance.TagsPlugin.getAllPossibleTags();
    this.theme_tags = available_tags.filter((tag) => tag.category === "Themenfelder").sort(this.sortTagsByName);
    this.branch_tags = available_tags.filter((tag) => tag.category === "Branchen").sort(this.sortTagsByName);
    this.region_tags = available_tags.filter((tag) => tag.category === "Region").sort(this.sortTagsByName);
    this.loadingOptions = false;
  },
  methods: {
    sortTagsByName(tag1, tag2) {
      if (tag1.name > tag2.name) { return 1; }
      if (tag1.name < tag2.name) { return -1; }
      return -1;
    },
    search() {
      this.loadingMentors = true;
      this.$bom.mentoring.instances.all
        .onClient("26")
        .startingBefore("today")
        .endingAfter("today")
        .sortedBy("from")
        .contains(this.search_term)
        .including("TagsPlugin", "ReferencesPlugin", "ArchiveRelationsPlugin", "IncidentPlugin")
        .withTags(...this.active_tags)
        .fetch()
        .then(({instances}) => {
          this.mentors = instances;
        })
        .finally(() => {
          this.loadingMentors = false;
        });
    },
    isMentorAvailable(mentor) {
      const current_registrations = mentor.ReferencesPlugin.all
        .filter((ref) => ref.from < today && ref.to > today);
      return current_registrations.length < mentor.IncidentPlugin.max_registrations;
    },
  },
  i18n: {
    messages: {
      de: {
        search_mentor: "Mentor/-in suchen",
        theme: "Themengebiete",
        branch: "Branchen",
        region: "Regionen",
        search_name: "Mentor/-in suchen (Name / Vorname)",
        search_now: "Mentor/-in anzeigen",
        loading_message: "Mentoren/-innen werden geladen",
        empty_message: "Keine Mentoren/-innen gefunden",
        only_available: "Nur verfügbare Mentoren/-innen anzeigen",
      },
      fr: {
        search_mentor: "Rechercher un mentor",
        theme: "Sujets",
        branch: "Secteur",
        region: "Régions",
        search_name: "Rechercher un mentor (nom / prénom)",
        search_now: "Montrer les mentors",
        loading_message: "Les mentors sont chargées",
        empty_message: "Aucun mentor trouvé",
        only_available: "Montrer uniquement les mentors disponibles",
      },
    },
  },
};
</script>

<style scoped>

</style>

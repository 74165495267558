<template>
    <fragment>
      <foreignkey-text-input
        :box-probs="boxProbs"
        :label="$t('facebook')"
        :fknumber="FOREIGN_KEY_NUMBER_FACEBOOK"
        fkcategory="Facebook"
        field="name"
        website
      />
      <foreignkey-text-input
        :box-probs="boxProbs"
        :label="$t('linkedin')"
        :fknumber="FOREIGN_KEY_NUMBER_LINKEDIN"
        fkcategory="Linkedin"
        field="name"
        website
      />
      <foreignkey-text-input
        :box-probs="boxProbs"
        :label="$t('xing')"
        :fknumber="FOREIGN_KEY_NUMBER_XING"
        fkcategory="XING"
        field="name"
        website
      />
      <foreignkey-text-input
        :box-probs="boxProbs"
        :label="$t('twitter')"
        :fknumber="FOREIGN_KEY_NUMBER_TWITTER"
        fkcategory="Twitter"
        field="name"
        website
      />
      <foreignkey-text-input
        :box-probs="boxProbs"
        :label="$t('more')"
        :fknumber="FOREIGN_KEY_NUMBER_WEITERE"
        fkcategory="Weitere"
        field="name"
        website
      />
    </fragment>
</template>

<script>
import ForeignkeyTextInput from "../../../../components/Edit/EditBoxChildren/ForeignkeyTextInput.vue";

import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import MapForeignKeyNumbersMixin from "../../../../mixins/ConstMappers/MapForeignKeyNumbersMixin.js";

/**
 * Mixins: EditBoxChildMixin, MapForeignKeyNumbersMixin
 */
export default {
  name: "SocialmediaSection",
  path: "pages.address.person.sections.socialmedia_section",
  mixins: [EditBoxChildMixin, MapForeignKeyNumbersMixin],
  components: {
    ForeignkeyTextInput,
  },
  i18n: {
    messages: {
      de: {
        facebook: "Facebook",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        xing: "XING",
        more: "Weitere",
      },
      fr: {
        facebook: "Facebook",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        xing: "XING",
        more: "Supplémentaire",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <fragment>
      <date-picker
        :box-probs="boxProbs"
        field="from"
        :label="$t('mentoring_start')"
      />
      <date-picker
        :box-probs="boxProbs"
        field="to"
        :label="$t('mentoring_end')"
      />
    </fragment>
</template>

<script>
import DatePicker from "../../../../components/Edit/EditBoxChildren/DatePicker.vue";
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";

export default {
  name: "MentoringTimerangeSection",
  path: "pages.mentoring.mentoring_details.sections.mentoring_timerange_section",
  components: {DatePicker},
  mixins: [EditBoxChildMixin],
  i18n: {
    messages: {
      de: {
        mentoring_start: "Start des Mentorings",
        mentoring_end: "Ende des Mentorings",
      },
      fr: {
        mentoring_start: "Début du mentorat",
        mentoring_end: "Fin du mentorat",
      },
    },
  },
};
</script>

<style scoped>

</style>

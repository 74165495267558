<template>
    <fragment>
      <text-input
        field="search_address_registration_2.info3"
        :label="$t('search_address_registration_2.info3')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address_registration_2.info4"
        :label="$t('search_address_registration_2.info4')"
        :box-probs="boxProbs"
      />
      <address-id-input
        field="search_address_registration_2.location_address_id"
        :selectable-type-numbers="[21641]"
        :label="$t('search_address_registration_2.location_address_id')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_registration_2.tags.degree"
        tag-category="Weiterbildungs-Stufe"
        module="qualification"
        :label="$t('search_address_registration_2.tags.degree')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address_registration_2.end_year"
        :label="$t('search_address_registration_2.end_year')"
        numeric
        :max-length="4"
        :box-probs="boxProbs"
      />
    </fragment>
</template>

<script>
import TextInput from "../../../components/Edit/EditBoxChildren/TextInput.vue";
import SearchSectionMixin from "../../../mixins/SearchSectionMixin.js";
import AddressIdInput from "../../../components/Edit/EditBoxChildren/AddressIdInput.vue";
import TagNumberInput from "../../../components/Edit/EditBoxChildren/TagNumberInput.vue";

export default {
  name: "QualificationSearchSection",
  path: "pages.whoswho.sections.qualification_search_section",
  components: {TagNumberInput, AddressIdInput, TextInput},
  mixins: [SearchSectionMixin],
  i18n: {
    messages: {
      de: {
        search_address_registration_2: {
          info3: "Weiterbildungs-Diplom",
          info4: "Weiterbildungs-Spezifikation",
          tags: {
            degree: "Weiterbildungs-Stufe",
          },
          location_address_id: "Weiterbildung Studienort",
          end_year: "Weiterbildung Abschlussjahr",
        },
      },
      fr: {
        search_address_registration_2: {
          info3: "Diplôme de formation continue",
          info4: "Spécification de formation continue",
          tags: {
            degree: "Niveau de formation continue",
          },
          location_address_id: "Lieu d'étude de la formation continue",
          end_year: "Fin de formation continue",
        },
      },
    },
  },
};
</script>

<style scoped>

</style>

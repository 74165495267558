<template>
  <input-wrapper v-show="show">
    <v-select
      v-model="value"
      :items="Object.values(items)"
      item-text="name"
      item-value="value"
      :label="label"
      required
      :readonly="!boxProbs.editmode"
      :loading="loading"
      :disabled="boxProbs.editmode && readonly"
    />
  </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

/**
 * The Manual dropdown input component takes an array of items as inputs and lets the user select
 * one freely.
 *
 * Mixins: InputMixin
 */
export default {
  name: "DropdownManualInput",
  path: "components.edit.edit_box_children.dropdown_manual_input",
  components: {InputWrapper},
  mixins: [InputMixin],
  props: {
    // Items to select from. Items has the form [{name: string, value: string}, ...]
    items: { type: Object, required: false },
  },
};
</script>

<style scoped>

</style>

<template>
  <section>
    <slot/>
  </section>
</template>

<script>
import RegMaskFieldMixin from "../../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMInputWrapper",
  path: "components.reg_mask.reg_mask_field.wrapper.r_m_input_wrapper",
  mixins: [RegMaskFieldMixin],
};
</script>

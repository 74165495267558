<template>
  <div class="mt-2">
    <v-chip
      close
      class="ma-1"
      v-for="chip in chips_generator"
      :key="chip.path"
      @click:close="resetChip(chip)"
    >
        <strong class="pr-1">
          {{chip.name}}
        </strong>
        <span v-if="!chip.name.endsWith('*')">
          {{chip.value}}
        </span>
    </v-chip>
  </div>
</template>

<script>

import { set } from "lodash";

export default {
  name: "SearchChips",
  path: "components.search_inputs.search_chips",
  components: {},
  props: {
    query: { type: Object, required: true },
  },
  data() {
    return {
      chips_generator: [],
    };
  },
  watch: {
    query: {
      handler(val) {
        console.log("watch chips")
        const chips = this.recursiveQueryParser(val);
        this.$emit("chips-change", chips);
        this.chips_generator = chips;
      },
      deep: true,
    },
  },
  created(){
    const chips = this.recursiveQueryParser(this.query);
    this.$emit("chips-change", chips);
    this.chips_generator = chips;
  },
  methods: {
    /**
     * recursiveQueryParser parses this.query and fetches all (key: value) pairs in an Array.
     * It stores the pairs with a path that mocks the Object-Structure of the Query like this:
     * query: {
     *   key1: value1
     *   sub_query: {
     *     key2: value2,
     *     key3: value3,
     *   }
     * }
     * This query input would result in a return like this:
     * chips = [{value: "value1", name: "key1", path: "key1"},
     *          {value: value2, name: key2, path: sub_query.key2},
     *          {value: value3, name: key3, path: sub_query.key3}
     *          ]
     * This array is used to generate chips that can hold those object informations and display
     * the search-value, search-field-naming and have the corresponding query-path.
     *
     * @param query
     * @param depth
     * @return {[]}
     */
    recursiveQueryParser(query, depth = "") {
      const chips = [];
      Object.entries(query)
        .forEach(([name, value]) => {
          const path = depth ? `${depth}.${name}` : name;
          const display_name = this.$t(path);
          if (value instanceof Object) {
            chips.push(...this.recursiveQueryParser(value, path));
          } else if (value && display_name !== "*hidden") {
            chips.push({name: display_name, value, path});
          }
        });
      return chips;
    },

    /**
     * On clicking the small 'x' on the Chip, the searchfield gets erased and the chip is removed,
     * such that it will not be considered in the search.
     *
     * @param chip
     */
    resetChip(chip) {
      set(this.query, chip.path, undefined);
      const chips = this.recursiveQueryParser(this.query);
      this.$emit("chips-change", chips);
      this.chips_generator = chips;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <related-address-section
      :empty-message="$t('emptyMessage')"
      :relation-type="ADDRESS_RELATION_TYPE_KONTAKT"
    />
  </div>
</template>

<script>

import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import MapAddressRelationTypesMixin from "../../mixins/ConstMappers/MapAddressRelationTypesMixin.js";
import RelatedAddressSection from "./RelatedAddressSection.vue";

/**
 * The "savedContacts" page just shows all your saved Contacts.
 */
export default {
  name: "SavedContacts",
  path: "pages.contacts.saved_contacts",
  mixins: [MapAddressRelationTypesMixin],
  components: {RelatedAddressSection, PageTitle},
  i18n: {
    messages: {
      de: {
        title: "Meine Kontakte",
        emptyMessage: "Sie haben noch keine Kontakte.",
      },
      fr: {
        title: "Mes contacts",
        emptyMessage: "Vous n'avez pas encore de contacts.",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <fragment>
      <html-input
        :box-probs="boxProbs"
        :label="boxProbs.editObject.person.name"
        field="person.description"
      />
      <html-input
        :box-probs="boxProbs"
        :label="boxProbs.editObject.education.name"
        field="education.description"
      />
      <html-input
        :box-probs="boxProbs"
        :label="boxProbs.editObject.highschool.name"
        field="highschool.description"
      />
    </fragment>
</template>

<script>
import HtmlInput from "../../../../components/Edit/EditBoxChildren/HtmlInput.vue";
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";

export default {
  name: "MentorInfoSection",
  path: "pages.mentoring.mentor_details.sections.mentor_info_section",
  components: {HtmlInput},
  mixins: [EditBoxChildMixin],
};
</script>

<style scoped>

</style>

<template>
  <fragment>
    <conditional-tag-section
      tag-number="658"
      tag-category="Publikation"
      tag-name="WEB"
      :bom-object="boxProbs.editObject"
      :text="$t('hidden_address_info')"
    >
      <text-input
        :box-probs="boxProbs"
        :label="$t('street')"
        :max-length="$config.LINE_INPUT_MAX_LENGTH"
        field="street"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('street_addition')"
        :max-length="$config.LINE_INPUT_MAX_LENGTH"
        field="street_addition"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('postcode')"
        :max-length="8"
        field="postcode"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('city')"
        :max-length="$config.LINE_INPUT_MAX_LENGTH"
        field="city"
      />
      <text-input
        :box-probs="boxProbs"
        :label="$t('country')"
        :max-length="$config.LINE_INPUT_MAX_LENGTH"
        field="country"
      />
    </conditional-tag-section>
  </fragment>
</template>

<script>
import ConditionalTagSection from "../../../../components/LogicComponents/ConditionalTagSection.vue";
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";

export default {
  name: "CompanyAddressSection",
  path: "pages.address.company.sections.company_address_section",
  mixins: [EditBoxChildMixin],
  components: {
    ConditionalTagSection,
    TextInput,
  },
  i18n: {
    messages: {
      de: {
        street: "Strasse",
        street_addition: "Zusatz",
        postcode: "PLZ",
        city: "Ort",
        country: "Land",
        hidden_address_info: "Diese Addresse ist nicht sichtbar.",
      },
      fr: {
        street: "Rue",
        street_addition: "Complément",
        postcode: "Code postale",
        city: "Lieu",
        country: "Pays",
        hidden_address_info: "Cette adresse n'est pas visible.",
      },
    },
  },
};
</script>

<style scoped>

</style>

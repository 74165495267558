<template>
  <image-card
    :title="address | companyname"
    :image="image"
    :action="routeTo"
  >
    <template slot="topright">
      <slot></slot>
    </template>
    <p>
      {{address | fullname}}
    </p>
    <p>
      {{address.street | none }},
      {{address.postcode | none }}
      {{address.city | none }}
    </p>
    <p>
      Tel.: {{address.phone_number | none}}
    </p>
    <p>
      Mobile.: {{address.mobile_number | none}}
    </p>
    <p>
      E-Mail: {{address.mail | none}}
    </p>
  </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-business.png";
import AddressCardMixin from "../../mixins/AddressCardMixin.js";

/**
 * The company card shows the most important information about a company address. On the left, the companys
 * profile image is shown, while on the right, some information like the company name is shown.
 * The company card is clickable and routes to the CompanyAddress.vue page.
 *
 * Mixins: AddressCardMixin
 */
export default {
  name: "CompanyCard",
  path: "components.cards.company_address_card",
  components: {ImageCard},
  mixins: [AddressCardMixin],
  data() {
    return {
      // The image that is shown on the left of the card.
      image: default_image,
      // Specifies where to route to when the card is clicked
      route_to: "company-address-readOnly",
    };
  },
};
</script>

<style scoped>
  p {
    margin-bottom: 4px !important;
  }
</style>

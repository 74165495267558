<template>
    <div>
      <page-title
        :title="$t('my_mentoring')"
      />
      <mentor-mentee-section
        :mentoring="mentoring"
        :mentor="mentor"
        :mentor-editable="viewer_is_mentor"
        :mentee-editable="viewer_is_mentee"
      />
      <collapsible-group>

        <collapsible :title="$t('registration_details')" :loading="loading">
          <edit-box
            :editable="viewer_is_mentor"
            :edit-object="mentoring"
          >
            <template v-slot:default="boxProbs">
              <mentoring-timerange-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
          <edit-box
            :editable="viewer_is_mentee"
            :edit-object="mentoring_positions"
          >
            <template v-slot:default="boxProbs">
              <mentoring-details-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>

        <collapsible :title="$t('type_and_location')" :loading="loading">
          <edit-box
            :edit-object="mentor"
            :editable="viewer_is_mentor"
          >
            <template v-slot:default="boxProbs">
              <mentoring-tags-section
                :box-probs="boxProbs"
                :tags="type_tags"
                :title="$t('mentoring_type')"
              />
              <mentoring-tags-section
                :box-probs="boxProbs"
                :tags="regionTags"
                :title="$t('mentoring_location')"
              />
              <mentoring-tags-section
                :box-probs="boxProbs"
                :tags="subjectTags"
                :title="$t('mentoring_subjects')"
              />
              <mentoring-tags-section
                :box-probs="boxProbs"
                :tags="branchTags"
                :title="$t('mentoring_branch')"
              />
            </template>
          </edit-box>
        </collapsible>

        <collapsible :title="$t('mentor_details')" :loading="loading">
          <edit-box
            :editable="viewer_is_mentor"
            :edit-object="joint_articles"
          >
            <template v-slot:default="boxProbs">
              <mentor-details-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>

        <collapsible :title="$t('mentee_details')" :loading="loading">
          <edit-box
            :editable="viewer_is_mentee"
            :edit-object="mentoring_positions"
          >
            <template v-slot:default="boxProbs">
              <mentee-details-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>

        <collapsible :title="$t('shared_note')" :loading="loading">
          <edit-box
            :edit-object="mentoring"
            :editable="true"
          >
            <template v-slot:default="boxProbs">
              <note-section
                :box-probs="boxProbs"
              />
            </template>
          </edit-box>
        </collapsible>

        <collapsible :title="$t('file_sharing')" :loading="loading">
          <file-sharing-section
            :mentoring="mentoring"
          />
        </collapsible>

      </collapsible-group>

      <v-row v-if="is_pending && viewer_is_mentor">
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            @click="modal_open = true"
          >
            {{ $t("accept_reject_mentoring") }}
          </v-btn>
        </v-col>
        <accept-reject-mentoring-modal
          v-model="modal_open"
          :id="mentoring.id"
        />
      </v-row>
    </div>
</template>

<script>
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import CollapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import Collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import MentoringDetailsSection from "./Sections/MentoringDetailsSection.vue";
import MentorDetailsMixin from "../../../mixins/MentorDetailsMixin.js";
import MentorDetailsSection from "./Sections/MentorDetailsSection.vue";
import MenteeDetailsSection from "./Sections/MenteeDetailsSection.vue";
import MentorMenteeSection from "./Sections/MentorMenteeSection.vue";
import NoteSection from "./Sections/NoteSection.vue";
import MentoringTagsSection from "./Sections/MentoringTagsSection.vue";
import MentoringDetailsMixin from "../../../mixins/MentoringDetailsMixin.js";
import AcceptRejectMentoringModal from "../../../components/Modals/AcceptRejectMentoringModal.vue";
import MentoringTimerangeSection from "./Sections/MentoringTimerangeSection.vue";
import FileSharingSection from "./Sections/FileSharingSection.vue";
import TempMandantSwitch from "../../../mixins/TempMandantSwitch.js";

export default {
  name: "MentoringDetails",
  path: "pages.mentoring.mentoring_details.mentoring_details",
  components: {
    FileSharingSection,
    MentoringTimerangeSection,
    AcceptRejectMentoringModal,
    MentoringTagsSection,
    NoteSection,
    MentorMenteeSection,
    MenteeDetailsSection,
    MentorDetailsSection,
    MentoringDetailsSection,
    EditBox,
    Collapsible,
    CollapsibleGroup,
    PageTitle,
  },
  mixins: [DetailPageMixin, MentorDetailsMixin, MentoringDetailsMixin, TempMandantSwitch],
  data: () => ({
    loading: true,
    mentoring: undefined,
    mentor: undefined,
    modal_open: false,
  }),
  computed: {
    viewer() {
      return this.$route.meta.viewer;
    },
    viewer_is_mentee() {
      return this.viewer === "mentee";
    },
    viewer_is_mentor() {
      return this.viewer === "mentor";
    },
  },
  created() {
    this.fetchMentoring()
      .then(() => {
        return this.fetchMentor();
      })
      .then(() => {
        return Promise.all([
          this.fetchMyPersonArticle(),
          this.fetchMyEducationsArticle(),
          this.fetchMyHighschoolArticle(),
        ]);
      })
      .then(() => {
        this.loading = false;
      });
  },
  i18n: {
    messages: {
      de: {
        my_mentoring: "Mein Mentoring",
        registration_details: "Details zur Anmeldung",
        mentor_details: "Details zum/zur Mentor/-in",
        mentee_details: "Details zum Mentee",
        shared_note: "Geteilte Notiz",
        type_and_location: "Art und Ort des Mentorings",
        mentoring_type: "Art des Mentorings",
        mentoring_location: "Orte des Mentorings",
        mentoring_subjects: "Mentoring Themen",
        mentoring_branch: "Mentoring Branchen",
        accept_reject_mentoring: "Mentoring akzeptieren/ablehnen",
        file_sharing: "Dateiaustausch",
      },
      fr: {
        my_mentoring: "Mon mentorat",
        registration_details: "Détails de l'inscription",
        mentor_details: "Détails du mentor",
        mentee_details: "Détails sur le mentoré",
        shared_note: "Note partagée",
        type_and_location: "Type et lieu du mentorat",
        mentoring_type: "Type de mentorat",
        mentoring_location: "Lieux de mentorat",
        mentoring_subjects: "Sujets du mentorat",
        mentoring_branch: "Les secteurs du mentorat",
        accept_reject_mentoring: "Accepter/rejeter le mentorat",
        file_sharing: "Échange de fichiers",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <!-- we receive from event: name, description, info2, info3, info4, info5, from, to, plugins
  filters: we give the filter "event" and the filter return "name" or "location" etc
  action: executed when click on it -->
    <image-card
      :title="academies | name"
      :image="image"
      :action="routeToEvent"
    >
      <p>
        <strong>{{$t("organizer")}}:</strong> {{ academies | responsible }}
      </p>
      <p>
        <strong>{{$t("brief_description")}}:</strong>
      </p>
      <p>
        {{ academies.info4 }}
      </p>
    </image-card>
</template>

<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-event.png";

export default {
  name: "AcademisCard",
  path: "components.cards.academis_card",
  components: {ImageCard},
  props: {
    academies: { type: Object, required: true },
  },
  data() {
    return {
      image: default_image,
    };
  },
  methods: {
    // when clicking on it, it leads to the EventDetails.vue site with the name "event-details"
    routeToEvent() {
      this.$router.push({
        name: "academies-details",
        params: {
          id: this.academies.id,
        },
      });
    },
  },
  i18n: {
    messages: {
      de: {
        brief_description: "Kurzbeschrieb",
        organizer: "Organisator",
      },
      fr: {
        brief_description: "Brève description",
        organizer: "Organisateur",
      },
      it: {
        brief_description: "Breve descrizione",
        organizer: "Organizzatore",
      },
    },
  },
};
</script>

<style scoped>

</style>

import { PROFILE_IMAGE} from "../constants/TAG_NUMBERS.js";
import ArchiveService from "./ArchiveService.js";

export const PROFILE_IMAGE_TAG = {category: "Report-Verwendung", number: PROFILE_IMAGE, name: "Vorschau Bild"};

/**
 * Extracts the profile image from a BomAddress. If return_default is set to true,
 * a default image for an address is returned so that the function consistently returns a base64
 * image.
 * @param {BomAddress} address
 * @returns {string|undefined}
 */
function getProfileImage(address) {
  return ArchiveService.getFileWithTag(address, PROFILE_IMAGE_TAG);
}

/**
 * Returns the filename of the profile image or undefined
 * @param {BomAddress} address
 * @returns {string|undefined}
 */
function getProfileImageName(address) {
  return ArchiveService.getFileNameWithTag(address, PROFILE_IMAGE_TAG);
}

/**
 * This is a high-level function that adds a profile image to any address. If the address already has
 * a profile image, it replaces the existing one. If it does not have the according archive, a new
 * one is created and the file as added.
 * The saved BomArchive is returned.
 * @param {BomAddress} address
 * @param {string} image_file
 * @param {string} filename
 * @returns {Promise<BomArchive>}
 */
function uploadNewProfileImage(address, image_file, filename = "profileimage") {
  return ArchiveService.uploadNewFileWithTag(address, PROFILE_IMAGE_TAG, image_file, filename);
}

/**
 * This high-level function fetches the profile image from an address. It finds the right
 * ArchiveRelation with the correct profile-image tag and loads its file plugin. Note that
 * the address is returned, not the profile image itself.
 * The function parallelizes the loading of the TagsPlugins to be as efficient as possible.
 * @param address
 * @returns {Promise<BomAddress>}
 */
function fetchProfileImage(address) {
  return ArchiveService.fetchFileWithTag(address, PROFILE_IMAGE_TAG, "profilbild");
}

export default {
  getProfileImageName,
  getProfileImage,
  fetchProfileImage,
  uploadNewProfileImage,
  PROFILE_IMAGE_TAG,
};

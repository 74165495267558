<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <collapsible-group>
      <collapsible :title="event | name">
        <edit-box
          :editable="false"
          :edit-object="event"
        >
          <template v-slot:default="boxProbs">
            <eventdetails-section
              :box-probs="boxProbs"
            />
          </template>
        </edit-box>
      </collapsible>
      <collapsible :title="$t('register')" v-if="event_in_the_future">
        <div v-if="event_registration_ended">
          <br>
          <p>
            {{ $t('registration_ended') }}
          </p>
        </div>
        <div v-else-if="event_registration_impossible">
          <br>
          <p>
            {{ $t('event_full') }}
          </p>
        </div>
        <div v-else-if="!users_event_registration && user_event_fetched">
          <v-btn class="mt-3"
            @click="registerForEvent"
          >
            {{ $t('register_button') }}
          </v-btn>
        </div>
        <div v-else-if="users_event_registration">
          <edit-box
            :editable="true"
            :edit-object="users_event_registration"
          >
            <template v-slot:default="boxProbs">
              <static-text
                :box-probs="boxProbs"
                label=""
                :text="$t('already_registered_text')"
              />
              <tag-on-off-switch
                :box-probs="boxProbs"
                tag-category="Publikation"
                tag-name="WEB Anonym"
                :tag-number="TAG_NUMBER_PUBLICATION_WEB_ANONYMOUS"
                :label-false="$t('hidden')"
                :label-true="$t('visible')"
              />
            </template>
          </edit-box>
          <v-btn
            color="primary"
            @click="endRegistration"
            style="margin-right: 10px"
          >
            {{ $t('end_registration_button') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="registerForEvent"
          >
            {{ $t('edit_registration_button') }}
          </v-btn>
        </div>
        <v-progress-linear v-else indeterminate/>
      </collapsible>
      <collapsible :title="$t('registered_users')">
        <loading-circle v-if="registered_addresses === undefined" class="mt-4"/>
        <v-list v-else>
          <div class="d-flex align-center">
            <search class="px-4"
              v-model="registered_user_search"
              @enter="searchEventRegistration"
              :loading="loading_registration"
            />
            <search-button
              @click="searchEventRegistration"
              :disabled="loading_registration"
            />
          </div>
          <!-- show the registered persons compact in a list on which you can click on -> like a card -->
          <compact-person-card
            v-for="address in registered_addresses"
            :key="address.id"
            :address="address"
          />
          <p v-if="registered_addresses.length === 0">
            {{ $t('no_registered_users') }}
          </p>
        </v-list>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DetailPageMixin from "../../../mixins/DetailPageMixin.js";
import PageTitle from "../../../components/UI-Elements/PageTitle.vue";
import collapsibleGroup from "../../../components/Structure/CollabsibleGroup.vue";
import collapsible from "../../../components/Structure/Collabsible.vue";
import EditBox from "../../../components/Edit/EditBox.vue";
import EventdetailsSection from "./EventdetailsSection.vue";
import StaticText from "../../../components/Edit/EditBoxChildren/StaticText.vue";
import TagOnOffSwitch from "../../../components/Edit/EditBoxChildren/TagOnOffSwitch.vue";
import MapTagNumberMixin from "../../../mixins/ConstMappers/MapTagNumberMixin.js";
import LoadingCircle from "../../../components/UI-Elements/LoadingCircle.vue";
import CompactPersonCard from "../../../components/Cards/CompactPersonCard.vue";
import SearchButton from "../../../components/SearchInputs/SearchButton.vue";
import Search from "../../../components/SearchInputs/Search.vue";

export default {
  name: "EventDetails",
  path: "pages.events.details.event_details",
  components: {
    Search,
    SearchButton,
    CompactPersonCard,
    LoadingCircle,
    TagOnOffSwitch,
    StaticText,
    EventdetailsSection,
    EditBox,
    collapsible,
    collapsibleGroup,
    PageTitle,
  },
  mixins: [DetailPageMixin, MapTagNumberMixin], // so that we have the ID for this page
  data() {
    return {
      event: undefined,
      users_event_registration: undefined,
      registered_addresses: undefined,
      loading_registration: false,
      user_event_fetched: false,
    };
  },
  computed: {
    /**
     * We map the getter "getUserId" form the user stoer module to a local computed property
     */
    ...mapGetters("user", {
      user_id: "getUserId",
    }),
    ...mapGetters("language", {
      language: "getLanguage",
    }),
    client_id() {
      return this.event?.ClientPlugin.client_id;
    },
    /**
     * This address is only editable if it is the users own address
     * @returns {boolean}
     */
    registered_user_search: {
      get() {
        return this.$route.query.search_term || "";
      },
      set(term) {
        this.$router.replace({query: { ...this.$route.query, search_term: term }});
      },
    },
    event_in_the_future() {
      return this.event?.from && this.event?.from > new Date();
    },
    event_registration_ended() {
      return this.event.IncidentPlugin.registration_end !== null && this.event.IncidentPlugin.registration_end <= new Date();
    },
    event_registration_impossible() {
      return !(this.event.IncidentPlugin.registration_possible || this.event.IncidentPlugin.waiting_queue_possible);
    },
  },
  methods: {
    /**
     * We map the method "fetchUser" from the user store module to a local method
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
    endRegistration() {
      this.users_event_registration.end();
      this.users_event_registration.save();
      this.$router.back();
    },
    registerForEvent() {
      const encoded_event_id = encodeURIComponent(this.event.id);
      window.open(`/BOM/Veranstaltungen/RegMask#/?id=${encoded_event_id}&module=event&client=${this.client_id}&language=${this.language}`);
    },
    // fetch all registered persons on that event (startungBefore and endingAfter is used to remove the users who were registered and unregistered himself)
    fetchAllEventRegistrations() {
      let bom_query = this.$bom.event.references.all
        .onClient(this.client_id)
        .toInstance(this.id)
        .startingBefore("today")
        .endingAfter("today")
        .including("AddressPlugin");

      if (this.registered_user_search) { // when the user enter something in the search componente
        bom_query = bom_query.contains(this.registered_user_search);
      }

      return bom_query.fetch()
        .then(({references}) => {
          // load for each person his address from the AddressPlugin
          const addresses = references.map((reference) => reference.AddressPlugin.address);
          return Promise.all(addresses.map((adr) => adr.loadAddressRelationsPlugin())).then(() => addresses);
        })
        .then((addresses) => {
          this.registered_addresses = addresses;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchEvent() {
      return this.$bom.event.instances.one
        .withId(this.id)
        .including("AddressRelationsPlugin", "IncidentPlugin")
        .fetch()
        .then(({instance}) => {
          this.event = instance;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // fetch all events on that the user is logged and take just that event on which we are with the id
    fetchMyEventRegistration() {
      return this.$bom.event.references.all // take all Events in which the user is logged in
        .ofLoggedInUser() // Event registrations of logged in user only
        .onClient(this.client_id)
        .toInstance(this.id)
        .including("TagsPlugin")
        .startingBefore(new Date())
        .endingAfter(new Date())
        .fetch()
        .then(({references}) => {
          this.user_event_fetched = true;
          // if the clicked event is that event for which the user is logged in -> then put it into the users_event_registration
          this.users_event_registration = references[0];
        });
    },
    async searchEventRegistration() {
      this.loading_registration = true;
      await this.fetchAllEventRegistrations();
      this.loading_registration = false;
    },
  },
  created() {
    window.removeEventListener("message", (event) => {
    }, false);

    this.fetchEvent().then(() => {
      this.fetchAllEventRegistrations();
      this.fetchMyEventRegistration();
    });
  },
  i18n: {
    messages: {
      de: {
        title: "Event Informationen",
        register: "Anmeldung",
        register_button: "Online-Anmeldung",
        edit_registration_button: "Anmeldung bearbeiten",
        already_registered_text: "Du bist bereits angemeldet.",
        hidden: "Verborgen",
        visible: "Öffentlich sichtbar",
        registered_users: "Angemeldete Personen",
        no_registered_users: "Keine angemeldete Personen",
        registration_ended: "Keine Anmeldung möglich, der Anmeldeschluss ist bereits vorbei.",
        event_full: "Keine Anmeldung möglich, der Event ist ausgebucht.",
      },
      fr: {
        title: "Informations sur l'événement",
        register: "Enregistrement",
        register_button: "Enregistrement en ligne",
        edit_registration_button: "Modifier l'inscription",
        already_registered_text: "Tu es déjà enregistré.",
        hidden: "Caché",
        visible: "Visible au public",
        registered_users: "Personnes inscrites",
        no_registered_users: "Aucune personne inscrite",
        registration_ended: "Pas d'inscription possible, la date limite d'inscription est déjà dépassée.",
        event_full: "Pas d'inscription possible, l'événement est complet.",
      },
    },
  },
};
</script>

export default {
  props: {
    tagName: { type: String, required: true},
    tagCategory: { type: String, required: true},
    tagNumber: { type: String, required: true},
  },
  computed: {
    is_tag_set() {
      // Checks out whether the tag is set in the boxProbs.editobject.TagsPlugin, return true or false
      return this.boxProbs.editObject.TagsPlugin.exists({ name: this.tagName, number: this.tagNumber, category: this.tagCategory });
    },
  },
  methods: {
    addOrRemoveTag(addTag) {
      if (addTag) {
        // Add tag to boxProbs.editobject.TagsPlugin
        this.boxProbs.editObject.TagsPlugin.create(this.tagCategory, this.tagNumber, this.tagName);
      } else {
        // Remove tag from boxProbs.editobject.TagsPlugin
        this.boxProbs.editObject.TagsPlugin.remove({ name: this.tagName, number: this.tagNumber, category: this.tagCategory });
      }
    },
  },
};

<template>
  <fragment>
    <v-col cols="12" v-for="channel in valid_info_channels" :key="channel.tag_number">
      <h3 class="primary--text">{{ channel.name }}</h3>
      <tag-on-off-switch
        :box-probs="boxProbs"
        :tag-category="channel.tag_category"
        :tag-name="channel.tag_name"
        :tag-number="channel.tag_number"
        :label-false="$t('unsubscribed')"
        :label-true="$t('subscribed')"
      />
    </v-col>
    <v-col cols="12" v-if="!loading_tags && !has_info_channels">
      <p>{{$t("empty_message")}}</p>
    </v-col>
    <v-col cols="12" v-if="loading_tags" class="pt-4">
      <loading-circle />
    </v-col>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import TagOnOffSwitch from "../../../components/Edit/EditBoxChildren/TagOnOffSwitch.vue";
import EditBoxChildMixin from "../../../mixins/EditBoxChildMixin.js";
import LoadingCircle from "../../../components/UI-Elements/LoadingCircle.vue";

export default {
  name: "InfoChannelsSection",
  path: "pages.communication.sections.info_channels_section",
  components: {LoadingCircle, TagOnOffSwitch},
  mixins: [EditBoxChildMixin],
  data() {
    return {
      loading_tags: true,
      valid_tag_numbers: [],
      infochannels: [
        {
          name: this.$t("wage_study"), tag_category: "Versand", tag_name: "Erhebung Lohnstudie", tag_number: "200000",
        },
        {
          name: this.$t("uni_info"), tag_category: "Versand", tag_name: "Infos über Fachhochschulen per Post", tag_number: "200005",
        },
        {
          name: this.$t("events_post"), tag_category: "Versand", tag_name: "Infos über Anlässe per Post", tag_number: "200004",
        },
        {
          name: this.$t("events_mail"), tag_category: "Versand", tag_name: "Infos über Anlässe per E-Mail", tag_number: "200003",
        },
        {
          name: this.$t("inline"), tag_category: "Versand", tag_name: "INLINE", tag_number: "200001",
        },
        {
          name: this.$t("inlineEPaper"), tag_category: "Versand", tag_name: "INLINE E-Paper", tag_number: "44001",
        },
        {
          name: this.$t("discounts"), tag_category: "Versand", tag_name: "Infos über Vergünstigungen per Post", tag_number: "200002",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
    valid_info_channels() {
      return this.infochannels.filter((channel) => this.valid_tag_numbers.includes(channel.tag_number));
    },
    has_info_channels() {
      return this.valid_info_channels.length > 0;
    },
  },
  created() {
    this.user.TagsPlugin.getAllPossibleTags()
      .then((tags) => {
        this.valid_tag_numbers = tags.map((tag) => tag.number);
      })
      .finally(() => {
        this.loading_tags = false;
      });
  },
  i18n: {
    messages: {
      de: {
        subscribed: "Abbonniert",
        unsubscribed: "Abbestellt",
        wage_study: "Erhebung Lohnstudie",
        uni_info: "Infos über Fachhoschulen per Post",
        events_post: "Infos über Anlässe per Post",
        events_mail: "Infos über Anlässe per E-Mail",
        inline: "INLINE",
        inlineEPaper: "INLINE E-Paper",
        discounts: "Infos über Vergünstigungen per Post",
        empty_message: "Keine Info-Kanäle vorhanden",
      },
      fr: {
        subscribed: "Abonné",
        unsubscribed: "Désabonné",
        wage_study: "Enquête sur les salaires",
        uni_info: "Informations sur les collèges par la poste",
        events_post: "Informations sur les événements par la poste",
        events_mail: "Informations sur les événements par e-mail",
        inline: "INLINE",
        inlineEPaper: "INLINE E-Paper",
        discounts: "Informations sur les réductions par e-mail",
        empty_message: "Aucun circuit d'information disponible",
      },
      it: {
        subscribed: "Abbonato",
        unsubscribed: "Annullato",
        wage_study: "Inchiesta sui salari",
        uni_info: "Informazioni sulle scuole universitarie professionali per posta",
        events_post: "Informazioni sugli eventi per posta ",
        events_mail: "Informazioni sugli eventi per E-Mail",
        inline: "INLINE",
        inlineEPaper: "INLINE E-Paper",
        discounts: "Informazioni sulle facilitazioni ai soci per posta",
        empty_message: "",
      },
    },
  },
};
</script>

import * as ADDRESS_RELATION_TYPES from "../../constants/ADDRESS_RELATION_TYPES.js";

export default {
  data() {
    return {
      ADDRESS_RELATION_TYPE_RECHNUNGSADRESSE: ADDRESS_RELATION_TYPES.RECHNUNGSADRESSE,
      ADDRESS_RELATION_TYPE_GESCHAEFTSADRESSE: ADDRESS_RELATION_TYPES.GESCHAEFTSADRESSE,
      ADDRESS_RELATION_TYPE_FIRMA: ADDRESS_RELATION_TYPES.FIRMA,
      ADDRESS_RELATION_TYPE_ARBEITGEBER: ADDRESS_RELATION_TYPES.ARBEITGEBER,
      ADDRESS_RELATION_TYPE_KONTAKT: ADDRESS_RELATION_TYPES.KONTAKT,
      ADDRESS_RELATION_TYPE_GEMERKT: ADDRESS_RELATION_TYPES.GEMERKT,
      ADDRESS_RELATION_TYPE_KORRESPONDENZ_ADDRESSE: ADDRESS_RELATION_TYPES.KORRESPONDENZ_ADDRESSE,
      ADDRESS_RELATION_TYPE_KORRESPONDENZ_NEWSLETTER: ADDRESS_RELATION_TYPES.KORRESPONDENZ_NEWSLETTER,
    };
  },
};

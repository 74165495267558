<template>
  <div>
    <page-title
      :title="$t('educational_school_psychology')"
    />
    <collapsible-group>
      <collapsible :title="$t('educational_school_psychology')">
        <edit-box
          :editable="true"
          :edit-object="user"
          @saved="fetchUser"
        >
          <template v-slot:default="boxProbs">
            <qualifications-switches-section
              :box-probs="boxProbs"
              :qualifications-channels="school_psychology_channels"
              :empty-message="school_psychology_message"
            />
          </template>
        </edit-box>
      </collapsible>
    </collapsible-group>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Collapsible from "../../components/Structure/Collabsible.vue";
import QualificationsSwitchesSection from "./section/QualificationsSwitchesSection.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import CollapsibleGroup from "../../components/Structure/CollabsibleGroup.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";

export default {
  name: "SchoolPsychology",
  path: "pages.qualifications.school_psychology",
  components: {
    PageTitle,
    CollapsibleGroup,
    EditBox,
    QualificationsSwitchesSection,
    Collapsible,
  },
  data() {
    return {
      school_psychology_channels: [
        {
          name: this.$t("diagnostics"), tag_category: "Erziehungs- und Schulpsychologie", tag_name: "Diagnostik", tag_number: "22543",
        },
        {
          name: this.$t("questions"), tag_category: "Erziehungs- und Schulpsychologie", tag_name: "Fragen rund um die Schule", tag_number: "22544",
        },
        {
          name: this.$t("group_therapy"), tag_category: "Erziehungs- und Schulpsychologie", tag_name: "Gruppentherapie", tag_number: "22545",
        },
        {
          name: this.$t("iv"), tag_category: "Erziehungs- und Schulpsychologie", tag_name: "IV-Anerkennung", tag_number: "22546",
        },
      ],
      school_psychology_message: this.$t("school_psychology_message"),
    };
  },
  computed: {
    /**
     * We map the users address from the user store module to a local computed property "user"
     */
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    /**
     * We map the action "fetchUser" from the user store module to a local method "fetchUser"
     */
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
  },
  i18n: {
    messages: {
      de: {
        educational_school_psychology: "Erziehungs- und Schulpsychologie",
        diagnostics: "Diagnostik",
        questions: "Fragen rund um die Schule",
        group_therapy: "Gruppentherapie",
        iv: "IV-Anerkennung",
        school_psychology_message: "Keine Erziehungs- und Schulpsychologie vorhanden",
      },
      fr: {
        educational_school_psychology: "Psychologie éducative et scolaire",
        diagnostics: "Diagnostics",
        questions: "Questions sur l'école",
        group_therapy: "Thérapie de groupe",
        iv: "Reconnaissance intraveineuse",
        school_psychology_message: "Aucune psychologie éducative et scolaire disponible",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div class="edit-box">
    <v-icon
      medium
      color="primary"
      v-if="editable && !editmode && !saving"
      class="edit-icon"
      @click="editmode = true"
    >
      mdi-pen
    </v-icon>
    <v-form
      v-model="form_valid"
    >
      <v-row>
        <slot
          :edit-object="editObject"
          :editmode="editmode"
          :saving="saving"
          :eventname="eventname"
        />
      </v-row>
    </v-form>
    <error-alert v-model="has_error" :message="$t('saveerror')" />
    <success-alert v-model="has_saved" :message="$t('savesuccess')" />
    <v-btn class="mb-3"
      v-if="editmode"
      color="primary"
      @click="saveClicked"
      :disabled="!form_valid"
    >
      {{$t("save")}}
    </v-btn>
    <v-btn class="mb-3"
      v-if="editmode"
      @click="cancelClicked"
      style="margin-left: 20px;"
    >
      {{$t("cancel")}}
    </v-btn>
  </div>
</template>

<script>
import ErrorAlert from "../Alerts/ErrorAlert.vue";
import SuccessAlert from "../Alerts/SuccessAlert.vue";

/**
 * The EditBox is a very abstract but super important component in this project. Its job is the following:
 * It receives the object that shall be edited as an input in "editObject". The editObject can be any
 * BomObject, like a BomAddress, a BomInstance or a BomReference.
 *
 * The EditBox passes this BomObject down to all its children within the <slot>. When the EditBox goes into
 * readonly mode, it displays an readonly-button and a cancel-button. These buttons control the editmode state.
 * The editmode-state is also passed down to all children within the <slot>.
 *
 * Further, the EditBox passes a (randomly generated) event-name down to its children.
 *
 * All children of the readonly box can write into the readonly object. When the "save-button" is clicked, the
 * editbox saves the editObject and emits a "saved" event to its children.
 *
 * When the "cancel-button" is clicked, it sends a "reset" event to all children.
 *
 * Basically, the EditBox gives the same editObject to all its children and then handles saving/cancelling.
 *
 * The EditBox also validates whether all the children have valid inputs stored. The <slot> is wrapped inside a
 * <v-form> element. Meanwhile, all Input components have some form of validation that is communicated up to
 * the v-form. Hence, the editbox only allows pressing the save button when all inputs are correctly filled.
 *
 * All children of the EditBox must implement the EditBoxChildMixin.js
 */
export default {
  name: "EditBox",
  path: "components.edit.edit_box",
  components: {SuccessAlert, ErrorAlert},
  props: {
    // The object that shall be edited, can be a BomAddress, BomInstance, BomReference, BomArticle or BomPosition
    editObject: { type: Object, required: true },
    // Whether the editbox can switch to readonly mode or not.
    editable: { type: Boolean, required: false, default: false },
    startInEditMode: { type: Boolean, default: false },
  },
  data() {
    return {
      editmode: false, // Controls whether the readonly box is currently in readonly mode
      form_valid: true, // Indicates whether all children could be validated for correctness
      saving: false, // Indicates that the editbox is currently saving its editObject
      has_error: false, // Indicates that there was an error while saving the editObject. Controls an ErrorAlert component
      has_saved: false, // Indicates that saving has finished. Controls a SuccessAlert component
      eventname: `reset-${Math.random().toString(36).substring(7)}`, // Randomly generated event name used to communicate events to all children within the <slot>
    };
  },
  created() {
    this.editmode = this.startInEditMode;
  },
  methods: {
    /**
     * When save is clicked, emmit a saving event to all children
     * and save the BomObject. Give the user feedback about the success of the saving operation
     * using the has_saved/has_error flags.
     */
    saveClicked() {
      this.saving = true;
      this.$eventBus.$emit(`${this.eventname}-save`);
      this.editObject.save()
        .then(() => {
          this.editmode = false;
          this.has_saved = true;
          this.$emit("saved", this.editObject);
        })
        .catch((err) => {
          console.error(err);
          this.has_error = true;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    /**
     * When cancel is clicked, send an event to all input items such that they can reset the
     * values to their initial state.
     */
    cancelClicked() {
      this.$eventBus.$emit(`${this.eventname}-reset`);
      this.editmode = false;
    },
  },
  i18n: {
    messages: {
      de: {
        save: "Speichern",
        cancel: "Abbrechen",
        saveerror: "Die Daten konnten nicht gespeichert werden. Bitte versuchen Sie es später erneut.",
        savesuccess: "Die Daten wurden erfolgreich gespeichert.",
      },
      fr: {
        save: "Sauver",
        cancel: "Avorter",
        saveerror: "Les données n'ont pas pu être enregistrées. Veuillez réessayer plus tard.",
        savesuccess: "Les données ont été enregistrées avec succès.",
      },
    },
  },
};
</script>

<style scoped>
  div.edit-box {
    position: relative;
    padding-top: 40px;
  }
  div.edit-box .edit-icon {
    position: absolute;
    top: 15px;
    right: 0;
    background-color: whitesmoke;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
</style>

import { get, set } from "lodash";
import EditBoxChildMixin from "./EditBoxChildMixin.js";

/**
 * The inputMixin defines an input field that lets the user readonly one field within the editObject.
 * It inherits from the EditBoxChild mixin, since an inputfield uses the functionality provided
 * by the editBox.
 * an InputField always controls / edits / updtes one field within the editObject (which is passed
 * down by the editBox).
 *
 * Mixins: EditBoxChildMixin
 */
export default {
  mixins: [EditBoxChildMixin],
  props: {
    // Field name within the editObject that shall be edited. For an address, this could be "last_name"
    field: { type: String, required: false },
    // Label that shall be displayed above the input field. For example: "Nachname"
    label: { type: String, required: true },
    // Controlls the row size on small phones. 12 = full-width
    cols: { type: String, default: "12" },
    // Controlls the row size on large phones. 6 = half-width
    sm: { type: String, default: "6" },
    // Controlls the row size on tablets and desktop. 4 = 1/3-width
    md: { type: String, default: "4" },
    showAlways: { type: Boolean, default: false },
  },
  data() {
    return {
      initial_value: "", // Holds the value that is initially saved in the given "field"
    };
  },
  computed: {
    show() {
      return (Boolean(this.value) || this.boxProbs.editmode || this.showAlways);
    },
    /**
     * The "value" compoted property is bound to the corresponding input field in the user interface
     * and reads out the current value from the editObject, but also saves changes made to the
     * input field back into the editObject.
     */
    value: {
      /**
       * Reads out the current value from the editBox according to the "field"-name
       * @returns {string|*}
       */
      get() {
        if (this.boxProbs.editObject && get(this.boxProbs.editObject, this.field)) {
          return get(this.boxProbs.editObject, this.field);
        }
        return "";
      },
      /**
       * Overwrites the editBox "field" value with the new value.
       * @param val
       */
      set(val) {
        if (this.boxProbs.editObject) {
          set(this.boxProbs.editObject, this.field, val);
        }
      },
    },
  },
  watch: {
    /**
     * When the editObject has finished loading, read out its value and set it as the initial
     * value
     */
    loading: {
      handler(now_loading) {
        if (!now_loading) {
          this.initial_value = this.value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * If the reset event is received, overwrite the editObject field with the initial value.
     */
    reset() {
      this.value = this.initial_value;
    },
  },
};

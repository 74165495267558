<template>
    <mentor-tags-section
      :title="title"
      :tags="tags"
      :box-probs="boxProbs"
    />
</template>

<script>
import MentorTagsSection from "../../MentorDetails/Sections/MentorTagsSection.vue";
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";

export default {
  name: "MentoringTagsSection",
  path: "pages.mentoring.mentoring_details.sections.mentoring_tags_section",
  components: { MentorTagsSection },
  mixins: [EditBoxChildMixin],
  props: {
    title: { type: String, required: true },
    tags: { type: Array, required: true, default: () => [] },
  },
};
</script>

<style scoped>

</style>

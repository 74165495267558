import * as TAG_NUBMERS from "../../constants/TAG_NUMBERS.js";

export default {
  data() {
    return {
      TAG_NUMBER_PROFILE_IMAGE: TAG_NUBMERS.PROFILE_IMAGE,
      TAG_NUMBER_PUBLICATION_WEB_ANONYMOUS: TAG_NUBMERS.PUBLICATION_WEB_ANONYMOUS,
      TAG_NUMBER_CV: TAG_NUBMERS.CV,
      TAG_NUMBER_ACCEPTED: TAG_NUBMERS.ACCEPTED,
      TAG_NUMBER_REJECTED: TAG_NUBMERS.REJECTED,
      TAG_NUMBER_PENDING: TAG_NUBMERS.PENDING,
      TAG_NUMBER_TEMPORARY: TAG_NUBMERS.TEMPORARY,
    };
  },
};

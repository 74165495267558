<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <!-- in components=child it is defined what should be done to execute an event (eg by entering, by changing smth etc)
     v-model: update user_search to a new parameter when we search something
     @enter: event with name "enter" is executed in the child (eg @enter @change @input etc) and execute fetchEvents -->
    <search
      v-model="user_search"
      @enter="search"
    />
    <div>
      <v-row>
        <search-date-picker
          v-model="start_date"
          :label="$t('start')"
        />
        <search-date-picker
          v-model="end_date"
          :label="$t('end')"
        />
      </v-row>
      <!-- allowed_dates="allowedDates" -->
    </div>
    <!-- @switch: event with name "switch" is executed in the child (eg @enter @change @input etc) and execute fetchEvents -->
    <all-clients-switch
      v-model="all_clients"
    />
    <search-button
      @click="search"
    />

    <!-- NEXT EVENTS -->
    <page-subtitle
      :subtitle="$t('next_event')"
      class="mt-4"
    />
    <card-group
      :loading="loading_event"
      :loading-message="$t('loading_message')"
      :empty="!next_event"
      :empty-message="$t('empty_message')"
    >
      <event-card
        v-if="next_event"
        :event="next_event"
      />
    </card-group>

    <!-- REGISTERED EVENTS -->
    <page-subtitle
      :subtitle="$t('registered_events')"
      class="mt-4"
    />
    <registered-event-section/>

    <!-- FURTHER EVENTS -->
    <page-subtitle
      :subtitle="$t('title')"
      class="mt-4"
    />
    <card-group
      :loading="loading_events"
      :loading-message="$t('loading_message')"
      :empty="events.length === 0"
      :empty-message="$t('empty_message')"
      @lazy-loading="onIntersect"
    >
      <event-card
        v-for="event in events"
        :key="event.id"
        :event="event"
      />
    </card-group>
  </div>
</template>

<script>
import add from "date-fns/add/index.js";
import set from "date-fns/set/index.js";
import SearchDatePicker from "../../components/SearchInputs/SearchDatePicker.vue";
import PageSubtitle from "../../components/UI-Elements/PageSubtitle.vue";
import RegisteredEventSection from "./components/RegisteredEventSection.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import Search from "../../components/SearchInputs/Search.vue";
import AllClientsSwitch from "../../components/SearchInputs/AllClientsSwitch.vue";
import SearchButton from "../../components/SearchInputs/SearchButton.vue";
import CardGroup from "../../components/Cards/CardGroup.vue";
import EventCard from "../../components/Cards/EventCard.vue";

export default {
  name: "Events",
  path: "pages.events.event_search",
  components: {
    RegisteredEventSection,
    PageSubtitle,
    EventCard,
    CardGroup,
    SearchButton,
    AllClientsSwitch,
    Search,
    PageTitle,
    SearchDatePicker,
  },
  created() {
    this.fetchEvents();
    this.fetchNextEvent();
  },
  data() {
    return {
      events: [],
      next_event: undefined,
      loading_events: false,
      loading_event: false,
      event_search: {}, // the sought for events that are being displayed
      total_number_of_events: undefined,
    };
  },
  methods: {
    search() {
      this.events = [];
      this.fetchEvents();
      this.fetchNextEvent();
    },
    fetchEvents() {
      this.loading_events = true;
      let bom_query = this.$bom.event.instances.all // bom-framework
        .skip(this.events.length) // skip the amount of events that have already been loaded
        .take(6) // take 6 events from bom
        .startingAfter(this.start_date)
        .endingBefore(this.end_date)
        .sortedBy("from")
        .including("AddressRelationsPlugin");

      if (this.all_clients) { // when the user change the "switcher" to "zu allen Events schweizweit"
        bom_query = bom_query.onAllClients();
      }
      if (this.user_search) { // when the user enter something in the search componente
        bom_query = bom_query.contains(this.user_search);
      }

      bom_query.fetch()
        .then(({
          instances,
          items_in_database,
        }) => {
          this.total_number_of_events = items_in_database;
          this.events = this.events.concat(instances);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_events = false; // put it as false so that it will not load anymore events
        });
    },
    fetchNextEvent() {
      const today = set(new Date(), {
        minutes: 0,
        seconds: 0,
        miliseconds: 0,
      });
      this.loading_event = true;
      this.$bom.event.instances.all // bom-framework
        .take(1) // take the next event (only 1)
        .startingAfter(today)
        .sortedBy("from")
        .including("AddressRelationsPlugin")
        .fetch()
        .then(({
          instances,
        }) => {
          this.next_event = instances[0];
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_event = false; // put it as false so that it will not load anymore events
        });
    },
    onIntersect() {
      if (!this.loading_events && this.events.length < this.total_number_of_events) {
        this.fetchEvents();
      }
    },
  },
  computed: {
    user_search: {
      get() {
        return this.$route.query.search_term || "";
      },
      set(term) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search_term: term,
          },
        });
      },
    },
    all_clients: {
      get() {
        return this.$route.query.show_all_events === "true";
      },
      set(choice) {
        if (choice) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              show_all_events: "true",
            },
          });
        } else {
          this.$router.replace({
            query: {
              ...this.$route.query,
              show_all_events: "false",
            },
          });
        }
      },
    },
    start_date: {
      get() {
        const query_data = this.$route.query.start;
        if (query_data) {
          return new Date(query_data);
        }
        return set(new Date(), {
          minutes: 0,
          seconds: 0,
          miliseconds: 0,
        });
      },
      set(date) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            start: date.toISOString()
              .substring(0, 10),
          },
        });
      },
    },
    end_date: {
      get() {
        const query_data = this.$route.query.end;
        if (query_data) {
          return new Date(query_data);
        }
        return add(set(new Date(), {
          minutes: 0,
          seconds: 0,
          miliseconds: 0,
        }), {years: 1});
      },
      set(date) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            end: date.toISOString()
              .substring(0, 10),
          },
        });
      },
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Events",
        loading_message: "Events werden geladen",
        empty_message: "Keine Events gefunden",
        start: "Suche von...",
        end: "...bis",
        next_event: "Nächster Event",
        registered_events: "Angemeldete Events",
      },
      fr: {
        title: "Événement",
        loading_message: "Les événements sont en cours de chargement",
        empty_message: "Aucun événement suivant trouvé",
        start: "Rechercher de...",
        end: "...jusqu'à",
        next_event: "Événement suivant",
        registered_events: "Événements inscrit",
      },
    },
  },
};
</script>

<style scoped>

</style>

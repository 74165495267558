<template>
  <v-expansion-panels accordion>
    <slot />
  </v-expansion-panels>
</template>

<script>
export default {
  name: "CollapsibleGroup",
  path: "components.structure.collabsible_group",
};
</script>

<style scoped>

</style>

<template>
  <div>
    <page-title
      :title="$t('become_mentor')"
    />
    <div v-html="description"/>
    <reg-mask
      :load-regmask-for="registration"
      :loading-progress.sync="loadingProgress"
      :success-message="$t('success_message')"
      @saved="onSaved"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import RegMask from "../../components/RegMask/RegMask.vue";

export default {
  name: "BecomeMentor",
  path: "pages.mentoring.become_mentor",
  components: {RegMask, PageTitle},
  data: () => ({
    mentor: {},
    registration: {},
    loadingProgress: 0,
    description: "",
    prev_client: undefined,
  }),
  created() {
    this.prev_client = this.$bom.client;
    this.$bom.client = {
      client_name: "fhmentoring",
      client_id: "26",
    };
    this.$bom.mentoring.instances.all
      .onClient("26")
      .withTags("29422")
      .including("ReferencesPlugin")
      .take(1)
      .fetch()
      .then(({instances}) => {
        this.loadingProgress = 10;
        this.mentor = instances[0];
        this.description = instances[0].description;
        return this.mentor.ReferencesPlugin.createAndSave(this.user.id, {client_id: "26"});
      })
      .then((reference) => {
        this.loadingProgress = 15;
        this.$nextTick().then(() => { this.registration = reference; });
      });
  },
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    ...mapActions("mentoring", {
      fetchOpenMentorRegistration: "fetchOpenMentorRegistration",
    }),
    onSaved() {
      new Promise((resolve) => setTimeout(resolve, 3000))
        .then(() => {
          return this.fetchOpenMentorRegistration();
        })
        .then(() => {
          this.$router.push({name: "home"});
        });
    },
  },
  beforeDestroy() {
    this.$bom.client = this.prev_client;
  },
  i18n: {
    messages: {
      de: {
        become_mentor: "Mentor/-in werden",
        success_message: "Anmeldung erfolgreich! Sie erhalten eine Benachrichtigung, sobald ihre Anfrage akzeptiert wurde.",
      },
      fr: {
        become_mentor: "Devenir un mentor",
        success_message: "Inscription réussie! Vous recevrez une notification dès que votre demande aura été acceptée.",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <fragment>
      <text-input
        field="search_address_relation.company_name"
        :label="$t('search_address_relation.company_name')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address_relation.city"
        :label="$t('search_address_relation.city')"
        :box-probs="boxProbs"
      />
      <text-input
        field="search_address_relation.company_function"
        :label="$t('search_address_relation.company_function')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_relation.company_tag_id"
        tag-category="Branche"
        module="addresses"
        :label="$t('search_address_relation.company_tag_id')"
        :box-probs="boxProbs"
      />
      <tag-number-input
        field="search_address_relation.tag_ids"
        tag-category="Firmenposition"
        module="addresses"
        :label="$t('search_address_relation.tag_ids')"
        :box-probs="boxProbs"
      />
    </fragment>
</template>

<script>
import SearchSectionMixin from "../../../mixins/SearchSectionMixin.js";
import TextInput from "../../../components/Edit/EditBoxChildren/TextInput.vue";
import TagNumberInput from "../../../components/Edit/EditBoxChildren/TagNumberInput.vue";

export default {
  name: "BusinessSearchSection",
  path: "pages.whoswho.sections.business_search_section",
  components: {TagNumberInput, TextInput},
  mixins: [SearchSectionMixin],
  i18n: {
    messages: {
      de: {
        search_address_relation: {
          city: "Arbeitsort",
          tag_ids: "Position in der Firma",
          tags: {
            position: "Position in der Firma",
          },
          company_name: "Firma",
          company_tag_id: "Branchee",
          company_function: "Funktion in der Firma",
        },
      },
      fr: {
        search_address_relation: {
          city: "Lieu de travail",
          tag_ids: "Position",
          tags: {
            position: "Position",
          },
          company_name: "Société",
          company_tag_id: "Secteur",
          company_function: "Fonction",
        },
      },
    },
  },
};
</script>

<style scoped>

</style>

/* eslint-disable no-shadow */
import bom from "../../plugins/bom.js";
import {TEMPORARY} from "../../constants/TAG_NUMBERS.js";

const state = () => ({
  become_mentor_instance: undefined,
  open_mentor_registration: undefined,
  own_mentor_instance: undefined,
  own_mentoring_references: undefined,
});

const getters = {
  /**
   * Determines whether the user has an open registration to become a mentor
   * @param state
   * @returns {boolean}
   */
  hasOpenMentorRegistration(state) {
    return !!state.open_mentor_registration;
  },
  /**
   * Determines whether the currently logged-in user is a mentor or not
   * @param state
   * @returns {boolean}
   */
  getIsMentor(state) {
    return !!state.own_mentor_instance;
  },
  getBecomeMentorInstance(state) {
    return state.become_mentor_instance;
  },
  getOpenMentorRegistration(state) {
    return state.open_mentor_registration;
  },
  getOwnMentorInstance(state) {
    return state.own_mentor_instance;
  },
  getIsMentee(state) {
    return state.own_mentoring_references && state.own_mentoring_references.length > 0;
  },
};

const mutations = {
  /**
   * Sets the become mentor instance
   * @param state
   * @param become_mentor_instance
   */
  _setBecomeMentorInstance(state, become_mentor_instance) {
    state.become_mentor_instance = become_mentor_instance;
  },
  /**
   * Sets the users open mentor registration
   * @param state
   * @param open_mentor_registration
   */
  _setOpenMentorRegistration(state, open_mentor_registration) {
    state.open_mentor_registration = open_mentor_registration;
  },
  /**
   * Sets the users own mentor instance
   * @param state
   * @param own_mentor_instance
   * @private
   */
  _setOwnMentorInstance(state, own_mentor_instance) {
    state.own_mentor_instance = own_mentor_instance;
  },
  /**
   * Set users mentoring references, shows that he is a mentee
   * @param state
   * @param own_mentoring_references
   * @private
   */
  _setOwnMentoringReferences(state, own_mentoring_references) {
    state.own_mentoring_references = own_mentoring_references;
  },
};

const actions = {
  async fetchBecomeMentorInstance({ commit }) {
    const { instances } = await bom.mentoring.instances.all
      .onClient("26")
      .withTags("29422")
      .take(1)
      .fetch();
    const instance = instances[0];
    commit("_setBecomeMentorInstance", instance);
    return instance;
  },
  async fetchOpenMentorRegistration({ state, commit }) {
    const id = state.become_mentor_instance?.id;
    const { references } = await bom.mentoring.references.all
      .ofLoggedInUser()
      .onClient("26")
      .withoutTags(TEMPORARY)
      .take(1)
      .toInstance(id)
      .fetch();
    const reference = references[0];
    commit("_setOpenMentorRegistration", reference);
    return reference;
  },
  async fetchOwnMentorInstance({ commit, rootState }) {
    const { instances } = await bom.mentoring.instances.all
      .onClient("26")
      .endingAfter("today")
      .startingBefore("today")
      .including("AddressRelationsPlugin")
      .fetch();
    const instance = instances.find((inst) => {
      return inst.AddressRelationsPlugin?.Verantwortlich?.find((adr_rel) => {
        return adr_rel.address.id === rootState.user.user_id;
      });
    });
    commit("_setOwnMentorInstance", instance);
    return instance;
  },
  async fetchOwnMenteeRegistrations({ commit }) {
    const { references } = await bom.mentoring.references.all
      .onClient("26")
      .withoutTags(TEMPORARY)
      .ofLoggedInUser()
      .take(1)
      .fetch();
    commit("_setOwnMentoringReferences", references);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/**
 * The AddressSelectorMixin shares functionality between EditBoxChildren that let the user select
 * an address which is then set as an address relation.
 * Example: We want to ask the user to which of his addresses fhconnect shall send the yearly membership bill to.
 * We therefore use an AddressSelectorMixin and parametrize the relationName to "Rechnugnsadresse" and the
 * relation number to the corresponding number "XXX". The selectable Type Number is [4450] since we want
 * that the user can only select form his CompanyAddresses, and "4450" is the relation number for CompanyAddress.
 * The user can then select form one of his CompanyAddresses and the selected address is set as a new AddressRelation
 * "RechnungsAdresse".
 */
export default {
  props: {
    // The name of the address relation to which the selected address shall be saved to
    // Example:"Firma"
    relationName: {type: String, required: true},
    // The number of the address relation to which the selected address shall be saved to
    // Example: "4450" (corresponds to "Firma")
    relationNumber: {type: String, required: true},
    // This parameter controlls which addresses are available for picking. The TypeNumber describes
    // an address relation type.
    selectableTypeNumbers: {type: Array, required: true},
    // Filter value that controls how the address is being displayed within the selector.
    // The available parameters are switch-options of the "this.display" method
    showAddress: {type: String, default: "summary"},
  },
  data() {
    return {
      items: [], // Addresses that can be selected
      initial_address: undefined, // Address that is initially selected.
      initial_address_relation: undefined,
      new_created_address_relation: undefined,
    };
  },
  computed: {
    address_relation() {
      return this.boxProbs?.editObject?.AddressRelationsPlugin?.all.find((adr_rel) => {
        return adr_rel.type === this.relationName && !adr_rel.to || adr_rel.to > new Date();
      });
    },
    /**
     * Reads out the address that is currently saved as an address-relation of the type- and number
     * that is to be set through the AddressSelector
     * @returns {undefined|BomAddress}
     */
    address() {
      return this.address_relation?.address;
    },
    /**
     * The value computed property is called whenever a new address is selected from the swtich.
     */
    value: {
      /**
       * The activate address is the one that is set within the address-relation
       * @returns {*}
       */
      get() {
        return this.address;
      },
      /**
       * When a new value is selected, either create a new address relation for the given type
       * or overwrite the existing one.
       * @param address_id
       */
      set(address_id) {
        const new_address = this.items.find((adr) => adr.id === address_id);
        if (!this.new_created_address_relation) {
          if (this.address_relation) {
            this.address_relation.end();
          }
          this.new_created_address_relation = this.boxProbs.editObject.AddressRelationsPlugin.create(
            this.relationNumber,
            this.relationName,
            new_address,
            new Date(),
          );
        } else {
          this.address_relation.address = new_address;
        }
      },
    },
  },
  methods: {
    /**
     * On reset, either overwrite the address relation with the initial address or remove the
     * newly created address relation.
     */
    reset() {
      if (!this.address) {
        return;
      }
      if (this.new_created_address_relation) {
        this.boxProbs.editObject.AddressRelationsPlugin.all.pop();
        this.new_created_address_relation = undefined;
      }
      if (this.initial_address_relation) {
        this.initial_address_relation.to = null;
      }
    },
    /**
     * This method controls how the addresses are displayed within the selector.
     * @param address
     * @returns {*}
     */
    display(address) {
      switch (this.showAddress) {
        case "summary": {
          return this.$options.filters.summary(address);
        }
        case "fullname": {
          return this.$options.filters.fullname(address);
        }
        case "companyname": {
          return this.$options.filters.companyname(address);
        }
        case "summary_with_mail": {
          return this.$options.filters.summary_with_mail(address);
        }
        case "summary_only_mail": {
          return this.$options.filters.summary_only_mail(address);
        }
        default: {
          return address[this.showAddress];
        }
      }
    },
  },
};

import {mapGetters} from "vuex";

/**
 * AddRemoveAddressRelation Mixin adds a person to the noticed contacts or to the saved contacts list
 */

export default {
  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },
  methods: {
    addressRelationExists(address_relation_type) {
      if (!this.user.AddressRelationsPlugin[address_relation_type]) {
        return false;
      }
      return this.user.AddressRelationsPlugin.all.some((address_relation) => {
        return address_relation.address.id === this.address.id && address_relation.type === address_relation_type;
      });
    },
    addAddressToAddressRelation(address_relation_type) {
      const address_relation_type_map = {Gemerkt: this.ADDRESS_RELATION_TYPE_GEMERKT, Kontakt: this.ADDRESS_RELATION_TYPE_KONTAKT};
      this.user.AddressRelationsPlugin.create(address_relation_type_map[address_relation_type], address_relation_type, this.address, new Date());
    },
    removeAddressFromAddressRelation(address_relation_type) {
      const adr_rel_to_be_removed = this.user.AddressRelationsPlugin[address_relation_type].find((adr_rel) => adr_rel.address.id === this.address.id);
      if (adr_rel_to_be_removed) {
        this.user.AddressRelationsPlugin.remove(adr_rel_to_be_removed);
      }
    },
  },
};

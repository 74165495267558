<template>
  <v-app-bar
    fixed
    color="primary"
    elevate-on-scroll
    dark
    app
  >
    <v-app-bar-nav-icon
      v-if="logged_in && client"
      @click="$emit('drawer')"
    ></v-app-bar-nav-icon>

    <router-link :to="{name: 'home'}" class="home-link">
      <img
        :src="logo"
        alt="Logo"
        id="logo"
      >
    </router-link>

    <v-spacer></v-spacer>

    <language-switch />
    <client-switch v-if="logged_in && user"/>
    <logout v-if="logged_in"/>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSwitch from "./TopbarComponents/LanguageSwitch.vue";
import Logout from "./TopbarComponents/Logout.vue";
import ClientSwitch from "./TopbarComponents/ClientSwitch.vue";
import logo from "../../assets/images/logos/fhconnect.png";

/**
 * The topbar shows the fhconnect logo as well as the langauge-switch, client-switch and logout button.
 */
export default {
  name: "Topbar",
  path: "components.navigation.topbar",
  components: { ClientSwitch, Logout, LanguageSwitch },
  data() {
    return {
      logo: logo, // Application logo in base64
    };
  },
  computed: {
    /**
     * Map the users login state from the auth store module to a local computed property
     */
    ...mapGetters("auth", {
      logged_in: "isLoggedIn",
    }),
    /**
     * Map the user and the selected client from the user store module to local computed properties
     */
    ...mapGetters("user", {
      user: "getUser",
      client: "getClient",
    }),
  },
};
</script>

<style scoped>
  a.home-link {
    width: 100%;
  }
  img#logo {
    margin-left: 10px;
    margin-top: 5px;
    width: 195px;
    max-width: 90%;
  }
</style>

export default {
  props: {
    query: { type: Object, required: true },
  },
  computed: {
    boxProbs() {
      return {
        editObject: this.query,
        editmode: true,
        saving: false,
        eventname: "extended-search",
      };
    },
  },
};

<template>
  <div>
    <page-title
      :title="$t('title')"
    />
    <edit-box
      :start-in-edit-mode="inEditMode"
      :editable="!readOnly"
      :edit-object="education"
    >
      <template v-slot:default="boxProbs"> <!-- {editObject: Object, editmode: Boolean, saving: Boolean, eventname: String} -->
        <tag-selector
          :box-probs="boxProbs"
          :label="$t('subject')"
          tag-category="Fachrichtung"
        />
        <tag-selector
          :box-probs="boxProbs"
          :label="$t('specialization')"
          tag-category="Vertiefungsrichtung"
        />
        <tag-selector
          :box-probs="boxProbs"
          :label="$t('level')"
          tag-category="Ausbildungs-Stufe"
        />
        <address-selector
          :box-probs="boxProbs"
          :label="$t('location')"
          relation-name="Ort"
          relation-number="21641"
          show-address="last_name"
          :selectable-type-numbers="[21641]"
        />
        <text-input
          :box-probs="boxProbs"
          :label="$t('thesis')"
          :max-length="$config.LINE_INPUT_MAX_LENGTH"
          field="info3"
        />
        <v-row class="ma-0">
          <year-picker
            :box-probs="boxProbs"
            :label="$t('start')"
            :max-date="endDate"
            field="from"
          />
          <year-picker
            :box-probs="boxProbs"
            :label="$t('end')"
            :min-date="startDate"
            field="to"
          />
        </v-row>
      </template>
    </edit-box>
    <br>
    <delete-button
      v-if="!readOnly"
      :deleted="deleted"
      :deleting="deleting"
      @delete="deleteEducation"
    />
  </div>
</template>

<script>
import YearPicker from "../../components/Edit/EditBoxChildren/YearPicker.vue";
import TextInput from "../../components/Edit/EditBoxChildren/TextInput.vue";
import PageTitle from "../../components/UI-Elements/PageTitle.vue";
import EditBox from "../../components/Edit/EditBox.vue";
import TagSelector from "../../components/Edit/EditBoxChildren/TagSelector.vue";
import AddressSelector from "../../components/Edit/EditBoxChildren/AddressSelector.vue";
import DeleteButton from "../../components/UI-Elements/DeleteButton.vue";
import DetailPageMixin from "../../mixins/DetailPageMixin.js";

/**
 * The education page displays information about an education (ausbildung).
 */
export default {
  name: "Education",
  path: "pages.education.education",
  mixins: [DetailPageMixin],
  components: {
    YearPicker,
    DeleteButton,
    AddressSelector,
    TagSelector,
    EditBox,
    PageTitle,
    TextInput,
  },
  data() {
    return {
      education: {}, // The education that is being displayed here
    };
  },
  /**
   * When created, fetch the education reference by the ID that can be extracted from the URL.
   * The this.id variable is defined in the mixin.
   */
  created() {
    this.$bom.education.references.one
      .withId(this.id)
      .including("TagsPlugin", "AddressRelationsPlugin")
      .fetch()
      .then(({reference}) => {
        this.education = reference;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  computed: {
    readOnly() {
      return this.$route.meta.readOnly;
    },
    inEditMode() {
      return this.$route.query.editmode === "true";
    },
    endDate() {
      return this.education?.to;
    },
    startDate() {
      return this.education?.from;
    },
  },
  methods: {
    /**
     * If an education shall be deleted, we actually delete the according reference. We can't just
     * end it like we did for CompanyAddresses, since the "end"-date of the reference already indicates
     * the graduation date.
     */
    deleteEducation() {
      this.deleting = true;
      this.education.delete()
        .then(() => {
          this.deleted = true;
        });
    },
  },
  i18n: {
    messages: {
      de: {
        title: "Meine Ausbildung",
        subject: "Fachrichtung",
        specialization: "Vertiefungsrichtung",
        level: "Ausbildungsstufe",
        location: "Studienort",
        start: "Beginn",
        end: "Ende",
        thesis: "Diplomarbeit",
      },
      fr: {
        title: "Ma formation",
        subject: "Domaine",
        specialization: "Spécialisation",
        level: "Niveau de formation",
        location: "Lieu d'études",
        start: "Début",
        end: "Fin",
        thesis: "Travail de diplôme",
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <input-wrapper>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </input-wrapper>
</template>

<script>
import {mapGetters} from "vuex";
import InputWrapper from "../Edit/EditBoxChildren/InputWrapper.vue";

/**
 * The DatePicker component lets the user choose a date. It opens up the current months, and you can either directly
 * choose a day in the current month, switch back or forward a couple months, or click on the year and change the year,
 * which then makes you to choose the month and the day as well.
 *
 */
export default {
  name: "SearchDatePicker",
  path: "components.search_inputs.search_date_picker",
  components: {InputWrapper},
  props: {
    // Label that shall be displayed above the input field. For example: "Nachname"
    label: { type: String, required: true },
    value: { type: Date, required: true },
  },
  data: () => ({
    menu: false, // Controls whether the datepicker is open and active
  }),
  computed: {
    date: {
      /**
       * Return the date that is saved inside "this.value" as a string in the form "2019-01-28"
       * @returns {string|null}
       */
      get() {
        if (this.value) {
          return this.value.toISOString().substr(0, 10);
        }
        return null;
      },
      /**
       * If a new date is assigned, assign it to this.value but ensure that it is a JavaScript Date
       * object and not a string. This step is needed since the datepicker returns a string, but
       * we actually want to save a Date object into the editObject.
       * @param {Date} date - returns the date saved by the user in the datepicker
       */
      set(date) {
        if (date instanceof Date) {
          this.$emit("input", date);
        } else {
          this.$emit("input", new Date(date));
        }
      },
    },
  },
};
</script>

<style scoped>

</style>

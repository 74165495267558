<template>
  <div>
    <h4 class="text--black font-weight-bold mt-4" :class="{'text--primary': valid}">{{ group.title }}</h4>
    <v-checkbox
      class="ma-0"
      :color="valid ? 'primary' : 'black'"
      v-for="(option, index) in group.options"
      :key="option.id"
      :label="option.name"
      :true-value="true"
      :false-value="false"
      :disabled="loading"
      :input-value="checkboxes_selected[index]"
      @change="onOptionClick(option, index)"
    />
    <p
      v-for="error in errors"
      :key="error"
      class="error--text pa-0 ma-0 font-weight-light font-italic body-2"
    >
      {{error}}
    </p>
    <v-divider class="mt-2"/>
  </div>
</template>

<script>
import { minValue, maxValue, required } from "vuelidate/lib/validators/index.js";
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMCheckBoxGroup",
  path: "components.reg_mask.reg_mask_field.r_m_check_box_group",
  mixins: [RegMaskFieldMixin],
  data: () => ({
    number_of_selected: 0,
    checkboxes_selected: [],
    checkboxes_loading: [],
  }),
  computed: {
    min_selected() {
      return this.group.min_select;
    },
    max_selected() {
      return this.group.max_select;
    },
    errors() {
      const errors = [];
      const field = this.$v.number_of_selected;
      const params = field.$params;
      const value = field.$model;
      if (field.$invalid) {
        if (!field.minValue) {
          errors.push(this.$t("min_selected", { min: params.minValue.min, selected: value }));
        }
        if (!field.maxValue) {
          errors.push(this.$t("max_selected", { max: params.maxValue.max, selected: value }));
        }
      }
      return errors;
    },
    loading() {
      return this.checkboxes_loading.some((loading) => loading);
    },
  },
  methods: {
    syncShadowState() {
      this.group.options.forEach((option, index) => {
        this.$set(this.checkboxes_selected, index, option.selected);
        this.$set(this.checkboxes_loading, index, false);
      });
      this.number_of_selected = this.group.number_of_selected;
    },
    onOptionClick(option, index) {
      let promise;
      this.$set(this.checkboxes_loading, index, true);
      if (option.selected) {
        promise = option.deselect();
      } else {
        promise = option.select();
      }
      promise.then(() => {
        this.syncShadowState();
      });
    },
  },
  validations() {
    return {
      number_of_selected: {
        ...(this.group.required && { required }),
        minValue: minValue(this.min_selected),
        maxValue: maxValue(this.max_selected),
      },
    };
  },
  i18n: {
    messages: {
      de: {
        min_selected: "Es wurden zuwenige Optionen ausgewählt ({selected}/{min})",
        max_selected: "Es wurden zuviele Optionen ausgewählt ({selected}/{max})",
      },
      fr: {
        min_selected: "Trop peu d'options ont été sélectionnées ({selected}/{min})",
        max_selected: "Trop d'options ont été sélectionnées ({selected}/{max})",
      },
    },
  },
};
</script>

<style scoped>
  div.v-input--checkbox >>> div.v-messages {
    height: 0 !important;
    position: absolute;
  }
</style>

<template>
  <input-wrapper v-show="show">
    <v-select
      v-model="value"
      :items="items"
      item-text="name"
      item-value="value"
      :label="label"
      required
      :readonly="!boxProbs.editmode"
      :loading="loading || items.length === 0"
      :disabled="boxProbs.editmode && readonly"
    />
  </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

/**
 * The dropdown auto-input component asks the bom-framework for a specific input field (like: country)
 * what values are even possible to be set (which is defined by the bom-backend).
 * The valid options (like "Switzerland", "Germany") are then saved as values from which the user
 * can choose using a dropdown.
 * https://vuetifyjs.com/en/components/selects/
 *
 * Mixins: InputMixin
 */
export default {
  name: "DropdownAutoInput",
  path: "components.edit.edit_box_children.dropdown_auto_input",
  components: {InputWrapper},
  mixins: [InputMixin],
  data() {
    return {
      items: [], // Possible choices from which the user can select one
    };
  },
  /**
   * On created, ask the bom-framework what options there are for the specified field and save
   * the options to "this.items".
   * Note that the v-select component expects an array of object in the form {name: string, value: string},
   * but the getSpecificationsOf() bom-framework function returns an array of strings [string, string, ...],
   * so we need to map it into the correct form first before assigning it to "this.items".
   */
  created() {
    this.boxProbs.editObject.getSpecificationsOf(this.field)
      .then((field_definition) => {
        this.items = field_definition.options.map((option) => ({name: option, value: option}));
      });
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <card-group>
      <menu-card
        v-for="(menu_item, index) in menu_items"
        :key="menu_item.title + index"
        :title="menu_item.title"
        :image="menu_item.image"
        :links="menu_item.links"
      />
      <fragment v-if="loaded_ads">
        <full-image-card
          v-for="ad in ads"
          :key="ad.info2"
          :link="ad.info2"
          :image="ad.ArchiveRelationsPlugin.all[0].archive.FilePlugin.download_url"
        />
      </fragment>

    </card-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {BECOME_MENTOR} from "@/constants/MENTOR";
import FullImageCard from "../components/Cards/FullImageCard.vue";
import MenuCard from "../components/Cards/MenuCard.vue";
import CardGroup from "../components/Cards/CardGroup.vue";

import group_image from "../assets/images/menu/group.jpg";
import happenings_image from "../assets/images/menu/talk.jpg";
import service_image from "../assets/images/menu/write.jpg";
import mentoring_image from "../assets/images/menu/mentoring.jpg";
import marketplace_image from "../assets/images/menu/mentoring.jpg";
import news_image from "../assets/images/menu/news.jpg";


/**
 * The home-page shows generates menu cards according to the "menu_items" data structure.
 */
export default {
  name: "Home",
  path: "pages.home",
  components: {
    FullImageCard,
    CardGroup,
    MenuCard,
  },
  data() {
    return {
      ads: [],
      loaded_ads: false,
      ad_img: "",
      ad_url: "",
    };
  },
  created() {
    /**
     * On created fetch all advertisements present on the current client that start before and end
     * after today. Filter all ads to get the ones with "Werbung Unten". If ads were found call
     * selectRandomAd() to select a random ad to display.
     */
    this.$bom.advertisement.references.all
      .onClient(this.client.client_id)
      .startingBefore(new Date())
      .endingAfter(new Date())
      .including("ArchiveRelationsPlugin")
      .fetch()
      .then(({references}) => {
        this.ads = references.filter((ad) => {
          return ad.name === "Werbung Unten";
        });
        this.loadAd();
        /* if (this.ads.length > 0) {
          this.selectRandomAd();
        } */
      });
  },
  methods: {
    /**
     * Select a random advertisement to display on the home page and get the url and image of the
     * respective ad to display and save it under ad_img and ad_url
     */
    async selectRandomAd() {
      const random_ad = this.ads[Math.floor(Math.random() * this.ads.length)];
      const archive = random_ad.ArchiveRelationsPlugin.all[0].archive;
      await archive.loadFilePlugin();
      this.ad_url = random_ad.info2;
      this.ad_img = archive.FilePlugin.download_url;
    },

    /**
     * Loads all Ads
     */
    loadAd() {
      return Promise.all(this.ads.map((ad) => ad.ArchiveRelationsPlugin.all[0].archive.loadFilePlugin())).then(() => {
        this.loaded_ads = true;
      });
    },
  },
  computed: {
    /**
     * We map the "getUserImage" getter form the user store module to the local computed
     * property "user_image" and the "getClient" getter to use the client_id to fetch the
     * advertisements
     */
    ...mapGetters("user", {
      user_image: "getUserImage",
      client: "getClient",
    }),
    /**
     * We getters from the mentoring module
     */
    ...mapGetters("mentoring", {
      is_mentor: "getIsMentor",
      is_mentee: "getIsMentee",
      has_open_mentor_registration: "hasOpenMentorRegistration",
      mentor_instance: "getOwnMentorInstance",
    }),
    ...mapGetters("config", {
      isProductionBuild: "isProductionBuild",
      hasAddressModule: "hasAddressModule",
      hasAddressReadOnlyModule: "hasAddressReadOnlyModule",
      hasEventModule: "hasEventModule",
      hasMentorModule: "hasMentorModule",
      hasMenteeModule: "hasMenteeModule",
      hasNewsModule: "hasNewsModule",
      hasFormsModule: "hasFormsModule",
      hasMarketplaceModule: "hasMarketplaceModule",
      hasEducationModule: "hasEducationModule",
      hasFurtherEducationModule: "hasFurtherEducationModule",
      hasStudiesReadOnly: "hasStudiesReadOnly",
      hasWhosWhoModule: "hasWhosWhoModule",
      hasAlumniModule: "hasAlumniModule",
      hasMembercardModule: "hasMembercardModule",
      hasQualificationModule: "hasQualificationModule",
      hasAcademiesModule: "hasAcademiesModule",
    }),
    /**
     * Each menu item becomes its own card. The title is displayed at the top of the card, the
     * image on the left. The links are displayed as text on the right.
     */
    menu_items() {
      return [
        ...this.profileMenuItem,
        ...this.whoIsWhoMenuItem,
        ...this.eventsMenuItem,
        ...this.serviceMenuItem,
        ...this.mentoringMenuItem,
        ...this.marketplaceMenuItem,
        ...this.newsMenuItem,
        ...this.formsMenuItem,
        ...this.qualificationMenuItem,
        ...this.academiesMenuItem,
      ];
    },
    profileMenuItem() {
      const item = {
        title: this.$t("profile"),
        image: this.user_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasAddressModule) {
        item.links.push({ text: this.$t("address"), route: "profile-address", params: { client } });
      }
      if (this.hasAddressReadOnlyModule) {
        item.links.push({ text: this.$t("addressReadOnly"), route: "profile-address-readOnly", params: { client } });
      }
      if (this.hasEducationModule && this.hasFurtherEducationModule) {
        item.links.push({ text: this.$t("study"), route: "studies", params: { client } });
      }
      if (this.hasStudiesReadOnly) {
        item.links.push({ text: this.$t("studyReadOnly"), route: "studiesRO", params: { client } });
      }
      item.links.push({ text: this.$t("communication"), route: "communication", params: { client } });
      item.links.push({ text: this.$t("settings"), route: "settings", params: { client } });
      return [item];
    },
    whoIsWhoMenuItem() {
      const item = {
        title: this.$t("who"),
        image: group_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasWhosWhoModule) {
        item.links.push({ text: this.$t("search"), route: "whoswhoSearch", params: { client} });
        item.links.push({ text: this.$t("remembered"), route: "notedContacts", params: { client} });
        item.links.push({ text: this.$t("contacts"), route: "savedContacts", params: { client } });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    eventsMenuItem() {
      const item = {
        title: this.$t("happenings"),
        image: happenings_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasEventModule) {
        item.links.push({ text: this.$t("search_event"), route: "eventSearch", params: { client } });
        item.links.push({ text: this.$t("links.name.fhnews"), route: "fhnews" });
        item.links.push({ text: this.$t("links.name.fhmaster"), route: "fhmaster" });
        item.links.push({ text: this.$t("links.name.fhjobs"), route: "fhjobs" });
        item.links.push({ text: this.$t("links.name.fhlohn"), route: "fhlohn" });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    serviceMenuItem() {
      const item = {
        title: this.$t("services"),
        image: service_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasAlumniModule) {
        item.links.push({ text: this.$t("alumni_download"), route: "alumni", params: { client } });
      }
      if (this.hasMembercardModule) {
        item.links.push({ text: this.$t("membercard_download"), route: "membercard", params: { client } });
      }
      item.links.push({ text: this.$t("contact_secretary"), route: "administration", params: { client } });
      return [item];
    },

    mentoringMenuItem() {
      const item = {
        title: this.$t("mentoring"),
        image: mentoring_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasMenteeModule) {
        if (this.is_mentee) {
          item.links.push({text: this.$t("my_mentorings"), route: "menteesMentorings", params: { client } });
        }
        if (!this.is_mentor && !this.has_open_mentor_registration) {
          item.links.push({text: this.$t("become_mentor"), route: "becomeMentor", params: { client } });
        }
        item.links.push({text: this.$t("search_mentors"), route: "searchMentor", params: { client } });
      }
      if (this.hasMentorModule) {
        if (this.is_mentor) {
          item.links.push({text: this.$t("my_mentor_profile"), route: "mentorDetails", params: { client, id: this.mentor_instance.id }});
          item.links.push({text: this.$t("manage_mentees"), route: "mentorsMentorings", params: {client}});
        } else if (this.has_open_mentor_registration) {
          item.links.push({text: this.$t("review_in_progress"), route: "home", params: { client }});
        } else {
          item.links.push({text: this.$t("become_mentor"), route: "becomeMentor", params: { client }});
        }
        item.links.push({text: this.$t("mentoring_agreement"), route: "mentoringAgreement", params: { client }});
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    marketplaceMenuItem() {
      const item = {
        title: this.$t("marketplace"),
        image: marketplace_image,
        links: [],
      };
      const client = this.client.client_id;
      const link_args = { route: "marketPlace", params: { client } };
      if (this.hasMarketplaceModule) {
        item.links.push({ text: this.$t("job"), ...link_args });
        item.links.push({ text: this.$t("internship"), ...link_args });
        item.links.push({ text: this.$t("ad"), ...link_args });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    newsMenuItem() {
      const item = {
        title: this.$t("news"),
        image: news_image,
        links: [],
      };
      const client = this.client.client_id;
      const link_args = { route: "news", params: { client } };
      if (this.hasNewsModule) {
        item.links.push({ text: this.$t("project"), ...link_args });
        item.links.push({ text: this.$t("politics"), ...link_args });
        item.links.push({ text: this.$t("association"), ...link_args });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    formsMenuItem() {
      const item = {
        title: this.$t("forms"),
        image: news_image,
        links: [],
      };
      const client = this.client.client_id;
      const link_args = { route: "forms", params: { client } };
      if (this.hasFormsModule) {
        item.links.push({ text: this.$t("application_and_templates"), ...link_args });
        item.links.push({ text: this.$t("statutes_and_guidelines"), ...link_args });
        item.links.push({ text: this.$t("annual_statistics"), ...link_args });
        item.links.push({ text: this.$t("general_assembly"), ...link_args });
        item.links.push({ text: this.$t("technical_title_form"), ...link_args });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    qualificationMenuItem() {
      const item = {
        title: this.$t("qualification"),
        image: this.user_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasQualificationModule) {
        item.links.push({ text: `${this.client.client_name} ${this.$t("liability")}`, route: "liability", params: { client } });
        item.links.push({ text: this.$t("practice_permit"), route: "practicePermit", params: { client } });
        item.links.push({ text: this.$t("work_organisation_psychology"), route: "workPsychology", params: { client } });
        item.links.push({ text: this.$t("career_rehabilitation_psychology"), route: "careerPsychology", params: { client } });
        item.links.push({ text: this.$t("school_psychology"), route: "schoolPsychology", params: { client } });
        item.links.push({ text: this.$t("clinical_psychology"), route: "clinicalPsychology", params: { client } });
        item.links.push({ text: this.$t("subject_areas"), route: "subjectAreas", params: { client } });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
    academiesMenuItem() {
      const item = {
        title: this.$t("academies"),
        image: news_image,
        links: [],
      };
      const client = this.client.client_id;
      if (this.hasAcademiesModule) {
        item.links.push({ text: this.$t("search_academies"), route: "academiesSearch", params: { client } });
      }
      if (item.links.length === 0) { return []; }
      return [item];
    },
  },
  i18n: {
    messages: {
      de: {
        profile: "Mein Profil",
        study: "Studium",
        address: "Adresse",
        communication: "Kommunikation",
        who: "Who's Who",
        search: "Suchen",
        remembered: "Gemerkte Kontakte",
        contacts: "Meine Kontakte",
        happenings: "Was geht ab",
        search_event: "Events",
        registered_events: "Angemeldete Events",
        fhnews: "fhnews.ch",
        fhmaster: "fhmaster.ch",
        fhjobs: "fhjobs.ch",
        fhlohn: "fhlohn.ch",
        services: "Services",
        mentoring: "fhmentoring",
        my_mentorings: "Meine Mentorings",
        review_in_progress: "Anmeldung ist pendent",
        manage_mentees: "Mentees verwalten",
        pending_registration: "Pendente Registrierung",
        search_mentors: "Mentor/-in suchen",
        become_mentor: "Mentor/-in werden",
        my_mentor_profile: "Mein Mentoring-Profil",
        mentoring_agreement: "Mentoring-Vereinbarung",
        alumni_download: "Alumni-Ausweis",
        membercard_download: "Membercard",
        contact_secretary: "Kontakt Sekretariat",
        settings: "Kontoeinstellungen",
        visibility: "Sichtbarkeit",
        preferences: "Kontoeinstellungen",
        marketplace: "Marktplatz",
        news: "News",
        alumni: "Alumni-Ausweis",
        membercard: "Membercard",
        administration: "Kontakt Sekretariat",
        job: "Stellenangebote",
        internship: "Praktika",
        ad: "Inserate",
        project: "Projekte",
        politics: "Berufspolitik",
        association: "Verband",
        forms: "Formulare",
        application_and_templates: "Anträge & Vorlagen",
        statutes_and_guidelines: "Statuten & Richtlinien",
        annual_statistics: "Jahresberichte",
        general_assembly: "Mitgliederversammlung",
        technical_title_form: "Fachtitelformulare",
        qualification: "Qualifikationen",
        liability: "Kollektiv-Haftpflicht",
        practice_permit: "Praxisbewilligung",
        work_organisation_psychology: "Arbeits- und Organisationspsychologie",
        career_rehabilitation_psychology: "Laufbahn- und Rehabilitationspsychologie",
        school_psychology: "Erziehungs- und Schulpsychologie",
        clinical_psychology: "Klinische Psychologie",
        subject_areas: "Fachrichtungen",
      },
      fr: {
        profile: "Mon profil",
        study: "Mes études",
        address: "Adresse",
        communication: "Communication",
        who: "Carnet de contacts",
        search: "Rechercher",
        remembered: "Contacts mémorisés",
        contacts: "Mes contacts",
        happenings: "Événements actuels",
        search_event: "Événements",
        registered_events: "Événements enregistrés",
        fhnews: "fhnews.ch",
        fhmaster: "fhmaster.ch",
        fhjobs: "fhjobs.ch",
        fhlohn: "fhlohn.ch",
        services: "Services",
        mentoring: "fhmentoring",
        my_mentorings: "Mon mentorat",
        review_in_progress: "L'inscription est en attente",
        manage_mentees: "Gérer les mentorés",
        pending_registration: "En attente d'inscription",
        search_mentors: "Rechercher des mentors",
        become_mentor: "Devenez un mentor",
        my_mentor_profile: "Mon profil de mentorat",
        mentoring_agreement: "Accord de mentorat",
        alumni_download: "Confirmation d'adhésion",
        membercard_download: "Membercard",
        contact_secretary: "Contact du secrétariat",
        settings: "Réglages",
        visibility: "Visibilité",
        preferences: "Paramètres du compte",
        marketplace: "Place du marché",
        news: "News",
        alumni: "Confirmation d'adhésion",
        membercard: "Membercard",
        administration: "Contact du secrétariat",
        job: "Annonces d'emploi",
        internship: "Stages",
        ad: "Annonces publicitaires",
        project: "Projets",
        politics: "Politique professionnelle",
        association: "Association",
        forms: "Formes",
        application_and_templates: "Applications et modèles",
        statutes_and_guidelines: "Statuts et directives",
        annual_statistics: "Rapports annuels",
        general_assembly: "Assemblée générale",
        technical_title_form: "Formulaires de titre professionnel",
        qualification: "Qualifications",
        liability: "Responsabilité collective",
        practice_permit: "Licence de pratique",
        work_organisation_psychology: "Psychologie du travail et de l'organisation",
        career_rehabilitation_psychology: "Psychologie de carrière et de réadaptation",
        school_psychology: "Psychologie éducative et scolaire",
        clinical_psychology: "Psychologie clinique",
        subject_areas: "Sujets",
      },
    },
  },
};
</script>

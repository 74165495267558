<template>
  <v-app class="app-background" :style="styleVars">
    <navigation :key="`navigation-${key}`"/>
    <v-main :key="`mainapp-${key}`">
      <v-container fluid class="app-background">
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="8"
            offset-xl="2"
          >
            <vue-page-transition name="fade-in-right">
              <router-view />
            </vue-page-transition>
          </v-col>
        </v-row>
      </v-container>
      <blocking-loading />
    </v-main>
    <page-footer />
    <devbanner />
    <snackbar-service />
  </v-app>
</template>

<script>
/**
 * This is the starting point of our application. The App.vue component is always shown, while the
 * main content of our page will be rendered inside of the "<router-view />" tag.
 *
 * This component implements a neat trick to refresh the content of our whole application. Normally,
 * if you want to refresh a page, you would normally reload the whole application. Instead, we can
 * trick vue into thinking that a specific component has changed and must be de-mounted and re-mounted.
 * We do that by changing the components key, which is a unique identifier. Changing the components
 * key means that the old component will be removed and a new one will be mounted.
 * Using the event bus, we can change the key of the v-app component everytime we receive the "rerender"
 * event. This is significantly faster than reloading the page and has the advantage that the
 * vuex store etc. remain online.
 */
import {mapGetters, mapMutations} from "vuex";
import Navigation from "./components/Navigation/NavigationWrapper.vue";
import PageFooter from "./components/Footer/PageFooter.vue";
import BlockingLoading from "./components/UI-Elements/BlockingLoading.vue";
import SnackbarService from "./components/Snackbars/SnackbarService.vue";
import Devbanner from "./components/Banners/Devbanner.vue";

export default {
  name: "App",
  path: "app",
  components: {
    BlockingLoading,
    PageFooter,
    Navigation,
    SnackbarService,
    Devbanner,
  },
  data() {
    return {
      key: 0, // This key is incremented when the page shall be reloaded.
    };
  },
  computed: {
    ...mapGetters("config", {
      color: "getColor",
    }),
    styleVars() {
      return {
        "--primary": this.color,
      };
    },
  },
  watch: {
    color: {
      handler() {
        this.$vuetify.theme.themes.light.primary = this.color;
      },
      immediate: true,
    },
  },
  /**
   * On create of our application, we register the event bus and listen to the "rerender" event.
   */
  created() {
    this.$eventBus.$on("rerender", this.rerender);
    const lang = localStorage.getItem("bom_lang");
    if (lang) {
      this.setLanguage(JSON.parse(lang));
    }
  },
  /**
   * Before the app is closed, we de-register the rerender event listener.
   */
  beforeDestroy() {
    this.$eventBus.$off("rerender", this.rerender);
  },
  methods: {
    /**
     * To rerender the application, we increment the key to trigger a rerender of the v-app comopnent.
     */
    rerender() {
      const non_rerenderable_route_names = ["home", "client-chooser"];
      if (non_rerenderable_route_names.includes(this.$route.name)) {
        console.warn("Trying to rerender a preset page, abort");
        return;
      }
      this.key = `${parseInt(this.key, 10) + 1}`;
    },
    /**
     * Map the langauge setter from the language store module to a local method
     */
    ...mapMutations("language", {
      setLanguage: "setLanguage",
    }),
  },
};
</script>

<style scoped>
  .app-background {
    background-color: whitesmoke;
  }
</style>

<template>
  <v-alert
    dense
    outlined
    type="error"
    :value="value"
    @input="$emit('input', $event)"
    dismissible
  >
    {{message}}
  </v-alert>
</template>

<script>
import alert from "../../mixins/AlertMixin.js";

/**
 * The AlertError component is a red, dismissable box that shows an error message to the user.
 * It is a wrapper for https://vuetifyjs.com/en/components/alerts/
 *
 * Mixins: Alert.js
 */
export default {
  name: "ErrorAlert",
  path: "components.alerts.error_alert",
  mixins: [alert],
};
</script>

<style scoped>

</style>

<template>
  <input-wrapper v-show="show">
    <v-autocomplete
      v-model="value"
      :items="items"
      item-text="name"
      item-value="number"
      :label="label"
      auto-select-first
      :readonly="!boxProbs.editmode"
      :loading="!Array.isArray(items)"
      :disabled="boxProbs.editmode && readonly"
      placeholder=" "
    />
  </input-wrapper>
</template>

<script>
import InputMixin from "../../../mixins/InputMixin.js";
import InputWrapper from "./InputWrapper.vue";

export default {
  name: "TagSelector",
  path: "components.edit.edit_box_children.tag_selector",
  components: {InputWrapper},
  mixins: [InputMixin],
  props: {
    tagCategory: { type: String, required: true },
  },
  data() {
    return {
      items: undefined,
      initial_tag: undefined,
    };
  },
  computed: {
    tag() {
      if (this.boxProbs.editObject && this.boxProbs.editObject.TagsPlugin) {
        return this.boxProbs.editObject.TagsPlugin.all.find((tag) => {
          return tag.category === this.tagCategory;
        });
      }
      return undefined;
    },
    value: {
      get() {
        return this.tag;
      },
      set(tagnumber) {
        if (this.tag) {
          this.tag.remove();
        }
        const new_tag = this.items.find((tag) => {
          return tag.number === tagnumber;
        });
        new_tag.add();
      },
    },
  },
  methods: {
    editObjectUpdate() {
      if (!this.boxProbs.editObject || !this.boxProbs.editObject.TagsPlugin) { return; }
      this.initial_tag = this.tag;
      this.boxProbs.editObject.TagsPlugin.getAllPossibleTags(this.tagCategory)
        .then((possible_tags) => {
          this.items = possible_tags;
        });
    },
    reset() {
      if (this.tag) {
        this.tag.remove();
      }
      if (this.initial_tag) {
        this.initial_tag.add();
      }
    },
  },
};
</script>

<style scoped>

</style>

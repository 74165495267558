<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    width="800"
    overlay-color="white"
    overlay-opacity="0.9"
  >
    <div
      style="position: relative;"
    >
      <slot />
      <div
        class="close-icon"
        @click="$emit('input', false)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericModal",
  path: "components.modals.generic_modal",
  props: {
    value: {type: Boolean, required: true},
  },
};
</script>

<style scoped>
  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
</style>

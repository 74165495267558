<template>
  <p>
    RMCStaticText
  </p>
</template>

<script>
import RegMaskFieldMixin from "../../../mixins/RegMaskFieldMixin.js";

export default {
  name: "RMStaticText",
  path: "components.reg_mask.reg_mask_field.r_m_static_text",
  mixins: [RegMaskFieldMixin],
};
</script>

<style scoped>

</style>

/* eslint-disable no-shadow */
import i18n from "../../languages/i18n.js";
import bom from "../../plugins/bom.js";
/**
 * The language store handles language switching within our application. It uses
 * i18n to set a new language. Note that the actual resolving of language strings
 * happens directly using i18n, the store only helps changing the language or asking
 * which languages are available.
 */

const state = () => ({
  language: i18n.locale, // Currently set language of user interface
  available_languages: i18n.t("languages"), // List of all available languages
});

const getters = {
  /**
   * Returns current language
   * @param state
   * @returns {string}
   */
  getLanguage(state) {
    return state.language;
  },
  /**
   * Returns current language
   * @param state
   */
  getActiveLanguage(state) {
    return state.active_language;
  },
  /**
   * Returns a list of available languages in the form [{name: "Deutsch", code: "de"}, ...]
   * @param state
   * @returns {{name: string, code: string}[]}
   */
  getAvailableLanguages(state) {
    return state.available_languages;
  },
};

const mutations = {
  /**
   * Sets a new language as the currently active language. It manipulates the store but also
   * sets a new language in i18n.
   * @param state
   * @param {{code: string}} language
   */
  setLanguage(state, language) {
    localStorage.setItem("bom_lang", JSON.stringify(language));
    i18n.locale = language.code;
    state.active_language = language.code;
    state.available_languages = i18n.t("languages");
    if (window.bomconfig.switch_bom_language) {
      bom.language = language.code;
    }
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

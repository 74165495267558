<template>
  <div>
    <div
      id="progress-circle-wrapper"
    >
      <v-progress-circular
        :size="50"
        :color="color"
        indeterminate
      />
    </div>
    <br v-if="message">
    <p class="wait-message" v-if="message">
      {{message}}
    </p>
  </div>
</template>

<script>

/**
 * Displays an infinitely turning loading cycle, together with a loading message
 */
export default {
  name: "LoadingCircle",
  path: "components.ui_elements.loading_circle",
  props: {
    // Color of loading circle
    color: { type: String, default: "primary" },
    // Message to be shown under the loading circle
    message: { type: String, default: "", required: false },
  },
};
</script>

<style scoped>
  #progress-circle-wrapper {
    margin: 0 auto;
    width: 50px;
    height: 50px;
  }
  p.wait-message {
    width: 100%;
    text-align: center;
  }
</style>

<template>
  <v-row dense>
    <input-wrapper
      sm="12"
      md="6"
    >
      <div
        class="image"
      >
        <squared-image
          :image="file || defaultFile"
        />
      </div>
    </input-wrapper>
    <input-wrapper
      sm="12"
      md="6"
      v-if="boxProbs.editmode && !readonly"
    >
      <v-file-input
        show-size
        prepend-icon=""
        append-icon="mdi-upload"
        color="primary"
        :loading="loading || loading_archive"
        :label="has_file ? $t('replace') : $t('upload')"
        @change="fileSelected($event)"
      />
      <v-btn
        v-if="had_file_initially"
        @click="removeFile"
      >
        {{$t("delete")}}
        <v-icon
          right
          dark
          small
        >
          mdi-delete
        </v-icon>
      </v-btn>
    </input-wrapper>
  </v-row>
</template>

<script>
import { get } from "lodash";
import default_image from "../../../assets/images/placeholders/std-private.png";
import SquaredImage from "../../Images/SquaredImage.vue";
import { PROFILE_IMAGE_TAG } from "../../../services/ProfileImageService.js";
import InputMixin from "../../../mixins/InputMixin.js";
import ArchiveMixin from "../../../mixins/ArchiveMixin.js";
import InputWrapper from "./InputWrapper.vue";

/**
 * The ImageUploader shows the users current image to the left and lets him upload a new image.
 * The new image is saved within an Archive. Much of the functionality here is outsourced into
 * the ProfileImageService.
 *
 * Mixins: InputMixin, MapUserMixin
 */
export default {
  name: "ImageUploader",
  path: "components.edit.edit_box_children.image_uploader",
  components: {InputWrapper, SquaredImage },
  mixins: [InputMixin, ArchiveMixin],
  props: {
    // Image that shall be shown if the editObject has no user_image linked to his address
    defaultFile: { type: String, default: default_image },
    filename: { type: String, default: "image" },
    subobject: { type: String, required: false },
  },
  data: () => ({
    tag: PROFILE_IMAGE_TAG,
  }),
  computed: {
    bom_object() {
      if (!this.subobject) {
        return this.boxProbs.editObject;
      }
      return get(this.boxProbs.editObject, this.subobject);
    },
  },
  i18n: {
    messages: {
      de: {
        upload: "Profilbild hochladen",
        replace: "Profilbild ersetzen",
        delete: "Profilbild löschen",
      },
      fr: {
        upload: "Télécharger une photo de profil",
        replace: "Remplacer la photo de profil",
        delete: "Supprimer la photo de profil",
      },
    },
  },
};
</script>

<style scoped>
  div.image {
    max-width: 150px;
  }
</style>

<template>
  <fragment>
    <template v-if="newCreated">
      <text-input
        :box-probs="boxProbs"
        :label="$t('company')"
        :max-length="$config.LINE_INPUT_MAX_LENGTH"
        field="last_name"
      />
    </template>
    <template v-else>
      <dynamic-text
        :box-probs="boxProbs"
        :label="$t('company')"
        field="company_name"
      />
    </template>
    <text-input
      :box-probs="boxProbs"
      :label="$t('companyaddition')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="company_addition"
    />
    <text-input
      :box-probs="boxProbs"
      :label="$t('website')"
      :max-length="$config.LINE_INPUT_MAX_LENGTH"
      field="website"
      website
    />
    <tag-selector
      :box-probs="boxProbs"
      :label="$t('field')"
      tag-category="Branche"
    />
  </fragment>
</template>

<script>
import EditBoxChildMixin from "../../../../mixins/EditBoxChildMixin.js";
import TextInput from "../../../../components/Edit/EditBoxChildren/TextInput.vue";
import TagSelector from "../../../../components/Edit/EditBoxChildren/TagSelector.vue";
import DynamicText from "../../../../components/Edit/EditBoxChildren/DynamicText.vue";

export default {
  name: "CompanyDataSection",
  path: "pages.address.company.sections.company_data_section",
  mixins: [EditBoxChildMixin],
  components: {
    DynamicText,
    TagSelector,
    TextInput,
  },
  props: {
    newCreated: { type: Boolean, required: true },
  },
  i18n: {
    messages: {
      de: {
        company: "Firma",
        companyaddition: "Zusatz",
        website: "Webseite",
        field: "Branche",
      },
      fr: {
        company: "Entreprise",
        companyaddition: "Suplmément",
        website: "Site web",
        field: "Secteur des entreprises",
      },
    },
  },
};
</script>

<style scoped>

</style>

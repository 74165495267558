/**
 * The datepicker mixin is used for the different date pickers that use the editbox.
 */
import {mapGetters} from "vuex";

export default {
  props: {
    maxDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    minDate: {
      type: Date,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    menu: false, // Controls whether the datepicker is open and active
  }),
  watch: {
    /**
     * When the menu is opened, set the datepicker to "YEAR" mode such that the user can only
     * select the year. We have to do this every time the date picker is newly opened.
     * The function is wrapped within a setTimeout to make it asynchronously, which ensures that
     * the datepicker component is actually available within the <template> and we can manipulate it.
     * @param val
     */
    menu(val) {
      if (val) {
        setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },
  },
  computed: {
    /**
     * Map the users language from the language store module to a local computed "langauge".
     */
    ...mapGetters("language", {
      language: "getLanguage",
    }),
    maxDateString() {
      return this.maxDate?.toISOString()
        .substr(0, 10);
    },
    minDateString() {
      return this.minDate?.toISOString()
        .substr(0, 10);
    },
    /**
     * The "date" variable is dynamically resolved. When this.date = X is called, the set(X) function
     * is executed. When const a = this.date is called, the get() function is executed.
     */
    date: {
      /**
       * Return the date that is saved inside "this.value" as a string in the form "2019-01-28"
       * @returns {string|null}
       */
      get() {
        if (this.value) {
          return this.value.toISOString()
            .substr(0, 10);
        }
        return null;
      },
      /**
       * If a new date is assigned, assign it to this.value but ensure that it is a JavaScript Date
       * object and not a string. This step is needed since the datepicker returns a string, but
       * we actually want to save a Date object into the editObject.
       * @param date
       */
      set(date) {
        if (date instanceof Date) {
          this.value = date;
        } else {
          this.value = new Date(date);
        }
      },
    },
  },
};

<template>
  <image-card
    :title="address | fullname"
    :image="image"
    :action="routeTo"
  >
    <template slot="topright">
      <slot></slot>
    </template>
    <p>
      {{address.street | none }},
      {{address.postcode | none }}
      {{address.city | none }}
    </p>
    <p>
      Tel.: {{address.phone_number | none}}
    </p>
    <p>
      Mobile.: {{address.mobile_number | none}}
    </p>
    <p>
      E-Mail: {{address.mail | none}}
    </p>
    <p>
      {{`${$t("company")}: ${company_addresses}`}}
    </p>
  </image-card>
</template>
<script>
import ImageCard from "./ImageCard.vue";
import default_image from "../../assets/images/placeholders/std-private.png";
import AddressCardMixin from "../../mixins/AddressCardMixin.js";

const now = new Date();

/**
 * The person card shows the most important information about a person address. On the left, the persons
 * profile image is shown, while on the right, some information like the persons name is shown.
 * The person card is clickable and routes to the PersonAddress.vue page.
 *
 * Mixins: AddressCardMixin
 */
export default {
  name: "PersonCard",
  path: "components.cards.person_address_card",
  components: {ImageCard},
  mixins: [AddressCardMixin],
  data() {
    return {
      // The image that is shown on the left of the card.
      image: default_image,
      // Specifies where to route to when the card is clicked
      route_to: "person-address",
    };
  },
  computed: {
    /**
     * Function to fetch Companies that a user is working at. These Company-Addresses should be shown in the
     * result of the Who's who search.
     *
     * @return {VueI18n.TranslateResult}
     */
    // TODO: Michael Aschwanden has the same company_address twice, which results in showing the same company_address in the card.
    company_addresses() {
      let company_string = "";
      const unique_companies = [];
      if (this.address.AddressRelationsPlugin?.all.length > 0) {
        const address_relations = this.address.AddressRelationsPlugin.Geschäftsadresse ? this.address.AddressRelationsPlugin.Geschäftsadresse : [];
        address_relations.forEach((address_relation) => {
          if (!unique_companies.includes(address_relation.address.company_name)) {
            const already_started = !address_relation.from || address_relation.from < now;
            const not_yet_ended = !address_relation.to || address_relation.to > now;
            if (already_started && not_yet_ended) {
              unique_companies.push(address_relation.address.company_name);
            }
          }
        });
        company_string += unique_companies.join(", ");
      }
      return company_string;
    },
  },
  i18n: {
    messages: {
      de: {
        company: "Firma",
      },
      fr: {
        company: "Entreprise",
      },
    },
  },
};
</script>
<style scoped>
  p {
    margin-bottom: 4px !important;
  }

  .topcorner {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }

</style>
